import instance from './index';
import suwAPI from '../config/config'

const chatService = {

  getAllContacts() {
    return instance({ requiresAuth: true }).get(suwAPI.apiChat+'/all_conversations');
  },

  getNewContacts(search = '') {
    return instance({ requiresAuth: true }).get(suwAPI.apiChat+'/new_users?search='+search);
  },

  getSearchChats(search = '') {
    return instance({ requiresAuth: true }).get(suwAPI.apiChat+'/search_chats?search='+search);
  },

  getEmoji() {
    return instance({ requiresAuth: true }).get(suwAPI.apiChat+'/emoji');
  },

  findConversation(user) {
    return instance({ requiresAuth: true }).get(`${suwAPI.apiChat}/conversation-find/${user}`);
  },

  getConversation(data) {
    return instance({ requiresAuth: true }).get(`${suwAPI.apiChat}/conversation/${data.id}?page=${data.page}&type=${data.type}`);
  },

  getConversationFrom(data) {
    return instance({ requiresAuth: true }).get(`${suwAPI.apiChat}/conversation-from/${data.id}?search=${data.search}&message_id=${data.message_id}`);
  },

  getConversationLoadMoreFrom(data) {
    return instance({ requiresAuth: true }).get(`${suwAPI.apiChat}/conversation-load-more-from/${data.id}?type=${data.type}&message_id=${data.message_id}&page=${data.page}`);
  },

  getUserProfile(id) {
    return instance({ requiresAuth: true }).get(`${suwAPI.apiChat}/conversation-user/${id}`);
  },

  postChatMessage(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiChat+'/conversation', data);
  },

  toggleMute(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiChat+'/conversation-mute/'+ id);
  },

  setUnread(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiChat+'/conversation-unread/'+ id);
  },

  getConversationParams(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiChat+'/conversation-params/'+ id);
  },

  setRead(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiChat+'/conversation-set-read/'+ id);
  },

  delete(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiChat+'/conversation-delete', data);
  },

  putFiles(id, data) {
    let formData = new FormData();
    data.forEach(function(file) {
      formData.append('files[]', file);
    });
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiPutFiles+'/conversation/'+id, formData);
  },

  putMultiFiles(data) {
    let formData = new FormData();
    data.forEach(function(file) {
      formData.append('files[]', file);
    });
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiPutFiles, formData);
  },


}

export default chatService;