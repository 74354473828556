// import companyService from '../services/service.company'
// import axios from 'axios'

const popups = {
  state: {
    experiencePopup: false,
    educationPopup: false,
    mediaPopup: false,
    mediaCompanyPopup: false,
    viewfilePopup: false,
    viewfilePopupSrc: false,
    viewfilePopupExt: false,
    viewfilePopupWidth: 600,
    successPopup: false,
    confirmPopup: false,
    confirmRoute: false,
    confirmUserProfile: false,
    confirmCompanyProfile: false,
    confirmRemoveEvent: false,
    confirmRemovePage: false,
    confirmRemoveExp: false,
    confirmRemoveEdu: false,
    confirmRemoveMedia: false,
    confirmRemoveCompanyMedia: false,
    confirmRemovePost: false,
    confirmRemoveComment: false,
    confirmRemoveReview: false,
    confirmRemoveJob: false,
    confirmNoguards: false,
    companyLogoPopup: false,
    userEditLogoPopup: false,
    companyBannerPopup: false,
    companyRemoveMembers: false,
    previewCompanyPopup: false,
    PreviewProfilePopup: false,
    previewCompanyData: false,
    companyInviteMember: false,
    calendarEventPopup: false,
    calendarShowEventPopup: false,
    calendarShowEventPopupX: false,
    calendarShowEventPopupY: false,
    calendarEventPopupData: false,
    errorsPopup: {
      show: false,
      data: []
    },
    userReviewsPopup: false,
    userReviewAddPopup: false,
    fullFavoritesViewPopup: false,
    fullReviewsViewPopup: false,
    fullReviewAddPopup: false,
    fullReviewData: {},
    fullMessagesViewPopup: false,
    fullJobPopup: false,
    userReviewPopup: false,
    settingsPopup: false,
    pageAddPopup: false,
    pageData: {
      type: 'create',
      id: '',
      title: '',
      auto_join: '',
    },
    postAddPopup: false,
    postPersonalMessagePopup: false,
    postPersonalMessageData: false,
    postData: {
      type: 'create',
      text: '',
      post_id: '',
      files: [],
    },
    postCommentEditPopup: false,
    commentData: {
      text: '',
      comment_id: '',
      files: [],
    },
    pageInviteMembersPopup: false,
    pageRemoveMembersPopup: false,
    // Data
    experienceData: {},
    educationData: '',
    mediaData: '',
    mediaCompanyData: '',
    forumNewTopicPopup: '',
    forumTopicSuccessPopup: '',
    companyData: {},
    WhatIsManagerPopup: false,
    confirmRemoveNote: false,
    confirmClosePost: false,
    feedbackPopup: false,
  },
  mutations: {
    STATE_POPUP: (state, payload) => {
      if(payload.show) document.body.classList.add('modal-open');
      if(!payload.show) document.body.classList.remove('modal-open');
      switch (payload.popup) {
        case 'experiencePopup':
          if(payload.data) {
            state.experienceData = payload.data;
          } else {
            state.experienceData = false;
          }
          state.experiencePopup = payload.show;
          break;

        case 'educationPopup':
          if(payload.data) {
            state.educationData = payload.data;
          } else {
            state.educationData = false;
          }
          state.educationPopup = payload.show;
          break;

        case 'mediaPopup':
          if(payload.data) {
            state.mediaData = payload.data;
          } else {
            state.mediaData = false;
          }
          state.mediaPopup = payload.show;
          break;

        case 'viewfilePopup':
          if (payload.data?.type === 'link') {
              document.body.classList.remove('modal-open');
              window.open(payload.data.link, '_blank');
              return;
          }
          state.viewfilePopup = payload.show;
          state.viewfilePopupSrc = payload.data?.link;
          state.viewfilePopupExt = payload.data?.ext;
          state.viewfilePopupWidth = payload.data?.width;
          break;

        case 'successPopup':
          state.successPopup = payload.show;
          break;

        case 'confirmPopup':
          if(payload.name == "USER_PROFILE"){
            state.confirmUserProfile = payload.data;
          }
          if(payload.name == "COMPANY_PROFILE"){
            state.confirmCompanyProfile = payload.data;
          }
          if(payload.name == "REMOVE_EVENT"){
            state.confirmRemoveEvent = true;
          }
          if(payload.name == "REMOVE_PAGE"){
            state.confirmRemovePage = true;
          }
          if(payload.name == "REMOVE_EXP"){
            state.confirmRemoveExp = true;
          }
          if(payload.name == "REMOVE_EDU"){
            state.confirmRemoveEdu = true;
          }
          if(payload.name == "REMOVE_MEDIA"){
            state.confirmRemoveMedia = true;
          }
          if(payload.name == "REMOVE_COMPANY_MEDIA"){
            state.confirmRemoveCompanyMedia = true;
          }
          if(payload.name == "REMOVE_POST"){
            state.confirmRemovePost = true;
          }
          if(payload.name == "REMOVE_COMMENT"){
            state.confirmRemoveComment = true;
          }
          if(payload.name == "REMOVE_REVIEW"){
            state.confirmRemoveReview = true;
          }
          if(payload.name == "JOB_DELETE"){
            state.confirmRemoveJob = true;
          }
          if(payload.name == "NOTE_DELETE"){
            state.confirmRemoveNote = true;
          }
          if(payload.name == "CLOSE_POST"){
            state.confirmClosePost = true;
          }

          if(payload.noguards) {
            state.confirmNoguards = payload.noguards;
          } else {
            state.confirmNoguards = false;
          }

          state.confirmRoute = payload.route;
          state.confirmPopup = payload.show;
          break;

        case 'companyLogoPopup':
          state.companyLogoPopup = payload.show;
          break;

        case 'userEditLogoPopup':
          state.userEditLogoPopup = payload.show;
          break;

        case 'companyBannerPopup':
          state.companyBannerPopup = payload.show;
          break;

        case 'forumNewTopicPopup':
          state.forumNewTopicPopup = payload.show;
          break;

        case 'forumTopicSuccessPopup':
          state.forumTopicSuccessPopup = payload.show;
          break;

        case 'mediaCompanyPopup':
          if(payload.data) {
            state.mediaCompanyData = payload.data;
          } else {
            state.mediaCompanyData = {
              title: '',
              description: '',
              restrict: false
            };
          }
          state.mediaCompanyPopup = payload.show;
          break;

        case 'companyRemoveMembers':
          state.companyRemoveMembers = payload.show;
          break;

        case 'previewCompanyPopup':
          if(payload.data) {
            state.previewCompanyData = payload.data;
          } else {
            state.previewCompanyData = false;
          }
          state.previewCompanyPopup = payload.show;
          break;

        case 'PreviewProfilePopup':
          state.PreviewProfilePopup = payload.show;
          break;

        case 'companyInviteMember':
          state.companyInviteMember = payload.show;
          break;

        case 'calendarEventPopup':
          if(payload.data) {
            state.calendarEventPopupData = payload.data;
          } else {
            state.calendarEventPopupData = false;
          }
          state.calendarEventPopup = payload.show;
          break;

        case 'calendarShowEventPopup':
          state.calendarShowEventPopup = payload.show;
          state.calendarShowEventPopupX = payload.posx;
          state.calendarShowEventPopupY = payload.posy;
          // console.log(payload.posx, payload.posy);
          break;

        case 'errorsPopup':
          state.errorsPopup.show = payload.show;
          if(payload.data) {
            state.errorsPopup.data = payload.data;
          } else {
            state.errorsPopup.data = [];
          }
          break;

        case 'userReviewsPopup':
          state.userReviewsPopup = payload.show;
          break;
        case 'userReviewAddPopup':
          state.userReviewAddPopup = payload.show;
          break;
        case 'fullReviewAddPopup':
          state.fullReviewAddPopup = payload.show;
          if (payload.data) {
            state.fullReviewData = payload.data;
          } else {
            state.fullReviewData = {};
          }
          break;
        case 'fullFavoritesViewPopup':
          state.fullFavoritesViewPopup = payload.show;
          break;
        case 'fullReviewsViewPopup':
          state.fullReviewsViewPopup = payload.show;
          break;
        case 'fullMessagesViewPopup':
          state.fullMessagesViewPopup = payload.show;
          break;
        case 'fullJobPopup':
          state.fullJobPopup = payload.show;
          break;
        case 'userReviewPopup':
          state.userReviewPopup = payload.show;
          break;
        case 'settingsPopup':
          state.settingsPopup = payload.show;
          break;
        case 'pageAddPopup':
          state.pageAddPopup = payload.show;
          if (payload.data) {
            state.pageData = payload.data;
          } else {
            state.pageData = {
              type: 'create',
              id: '',
              title: '',
              auto_join: '',
            };
          }
          break;
        case 'postAddPopup':
          state.postAddPopup = payload.show;
          if (payload.data) {
            state.postData = payload.data;
          } else {
            state.postData = {
              type: 'create',
              text: '',
              post_id: '',
              files: [],
            };
          }
          break;
        case 'postCommentEditPopup':
          state.postCommentEditPopup = payload.show;
          if (payload.data) {
            state.commentData = payload.data;
          } else {
            state.commentData = {
              text: '',
              comment_id: '',
              files: [],
            };
          }
          break;
        case 'pageInviteMembersPopup':
          state.pageInviteMembersPopup = payload.show;
          break;
        case 'pageRemoveMembersPopup':
          state.pageRemoveMembersPopup = payload.show;
          break;
        case 'postPersonalMessage':
            if(payload.data) {
              state.postPersonalMessageData = payload.data;
            } else {
              state.postPersonalMessageData = false;
            }
            state.postPersonalMessagePopup = payload.show;
            break;
        case 'WhatIsManagerPopup':
          state.WhatIsManagerPopup = payload.show;
          break;
        case 'feedbackPopup':
          state.feedbackPopup = payload.show;
          break;
      }
    },

    SET_VIEWFILE_POPUP: (state, payload) => {
      state.viewfilePopupSrc = payload.link;
      state.viewfilePopupExt = payload.ext;
      state.viewfilePopupWidth = payload.width;
    },

    CLEAR_SRC: (state) => {
      state.viewfilePopupSrc = false;
      state.viewfilePopupExt = false;
      state.viewfilePopupWidth = 600;
    },

    CLOSE_ALL_POPUPS: (state) => {
      Object.keys(state).forEach(function(key) {
        if(state[key] === true) {
          console.log(key)
          state[key] = false;
        }
      });
      state.errorsPopup = {
        show: false,
        data: []
      };
      state.fullReviewData = {};
      state.pageData = {
        type: 'create',
        id: '',
        title: '',
        auto_join: '',
      };
      state.postData = {
        type: 'create',
        text: '',
        post_id: '',
        files: [],
      };
      state.commentData = {
        text: '',
        comment_id: '',
        files: [],
      };
      state.experienceData = {};
      state.companyData = {};
      state.confirmRemovePage = false;
      state.confirmPopup = false;
      state.successPopup = false;
      document.body.classList.remove('modal-open');
    },
  },
  actions: {
    async SHOW_POPUP({commit}, payload) {
      commit('STATE_POPUP', payload);
    },

    CLOSE_ALL_POPUPS({commit}) {
      commit('CLOSE_ALL_POPUPS')
    },

    VIEW_FILE_POPUP({commit}, payload) {
      commit('SET_VIEWFILE_POPUP', payload);
      /* axios({
        method: 'get',
        url: payload.link,
        responseType: 'arraybuffer',
        headers: {
          'Authorization': 'Bearer '+localStorage.access_token+''
        }
      })
      .then(function(resp) {
        var mimeType = resp.headers['content-type'].toLowerCase();
        // var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
        // var src = 'data:' + mimeType + ';base64,' + imgBase64;
        // commit('SET_VIEWFILE_POPUP', src);
        console.log('mimeType');
        console.log(mimeType);
      }).catch((function() {
        // var src = 'https://suw.abovebits.com/files/157bec0c7fa04563a01077406cc41eec.png';
        // console.log(src);
      })); */
    },
    CLEAR_SRC({commit}) {
      commit('CLEAR_SRC');
    }
  },
  getters: {

  }
}
export default popups;