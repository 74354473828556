<template>
    <div class="header__logo logo">
        <router-link :to="{name: 'cover'}">
            <img src="../assets/images/logo.png" alt="Start-Up World" />
            <!-- <svg class="logo__img " width="159" height="23" viewBox="0 0 159 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.965 7.511C7.899 7.643 7.821 7.736 7.731 7.79C7.647 7.844 7.545 7.871 7.425 7.871C7.305 7.871 7.17 7.826 7.02 7.736C6.87 7.64 6.693 7.535 6.489 7.421C6.285 7.307 6.045 7.205 5.769 7.115C5.499 7.019 5.178 6.971 4.806 6.971C4.47 6.971 4.176 7.013 3.924 7.097C3.678 7.175 3.468 7.286 3.294 7.43C3.126 7.574 3 7.748 2.916 7.952C2.832 8.15 2.79 8.369 2.79 8.609C2.79 8.915 2.874 9.17 3.042 9.374C3.216 9.578 3.444 9.752 3.726 9.896C4.008 10.04 4.329 10.169 4.689 10.283C5.049 10.397 5.415 10.52 5.787 10.652C6.165 10.778 6.534 10.928 6.894 11.102C7.254 11.27 7.575 11.486 7.857 11.75C8.139 12.008 8.364 12.326 8.532 12.704C8.706 13.082 8.793 13.541 8.793 14.081C8.793 14.669 8.691 15.221 8.487 15.737C8.289 16.247 7.995 16.694 7.605 17.078C7.221 17.456 6.75 17.756 6.192 17.978C5.634 18.194 4.995 18.302 4.275 18.302C3.861 18.302 3.453 18.26 3.051 18.176C2.649 18.098 2.262 17.984 1.89 17.834C1.524 17.684 1.179 17.504 0.855 17.294C0.537 17.084 0.252 16.85 0 16.592L0.702 15.431C0.768 15.347 0.846 15.278 0.936 15.224C1.032 15.164 1.137 15.134 1.251 15.134C1.401 15.134 1.563 15.197 1.737 15.323C1.911 15.443 2.115 15.578 2.349 15.728C2.589 15.878 2.868 16.016 3.186 16.142C3.51 16.262 3.897 16.322 4.347 16.322C5.037 16.322 5.571 16.16 5.949 15.836C6.327 15.506 6.516 15.035 6.516 14.423C6.516 14.081 6.429 13.802 6.255 13.586C6.087 13.37 5.862 13.19 5.58 13.046C5.298 12.896 4.977 12.77 4.617 12.668C4.257 12.566 3.891 12.455 3.519 12.335C3.147 12.215 2.781 12.071 2.421 11.903C2.061 11.735 1.74 11.516 1.458 11.246C1.176 10.976 0.948 10.64 0.774 10.238C0.606 9.83 0.522 9.329 0.522 8.735C0.522 8.261 0.615 7.799 0.801 7.349C0.993 6.899 1.269 6.5 1.629 6.152C1.995 5.804 2.442 5.525 2.97 5.315C3.498 5.105 4.101 5 4.779 5C5.547 5 6.255 5.12 6.903 5.36C7.551 5.6 8.103 5.936 8.559 6.368L7.965 7.511Z" fill="#00A961"/>
                <path d="M19.8114 5.144V7.133H15.8964V18.158H13.4754V7.133H9.54239V5.144H19.8114Z" fill="#00A961"/>
                <path d="M31.4661 18.158H29.5941C29.3841 18.158 29.2101 18.107 29.0721 18.005C28.9401 17.897 28.8441 17.765 28.7841 17.609L27.8121 14.954H22.4211L21.4491 17.609C21.4011 17.747 21.3051 17.873 21.1611 17.987C21.0231 18.101 20.8521 18.158 20.6481 18.158H18.7671L23.8791 5.144H26.3541L31.4661 18.158ZM23.0421 13.244H27.1911L25.6071 8.915C25.5351 8.723 25.4541 8.498 25.3641 8.24C25.2801 7.976 25.1961 7.691 25.1121 7.385C25.0281 7.691 24.9441 7.976 24.8601 8.24C24.7821 8.504 24.7041 8.735 24.6261 8.933L23.0421 13.244Z" fill="#00A961"/>
                <path d="M35.3084 13.073V18.158H32.8874V5.144H36.8564C37.7444 5.144 38.5034 5.237 39.1334 5.423C39.7694 5.603 40.2884 5.858 40.6904 6.188C41.0984 6.518 41.3954 6.914 41.5814 7.376C41.7734 7.832 41.8694 8.336 41.8694 8.888C41.8694 9.326 41.8034 9.74 41.6714 10.13C41.5454 10.52 41.3594 10.874 41.1134 11.192C40.8734 11.51 40.5734 11.789 40.2134 12.029C39.8594 12.269 39.4544 12.461 38.9984 12.605C39.3044 12.779 39.5684 13.028 39.7904 13.352L43.0484 18.158H40.8704C40.6604 18.158 40.4804 18.116 40.3304 18.032C40.1864 17.948 40.0634 17.828 39.9614 17.672L37.2254 13.505C37.1234 13.349 37.0094 13.238 36.8834 13.172C36.7634 13.106 36.5834 13.073 36.3434 13.073H35.3084ZM35.3084 11.336H36.8204C37.2764 11.336 37.6724 11.279 38.0084 11.165C38.3504 11.051 38.6294 10.895 38.8454 10.697C39.0674 10.493 39.2324 10.253 39.3404 9.977C39.4484 9.701 39.5024 9.398 39.5024 9.068C39.5024 8.408 39.2834 7.901 38.8454 7.547C38.4134 7.193 37.7504 7.016 36.8564 7.016H35.3084V11.336Z" fill="#00A961"/>
                <path d="M53.2626 5.144V7.133H49.3476V18.158H46.9266V7.133H42.9936V5.144H53.2626Z" fill="#00A961"/>
                <path d="M52.7655 11.795H57.4275V13.649H52.7655V11.795Z" fill="#00A961"/>
                <path d="M64.8862 16.205C65.3542 16.205 65.7712 16.127 66.1372 15.971C66.5092 15.815 66.8212 15.596 67.0732 15.314C67.3312 15.032 67.5262 14.69 67.6582 14.288C67.7962 13.886 67.8652 13.436 67.8652 12.938V5.144H70.2862V12.938C70.2862 13.712 70.1602 14.429 69.9082 15.089C69.6622 15.743 69.3052 16.31 68.8372 16.79C68.3692 17.264 67.8022 17.636 67.1362 17.906C66.4702 18.17 65.7202 18.302 64.8862 18.302C64.0522 18.302 63.3022 18.17 62.6362 17.906C61.9702 17.636 61.4032 17.264 60.9352 16.79C60.4732 16.31 60.1162 15.743 59.8642 15.089C59.6182 14.429 59.4952 13.712 59.4952 12.938V5.144H61.9162V12.929C61.9162 13.427 61.9822 13.877 62.1142 14.279C62.2522 14.681 62.4472 15.026 62.6992 15.314C62.9512 15.596 63.2602 15.815 63.6262 15.971C63.9982 16.127 64.4182 16.205 64.8862 16.205Z" fill="#00A961"/>
                <path d="M75.2459 13.577V18.158H72.8249V5.144H77.0639C77.9339 5.144 78.6869 5.246 79.3229 5.45C79.9589 5.654 80.4839 5.939 80.8979 6.305C81.3119 6.671 81.6179 7.109 81.8159 7.619C82.0139 8.129 82.1129 8.687 82.1129 9.293C82.1129 9.923 82.0079 10.502 81.7979 11.03C81.5879 11.552 81.2729 12.002 80.8529 12.38C80.4329 12.758 79.9079 13.052 79.2779 13.262C78.6479 13.472 77.9099 13.577 77.0639 13.577H75.2459ZM75.2459 11.687H77.0639C77.5079 11.687 77.8949 11.633 78.2249 11.525C78.5549 11.411 78.8279 11.252 79.0439 11.048C79.2599 10.838 79.4219 10.586 79.5299 10.292C79.6379 9.992 79.6919 9.659 79.6919 9.293C79.6919 8.945 79.6379 8.63 79.5299 8.348C79.4219 8.066 79.2599 7.826 79.0439 7.628C78.8279 7.43 78.5549 7.28 78.2249 7.178C77.8949 7.07 77.5079 7.016 77.0639 7.016H75.2459V11.687Z" fill="#00A961"/>
                <rect x="85.676" width="73" height="23" rx="3" fill="#00A961"/>
                <path d="M88.676 5.144H90.71C90.92 5.144 91.094 5.195 91.232 5.297C91.376 5.393 91.472 5.525 91.52 5.693L93.734 13.478C93.788 13.67 93.836 13.88 93.878 14.108C93.926 14.33 93.971 14.567 94.013 14.819C94.061 14.567 94.112 14.33 94.166 14.108C94.226 13.88 94.289 13.67 94.355 13.478L96.911 5.693C96.959 5.555 97.052 5.429 97.19 5.315C97.334 5.201 97.508 5.144 97.712 5.144H98.423C98.633 5.144 98.807 5.195 98.945 5.297C99.083 5.393 99.179 5.525 99.233 5.693L101.771 13.478C101.903 13.862 102.017 14.288 102.113 14.756C102.155 14.522 102.197 14.297 102.239 14.081C102.287 13.865 102.335 13.664 102.383 13.478L104.597 5.693C104.639 5.543 104.732 5.414 104.876 5.306C105.02 5.198 105.194 5.144 105.398 5.144H107.297L103.256 18.158H101.069L98.225 9.266C98.189 9.152 98.15 9.029 98.108 8.897C98.072 8.765 98.036 8.624 98 8.474C97.964 8.624 97.925 8.765 97.883 8.897C97.847 9.029 97.811 9.152 97.775 9.266L94.904 18.158H92.717L88.676 5.144Z" fill="white"/>
                <path d="M121.209 11.651C121.209 12.605 121.05 13.49 120.732 14.306C120.414 15.116 119.967 15.818 119.391 16.412C118.815 17.006 118.122 17.471 117.312 17.807C116.502 18.137 115.605 18.302 114.621 18.302C113.637 18.302 112.737 18.137 111.921 17.807C111.111 17.471 110.415 17.006 109.833 16.412C109.257 15.818 108.81 15.116 108.492 14.306C108.174 13.49 108.015 12.605 108.015 11.651C108.015 10.697 108.174 9.815 108.492 9.005C108.81 8.189 109.257 7.484 109.833 6.89C110.415 6.296 111.111 5.834 111.921 5.504C112.737 5.168 113.637 5 114.621 5C115.605 5 116.502 5.168 117.312 5.504C118.122 5.84 118.815 6.305 119.391 6.899C119.967 7.487 120.414 8.189 120.732 9.005C121.05 9.815 121.209 10.697 121.209 11.651ZM118.725 11.651C118.725 10.937 118.629 10.298 118.437 9.734C118.251 9.164 117.981 8.681 117.627 8.285C117.273 7.889 116.841 7.586 116.331 7.376C115.827 7.166 115.257 7.061 114.621 7.061C113.985 7.061 113.412 7.166 112.902 7.376C112.392 7.586 111.957 7.889 111.597 8.285C111.243 8.681 110.97 9.164 110.778 9.734C110.586 10.298 110.49 10.937 110.49 11.651C110.49 12.365 110.586 13.007 110.778 13.577C110.97 14.141 111.243 14.621 111.597 15.017C111.957 15.407 112.392 15.707 112.902 15.917C113.412 16.127 113.985 16.232 114.621 16.232C115.257 16.232 115.827 16.127 116.331 15.917C116.841 15.707 117.273 15.407 117.627 15.017C117.981 14.621 118.251 14.141 118.437 13.577C118.629 13.007 118.725 12.365 118.725 11.651Z" fill="white"/>
                <path d="M125.624 13.073V18.158H123.203V5.144H127.172C128.06 5.144 128.819 5.237 129.449 5.423C130.085 5.603 130.604 5.858 131.006 6.188C131.414 6.518 131.711 6.914 131.897 7.376C132.089 7.832 132.185 8.336 132.185 8.888C132.185 9.326 132.119 9.74 131.987 10.13C131.861 10.52 131.675 10.874 131.429 11.192C131.189 11.51 130.889 11.789 130.529 12.029C130.175 12.269 129.77 12.461 129.314 12.605C129.62 12.779 129.884 13.028 130.106 13.352L133.364 18.158H131.186C130.976 18.158 130.796 18.116 130.646 18.032C130.502 17.948 130.379 17.828 130.277 17.672L127.541 13.505C127.439 13.349 127.325 13.238 127.199 13.172C127.079 13.106 126.899 13.073 126.659 13.073H125.624ZM125.624 11.336H127.136C127.592 11.336 127.988 11.279 128.324 11.165C128.666 11.051 128.945 10.895 129.161 10.697C129.383 10.493 129.548 10.253 129.656 9.977C129.764 9.701 129.818 9.398 129.818 9.068C129.818 8.408 129.599 7.901 129.161 7.547C128.729 7.193 128.066 7.016 127.172 7.016H125.624V11.336Z" fill="white"/>
                <path d="M137.338 16.16H142.549V18.158H134.917V5.144H137.338V16.16Z" fill="white"/>
                <path d="M155.772 11.651C155.772 12.605 155.613 13.481 155.295 14.279C154.977 15.077 154.53 15.764 153.954 16.34C153.378 16.916 152.685 17.363 151.875 17.681C151.065 17.999 150.168 18.158 149.184 18.158H144.216V5.144H149.184C150.168 5.144 151.065 5.306 151.875 5.63C152.685 5.948 153.378 6.395 153.954 6.971C154.53 7.541 154.977 8.225 155.295 9.023C155.613 9.821 155.772 10.697 155.772 11.651ZM153.297 11.651C153.297 10.937 153.201 10.298 153.009 9.734C152.817 9.164 152.544 8.684 152.19 8.294C151.836 7.898 151.404 7.595 150.894 7.385C150.39 7.175 149.82 7.07 149.184 7.07H146.655V16.232H149.184C149.82 16.232 150.39 16.127 150.894 15.917C151.404 15.707 151.836 15.407 152.19 15.017C152.544 14.621 152.817 14.141 153.009 13.577C153.201 13.007 153.297 12.365 153.297 11.651Z" fill="white"/>
            </svg> -->
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "Logo",
        components: {

        },
    }
</script>
