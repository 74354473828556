import Vuex from 'vuex'
import popups from './popup'
import user from './user'
import company from './company'
import crm from './crm'
import chat from './chat'
import calendar from './calendar'
import notifications from './notifications'
import page from './page'
import forum from './forum'
import employee from './employee'
import job from './job'

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  plugins: [],
  modules: {
    popups,
    user,
    company,
    crm,
    chat,
    calendar,
    notifications,
    page,
    forum,
    employee,
    job,
  }
});

export default store;