<template>
  <div v-if="this.popups.feedbackPopup" :class="{ 'popup-visible' : this.popups.feedbackPopup }">
    <div class="popup-wrap" @click.self="closePopup">
      
      <div class="popup feedback_popup" :class="{ 'active' : this.popups.feedbackPopup }">
        
        <div class="popup__header">
          <div class="popup__title">
            <span v-if="step == 'first'" class="title-text">Feedback for Start-Up World</span>
            <span v-else class="title-text">Confirm Feedback</span>
          </div>
          <div class="popup-action ml-auto">
            <div class="popup-action__item popup-action__item_remove" @click.prevent="closePopup">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"/>
              </svg>
            </div>
          </div>
        </div>
        
        <div class="popup__content overflow_visible">
          <div v-if="step == 'first'" class="popup__section">
            <div class="wysiwyg">
              <p>Thank you so much for being amongst our original members! We really appreciate you joining our platform despite how much work is still left to be done!</p>
              <p>We are working very hard to improve the platform, but unfortunately due to us coming to the end of what we've managed to raise, our immediate work from this point will be a bit slower than we would like. But as any experienced entrepreneur will tell us, we must push forward!</p>
              <p>Therefore instead of slowing down on all fronts, we will be spending this next bit of time to get your feedback, see what needs to be fixed, what great ideas you come up with that we can incorporate, etc.</p>
              <p>When we start charging subscription fees to the experts and investors we will be able to move forward full force with all the great ideas we will amass by then!</p>
              <p>Please give us your feedback so when we can move forward we'll be as prepared as possible to serve your needs as best we can!</p>
              <p>Thank you for your patience<br>With lots of love, Start-Up World Team</p>
            </div>
            <div class="form-field">
              <div class="form-field__header">
                <label class="form-field__title">Purpose of your post:</label>
              </div>
              <div :class="{'dropdown_active': openTypes}" class="dropdown">
                <div @click.prevent="openTypes = !openTypes" class="dropdown__init btn btn_select">
                  <span class="title-text">{{ userdata.feedback_types[type] }}</span>
                  <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                  </svg>
                </div>
                <div class="dropdown__content mw-100">
                  <ul class="dropdown__list">
                    <li v-for="(title, index) in userdata.feedback_types" :key="index" class="dropdown__item">
                      <div class="dropdown__link pointer" :class="{'jcf-selected': type == index}" @click="selectType(index)">
                        {{title}}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            
            <div class="form-field__header">
              <label class="form-field__title">Feedback</label>
            </div>
            <div class="form-field__content border-bug">
              <textarea placeholder="Add feedback or information for new idea/function/feature here" class="form-field__input" v-model="description"></textarea>
            </div>
          </div>
          
          <div v-else class="popup__section">
            
            <div class="box">
              <div class="box__list">
                <div class="box__item">
                  <div class="box__avatar">
                    <img v-if="userdata.user_profile.avatar" :src="userdata.user_profile.avatar" alt="" />
                    <img v-else src="@/assets/images/avatar-default.png" alt="">
                  </div>
                  <div class="box__main">
                    <div class="wysiwyg">
                      <h3>
                        <router-link :to="{name: 'profile', params: {id: userdata.user_profile.id}}" :class="{'disabled': userdata.user_profile.private}">{{userdata.user_profile.name}}</router-link>
                      </h3>
                      <ul>
                        <li v-if="userdata.user_profile.position">{{userdata.user_profile.position}}</li>
                        <li v-if="userdata.user_profile.company">
                          <router-link :to="{name: 'company_profile', params: {id: userdata.user_profile.company.id}}">{{userdata.user_profile.company.companyname}}</router-link>
                        </li>
                        <li>{{userdata.user_profile.location}}</li>
                        <li v-if="userdata.user_profile.type_of_investment">{{userdata.user_profile.type_of_investment}}</li>
                        <li v-for="industry in userdata.user_profile.company.companyindustry" :key="industry.id">{{industry.value}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-field__header">
              <label class="form-field__title">Purpose of your post:</label>
            </div>
            <div class="form-field__content preview_content">
              {{ userdata.feedback_types[type] }}
            </div>

            <div class="form-field__header">
              <label class="form-field__title">Feedback</label>
            </div>
            <div class="form-field__content preview_content">
              {{ description }}
            </div>
            
          </div>
        </div>
        
        <div class="popup__footer">
          <div v-if="step == 'first'" class="btn-group btn-group_secondary content-between">
            <a @click.prevent="closePopup" href="#" class="btn btn_secondary">
              <span class="btn__text">Cancel</span>
            </a>
            <a @click.prevent="toStep('second')" href="#" class="btn btn_primary">
              <span class="btn__text">Confirm Post</span>
            </a>
          </div>
          
          <div v-else class="btn-group btn-group_secondary content-between">
            <a @click.prevent="toStep('first')" href="#" class="btn btn_secondary">
              <span class="btn__text">Return to Edit</span>
            </a>
            <a @click.prevent="saveFeedback" href="#" class="btn btn_primary">
              <span class="btn__text">Send Feedback</span>
            </a>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "feedBackPopup",
  data() {
    return {
      step: 'first',
      
      type: 'other',
      description: '',
      openTypes: false,
    }
  },
  methods: {
    closePopup() {
      store.dispatch('SHOW_POPUP', { popup: 'feedbackPopup', show: false});
    },
    selectType(index) {
      this.type = index;
      this.openTypes = false;
    },
    closeSelect(e) {
      if (!e.target.closest(".dropdown_active")) {
        this.openTypes = false;
      }
    },
    
    openedPopup(){
      let current_route = this.$route.name;
      if(current_route == 'home') this.type = 'page';
      if(current_route == 'crmUser') this.type = 'crm';
      if( Object.keys(this.userdata.feedback_types).filter(i => i == current_route).length > 0 ) {
        this.type = current_route;
      }
    },
    toStep(step) {
      if(!this.description.length) {
        return;
      }
      this.step = step;
    },
    saveFeedback() {
      store.dispatch('USER_SEND_FEEDBACK', {
        type: this.type,
        description: this.description,
        user_id: this.userdata.user_profile.id,
        company_type: this.company.companyInfo.companytype,
      });
    },
    
  },
  computed: {
    popups: () => store.state.popups,
    userdata: () => store.state.user,
    company: () => store.state.company,
  },
  watch: {
    'popups.feedbackPopup'(newVal) {
      if(newVal) this.openedPopup();
      else this.step = 'first';
    },
    searchuser: function () {
      this.debouncedSearch();
    },
  },
  created() {
    document.addEventListener('click', this.closeSelect);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeSelect)
  }
}
</script>
