<template>
  <router-view></router-view>
</template>

<script>
import store from './store'

export default {
  name: "App",
  methods: {
    keyEvent(e) {
      if ( e.keyCode == 27 ) store.dispatch('CLOSE_ALL_POPUPS');
    },
  },
  async created() {
    await store.dispatch('USER_GET_INIT_APP');
    window.addEventListener('keydown', this.keyEvent)
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
