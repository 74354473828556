<template>
    <main-layout>
        <div class="main">
            <div class="main__header header">
                <div class="header__wysiwyg wysiwyg">
                    <h2>CRM</h2>
                </div>
            </div>

            <!-- main-content -->
            <div class="main__content">
                <div class="entrepreneur">

                    <Banner></Banner>

                    <Calendar></Calendar>

                    <Card></Card>
                </div>
            </div>
        </div>
        <ViewfilePopup></ViewfilePopup>
    </main-layout>
</template>

<script>
    import MainLayout from "../layouts/Main.vue"
    import store from '../store'
    import Banner from '@/components/general/Banner'
    import Calendar from '@/components/general/Calendar'
    import Card from '@/components/crm/Card'
    import ViewfilePopup from '@/components/popups/Viewfile'

    export default {
        name: "CRM",
        components: {
            MainLayout,
            Banner,
            Calendar,
            Card,
            ViewfilePopup
        },
        mounted() {
        },
        methods: {
        },
        created() {
            store.dispatch('GET_CRM_CARD', this.$route.params.id);
        },
    }
</script>
