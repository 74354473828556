import { router } from '@/router'
import companyService from '../services/service.company'
import userProfileService from '@/services/service.userprofile'
import natsIO from '@/plugins/natsIO'

const company = {
  state: {
    regCompanyPopup: false,
    regCompanyId: '',
    regCompanyErrNotify: false,

    regCompanyIndustry: [],
    regCompanyInvestment: [],
    regCompanyFunding: [],

    media: '',
    companyInfo: {
      id: '',
      companybanner: "",
      companycity: "",
      companycountry: "",
      companydescription: "",
      companylogo: "",
      companyname: "",
      industry: [],
      registration_number: "",
      stage_of_funding: "",
      investment: "",
      type_of_investment: "",
      like: 0,
      like_active: false,
      current_user_role: '',
      companytype: '',

      industry_labels: [],
    },
    view_company_profile: {},
    view_industryList: '',
    companyMembers: [],
    inviteSearchResult: [],
    inviteErrorMsg:'',
    removeSearchResult: [],

    media_to_remove: '',
  },
  mutations: {
    SET_REG_COMPANY: (state, payload) => {
      state.regCompanyPopup = payload.popup;
      state.regCompanyId = payload.id;
      state.regCompanyErrNotify = payload.errNotify;
    },
    SET_REG_COMPANY_INDUSTRY: (state, payload) => {
      state.regCompanyIndustry = payload;

      state.regCompanyIndustry.forEach((item, index) => {
        if (state.companyInfo.industry_labels.includes(item.id))
          state.regCompanyIndustry[index].selected = true;
      });
    },
    SET_REG_COMPANY_INVESTMENT: (state, payload) => {
      state.regCompanyInvestment = payload;

      state.regCompanyInvestment.forEach((item, index) => {
        if (state.companyInfo.type_of_investment == item.id)
          state.regCompanyInvestment[index].selected = true;
      });
    },
    SET_REG_COMPANY_FUNDING: (state, payload) => {
      state.regCompanyFunding = payload;

      state.regCompanyFunding.forEach((item, index) => {
        if (state.companyInfo.stage_of_funding == item.id)
          state.regCompanyFunding[index].selected = true;
      });
    },
    SET_COMPANY_INFO: (state, payload) => {
      state.companyInfo = payload;
    },
    RESET_COMPANY_INFO: (state) => {
      state.companyInfo = {
        id: '',
        companybanner: "",
        companycity: "",
        companycountry: "",
        companydescription: "",
        companylogo: "",
        companyname: "",
        industry: [],
        registration_number: "",
        stage_of_funding: "",
        investment: "",
        type_of_investment: "",
        like: 0,
        like_active: false,
        current_user_role: '',
        companytype: '',

        industry_labels: [],
      };
      state.regCompanyIndustry = [];
      state.regCompanyInvestment = [];
      state.regCompanyFunding = [];
    },
    SET_COMPANY_MEDIA: (state, payload) => {
      state.media = payload;
    },
    SET_COMPANY_MEMBERS: (state, payload) => {
      state.companyMembers = payload;
    },
    SET_COMPANY_MEMBERS_TO_REMOVE: (state, payload) => {
      state.removeSearchResult = payload;
    },
    SET_VIEW_COMPANYINFO: (state, payload) => {
      state.view_company_profile = payload;
    },
    SET_VIEW_COMPANYINFO_INDUSTRY: (state, payload) => {
      if(payload) {
        state.view_industryList = payload;
      }
    },
    RESET_VIEW_COMPANYINFO_INDUSTRY: (state) => {
      state.view_industryList = '';
    },
    COMPANY_MEMBERS_REMOVE_INIT: (state) => {
      state.removeSearchResult = state.companyMembers;
    },
    REMOVE_MEMBER_FROM_LIST: (state, payload) => {
      state.companyMembers.splice(payload.index, 1);
    },
    SET_INVITE_TO_MEMBERS: (state, payload) => {
      state.inviteSearchResult = payload.data;
    },
    RESET_SEARCH_USER_TO_INVITE:(state) => {
      state.inviteSearchResult = [];
    },
    SET_INV_ERR_MSG:(state, payload) => {
      state.inviteErrorMsg = payload;
    },
    SET_COMPANY_LIKE:(state, payload) => {
        state.view_company_profile.like = payload.like;
        state.view_company_profile.like_active = payload.like_active;
    },

    COMPANY_SET_MEDIA_TO_DELETE: (state, payload) => {
      state.media_to_remove = payload;
    },

  },
  actions: {
    RESET_COMPANY_NAME({commit}) {
      let data = {};
        data.popup = false;
        data.id = '';
        data.errNotify = false;
        commit('SET_REG_COMPANY', data);
    },

    async SEARCH_COMPANY({commit}, payload) {
      const response = await companyService.industrySearch(payload);
      if(response.status === 404) {
        let data = {};
        data.popup = false;
        data.id = '';
        data.errNotify = false;
        commit('SET_REG_COMPANY', data);
      }

      if(response.status === 200) {
        let data = {};
        data.popup = true;
        data.id = response.data[0].id;
        data.errNotify = false;
        commit('SET_REG_COMPANY', data);
      }
    },

    REG_ACCEPT_JOIN_COMPANY({commit}) {
      let data = {};
      data.popup = false;
      data.id = this.state.company.regCompanyId;
      data.errNotify = false;
      commit('SET_REG_COMPANY', data);
    },

    REG_REJECT_JOIN_COMPANY({commit}) {
      let data = {};
      data.popup = false;
      data.id = '';
      data.errNotify = true;
      commit('SET_REG_COMPANY', data);
    },

    async GET_COMPANY_INDUSTRY({commit}, payload) {
      let response = false;
      let responseInvestor = false;
      switch (payload) {
        case 'entrepreneur':
          response = await companyService.industry();
          break;
        case 'expert':
          response = await companyService.industryBusiness();
          break;
        case 'investor':
          response = await companyService.industry();
          responseInvestor = await companyService.industryInvestor();
          break;
      }

      if(response.status === 200) {

        if(payload == 'entrepreneur') {
          let sof = [
            {id: 'Funding stage', value: 'Funding stage'},
            {id: 'Not looking for funding', value: 'Not looking for funding'},
            {id: 'Preparing for funding', value: 'Preparing for funding'},
          ];
          commit('SET_REG_COMPANY_FUNDING', sof);
        }

        commit('SET_REG_COMPANY_INDUSTRY', response.data.data);
      }
      if(responseInvestor.status === 200) {
        commit('SET_REG_COMPANY_INVESTMENT', responseInvestor.data.data);
      }
    },

    async GET_MY_COMPANY_INFO({commit}) {
      const responseGetCompany = await companyService.getMyCompany();
      if(responseGetCompany.status === 200) {
        commit('SET_COMPANY_INFO', responseGetCompany.data);
        if(responseGetCompany.data.current_user_role === 'administrator') {
          commit('RESET_VIEW_COMPANYINFO_INDUSTRY');
        } else {
          let industry = responseGetCompany.data.companyindustry;
          let industryArr = [];
          for (let index = 0; index < industry.length; index++) {
            industryArr.push(industry[index]['value']);
          }
        commit('SET_VIEW_COMPANYINFO_INDUSTRY', industryArr.join(', '));
        }

      }
    },

    async GET_COMPANY_INFO({commit, dispatch}, payload) {
      commit('RESET_VIEW_COMPANYINFO_INDUSTRY');

        const responseGetCompany = await companyService.getCompany(payload);
        if(responseGetCompany.status === 200) {

          let industry = responseGetCompany.data.companyindustry;
            let industryArr = [];
            for (let index = 0; index < industry.length; index++) {
              industryArr.push(industry[index]['value']);
            }
          commit('SET_VIEW_COMPANYINFO_INDUSTRY', industryArr.join(', '));

          responseGetCompany.data.id = payload;
          commit('SET_VIEW_COMPANYINFO', responseGetCompany.data);

          await dispatch('GET_COMPANY_MEDIA', payload);
          await dispatch('GET_COMPANY_MEMBERS', payload);
        } else {
          router.push({ name: 'company_profile_edit'});
        }
    },

    async GET_COMPANY_MEMBERS({commit}, payload) { // get members by company id
      const responseGetCompanyMembers = await companyService.getMembers(payload);
      commit('SET_COMPANY_MEMBERS', responseGetCompanyMembers.data.data.items);
    },

    async GET_COMPANY_TEAM_MEMBERS({commit}) {
      const responseGetCompanyMembers = await companyService.getMembers(this.state.user.user_profile.companyid);
      commit('SET_COMPANY_MEMBERS', responseGetCompanyMembers.data.data.items);
    },

    async SEARCH_COMPANY_TEAM_MEMBERS({commit}, payload) {
      const responseSearchCompanyMembers = await companyService.searchMembers(this.state.user.user_profile.companyid, payload);
      commit('SET_COMPANY_MEMBERS', responseSearchCompanyMembers.data.data.items);
    },

    async SAVE_COMPANY({commit, dispatch}, payload) {
      console.log(commit);

      payload.industry = this.state.company.regCompanyIndustry.filter(a => a.selected).map(a => a.id);
      payload.type_of_investment = this.state.company.regCompanyInvestment.filter(a => a.selected).map(a => a.id)[0];
      payload.stage_of_funding = this.state.company.regCompanyFunding.filter(a => a.selected).map(a => a.id)[0];

      delete payload.companyindustry;
      delete payload.media;
      delete payload.initial_user_id;
      delete payload.companytype;

      if(!payload.companylogo) {
        payload.companylogo = "";
      }

      if(!payload.companybanner) {
        payload.companybanner = "";
      }

      let response = '';
      if(payload.id) {
        let compId = payload.id;
        response = await companyService.putCompany(payload);
        dispatch('GET_MY_COMPANY_INFO', compId);
        dispatch('SET_USER_PROFILE');
      }
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'experiencePopup', show: false});
      }
    },

    async GET_COMPANY_MEDIA({commit}, payload) {
      const response = await companyService.getMedia(payload);
      if(response.status === 200) {
        commit('SET_COMPANY_MEDIA', response.data.data);
      }
    },

    async SAVE_COMPANY_MEDIA({commit, dispatch}, payload) {
      if(!payload.attachment){
        payload.attachment = "";
      }

      let response = '';
      let compid = payload.compid;
      console.log(commit);
      if(payload.id){
        response = await companyService.putMedia(payload);
      } else {
        response = await companyService.postMedia(payload);
      }
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        dispatch('GET_COMPANY_MEDIA', compid);
      }
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'mediaCompanyPopup', show: false});
        dispatch('GET_COMPANY_MEDIA', compid);
      }
    },

    async REMOVE_COMPANY_MEDIA({commit, dispatch}) {
      const response = await companyService.removeMedia(this.state.company.media_to_remove);
      if(response.status === 200) {
        commit('COMPANY_SET_MEDIA_TO_DELETE', '');
        dispatch('GET_COMPANY_MEDIA', this.state.company.companyInfo.id);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async SEARCH_USER_TO_REMOVE({commit}, payload) {
      const response = await userProfileService.search(payload);
      if(response.status === 404) {
        console.log('404')
      }
      if(response.status === 200) {
        commit('SET_COMPANY_MEMBERS_TO_REMOVE', response.data.data);
      }
    },

    async REMOVE_MEMBERS_LIST({commit, dispatch}, payload) {
      console.log(commit);
      for (let index = 0; index < payload.length; index++) {
        await companyService.removeMember(this.state.user.user_profile.companyid, payload[index]);
        dispatch('REMOVE_MEMBER_FROM_LIST',payload[index]);
      }

    },

    REMOVE_MEMBER_FROM_LIST({commit}, payload) {
      let companyMembers = this.state.company.companyMembers;
      var data = {};
      for (let index = 0; index < companyMembers.length; index++) {
        if(payload == companyMembers[index].id){
          data.index = index;
          commit('REMOVE_MEMBER_FROM_LIST', data);
        }
      }
    },

    async SEARCH_USER_TO_INVITE({commit}, payload) {
      const response = await userProfileService.searchInvite(payload);

      if(response.status === 404) {
        console.log('404')
      }

      if(response.status === 200) {
        if(response.data.length <= 0) {
          commit('SET_INVITE_TO_MEMBERS', {});
        } else {
          commit('SET_INVITE_TO_MEMBERS', response.data);
        }
      }
    },

    async SEARCH_USER_ON_PLATFORM({commit}, payload) {
      const response = await userProfileService.searchPlatform(payload);

      if(response.status === 404) {
        console.log('404')
      }

      if(response.status === 200) {
        if(response.data.length <= 0) {
          commit('SET_INVITE_TO_MEMBERS', {});
        } else {
          commit('SET_INVITE_TO_MEMBERS', response.data);
        }
      }
    },

    RESET_SEARCH_USER_TO_INVITE({commit}) {
      commit('RESET_SEARCH_USER_TO_INVITE');
    },

    RESET_COMPANY_INFO({commit}) {
      commit('RESET_COMPANY_INFO');
    },

    async SEND_INVITE({commit, dispatch}, payload) {
      console.log(commit);
      // Invite to email
      if(payload.email){
        let data = {};
        data.email = payload.email;
        data.role = payload.role;
        let response = await  companyService.sendInviteEmail(payload.company, data);
        if(response.status === 422) {
          dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'This email is not valid.' });
        } else if((response.status === 409)) {
          dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'This email is already registered. Please search for the user inside the Platform.' });
        } else if((response.status === 401)) {
          dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'You not eligible to perform this action.' });
        } else {
          dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'The invite was successfully sent to the user /users. Once accepted, the user will be added to your team.' });
          if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
        }
      }

      // Invite to list
      if(payload.inviteList) {
        for (let index = 0; index < payload.inviteList.length; index++) {
          // let role = 'employee';
          let user = payload.inviteList[index];
          let company = payload.company;
          let data = {};
          data.role = 'employee';

          if(payload.asAdmin.includes(payload.inviteList[index])){
            data.role = 'administrator';
          }

          let response = await companyService.sendInvite(company, user, data);
          if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
        }
      }
    },

    async COMPANY_LIKE({commit}, payload){
      let data = {
          compid: payload,
          user_id: this.state.user.user_profile.id
      };
      const response = await companyService.setLike(data);
      commit('SET_COMPANY_LIKE', response.data);
    },

    async REQUEST_COMPANY_MEDIA({dispatch}, payload) {
      const response = await companyService.requestMedia(payload);
      if(response.status !== 200) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
      if (response.data.notify) {
        response.data.notify.params.to_users.forEach(user => {
          response.data.notify.to_user = user;
          natsIO.publishNotify(user, response.data.notify);
        });
      }
    }

  },
  getters: {
    getCompanyType(state) {
      return  state.company.companyInfo.companytype;
    }
  }
}
export default company;