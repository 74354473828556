<template>
<div class="notes__group group w-30">
  <div class="group__header">
    <span class="title-text">Team Notes</span>
    <ToolTip
      :type="'top-right'"
      :msg="'This space is used for passing notes/ files across your team regarding specific members/companies on the platform. Notes added here are viewable and accessible across all of and only your direct team members, no third party partners you may be working with.'"
    />
  </div>
  <div class="group__content">
    <div class="box crm_messages">
      <div class="box__list">
        <div v-for="note in this.crm.notes" :key='note.id' class="box__item box__item_secondary br_0 bg_0">
          <div v-if="(note.user.id == userdata.user_profile.id)" class="notes-action ml-auto">
            <a href="#" @click.prevent="editNote(note.id)" class="notes-action__item notes-action__item_edit">
              <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z" fill="#00A961"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.1582C14.777 2.1582 14.5631 2.24681 14.4053 2.40452L7.42724 9.38263L7.03079 10.9684L8.61658 10.572L15.5947 3.59386C15.7524 3.43615 15.841 3.22224 15.841 2.99919C15.841 2.77615 15.7524 2.56224 15.5947 2.40452C15.437 2.24681 15.2231 2.1582 15 2.1582ZM13.3447 1.34386C13.7837 0.904842 14.3791 0.658203 15 0.658203C15.6209 0.658203 16.2163 0.904842 16.6553 1.34386C17.0944 1.78288 17.341 2.37832 17.341 2.99919C17.341 3.62006 17.0944 4.2155 16.6553 4.65452L9.53035 11.7795C9.43423 11.8756 9.31379 11.9438 9.18192 11.9768L6.18192 12.7268C5.92634 12.7907 5.65597 12.7158 5.46969 12.5295C5.2834 12.3432 5.20851 12.0729 5.27241 11.8173L6.02241 8.81729C6.05538 8.68542 6.12357 8.56498 6.21969 8.46886L13.3447 1.34386Z" fill="#00A961"></path></svg>
            </a>
            <a href="#" @click.prevent="deleteNote(note.id)" class="notes-action__item notes-action__item_trash">
              <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#00A961"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#00A961"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#00A961"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#00A961"></path></svg>
            </a>
          </div>
          <div class="box__avatar box__avatar_secondary">
            <img v-if="note.user.avatar" :src="note.user.avatar" alt="" />
            <img v-else src="@/assets/images/avatar-default.png" alt="Start-Up World" />
          </div>
          <div class="box__main">
            <div class="box__header">
              <div class="wysiwyg wysiwyg_secondary mb_0">
                <router-link :to="'/profile/'+note.user.id">
                  <h3>{{note.user.firstname}} {{note.user.lastname}}</h3>
                </router-link>
                <ul>
                  <li>{{note.date_added}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="box__content">
            <div class="wysiwyg">
              <p>
                {{note.note}}
              </p>
            </div>
            <div v-if="note.attachment" class="files">
              <div class="files__list">
                <div class="files__item">
                  <a href="#" @click.prevent="showAttach(note.attachment)" class="files__link">
                    <svg v-show="note.attachment.ext_type === 'video'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5932 4.58313C17.843 4.71168 18 4.96906 18 5.25V12.75C18 13.0309 17.843 13.2883 17.5932 13.4169C17.3434 13.5454 17.0427 13.5236 16.8141 13.3603L11.5641 9.6103C11.367 9.46952 11.25 9.24222 11.25 9C11.25 8.75779 11.367 8.53049 11.5641 8.3897L16.8141 4.6397C17.0427 4.47641 17.3434 4.45457 17.5932 4.58313ZM13.2903 9L16.5 11.2926V6.70739L13.2903 9Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V12.75C1.5 13.1642 1.83579 13.5 2.25 13.5H10.5C10.9142 13.5 11.25 13.1642 11.25 12.75V5.25C11.25 4.83579 10.9142 4.5 10.5 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H10.5C11.7426 3 12.75 4.00736 12.75 5.25V12.75C12.75 13.9926 11.7426 15 10.5 15H2.25C1.00736 15 0 13.9926 0 12.75V5.25Z" fill="#00A961" />
                    </svg>
                    <svg v-show="note.attachment.ext_type === 'document'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
                    </svg>
                    <svg v-show="note.attachment.ext_type === 'image'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961" />
                    </svg>
                    <span class="files__text">{{ note.attachment.user_defined_filename }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="add_notes" class="group__footer">
    <div class="group__formular">
      <form action="index.html">
        <div class="form-field">
          <div class="form-field__content">
            <textarea name="textarea"
            v-model="teamNoteText" class="form-field__input"
              :class="{ 'input-errors' : this.errors.note }"
              ref="teamNoteText"
              placeholder="Type here ...."></textarea>
            <p v-if="this.errors.note" class="form-errors">{{ this.errors.note }}</p>
          </div>
          <div class="form-field mb_0">
            <div class="attach">
              <div v-show="((this.file || this.edit_note_item.attachment) && !this.removed_attach)" class="attach__list">
                <div class="attach__item attach-item">
                  <div class="attach-item__ico">
                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961" />
                    </svg>
                  </div>
                  <div class="attach-item__input">
                    <input type="file" id="file" class="input" ref="file" @change.prevent="handleFileUpload">
                  </div>
                  <div class="attach-item__title">
                    <span class="title-text">{{ this.file.name }}</span>
                    <span v-if="this.edit_note_item.attachment" class="title-text">{{ this.edit_note_item.attachment.user_defined_filename }}</span>
                  </div>
                  <div @click="removeFile" class="attach-item__remove">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#333333" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#333333" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#333333" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#333333" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="btn-group btn-group_secondary content-right">
                <div @click="uploadInit" class="attach__init attach__init_secondary mr-auto mb_0">
                  <svg class="ico" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1499 1.98991C12.4864 1.98991 11.85 2.2535 11.3809 2.72268L3.72253 10.381C2.9406 11.1629 2.50132 12.2235 2.50132 13.3293C2.50132 14.4351 2.9406 15.4956 3.72253 16.2775C4.50445 17.0594 5.56496 17.4987 6.67077 17.4987C7.77658 17.4987 8.83709 17.0594 9.61901 16.2775L17.2773 8.61917C17.6028 8.29374 18.1304 8.29374 18.4559 8.61917C18.7813 8.94461 18.7813 9.47225 18.4559 9.79768L10.7975 17.456C9.70304 18.5505 8.2186 19.1654 6.67077 19.1654C5.12294 19.1654 3.6385 18.5505 2.54401 17.456C1.44953 16.3615 0.834656 14.8771 0.834656 13.3293C0.834656 11.7814 1.44953 10.297 2.54401 9.20251L10.2023 1.54417C10.9841 0.762424 12.0444 0.323242 13.1499 0.323242C14.2555 0.323242 15.3158 0.762424 16.0975 1.54417C16.8793 2.32592 17.3185 3.3862 17.3185 4.49176C17.3185 5.59732 16.8793 6.6576 16.0975 7.43935L8.43086 15.0977C7.96184 15.5667 7.32572 15.8302 6.66244 15.8302C5.99915 15.8302 5.36303 15.5667 4.89401 15.0977C4.425 14.6287 4.16151 13.9925 4.16151 13.3293C4.16151 12.666 4.425 12.0299 4.89401 11.5608L11.9694 4.49383C12.295 4.16858 12.8226 4.16889 13.1479 4.49452C13.4731 4.82015 13.4728 5.34779 13.1472 5.67303L6.07253 12.7394C5.91629 12.8958 5.82818 13.1082 5.82818 13.3293C5.82818 13.5505 5.91607 13.7627 6.07253 13.9192C6.22898 14.0756 6.44118 14.1635 6.66244 14.1635C6.8837 14.1635 7.09589 14.0756 7.25235 13.9192L14.919 6.26084C15.388 5.79168 15.6518 5.15514 15.6518 4.49176C15.6518 3.82823 15.3882 3.19187 14.919 2.72268C14.4498 2.2535 13.8135 1.98991 13.1499 1.98991Z" fill="#BFC8D1" />
                  </svg>
                </div>
                <a href="#" @click.prevent="addNotes" class="btn btn_secondary">
                  <span class="btn__text">Cancel</span>
                </a>
                <a href="#" @click.prevent="addTeamNote" class="btn btn_primary">
                  <span v-if="!this.edit_note" class="btn__text">Add note</span>
                  <span v-else class="btn__text">Edit note</span>
                </a>
              </div>
            </div>
          </div><!-- //form-field -->
        </div><!-- //form-field -->
      </form>
    </div>
  </div>
  <div v-else class="group__footer group__footer_secondary">
    <div class="group__formular">
      <button @click.prevent="addNotes" type="submit" class="btn btn_tertiary">
        <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="white"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="white"></path>
        </svg>
        <span class="btn__text">Add notes</span>
      </button>
    </div>
  </div>

</div>
</template>
<script>
import ToolTip from '@/components/ToolTip'
import store from '@/store'
import func from '@/plugins/functions'

export default {
  name: "CardTeamNotes",
  components: {
    ToolTip,
  },
  data() {
    return {
        edit_note: false,
        edit_note_item: '',
        removed_attach: false,
        add_notes: false,
        file: '',
        teamNoteText: '',
        errors:{
            note: false,
        },
    }
  },
  mounted() {
    this.scrollInit();
    this.loadmore();
  },
  updated() {
    this.scrollInit();
  },
  methods: {
     loadmore() {
        let container = this.$el.querySelector(".group__content");
        container.onscroll = async() => {
            let saved = container.scrollHeight;
            if(container.scrollTop <= 0) {
                if(parseInt(this.crm.notes_meta.page + 1) <= this.crm.notes_meta.total_pages){
                    let data = {};
                    data.id = this.crm.cardInfo.id;
                    data.page = parseInt(this.crm.notes_meta.page + 1);
                    await store.dispatch('CARD_NOTES_LOAD_MORE', data);
                    container.scrollTop = container.scrollHeight - saved;
                }
            }
        }
    },
    scrollInit() {
        let container = this.$el.querySelector(".group__content");
        container.scrollTop = container.scrollHeight;
    },
    resetErrors() {
        this.errors.note = false;
    },
    addNotes() {
        this.edit_note = false;
        this.add_notes = !this.add_notes;
        this.teamNoteText = "";
        this.file = '';
        this.removed_attach = false;
        this.resetErrors();
        if(this.add_notes) this.$nextTick(() => {
          this.$refs.teamNoteText.focus();
          this.$refs.teamNoteText.addEventListener('keydown', this.inputEvent);
        });
        if(!this.add_notes) this.$refs.teamNoteText.removeEventListener('keydown', this.inputEvent);
    },
    inputEvent(event){
      func.inputEvent(event, this.addTeamNote, (()=>this.teamNoteText += "\n"))
    },
    async addTeamNote() {
        this.edit_note = false;
        this.resetErrors();
        if(this.$refs.teamNoteText.value.length > 0){
            if(this.file) {
                await store.dispatch('PUT_FILE', this.file);
            }
            let data = {};
            data.card_id = this.crm.cardInfo.id;
            data.note = this.$refs.teamNoteText.value;
            data.file = this.file;
            if(this.file) {
                data.attachment_id = this.userdata.put_file.id;
            }
            if(this.edit_note_item) {
              data.note_id = this.edit_note_item.id;
              await store.dispatch('UPDATE_NOTE', data);
            } else {
              await store.dispatch('ADD_TEAM_NOTE', data);
            }

            this.teamNoteText = "";
            this.file = '';
            this.add_notes = false;
            this.edit_note_item = '';

            await store.dispatch('GET_CARD_MEDIA', this.crm.cardInfo.id);
            this.userdata.put_file = '';
            this.scrollInit();
        } else {
            this.errors.note = 'This field is required.';
        }
    },
    uploadInit: function() {
        const elem = this.$refs.file;
        elem.click();
    },
    handleFileUpload: function() {
        this.file = this.$refs.file.files[0];
        this.removed_attach = false;
    },
    removeFile: function() {
        this.file = '';
        this.removed_attach = true;
    },
    showAttach: function(attachment) {
        store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
    },
    editNote: function(id) {
      this.edit_note = true;
      this.add_notes = false;
      this.addNotes();
      this.crm.notes.forEach(item => {
        if(id == item.id){
          this.edit_note_item = item;
          this.teamNoteText = item.note;
        }
      });
      this.edit_note = true;
    },
    deleteNote(id) {
      store.commit('SET_NOTE_TO_DELETE', id);
      store.dispatch('SHOW_POPUP', {
          popup: 'confirmPopup',
          show: 'Are you sure you want to delete this note?',
          route: '',
          name: 'NOTE_DELETE',
          data: '',
      });
    },
  },
  computed: {
      crm: () => store.state.crm,
      userdata: () => store.state.user
  }
}
</script>
