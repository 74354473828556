<template>
<div :class="{ 'popup-visible' : this.popups.pageAddPopup }">
    <div class="popup-wrap" @click.self="closePopup">

        <!-- // page-add -->
        <div class="popup page-add-popup" :class="{ 'active' : this.popups.pageAddPopup }">

                <div class="popup__header">
                    <div class="popup__title">
                        <span v-if="this.popups.pageData.type == 'create'" class="title-text">Add work page</span>
                        <span v-else class="title-text">Edit work page</span>
                    </div>
                    <div class="popup-action ml-auto">
                        <div class="popup-action__item popup-action__item_remove" @click.prevent="closePopup">
                            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"/>
                            </svg>
                        </div>
                    </div>
                </div><!-- //popup__header -->
                <div class="popup__content">
                    <div v-if="this.popups.pageData.type == 'create'" class="popup__section">

                        <div class="form-field">
                            <div class="form-field__header">
                                <label class="form-field__title">Page title</label>
                            </div>
                            <div class="form-field__content">
                                <input v-model="new_page.title" ref="inputTitleCreate" type="text" placeholder="Enter title" class="form-field__input" required>
                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__header">
                                <label class="form-field__title">Restrictions</label>
                            </div>
                            <div class="form-field__content">

                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="addAllMembers"
                                            type="checkbox" name="chekbox" required>
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                        </svg>
                                        <span class="custom-check__text">Invite All Team Members</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">

                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="new_page.auto_join"
                                            type="checkbox" name="chekbox" required>
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                        </svg>
                                        <span class="custom-check__text">Automatically join new team members to this page</span>
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div class="form-field">
                            <div class="form-field__header">
                                <label class="form-field__title">Invite other users to Work Page</label>
                            </div>
                            <div class="form-field__content" :style="{'min-height': minHeightField + 'px'}">

                                <div class="select-smart select" ref="smartSelect">
                                    <div class="select__header select-header">
                                        <div class="select-header__content">
                                            <div class="select-header__list">
                                                <div v-for="contact in new_page.users" :key='contact.id' class="select-header__item select-item">
                                                    <div class="select-item__title">
                                                        <span class="title-text">{{contact.firstname}} {{contact.lastname}}</span>
                                                    </div>
                                                    <div @click="deleteUser(contact.id)" class="select-item__action">
                                                        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0303 3.96967C14.3232 4.26256 14.3232 4.73744 14.0303 5.03033L5.03033 14.0303C4.73744 14.3232 4.26256 14.3232 3.96967 14.0303C3.67678 13.7374 3.67678 13.2626 3.96967 12.9697L12.9697 3.96967C13.2626 3.67678 13.7374 3.67678 14.0303 3.96967Z" fill="#00A961"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 3.96967C4.26256 3.67678 4.73744 3.67678 5.03033 3.96967L14.0303 12.9697C14.3232 13.2626 14.3232 13.7374 14.0303 14.0303C13.7374 14.3232 13.2626 14.3232 12.9697 14.0303L3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967Z" fill="#00A961"/>
                                                        </svg>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div @click="toggleShowSearch" class="select-header__init">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 11.7803C4.26256 12.0732 4.73744 12.0732 5.03033 11.7803L9 7.81066L12.9697 11.7803C13.2626 12.0732 13.7374 12.0732 14.0303 11.7803C14.3232 11.4874 14.3232 11.0126 14.0303 10.7197L9.53033 6.21967C9.23744 5.92678 8.76256 5.92678 8.46967 6.21967L3.96967 10.7197C3.67678 11.0126 3.67678 11.4874 3.96967 11.7803Z" fill="#5D6167"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-show="show_search" class="select__dropdown" ref="dropDownSearch">

                                        <div class="select__search">
                                            <div class="form-field  form-field_tertiary">
                                                <div class="form-field__content">
                                                    <input type="text" placeholder="Search ...."
                                                        v-model="searchuser"
                                                        class="form-field__input" required>
                                                    <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="select__list">

                                            <div v-for="(contact,index) in this.page.searchResult" :key='contact.id' class="select-item">
                                                <div class="select__item">
                                                    <div class="select-item__avatar">
                                                        <img v-if="contact.avatar" :src="contact.avatar" alt="" />
                                                        <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                                    </div>
                                                    <div class="select-item__wysiwyg wysiwyg">
                                                        <span class="title-text">{{ contact.firstname }} {{ contact.lastname }} </span>
                                                        <ul>
                                                            <li class="list__item">{{contact.position}}</li>
                                                            <li class="list__item">{{contact.companyname}}</li>
                                                            <li class="list__item">{{contact.country}}, {{contact.city}}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="select-item__action">
                                                        <div v-show="contact.selected" class="custom-check">
                                                            <label class="custom-check__label">
                                                                <input class="custom-check__input"
                                                                    v-model="contact.is_admin"
                                                                    :disabled="contact.block_add"
                                                                    @change="selectUsers(index)"
                                                                    type="checkbox" name="chekbox" required>
                                                                <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/>
                                                                        <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g>
                                                                    </g>
                                                                </svg>
                                                                <span class="custom-check__text">Add as admin</span>
                                                            </label>
                                                        </div>

                                                        <div class="custom-check">
                                                            <label class="custom-check__label">
                                                                <span class="custom-check__text">&nbsp;&nbsp;</span>
                                                                <input class="custom-check__input"
                                                                    v-model="contact.selected"
                                                                    :disabled="contact.block_add"
                                                                    @change="selectUsers(index)"
                                                                    type="checkbox" name="chekbox" required>
                                                                <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/>
                                                                        <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g>
                                                                    </g>
                                                                </svg>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-show="contact.block_add" class="exclude_from_invite">{{contact.alert_msg}}</div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__header">
                                <label class="form-field__title">Invite to the Work Page via email (For those not yet using our platform.)</label>
                            </div>
                            <div class="form-field__content">
                                <input v-model="new_page.email" type="text" placeholder="Please enter email address" class="form-field__input" required>
                            </div>
                        </div>
                    </div>

                    <div v-else class="popup__section">

                        <div class="form-field">
                            <div class="form-field__header">
                                <label class="form-field__title">Page title</label>
                            </div>
                            <div class="form-field__content">
                                <input v-model="this.popups.pageData.title" ref="inputTitleEdit" type="text" placeholder="Enter title" class="form-field__input" required>
                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">

                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input" type="checkbox" name="chekbox"
                                            v-model="this.popups.pageData.auto_join">
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/>
                                                <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g>
                                            </g>
                                        </svg>
                                        <span class="custom-check__text">Automatically join new team members to this page</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="popup__footer">
                    <div class="btn-group btn-group_secondary content-between">
                        <a href="#" class="btn btn_secondary" @click.prevent="closePopup">
                            <span class="btn__text">Cancel</span>
                        </a>

                        <a v-if="this.popups.pageData.type == 'create'" @click="createPage" href="#" class="btn btn_primary">
                            <span class="btn__text">Create Work Page</span>
                        </a>
                        <a v-else @click="updatePage" href="#" class="btn btn_primary">
                            <span class="btn__text">Update  Work Page</span>
                        </a>
                    </div>
                </div>

        </div>
    </div>
</div>
</template>

<script>
import store from '@/store'
import func from '@/plugins/functions'

export default {
    name: "PageAddPopup",
    data() {
        return {
            searchuser: '',
            show_search: false,
            addAllMembers: false,
            new_page: {
                title: '',
                auto_join: true,
                email: '',
                users: {},
            },
            minHeightField: 46,
        }
    },
    methods: {
        closePopup() {
            store.dispatch('SHOW_POPUP', { popup: 'pageAddPopup', show: false});
            store.dispatch('RESET_USER_SEARCH');
        },
        async createPage() {
            const succes = await store.dispatch('STORE_WORK_PAGE', this.new_page);
            if (succes) this.resetInputData();
        },
        updatePage() {
            let data = {
                id: this.popups.pageData.id,
                title: this.popups.pageData.title,
                auto_join: this.popups.pageData.auto_join,
            };
            console.log(data)
            store.dispatch('UPDATE_PAGE', data);
        },
        async searchUserHandler() {
            if(this.searchuser.length >= 1) {
                await store.dispatch('SEARCH_PAGE_USER', {search: this.searchuser});
                this.checkSelectedUsers();
            } else {
                store.dispatch('RESET_USER_SEARCH');
            }
        },

        selectUsers(index) {
            this.page.searchResult.find((o) => {
                if (o.selected === true) {
                    this.new_page.users[o.id] = {
                        id: o.id,
                        is_admin: o.is_admin,
                        firstname: o.firstname,
                        lastname: o.lastname,
                    };
                }
            });
            if (this.page.searchResult[index].selected !== true) this.deleteUser(this.page.searchResult[index].id);
            this.calcHeight();
        },
        deleteUser(id) {
            delete this.new_page.users[id];
            let index_search = this.page.searchResult.findIndex(x => x.id == id);
            if (index_search !== -1) this.page.searchResult[index_search].selected = false;
            this.calcHeight();
            this.checkSelectedMembers();
        },
        resetInputData() {
            this.new_page = {
                title: '',
                auto_join: true,
                email: '',
                users: {},
            };
            this.searchuser = '';
            this.show_search = false;
            store.dispatch('RESET_USER_SEARCH');
        },

        checkSelectedUsers() {
            let self = this;
            this.page.searchResult.forEach((item, index) => {
                if (self.new_page.users[item.id] !== undefined) {
                    self.page.searchResult[index].selected = true;
                    self.page.searchResult[index].is_admin = self.new_page.users[item.id].is_admin;
                }
            });
            this.calcHeight();
        },

        async getAllMembers() {
            await store.dispatch('PAGE_GET_ALL_MEMBERS');
            this.page.getAllMembers.find((o) => {
                this.new_page.users[o.id] = {
                    id: o.id,
                    is_admin: false,
                    firstname: o.firstname,
                    lastname: o.lastname,
                };
            });
            this.checkSelectedUsers();
        },

        checkSelectedMembers() {
            let self = this;
            this.page.getAllMembers.forEach(item => {
                if (self.new_page.users[item.id] === undefined) self.addAllMembers = false;
            });
        },

        toggleShowSearch() {
            this.show_search = !this.show_search;
            this.calcHeight();
        },
        calcHeight() {
            this.$nextTick(() => {
                this.minHeightField = parseInt(this.$refs.smartSelect.clientHeight);
                this.minHeightField += parseInt(this.$refs.dropDownSearch.clientHeight);
            })
        },
        focus() {
            if(this.popups.pageData.type == 'create') {
                this.$refs.inputTitleCreate.focus()
            } else {
                let element = this.$refs.inputTitleEdit;
                element.focus();
                element.setSelectionRange(element.value.length,element.value.length);
            }
        },

    },
    computed: {
        popups: () => store.state.popups,
        userdata: () => store.state.user,
        page: () => store.state.page,
    },
    watch: {
        searchuser: function () {
            this.debouncedSearch();
        },
        addAllMembers(newVal) {
            if (newVal) this.getAllMembers();
        },
        'popups.pageAddPopup'(newVal) {
            if (newVal) setTimeout(() => this.focus(), "500");
        },
    },
    created() {
        this.debouncedSearch = func.debounce(this.searchUserHandler, 500);
    },
    mounted() {
        this.calcHeight();
    }
}
</script>

<style>
.exclude_from_invite {
  font-size: 12px;
  color: brown;
  text-align: left;
  padding-left: 60px;
  padding-bottom: 8px;
}
.select-smart .select__dropdown .select-item:hover {
    background-color: #F7F8FA;
}
</style>