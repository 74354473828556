<template>
<div :class="{ 'popup-visible': this.popups.fullFavoritesViewPopup }">
  <div class="popup-wrap" @click.self="closePopup">
    <div :class="{'active':this.popups.fullFavoritesViewPopup, 'popup_secondary' : !employee.favorites.length}" class="popup favorites-full-popup popup_tertiary popup_posts mh_100">

      <div class="popup__header sticky_header">
        <div class="popup__title">
          <span class="title-text">Favorites</span>
        </div>
        <div class="popup-action ml-auto" @click="closePopup">
          <div class="popup-action__item popup-action__item_remove">
            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                fill="#333333"></path>
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#333333"></path>
            </svg>
          </div>
        </div>
      </div>


      <div class="popup__content">

        <div v-if="employee.favorites.length" class="posts jobs">
          <div class="posts__list">

            <template v-for="(item, index) in employee.favorites" :key="index">
              <template v-if="item.type == 'user'">
                <CardUser :user="item.user" :tab="item.tab" :type="'favorites'"></CardUser>
              </template>
              <template v-if="item.type == 'company'">
                <CardCompany :company="item.company" :tab="item.tab" :type="'favorites'"></CardCompany>
              </template>
              <template v-if="item.type == 'job'">
                <CardJob :job="item.job" :tab="item.tab" :type="'favorites'"></CardJob>
              </template>

            </template>
          </div>

        </div>

        <div v-else class="wysiwyg align-center">
          <img class="mt100" src="@/assets/images/favorites-empty.svg" alt="No content">
          <h3>No content to display yet</h3>
          <p>Your posts that you add will be displayed here</p>
        </div>

      </div>

    </div>
  </div>
</div>
</template>

<script>
import store from '@/store'
import CardUser from "@/components/CardUser.vue"
import CardCompany from "@/components/CardCompany.vue"
import CardJob from "@/components/CardJob.vue"

export default {
  name: 'FullFavoritesView',
  components: {
    CardUser,
    CardCompany,
    CardJob,
  },
  props: {
    type: {
      default: "normal",
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {
    closePopup() {
      store.dispatch('SHOW_POPUP', { popup: 'fullFavoritesViewPopup', show: false });
      store.commit('FAVORITES_RESET');
    },

  },
  computed: {
    popups: () => store.state.popups,
    employee: () => store.state.employee,
    job: () => store.state.job,
  },
}
</script>
