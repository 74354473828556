<template>
    <div v-if="this.popups.calendarShowEventPopup" :class="{ 'popup-visible' : this.popups.calendarShowEventPopup }" class="popup entrepreneur-popup popup_tiny">
        <div class="popup__header">
            <div class="popup-action ml-auto">
                <div v-if="this.calendar.calendarViewEvent.data.created_by.id == this.userdata.user_profile.id"
                @click.prevent="editEvent(this.calendar.calendarViewEvent.id)" class="popup-action__item popup-action__item_edit">
                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.1582C14.777 2.1582 14.5631 2.24681 14.4053 2.40452L7.42724 9.38263L7.03079 10.9684L8.61658 10.572L15.5947 3.59386C15.7524 3.43615 15.841 3.22224 15.841 2.99919C15.841 2.77615 15.7524 2.56224 15.5947 2.40452C15.437 2.24681 15.2231 2.1582 15 2.1582ZM13.3447 1.34386C13.7837 0.904842 14.3791 0.658203 15 0.658203C15.6209 0.658203 16.2163 0.904842 16.6553 1.34386C17.0944 1.78288 17.341 2.37832 17.341 2.99919C17.341 3.62006 17.0944 4.2155 16.6553 4.65452L9.53035 11.7795C9.43423 11.8756 9.31379 11.9438 9.18192 11.9768L6.18192 12.7268C5.92634 12.7907 5.65597 12.7158 5.46969 12.5295C5.2834 12.3432 5.20851 12.0729 5.27241 11.8173L6.02241 8.81729C6.05538 8.68542 6.12357 8.56498 6.21969 8.46886L13.3447 1.34386Z" fill="#00A961"/>
                    </svg>
                </div>
                <div @click.prevent="removeEvent(this.calendar.calendarViewEvent.id)" class="popup-action__item popup-action__item_trash">
                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#00A961"/>
                    </svg>
                </div>
                <div @click.prevent="closeEvent" class="popup-action__item popup-action__item_remove">
                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4676 2.53243C15.8441 2.90901 15.8441 3.51956 15.4676 3.89614L3.89614 15.4676C3.51956 15.8441 2.90901 15.8441 2.53243 15.4676C2.15586 15.091 2.15586 14.4804 2.53243 14.1039L14.1039 2.53243C14.4804 2.15586 15.091 2.15586 15.4676 2.53243Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.53243 2.53243C2.90901 2.15586 3.51956 2.15586 3.89614 2.53243L15.4676 14.1039C15.8441 14.4804 15.8441 15.091 15.4676 15.4676C15.091 15.8441 14.4804 15.8441 14.1039 15.4676L2.53243 3.89614C2.15586 3.51956 2.15586 2.90901 2.53243 2.53243Z" fill="#00A961"/>
                    </svg>
                </div>
            </div>
        </div><!-- //popup__header -->
        <div class="popup__content">
            <div class="wysiwyg">
                <h2>{{ this.calendar.calendarViewEvent.title }}</h2>
            </div>
            <div class="dl">
                <div class="dl__list">
                    <div class="dl__item dl__item_tertiary">
                        <div class="dl__title">
                            <span class="title-text">Description</span>
                        </div>
                        <div class="dl__value w-100 e_desc_text">
                            <span class="value-text">{{ this.calendar.calendarViewEvent.description }}</span>
                        </div>
                    </div>
                    <div class="dl__item dl__item_tertiary">
                        <div class="dl__title">
                            <span class="title-text">Date</span>
                        </div>
                        <div class="dl__value w-100">
                            <span class="value-text">{{ this.calendar.calendarViewEvent.date }}</span>
                        </div>
                    </div>
                    <div class="dl__item dl__item_tertiary">
                        <div class="dl__title">
                            <span class="title-text">Hour</span>
                        </div>
                        <div class="dl__value w-100">
                            <span class="value-text">{{ this.calendar.calendarViewEvent.hour }}</span>
                        </div>
                    </div>
                    <div class="dl__item dl__item_tertiary">
                        <div class="dl__title">
                            <span class="title-text">Duration</span>
                        </div>
                        <div class="dl__value w-100">
                            <span class="value-text">{{ this.calendar.calendarViewEvent.duration }}</span>
                        </div>
                    </div>
                    <!-- <div class="dl__item dl__item_tertiary">
                        <div class="dl__title">
                            <span class="title-text">For</span>
                        </div>
                        <div class="dl__value w-100">
                            <span class="value-text">This work page</span>
                        </div>
                    </div> -->
                    <div v-if="this.calendar.calendarViewEvent.participants.length > 0" class="dl__item dl__item_tertiary">
                        <div class="dl__title">
                            <span class="title-text">Guests</span>
                        </div>
                        <div class="dl__value w-100 e_guest_list">
                            <div class="box">
                                <div class="box__list">
                                    <div
                                    v-for="guest in this.calendar.calendarViewEvent.participants" :key="guest.id"
                                    class="box__item align_big-desktop-center box__item_read p_0">
                                        <div class="box__avatar box__avatar_secondary">
                                            <img v-if="guest.avatar" :src="guest.avatar" alt="" />
                                            <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                        </div>
                                        <div class="box__main direction_big-desktop-row">
                                            <div class="box__header">
                                                <div class="wysiwyg wysiwyg_secondary m_0">
                                                    <h4 class="m_0">{{guest.firstname}} {{guest.lastname}} </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dl__item dl__item_secondary">
                        <div class="dl__title">
                            <span class="title-text">Attach File</span>
                        </div>
                        <div v-if="this.calendar.calendarViewEvent.attachment" class="dl__value w-100">
                            <div class="files">
                                <div class="files__list">
                                    <div class="files__item">
                                        <a href="#"
                                        @click.prevent="showAttach(this.calendar.calendarViewEvent.attachment)"
                                        class="files__link">
                                            <svg v-show="this.calendar.calendarViewEvent.attachment.ext_type === 'video'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5932 4.58313C17.843 4.71168 18 4.96906 18 5.25V12.75C18 13.0309 17.843 13.2883 17.5932 13.4169C17.3434 13.5454 17.0427 13.5236 16.8141 13.3603L11.5641 9.6103C11.367 9.46952 11.25 9.24222 11.25 9C11.25 8.75779 11.367 8.53049 11.5641 8.3897L16.8141 4.6397C17.0427 4.47641 17.3434 4.45457 17.5932 4.58313ZM13.2903 9L16.5 11.2926V6.70739L13.2903 9Z" fill="#00A961" />
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V12.75C1.5 13.1642 1.83579 13.5 2.25 13.5H10.5C10.9142 13.5 11.25 13.1642 11.25 12.75V5.25C11.25 4.83579 10.9142 4.5 10.5 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H10.5C11.7426 3 12.75 4.00736 12.75 5.25V12.75C12.75 13.9926 11.7426 15 10.5 15H2.25C1.00736 15 0 13.9926 0 12.75V5.25Z" fill="#00A961" />
                                            </svg>
                                            <svg v-show="this.calendar.calendarViewEvent.attachment.ext_type === 'document'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
                                            </svg>
                                            <svg v-show="this.calendar.calendarViewEvent.attachment.ext_type === 'image'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961" />
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961" />
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961" />
                                            </svg>
                                            <span class="files__text">{{this.calendar.calendarViewEvent.attachment.user_defined_filename}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!this.calendar.calendarViewEvent.attachment" class="dl__text">
                    The file was not attached
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'
// import axios from 'axios'

export default {
    name: "CalendarShowEvent",
    components: {

    },
    data() {
        return {
        }
    },
    methods: {
        editEvent: function() {
            let editableEvent = {};
            if(this.calendar.calendarViewEvent) {
                editableEvent.title = this.calendar.calendarViewEvent.title;
                editableEvent.description = this.calendar.calendarViewEvent.description;
                let datetime = new Date(this.calendar.calendarViewEvent.data.event_datetime*1000);
                let hourStart = datetime.getHours();
                let minuteStart = datetime.getMinutes();

                let editableDate = new Date(this.calendar.calendarViewEvent.date)
                editableEvent.date = editableDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });

                let hourH = hourStart < 10 ? "0"+hourStart : hourStart;
                let hourM = minuteStart < 10 ? "0"+minuteStart : minuteStart;
                editableEvent.hour = hourH+":"+hourM;

                let durationH = Math.floor(this.calendar.calendarViewEvent.data.duration/60);
                let durationM = this.calendar.calendarViewEvent.data.duration - Math.floor(this.calendar.calendarViewEvent.data.duration/60)*60;
                if(durationH<10) durationH = "0"+durationH;
                if(durationM<10) durationM = "0"+durationM;
                editableEvent.duration = durationH+":"+durationM;
                let i = '';
                editableEvent.participants = {};
                for (i in this.calendar.calendarViewEvent.participants) {
                    let p = this.calendar.calendarViewEvent.participants[i];
                    editableEvent.participants[p.id] = p.firstname + " " + p.lastname;
                }
                editableEvent.attachment = this.calendar.calendarViewEvent.attachment;
                editableEvent.id = this.calendar.calendarViewEvent.id;
            }

            store.dispatch('SHOW_POPUP', { popup: 'calendarEventPopup', show: true, data: editableEvent });
        },
        removeEvent: function(id) {
            store.dispatch('SET_ID_EVENT_REMOVE', id);
            store.dispatch('SHOW_POPUP', {
                popup: 'confirmPopup',
                show: 'Are you sure you want to delete this event?',
                route: '',
                name: 'REMOVE_EVENT',
                data: ''
            });
            store.dispatch('RESET_VIEW_EVENT');
            store.dispatch('SHOW_POPUP', { popup: 'calendarShowEventPopup', show: false});
        },
        closeEvent: function() {
            store.dispatch('RESET_VIEW_EVENT');
            store.dispatch('SHOW_POPUP', { popup: 'calendarShowEventPopup', show: false});
        },
        showAttach: function(attachment) {
            store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
        }
    },
    computed: {
        popups: () => store.state.popups,
        userdata: () => store.state.user,
        calendar: () => store.state.calendar
    },
    updated() {}
}

</script>
