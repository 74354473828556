<template>
    <div>
        <nav-header></nav-header>
        <div class="container">
            <h2>Page "{{ msg }}" under development.</h2>
        </div>
    </div>
</template>

<script>
    import Navheader from "../components/Navheader.vue"

    export default {
        name: "Contacts",
        components: {
            "nav-header":Navheader,
        },
        props: {
            msg: {type: String, default: "Contacts",},
        }
    }
</script>
