<template>
<vueper-slides fade class="no-shadow" autoplay duration="2000" transition-speed="1000" :arrows="false">
  <vueper-slide
    v-for="(slide, i) in slides"
    :key="i"
    :image="slide.image"
    :content="slide.content" />
</vueper-slides>
</template>

<script>
// vueperslides component.
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: { VueperSlides, VueperSlide },
  data: () => ({
  slides: [
  {
    content: "Hello Everyone,<br> When we launch, this space will be used to bring the pre-registration pages here as about/explanation pages for our new members to be. But it's not immediately possible and less relevant as we keep our early beta closed to our network in the meantime so we appreciate your patience!",
    // You can also provide a URL for the image.
    image: require('@/assets/images/content/sliders/slider-img01.png')
  },
  {
    content: "Hello Everyone,<br> When we launch, this space will be used to bring the pre-registration pages here as about/explanation pages for our new members to be. But it's not immediately possible and less relevant as we keep our early beta closed to our network in the meantime so we appreciate your patience!",
    // You can also provide a URL for the image.
    image: require('@/assets/images/content/sliders/slider-img01.png')
  },
  // Other slides.
],
})
}
</script>
<style>
.vueperslides__parallax-wrapper{
  min-height: 100vh;
}
.vueperslides__track-inner{
        -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom){
  justify-content: flex-end;
  min-height: 60px;
}
.vueperslides--fade .vueperslide{
  margin: auto;
  background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
    max-height: 400px;
}
.vueperslides__bullets{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    list-style: none;
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
}
.vueperslides__bullet{
    margin: 0;
    text-indent: -9999px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 5px;
    padding: 0;
    border: 1px solid #00A961;
    background-color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.vueperslides__bullet--active .default{
    border-color: #00A961;
}
</style>