import { router } from '@/router'
import notificationsService from '../services/service.notifications'
import natsIO from '@/plugins/natsIO'

const notifications = {
  state: {
    teamNotifications: [],
    projectNotifications: [],
    personalNotifications: {
      access_notify: {
        data: [],
      },
      forum_response: {
        data: [],
      },
      forum_updates: [],
      messages: {
        data: [],
      },
    },
    managersNotifications: {
      data: [],
    },
    managersRequestAccessNotifications: {
      data: [],
    },

    notificationsAvatars: {},
    notificationsNames: {},
    // notificationsEvents: {},
    // System Popup Notification
    popupNotify: false,
    popupNotifyType: false,
    popupNotifyMsg: false,

    count_new: 0,
  },
  mutations: {
    SET_TEAM_BOX_NOTIFICATIONS: (state, payload) => {
      state.teamNotifications = payload.team.data;
      state.count_new = payload.count_new;
    },
    SET_PROJECT_BOX_NOTIFICATIONS: (state, payload) => {
      state.projectNotifications = payload.project.data;
      state.count_new = payload.count_new;
    },
    SET_PERSONAL_BOX_NOTIFICATIONS: (state, payload) => {
      state.personalNotifications = payload;
      state.count_new = payload.count_new;
    },
    SET_MANAGERS_BOX_NOTIFICATIONS: (state, payload) => {
      state.managersNotifications = payload.managers.data;
      state.count_new = payload.count_new;
    },
    SET_MANAGERS_REQUEST_ACCESS_BOX_NOTIFICATIONS: (state, payload) => {
      state.managersRequestAccessNotifications = payload.managers_request_access;
      state.count_new = payload.count_new;
    },
    SET_COUNTS_NOTIFICATIONS: (state, payload) => {
      state.count_new = payload.count_new;
    },
    SET_NOTIFICATION_AVATAR: (state, payload) => {
      state.notificationsAvatars[payload.id] = payload.src;
    },
    SET_NOTIFICATION_NAME: (state, payload) => {
      state.notificationsNames[payload.id] = payload.name;
    },
    SET_POPUP_NOTIFY: (state, payload) => {
      state.popupNotifyType = payload.type;
      state.popupNotifyMsg = payload.msg;
      state.popupNotify = payload.show;
    }
  },
  actions: {
    async GET_NOTIFICATIONS_TEAM({commit}) {
      const response = await notificationsService.getNotifications('team');
      if(response.status === 200) {
        commit('SET_TEAM_BOX_NOTIFICATIONS', response.data);
      }
    },

    async GET_NOTIFICATIONS_PROJECT({commit}) {
      const response = await notificationsService.getNotifications('project');
      if(response.status === 200) {
        commit('SET_PROJECT_BOX_NOTIFICATIONS', response.data);
      }
    },

    async GET_NOTIFICATIONS_PERSONAL({commit}) {
      const response = await notificationsService.getNotifications('personal');
      if(response.status === 200) {
        commit('SET_PERSONAL_BOX_NOTIFICATIONS', response.data);
      }
    },

    async GET_NOTIFICATIONS_MANAGERS({commit}) {
      const response = await notificationsService.getNotifications('managers');
      if(response.status === 200) {
        commit('SET_MANAGERS_BOX_NOTIFICATIONS', response.data);
      }
    },

    async GET_NOTIFICATIONS_MANAGERS_REQUEST_ACCESS({commit}) {
      const response = await notificationsService.getNotifications('managers_request');
      if(response.status === 200) {
        commit('SET_MANAGERS_REQUEST_ACCESS_BOX_NOTIFICATIONS', response.data);
      }
    },

    async SET_NOTIFICATION_VIEWED({dispatch}, payload) {
      const response = await notificationsService.setNotificationViewed(payload.id);
      if(response.status === 200) {
        if(payload.type == 'personal') dispatch('GET_NOTIFICATIONS_PERSONAL');
        if(payload.type == 'team') dispatch('GET_NOTIFICATIONS_TEAM');
        if(payload.type == 'manager') dispatch('GET_NOTIFICATIONS_MANAGERS');
      }
    },


    async ALLOW_ACCESS({dispatch}, payload) {
      const response = await notificationsService.allowAccess(payload);
      if(response.status === 200) {
        dispatch('GET_NOTIFICATIONS_MANAGERS');
        dispatch('GET_NOTIFICATIONS_MANAGERS_REQUEST_ACCESS');
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
      }
    },

    async REJECT_ACCESS({dispatch}, payload) {
      const response = await notificationsService.rejectAccess(payload);
      if(response.status === 200) {
        dispatch('GET_NOTIFICATIONS_MANAGERS');
        dispatch('GET_NOTIFICATIONS_MANAGERS_REQUEST_ACCESS');
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
      }
    },

    async REJECT_INVITE({dispatch}, payload) {
      const response = await notificationsService.rejectInvite(payload);
      if(response.status === 200) {
        dispatch('GET_NOTIFICATIONS_TEAM');
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
      }
    },

    async ACCEPT_INVITE({dispatch}, payload) {
      const response = await notificationsService.acceptInvite(payload);
      if(response.status === 200) {
        await dispatch('GET_NOTIFICATIONS_TEAM');
        await dispatch('SET_USER_PROFILE');
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
        router.push({name: 'company_profile_edit'});
      }
    },

    async REJECT_INVITE_WORK_PAGE({dispatch}, payload) {
      const response = await notificationsService.rejectInviteWorkPage(payload);
      if(response.status === 200) {
        dispatch('GET_NOTIFICATIONS_TEAM');
      }
    },

    async ACCEPT_INVITE_WORK_PAGE({dispatch}, payload) {
      const response = await notificationsService.acceptInviteWorkPage(payload);
      if(response.status === 200) {
        dispatch('GET_NOTIFICATIONS_TEAM');
      }
    },


    async SHOW_POPUP_NOTIFY({commit}, payload) {
        commit('SET_POPUP_NOTIFY', payload);
        setTimeout(function() {
          payload.show = false;
          payload.type = false;
          payload.msg = false;
          commit('SET_POPUP_NOTIFY', payload);
        }, 15000);
    },
    async JOIN_REJECT({dispatch}, payload) {
      const response = await notificationsService.joinReject(payload);
      if(response.status === 200) {
        // commit('SET_MANAGERS_BOX_NOTIFICATIONS', response.data);
        dispatch('GET_NOTIFICATIONS_MANAGERS');
      }
    },
    async JOIN_ACCEPT({dispatch}, payload) {
      const response = await notificationsService.joinAccept(payload);
      if(response.status === 200) {
        // commit('SET_MANAGERS_BOX_NOTIFICATIONS', response.data);
        dispatch('GET_NOTIFICATIONS_MANAGERS');
      }
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'successPopup', show: response.data.message });
      }
    },

    /* Nats */
    async NATS_GET_NOTIFICATIONS_COUNTS({commit, dispatch}, payload) {
      const response = await notificationsService.getCountsNotifications();
      if(response.status === 200) {
        commit('SET_COUNTS_NOTIFICATIONS', response.data);
      }
      if(localStorage.route_name === 'notifications') { /* when open Notifications page */
        dispatch('GET_NOTIFICATIONS_PERSONAL');
        dispatch('GET_NOTIFICATIONS_TEAM');
        dispatch('GET_NOTIFICATIONS_PROJECT');
        dispatch('GET_NOTIFICATIONS_MANAGERS');
        dispatch('GET_NOTIFICATIONS_MANAGERS_REQUEST_ACCESS');
      }
      if(localStorage.route_name === 'forumTopic') { /* when open Forum Topic page */
        dispatch('GET_FORUM_TOPIC_NOTIFICATIONS', localStorage.route_param_id);
      }
      if(localStorage.route_name === 'crm') { /* when open CRM page */
        dispatch('GET_ALL_CONVERSTATIONS');
      }
      if(payload === 'WorkPageUpdate') {
        dispatch('GET_PAGES_DATA');
      }
      if(payload === 'ProjectUpdate') {
        dispatch('GET_PROJECTS_DATA');
      }
      if(payload === 'CompanyRole') dispatch('SET_USER_PROFILE');
      if(payload === 'CompanyMessage') dispatch('GET_MANAGER_CONVERSTATIONS');
    },
    NATS_NOTIFY_MANAGERS({dispatch}, payload) {
      if(payload.company === this.state.user.user_profile.companyid && ['administrator', 'community_manager'].includes(this.state.user.user_profile.in_company_role)) {
        dispatch('GET_MANAGER_CONVERSTATIONS');
      }
    },

  },
    getters: {
  }
}
export default notifications;