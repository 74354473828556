<template>
  <div class="site-header__user user">
    <div class="feedback">
      <button @click="showFeedBackPopup" class="feedback_btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C6.615 2.25 2.25 6.615 2.25 12C2.25 17.385 6.615 21.75 12 21.75C17.385 21.75 21.75 17.385 21.75 12C21.75 6.615 17.385 2.25 12 2.25ZM9.375 8.25C8.835 8.25 8.547 8.669 8.439 8.884C8.3107 9.15445 8.24604 9.45069 8.25 9.75C8.25 10.048 8.309 10.355 8.439 10.616C8.547 10.831 8.834 11.25 9.375 11.25C9.915 11.25 10.203 10.831 10.311 10.616C10.441 10.356 10.5 10.048 10.5 9.75C10.5 9.452 10.441 9.145 10.311 8.884C10.203 8.669 9.916 8.25 9.375 8.25ZM13.689 8.884C13.797 8.669 14.084 8.25 14.625 8.25C15.165 8.25 15.453 8.669 15.561 8.884C15.691 9.144 15.75 9.452 15.75 9.75C15.75 10.048 15.691 10.355 15.561 10.616C15.453 10.831 15.166 11.25 14.625 11.25C14.085 11.25 13.797 10.831 13.689 10.616C13.5607 10.3456 13.496 10.0493 13.5 9.75C13.5 9.452 13.559 9.145 13.689 8.884ZM15.712 15.712C15.7857 15.6433 15.8448 15.5605 15.8858 15.4685C15.9268 15.3765 15.9488 15.2772 15.9506 15.1765C15.9524 15.0758 15.9338 14.9758 15.8961 14.8824C15.8584 14.789 15.8023 14.7042 15.731 14.633C15.6598 14.5617 15.575 14.5056 15.4816 14.4679C15.3882 14.4302 15.2882 14.4116 15.1875 14.4134C15.0868 14.4152 14.9875 14.4372 14.8955 14.4782C14.8035 14.5192 14.7207 14.5783 14.652 14.652C14.3038 15.0003 13.8903 15.2766 13.4353 15.4652C12.9803 15.6537 12.4925 15.7507 12 15.7507C11.5075 15.7507 11.0197 15.6537 10.5647 15.4652C10.1097 15.2766 9.69623 15.0003 9.348 14.652C9.20582 14.5195 9.01778 14.4474 8.82348 14.4508C8.62918 14.4543 8.44379 14.533 8.30638 14.6704C8.16897 14.8078 8.09025 14.9932 8.08683 15.1875C8.0834 15.3818 8.15552 15.5698 8.288 15.712C9.27254 16.6964 10.6078 17.2494 12 17.2494C13.3922 17.2494 14.7275 16.6964 15.712 15.712Z" fill="#00A961"/>
        </svg>
        <span class="btn_text">Feedback</span>
      </button>
    </div>
    <div class="user__avatar">
      <img v-if="userdata.user_profile.avatar" :src="userdata.user_profile.avatar" alt="Start-Up World" />
      <img v-else src="../assets/images/avatar-default.png" alt="Start-Up World" />
    </div>
    <div class="user__name">
      <span class="name-text">{{ userdata.user_profile.firstname }} {{ userdata.user_profile.lastname }}</span><!-- Got from data -->
    </div>
    <div class="user__dropdown dropdown dropdown_secondary dropdown_secondary-content"
    :class="{ dropdown_active: showDropdown }">
    <div class="dropdown__init"
    @click="showDropdown = !showDropdown">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.08748 4.83751C3.31529 4.60971 3.68463 4.60971 3.91244 4.83751L6.99996 7.92503L10.0875 4.83751C10.3153 4.60971 10.6846 4.60971 10.9124 4.83751C11.1402 5.06532 11.1402 5.43466 10.9124 5.66247L7.41244 9.16247C7.18463 9.39028 6.81529 9.39028 6.58748 9.16247L3.08748 5.66247C2.85967 5.43466 2.85967 5.06532 3.08748 4.83751Z" fill="#5D6167"/>
    </svg>
  </div>
  <div class="dropdown__content">
    <ul class="dropdown__list">
      <li class="dropdown__item">
        <router-link :to="{name: 'company_profile_edit'}" @click="showDropdown = false" class="dropdown__link">Company Profile page</router-link>
      </li>
      <li class="dropdown__item">
        <router-link :to="{name: 'profile_edit'}" @click="showDropdown = false" class="dropdown__link">Profile page</router-link>
      </li>
      <li class="dropdown__item">
        <a href="#" @click.prevent="showSettingsPopup" class="dropdown__link">Settings</a>
      </li>
      <li class="dropdown__item">
        <a href="#" @click.prevent="logout" class="dropdown__link">Logout</a>
      </li>
    </ul>
  </div>
</div>

<div :class="{ 'popup-visible' : this.logoutPopup }">
  <div class="popup-wrap">
    <div class="popup successfully-popup" :class="{ 'active' : this.logoutPopup }">
      <form action="#" class="popup__form">
        <div class="popup__header">
          <div class="popup__title">
            <span class="title-text">Are you sure you want to logout?</span>
          </div>
          <div class="popup-remove" @click.prevent="confirmLogout(false)">
            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path>
            </svg>
          </div>
        </div><!-- //popup__header -->
        <div class="popup__content">
          <div class="popup__section">
            <div class="wysiwyg">
              <!-- <p>
                The verification email has been sent to your email box. Please follow the link from the
                verification
                email
              </p> -->
            </div>
          </div>
        </div>
        <div class="popup__footer">
          <div class="btn-group btn-group_secondary content-right">
            <a href="" @click.prevent="confirmLogout(false)" class="btn btn_secondary ">
              <span class="btn__text">No</span>
            </a>
            <a href="" @click.prevent="confirmLogout(true)" class="btn btn_primary">
              <span class="btn__text">Yes</span>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</template>

<script>

// import VLink from "./VLink.vue"
import store from '../store'

export default {
  name: "UserCorner",
  components: {
    // VLink,
  },
  data() {
    return {
      showDropdown: false,
      logoutPopup: false,
    }
  },
  
  methods: {
    logout: function() {
      this.logoutPopup = true;
    },
    confirmLogout: function(confirm) {
      if(confirm){
        store.dispatch('USER_LOGOUT');
      } else {
        this.logoutPopup = false;
      }
    },
    showSettingsPopup: function() {
      store.dispatch('SHOW_POPUP', { popup: 'settingsPopup', show: true});
    },
    closeSelect(e) {
      if (!e.target.closest(".dropdown_active")) {
        this.showDropdown = false;
      }
    },
    showFeedBackPopup() {
      store.dispatch('SHOW_POPUP', { popup: 'feedbackPopup', show: true});
    },
    
  },
  computed: {
    userdata: () => store.state.user,
  },
  mounted() {
    document.addEventListener('click', this.closeSelect)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeSelect)
  }
}



</script>
