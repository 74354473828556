const BaseUrl = process.env.VUE_APP_BACKEND_BASEURL;
const Providers = {
    google: BaseUrl+'user/auth/google/redirect',
    linkedin: BaseUrl+'user/auth/linkedin/redirect',
    calendar: BaseUrl+'user/auth/calendar',
}

const suwAPI = {
    apiBaseUrl : BaseUrl,
    oAuthProviders: Providers,
    apiBase: BaseUrl+'api/v1/',
    // User
    apiUser: BaseUrl+'api/v1/user',
    apiCompleteAccount: BaseUrl+'api/v1/user/completeaccount',
    apiUserConfirm: BaseUrl+'api/v1/user/email/verify/',
    apiSignUp: BaseUrl+'api/v1/user/createaccount',
    apiForgotPassword: BaseUrl+'api/v1/user/forgotpassword',
    apiLoginUrl: BaseUrl+'api/v1/user/login',
    apiLogout: BaseUrl+'api/v1/user/logout',
    apiRefresh: BaseUrl+'api/v1/user/refresh',
    apiRestorePassword: BaseUrl+'api/v1/user/restorepassword',
    apiAcceptInvitation: BaseUrl+'api/v1/user/accept_invitation',
    apiInvitationSignup: BaseUrl+'api/v1/user/invitation_signup',
    // Company
    apiGetCompany: BaseUrl+'api/v1/company',
    apiCompanySearch: BaseUrl+'api/v1/company/search/',
    apiIndustry: BaseUrl+'api/v1/company/industry',
    apiIndustryBusiness: BaseUrl+'api/v1/company/industry/business',
    apiIndustryInvestor: BaseUrl+'api/v1/company/industry/investor',
    // User Profile
    apiUserProfile: BaseUrl+'api/v1/user_profile',
    apiUserProfilePutAvatar: BaseUrl+'api/v1/files/put_avatar',
    apiUserProfilePutFile: BaseUrl+'api/v1/files/put',
    apiPutFiles: BaseUrl+'api/v1/files-multi',
    apiUserProfileExperience: BaseUrl+'api/v1/user_profile/experience',
    apiUserProfileEducation: BaseUrl+'api/v1/user_profile/education',
    apiUserProfileMedia: BaseUrl+'api/v1/user_profile/media',
    apiUserReview: BaseUrl+'api/v1/user_profile/review',
    apiUserSettings: BaseUrl+'api/v1/user_settings',
    apiUserUpdateCalendar: BaseUrl+'api/v1/user_settings/calendar',
    apiUploadPDF: BaseUrl+'api/v1/pdf-upload',
    apiUpdateFromPDF: BaseUrl+'api/v1/pdf-update',
    apiUserCancelRequestJoining: BaseUrl+'api/v1/cancel_request_joining',
    // Chat
    apiChat: BaseUrl+'api/v1/chat',
    // CRM
    apiCRM: BaseUrl+'api/v1/crm',
    apiCRMContacts: BaseUrl+'api/v1/crm/contacts',
    apiCRMCard: BaseUrl+'api/v1/crm/card',
    apiNotifications: BaseUrl+'api/v1/notifications',
    // Calendar
    apiCalendar: BaseUrl+'api/v1/calendar',
    // Pages
    apiPage: BaseUrl+'api/v1/page',
    apiPost: BaseUrl+'api/v1/post',
    apiPostComment: BaseUrl+'api/v1/post-comment',
    apiForum: BaseUrl+'api/v1/forum',
    apiEmployee: BaseUrl+'api/v1/employee',
    apiJob: BaseUrl+'api/v1/vacancy',
};

export default suwAPI;
