<template>
  <div class="section">
    <div class="section__header calendar_header">
      <div class="section__wysiwyg wysiwyg pointer">
        <h2 @click="showCalendarToggle" class="toggleCalendar" :class="{'toggleCalendarСollapsed': expandCalendar}">
          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9459_103618)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 6.21967C4.26256 5.92678 4.73744 5.92678 5.03033 6.21967L9 10.1893L12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967C14.3232 6.51256 14.3232 6.98744 14.0303 7.28033L9.53033 11.7803C9.23744 12.0732 8.76256 12.0732 8.46967 11.7803L3.96967 7.28033C3.67678 6.98744 3.67678 6.51256 3.96967 6.21967Z" fill="#5D6167"></path>
            </g>
            <defs>
              <clipPath id="clip0_9459_103618">
                <rect width="18" height="18" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>Calendar
          <ToolTip
            :type="'top mw-460'"
            :msg="'This is a Team/Platform integrated calendar, which can also connect to your google calendar*.<br><br>To add an event, simply click add event in the top right corner,<br><br>Add the:<br><br>- Title of the event<br>- Description<br>- Date, starting hour, and duration (how long you expect the calendar event to be)<br><br>- Invite guests through the platform be it teammates, third party experts you are working with, potential or current investors etc. this will automatically add it to everyone’s calendar and send a notification so no one misses it. If anyone can’t make the event they can remove it from their own calendars, removing them from the guest list on everyone else’s calendar as well.<br><br>- Attach any files that may be relevant to the event so everyone has easy access to the files leading up to the calendar event and at the event as well.<br><br>Finally save the event in the bottom right corner, and that’s all there is to it :)<br><br>*In order to connect your calendar to your google calendar so you have it all on the go with you, go to our settings section and click the button that says Link CRM Calendar to Google Calendar? Add your email, follow the steps from google and that’s it :)'"
          />
        </h2>
      </div>

      <div class="section__actions">
        <div class="form-group direction-row">
          <div class="form-field">
            <div class="form-field__content">
              <div :class="{'dropdown_active': selectCalendarPeriod}" class="dropdown">
                <div @click.prevent="openDropDown()" class="dropdown__init">
                  <a href="#" class="btn btn_ico">
                    <span class="title-text">{{this.CalendarPeriod}}</span>
                    <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg" style="max-width: initial;">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#00A961"></path>
                    </svg>
                  </a>
                </div>
                <div class="dropdown__content">
                  <ul class="dropdown__list">
                    <li class="dropdown__item">
                      <a @click.prevent="onChangeCalendarView('dayGridMonth')" href="#" class="dropdown__link">Month</a>
                    </li>
                    <li class="dropdown__item">
                      <a @click.prevent="onChangeCalendarView('timeGridWeek')" href="#" class="dropdown__link">Week</a>
                    </li>
                    <li class="dropdown__item">
                      <a @click.prevent="onChangeCalendarView('timeGridDay')" href="#" class="dropdown__link">Day</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div><!-- //form-field -->
          <div class="form-field">
            <div class="form-field__content">
              <button @click.prevent="addEventPopup" class="btn btn_primary ml-auto">Add event</button>
            </div>
          </div><!-- //form-field -->
        </div>
      </div>
    </div>
    <div v-show="this.expandCalendar" class="section__content calendar-wrapper">
      <div class="calendar">
        <div class="card" ref="cal"></div>
      </div>
    </div>
    <CalendarEvent></CalendarEvent>
    <CalendarShowEvent></CalendarShowEvent>
  </div>
</template>

<script>
import store from '@/store'
import {Calendar} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import CalendarEvent from '@/components/popups/CalendarEvent'
import CalendarShowEvent from '@/components/popups/CalendarShowEvent'
import ToolTip from '@/components/ToolTip'

export default {
  name: "Calendar",
  components: {
    CalendarEvent,
    CalendarShowEvent,
    ToolTip,
  },
  data() {
    return {
      Tcalendar: '',
      events: [],
      expandCalendar: false,
      selectCalendarPeriod: false,
      CalendarPeriod: 'Month',
    }
  },
  created() {
    this.$root.Calendar = this;
  },
  beforeMount() {
    // this.getCalendarEvents();
  },
  mounted(){
    this.Tcalendar = new Calendar(this.$refs.cal, {
      headerToolbar: {
        start: 'title prev next',
        center: '',
        end: ''
      },
      editable: true,
      allDaySlot: false,
      displayEventTime: false,
      contentHeight: 500,
      fixedWeekCount: false,
      events: this.events,
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
      initialView: 'dayGridMonth',
      eventClick: function(info) {
        store.dispatch('SHOW_POPUP', {
          popup: 'calendarShowEventPopup',
          show: false,
          posx: false,
          posy: false
        });
        store.dispatch('SET_VIEW_EVENT', info.event.id);
        store.dispatch('SHOW_POPUP', {
          popup: 'calendarShowEventPopup',
          show: true,
          posx: info.jsEvent.pageX,
          posy: info.jsEvent.pageY
        });

      },
      views: {
        // day: {
        //   titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
        // }
        // dayGridMonth: {
        //   titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
        // }
      },
    });
    this.Tcalendar.render();
    this.refreshCalendar();

    let fc_prev_button = this.$el.querySelector(".fc-prev-button");
    fc_prev_button.addEventListener("click", () => {
      var year = this.Tcalendar.getDate().getFullYear();
      var month = this.Tcalendar.getDate().getMonth()+1;
      this.refreshCalendarMonth(year, month);
    })

    let fc_next_button = this.$el.querySelector(".fc-next-button");
    fc_next_button.addEventListener("click", () => {
      var year = this.Tcalendar.getDate().getFullYear();
      var month = this.Tcalendar.getDate().getMonth()+1;
      this.refreshCalendarMonth(year, month);
    })

  },
  updated() {
    this.refreshCalendar();
  },
  methods: {
    async refreshCalendar() {
      await store.dispatch('GET_CALENDAR_EVENT');
      this.getCalendarEvents();
    },
    async refreshCalendarMonth(y, m) {
      await store.dispatch('GET_CALENDAR_EVENT_MONTH', {year: y, month: m});
      this.getCalendarEvents();
    },
    async getCalendarEvents() {
      await this.Tcalendar.removeAllEvents();
      // await store.dispatch('GET_CALENDAR_EVENT');
      let events = '';
      let prepareViewEvent = false;
      for (events in this.calendar.calendarEvents) {

        let data = {};
        let date = new Date(this.calendar.calendarEvents[events].event_datetime*1000);
        let end_date = new Date(this.calendar.calendarEvents[events].event_datetime*1000 + this.calendar.calendarEvents[events].duration*60*1000);
        data.id = this.calendar.calendarEvents[events].id;
        if(this.calendar.prepareViewEvent && this.calendar.prepareViewEvent == data.id) {
          prepareViewEvent = true;
        }
        data.title = this.calendar.calendarEvents[events].title;
        data.start = date.toISOString();
        data.end = end_date.toISOString();
        data.display = 'block';
        if(this.calendar.calendarEvents[events].created_by.id == this.userdata.user_profile.id) {
          data.backgroundColor = '#4DC390';
          data.borderColor = '#4DC390';
        } else {
          data.backgroundColor = '#ffffff';
          data.borderColor = '#00A961';
          data.textColor = '#00A961';
        }

        this.events.push(data);
        this.Tcalendar.addEvent(data);
      }
      if(this.calendar.prepareViewEvent && prepareViewEvent){
        await store.dispatch('SET_VIEW_EVENT', this.calendar.prepareViewEvent);
        store.dispatch('SHOW_POPUP', {
          popup: 'calendarShowEventPopup',
          show: false,
          posx: false,
          posy: false
        });
        store.dispatch('SHOW_POPUP', {
          popup: 'calendarShowEventPopup',
          show: true,
          // posx: info.jsEvent.pageX,
          // posy: info.jsEvent.pageY
        });
      }
      await store.dispatch('SET_PREPARE_VIEW_EVENT', '');
    },
    onChangeCalendarView: function(val) {
      if(!this.expandCalendar) this.showCalendarToggle();
      if(val == 'dayGridMonth') this.CalendarPeriod = "Month";
      if(val == 'timeGridWeek') this.CalendarPeriod = "Week";
      if(val == 'timeGridDay') this.CalendarPeriod = "Day";
      this.Tcalendar.changeView(val);
      this.selectCalendarPeriod = !this.selectCalendarPeriod;
    },
    addEventPopup: function() {
      if(!this.expandCalendar) this.showCalendarToggle();
      store.dispatch('RESET_VIEW_EVENT');
      store.dispatch('SHOW_POPUP', { popup: 'calendarEventPopup', show: true});
    },
    showCalendarToggle: function() {
      this.expandCalendar = !this.expandCalendar;
    },
    openDropDown: function() {
      this.selectCalendarPeriod = !this.selectCalendarPeriod;
    },
  },
  computed: {
      calendar: () => store.state.calendar,
      userdata: () => store.state.user,
      // FullCalendar: () => this.Tcalendar.currentData
  },
  watch: {
    'calendar.calendarEvents' () {
    //  console.log('refresh calendar')
     this.getCalendarEvents();
   }
}
}
</script>