<template>
  <main-layout>
    <div class="row">
      <div class="col col_8 col_tab-12">
        <div class="main">
          <div class="btn-group btn-group_secondary">
            <a @click.prevent="this.$router.go(-1)" href="#" class="btn pl-0">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1802 3.96967C11.4731 4.26256 11.4731 4.73744 11.1802 5.03033L7.21056 9L11.1802 12.9697C11.4731 13.2626 11.4731 13.7374 11.1802 14.0303C10.8873 14.3232 10.4125 14.3232 10.1196 14.0303L5.61957 9.53033C5.32668 9.23744 5.32668 8.76256 5.61957 8.46967L10.1196 3.96967C10.4125 3.67678 10.8873 3.67678 11.1802 3.96967Z" fill="#00A961"/>
              </svg>
              <span class="title-text">Back</span>
            </a>
          </div>
          <div class="header__wysiwyg wysiwyg">
            <h2 v-if="this.page.type == 'forum'">
              <router-link  :to="{name: 'forumTopic', params: {id: this.page.id}}">{{this.page.title}}</router-link>
            </h2>
            <h2 v-else-if="this.page.type == 'workpage'">
              <router-link  :to="{name: 'page', params: {id: this.page.id}}">{{this.page.title}}</router-link>
            </h2>
            <h2 v-else-if="this.page.type == 'home'">
              <router-link  :to="{name: 'home'}">{{this.page.title}}</router-link>
            </h2>
            <h2 v-else>{{this.page.title}}</h2>
          </div>


          <div class="posts">
            <div v-if="this.page.current_topic_post" class="post__list">
              <PostView :post="this.page.current_topic_post"></PostView>
            </div>
          </div>
        </div>

      </div>

      <div class="col col_4 col_tab-12">
        <aside class="sidebar sidebar_secondary" :class="{'sidebar_active': (page.open_sidebar_media || forum.open_sidebar_notification)}">
          <!-- header -->
          <div @click="closeSidebarMedia" class="sidebar__header">
            <svg class="title-ico" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24408 4.4107C6.91864 4.73614 6.91864 5.26378 7.24408 5.58922L11.6548 9.99996L7.24408 14.4107C6.91864 14.7361 6.91864 15.2638 7.24408 15.5892C7.56951 15.9147 8.09715 15.9147 8.42259 15.5892L13.4226 10.5892C13.748 10.2638 13.748 9.73614 13.4226 9.4107L8.42259 4.4107C8.09715 4.08527 7.56951 4.08527 7.24408 4.4107Z" fill="#5D6167" />
            </svg>
            <span class="title-text">Back</span>
          </div>
          <!-- content -->
          <Notifications v-if="['post'].includes($route.name)"></Notifications>
        </aside>
      </div>
    </div>

  <PostAddPopup></PostAddPopup>
  <ViewfilePopup></ViewfilePopup>
  <PostCommentEditPopup></PostCommentEditPopup>

  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue"
import store from '../store'
import PostView from '@/components/page/PostView'
import Notifications from '@/components/page/Notifications'
import PostAddPopup from '@/components/popups/PostAdd'
import ViewfilePopup from '@/components/popups/Viewfile'
import PostCommentEditPopup from '@/components/popups/PostCommentEdit'


export default {
  name: "Post",
  components: {
    MainLayout,
    PostView,
    Notifications,
    PostAddPopup,
    ViewfilePopup,
    PostCommentEditPopup,

  },
  props: {
  },
  data() {
    return {
      openSelect: false,
    }
  },
  methods: {
    getPost(id) {
      store.dispatch('GET_TOPIC_POST_DATA', id);
    },
    closeSidebarMedia() {
      if (['home', 'page'].includes(this.$route.name))
        store.commit('SET_SIDEBAR_MEDIA_OPEN', false);
      else
        store.commit('SET_SIDEBAR_NOTIFICATION_OPEN', false);
    },
  },
  created() {
    this.getPost(this.$route.params.id);
  },
  computed: {
    page: () => store.state.page,
    userdata: () => store.state.user,
    forum: () => store.state.forum,
  },
  beforeRouteUpdate(to, from) {
    if (from.params.id != to.params.id) {
      this.getPost(to.params.id);
    }
  }
}
</script>
