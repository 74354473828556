<template>
  <main-layout>
    <div class="row">
      <div class="col col_12">
        <div class="main">
          <!-- main-header -->
          <div class="main__header header">
            <div class="header__wysiwyg wysiwyg">
              <h1>Jobs</h1>
            </div>
          </div>
          <!-- main-content -->
          <div class="main__content">
            <form action="" class="form">
              <fieldset>
                <div class="row">
                  <div class="col col_4 col_mob-12 col_mob-12">
                    <div class="form-field">
                      <div class="form-field__content">
                        <div :class="{'dropdown_active': selectFoE}" class="dropdown">
                          <div @click.prevent="openDropDown('selectFoE')" class="dropdown__init btn btn_select">
                            <span class="title-text">
                              {{ job.field_of_expertise.filter(a => a.selected).length ?
                                job.field_of_expertise.filter(a => a.selected).map(a => a.value).join(', ') :
                                'Field of expertise' }}
                            </span>
                            <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167" />
                            </svg>
                          </div>
                          <div class="dropdown__content">
                            <div class="dropdown__content-header">
                              <h2>Filter</h2>
                            </div>
                            <ul class="dropdown__list three-rows">
                              <li class="dropdown__item">
                                <div class="custom-check dropdown__link">
                                  <label class="custom-check__label">
                                    <input class="custom-check__input" type="checkbox"
                                      v-model="job.all_foe"
                                      @change="selectAllFoW">
                                    <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g>
                                        <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                        <g>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span class="custom-check__text">All</span>
                                  </label>
                                </div>
                              </li>

                              <li v-for="foe in job.field_of_expertise" :key="foe.id" class="dropdown__item">
                                <div class="custom-check dropdown__link">
                                  <label class="custom-check__label">
                                    <input class="custom-check__input" type="checkbox"
                                      v-model="foe.selected"
                                      @change="filterFoW">
                                    <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g>
                                        <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                        <g>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span class="custom-check__text">{{foe.value}}</span>
                                  </label>
                                </div>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col col_4 col_mob-12 col_mob-12">
                    <div class="form-field">
                      <div class="form-field__content">
                        <div :class="{'dropdown_active': selectToE}" class="dropdown">
                          <div @click.prevent="openDropDown('selectToE')" class="dropdown__init btn btn_select">
                            <span class="title-text">
                              {{ job.type_of_employment.filter(a => a.selected).length ?
                                job.type_of_employment.filter(a => a.selected).map(a => a.value).join(', ') :
                                'Type of employment' }}
                            </span>
                            <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z"
                                fill="#5D6167" />
                            </svg>
                          </div>
                          <div class="dropdown__content">
                            <ul class="dropdown__list checkboxes">
                              <li class="dropdown__item">
                                <div class="custom-check dropdown__link">
                                  <label class="custom-check__label">
                                    <input class="custom-check__input" type="checkbox"
                                      v-model="job.all_toe"
                                      @change="selectAllToE">
                                    <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <g>
                                        <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                        <g>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span class="custom-check__text">All</span>
                                  </label>
                                </div>
                              </li>

                              <li v-for="(toe, index) in job.type_of_employment" :key="toe.id" class="dropdown__item">
                                <div class="custom-check dropdown__link">
                                  <label class="custom-check__label">
                                    <input class="custom-check__input" type="checkbox"
                                      v-model="toe.selected"
                                      @change="filterToE(index)">
                                    <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g>
                                        <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                        <g>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span class="custom-check__text">{{toe.value}}</span>
                                  </label>
                                </div>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col col_4 col_mob-12 col_mob-12">
                    <div class="form-field">
                      <div class="form-field__content">
                        <div :class="{'dropdown_active': selectHiring}" class="dropdown">
                          <div @click.prevent="openDropDown('selectHiring')" class="dropdown__init btn btn_select">
                            <span class="title-text">
                              {{ job.hiring.filter(a => a.selected).length ?
                                job.hiring.filter(a => a.selected).map(a => a.value).join(', ') :
                                'Hiring' }}
                            </span>
                            <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167" />
                            </svg>
                          </div>
                          <div class="dropdown__content">
                            <ul class="dropdown__list checkboxes">
                              <li class="dropdown__item">
                                <div class="custom-check dropdown__link">
                                  <label class="custom-check__label">
                                    <input class="custom-check__input" type="checkbox"
                                      v-model="job.all_hiring"
                                      @change="selectAllHiring">
                                    <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g>
                                        <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                        <g>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span class="custom-check__text">All</span>
                                  </label>
                                </div>
                              </li>

                              <li v-for="(hiring, index) in job.hiring" :key="hiring.id" class="dropdown__item">
                                <div class="custom-check dropdown__link">
                                  <label class="custom-check__label">
                                    <input class="custom-check__input" type="checkbox"
                                      v-model="hiring.selected"
                                      @change="filterHiring(index)">
                                    <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g>
                                        <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                        <g>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span class="custom-check__text">{{hiring.value}}</span>
                                  </label>
                                </div>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col col_9 col_big-desktop-8 col_small-desktop-7 col_tab-8 col_mob-12 col_mob-12">
                    <div class="form-field  form-field_tertiary">
                      <div class="form-field__content">
                        <input v-model="searchJobs" type="text" placeholder="Search ...." class="form-field__input" required>
                        <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333" />
                        </svg>
                        <svg @click="clearSearch" v-show="searchJobs.length" class="clear-search ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path></svg>
                      </div>
                    </div>
                  </div>
                  <div class="col col_3 col_big-desktop-4 col_small-desktop-5 col_tab-4 col_mob-12 col_mob-12">
                    <div class="section__action">
                      <div class="btn-group btn-group_secondary content_mobile-between">
                        <a @click.prevent="showMyJobs" href="#" class="btn btn_ico">
                          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3 3C2.80109 3 2.61032 3.07902 2.46967 3.21967C2.32902 3.36032 2.25 3.55109 2.25 3.75V14.25C2.25 14.4489 2.32902 14.6397 2.46967 14.7803C2.61032 14.921 2.80109 15 3 15H15C15.1989 15 15.3897 14.921 15.5303 14.7803C15.671 14.6397 15.75 14.4489 15.75 14.25V6C15.75 5.80109 15.671 5.61032 15.5303 5.46967C15.3897 5.32902 15.1989 5.25 15 5.25H8.25C7.99924 5.25 7.76506 5.12467 7.62596 4.91602L6.34861 3H3ZM1.40901 2.15901C1.83097 1.73705 2.40326 1.5 3 1.5H6.75C7.00076 1.5 7.23494 1.62533 7.37404 1.83397L8.65139 3.75H15C15.5967 3.75 16.169 3.98705 16.591 4.40901C17.0129 4.83097 17.25 5.40326 17.25 6V14.25C17.25 14.8467 17.0129 15.419 16.591 15.841C16.169 16.2629 15.5967 16.5 15 16.5H3C2.40326 16.5 1.83097 16.2629 1.40901 15.841C0.987053 15.419 0.75 14.8467 0.75 14.25V3.75C0.75 3.15326 0.987053 2.58097 1.40901 2.15901Z"
                              fill="#00A961" />
                          </svg>
                          <span class="btn__text">Your Job Postings</span>
                        </a>
                        <a @click.prevent="showFavorites" href="#" class="btn btn_ico">
                          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.25 9H15.75" stroke="#00A961" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.25 4.5H15.75" stroke="#00A961" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.25 13.5H15.75" stroke="#00A961" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <span class="btn__text">Favorites</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>

            <div class="section section_tertiary">
              <div class="posts jobs">
                <div class="posts__list">
                  <CardJob v-for="job in job.data" :key="job.id" :job="job" :type="'normal'"></CardJob>
                </div>
                <a @click.prevent="loadmore" v-show="job.meta.current_page < job.meta.last_page" href="javascript:;" class="btn btn_secondary">
                  <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_7883_838)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 2.25C1.16421 2.25 1.5 2.58579 1.5 3V6.75H5.25C5.66421 6.75 6 7.08579 6 7.5C6 7.91421 5.66421 8.25 5.25 8.25H0.75C0.335786 8.25 0 7.91421 0 7.5V3C0 2.58579 0.335786 2.25 0.75 2.25Z" fill="#00A961"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.5C12 10.0858 12.3358 9.75 12.75 9.75H17.25C17.6642 9.75 18 10.0858 18 10.5V15C18 15.4142 17.6642 15.75 17.25 15.75C16.8358 15.75 16.5 15.4142 16.5 15V11.25H12.75C12.3358 11.25 12 10.9142 12 10.5Z" fill="#00A961"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.92021 1.79729C8.13724 1.44527 9.42362 1.40727 10.6593 1.68686C11.895 1.96645 13.0397 2.55451 13.9867 3.39616C14.9336 4.23782 15.6519 5.30564 16.0745 6.49998C16.2127 6.89047 16.0082 7.31904 15.6177 7.45722C15.2272 7.5954 14.7986 7.39086 14.6605 7.00038C14.3224 6.0449 13.7477 5.19064 12.9902 4.51732C12.2326 3.844 11.3168 3.37355 10.3283 3.14988C9.33973 2.92621 8.31063 2.9566 7.337 3.23823C6.36338 3.51985 5.47696 4.04352 4.76047 4.76038C4.75492 4.76592 4.7493 4.77138 4.74358 4.77674L1.26358 8.04674C0.961725 8.33039 0.487081 8.31562 0.203437 8.01376C-0.0802062 7.7119 -0.0654396 7.23726 0.23642 6.95361L3.70808 3.69145C4.6022 2.79976 5.70705 2.1482 6.92021 1.79729ZM17.7966 9.9866C18.0802 10.2885 18.0654 10.7631 17.7636 11.0467L14.2919 14.3089C13.3978 15.2006 12.293 15.8522 11.0798 16.2031C9.86276 16.5551 8.57639 16.5931 7.3407 16.3135C6.10502 16.0339 4.9603 15.4458 4.01335 14.6042C3.06641 13.7625 2.3481 12.6947 1.92547 11.5004C1.78729 11.1099 1.99182 10.6813 2.38231 10.5431C2.77279 10.405 3.20136 10.6095 3.33954 11C3.67765 11.9555 4.25229 12.8097 5.00985 13.483C5.76741 14.1564 6.68318 14.6268 7.67173 14.8505C8.66028 15.0741 9.68938 15.0438 10.663 14.7621C11.6366 14.4805 12.523 13.9568 13.2395 13.24C13.2429 13.2366 13.2462 13.2333 13.2496 13.2301C13.2519 13.2279 13.2541 13.2258 13.2564 13.2236L16.7364 9.95361C17.0383 9.66997 17.5129 9.68474 17.7966 9.9866Z" fill="#00A961"></path>
                    </g>
                    <defs><clipPath id="clip0_7883_838"><rect width="18" height="18" fill="white"></rect></clipPath></defs>
                  </svg>

                  <span class="btn__text">Load more</span>
                </a>
              </div>

              <div v-show="!job.data.length" class="noResultsContainer">
                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="36" cy="36" r="36" fill="#00A961"></circle>
                  <path d="M40.2002 40.2L44.4002 44.4" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M27.5996 34.8C27.5996 38.7764 30.8232 42 34.7996 42C36.7912 42 38.5941 41.1913 39.8976 39.8844C41.1966 38.582 41.9996 36.7848 41.9996 34.8C41.9996 30.8235 38.776 27.6 34.7996 27.6C30.8232 27.6 27.5996 30.8235 27.5996 34.8Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h5>Nothing found for your request</h5>
                <p>Try changing the query and try again</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <FullFavoritesViewPopup :type="'job'"></FullFavoritesViewPopup>
    <FullJobPopup></FullJobPopup>
  </main-layout>
</template>

<script>
import store from '@/store'
import func from '@/plugins/functions'
import MainLayout from "../layouts/Main.vue"
import CardJob from "@/components/CardJob.vue"
import FullFavoritesViewPopup from '@/components/popups/FullFavoritesView'
import FullJobPopup from '@/components/popups/FullJob'

export default {
  name: "Jobs",
  components: {
    MainLayout,
    CardJob,
    FullFavoritesViewPopup,
    FullJobPopup,
  },
  data() {
    return {
      selectFoE: false,
      selectToE: false,
      selectHiring: false,
    }
  },
  methods: {

    loadmore() {
      store.dispatch('JOBS_LOAD_MORE');
    },

    clearSearch() {
      store.commit('SET_JOBS_SEARCH', '');
    },
    searchJobsHandler() {
      store.dispatch('GET_DATA_JOBS');
    },

    showFavorites() {
      store.dispatch('EMPLOYEE_FAVORITE_SHOW', 'job');
    },
    showMyJobs() {
      store.dispatch('JOBS_SHOW', 'job');
    },


    selectAllFoW() {
      store.dispatch('JOB_SELECT_ALL_FOE');
    },
    selectAllToE() {
      store.dispatch('JOB_SELECT_ALL_TOE');
    },
    selectAllHiring() {
      store.dispatch('JOB_SELECT_ALL_HIRING');
    },

    filterFoW() {
      this.debouncedFilterFoW();
    },
    filterFoWHandler() {
      store.dispatch('JOB_FILTER_FOE');
    },

    filterToE() {
      this.debouncedFilterToE();
    },
    filterToEHandler() {
      store.dispatch('JOB_FILTER_TOE');
    },

    filterHiring() {
      this.debouncedFilterHiring();
    },
    filterHiringHandler() {
      store.dispatch('JOB_FILTER_HIRING');
    },

    openDropDown(target) {
      let old = this[target];
        this.selectFoE = false;
        this.selectToE = false;
        this.selectHiring = false;
        this[target] = !old;
    },

    closeSelect(e) {
      if (!e.target.closest(".dropdown_active")) {
        this.selectFoE = false;
        this.selectToE = false;
        this.selectHiring = false;
      }
    },


  },
  watch: {
    searchJobs: function () {
      this.debouncedSearchJobs();
    },
  },
  computed: {
    job: () => store.state.job,
    searchJobs: {
      get() {
        return this.job.searchJobs;
      },
      set(value) {
        store.commit('SET_JOBS_SEARCH', value);
      }
    },
  },
  created() {
    store.dispatch('GET_DATA_JOBS');
    document.addEventListener('click', this.closeSelect);
    this.debouncedSearchJobs = func.debounce(this.searchJobsHandler, 500);
    this.debouncedFilterFoW = func.debounce(this.filterFoWHandler, 500);
    this.debouncedFilterToE = func.debounce(this.filterToEHandler, 500);
    this.debouncedFilterHiring = func.debounce(this.filterHiringHandler, 500);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeSelect)
  }
}
</script>
