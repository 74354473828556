import { connect, JSONCodec } from "nats.ws/cjs/nats.js";
import store from '../store'

let connectNatsMsg = '';
let connectNatsNotify = '';
let connectNatsChat = '';
let connectNatsEvent = '';
let nc = '';

const natsIO = {

    async initialNatsIO() {
        nc = await connect({
            reconnect: true,
            maxReconnectAttempts: -1,
            reconnectTimeWait: 5 * 1000, // 5s,
            maxPingOut: 3,
            pingInterval: 5 * 1000, // 5s,
            servers: process.env.VUE_APP_NATS_SERVER,
            token: process.env.VUE_APP_NATS_TOKEN
        });
        // console.log(`connected to NATS WS ${nc.getServer()}`);
        natsIO.initMsgChannel();
        natsIO.initChatChannel();
        natsIO.initNotifyChannel();
        natsIO.initEventChannel();
        await natsIO.subscribeStatusesChannel();
    },

    async publishMsg(recipient, data) {
        let subject = process.env.VUE_APP_NATS_WS_KEY+'_msg_'+recipient;
        const jc = JSONCodec();
        connectNatsMsg.publish(subject, jc.encode(data));
    },

    async publishChat(recipient, data) {
        let subject = process.env.VUE_APP_NATS_WS_KEY+'_chat_'+recipient;
        const jc = JSONCodec();
        connectNatsChat.publish(subject, jc.encode(data));
    },

    async publishNotify(recipient, data) {
        let subject = process.env.VUE_APP_NATS_WS_KEY+'_notify_'+recipient;
        const jc = JSONCodec();
        connectNatsNotify.publish(subject, jc.encode(data));
    },

    async publishEvent(data) {
        let subject = process.env.VUE_APP_NATS_WS_KEY+'_event';
        const jc = JSONCodec();
        connectNatsEvent.publish(subject, jc.encode(data));
    },

    async subscribeStatusesChannel(recipient){
        if(recipient){
            if(!store.state.chat.statuses[recipient]) {
                let data = {
                    status: "disconnect",  
                    user_id: recipient
                };

                if(store.state.user.user_profile.id == recipient) data.status = "offline";

                store.dispatch('SET_STATUSES', data);

                const subject = process.env.VUE_APP_NATS_WS_KEY+'_statuses_'+recipient;
                const jc = JSONCodec();
                let sub = nc.subscribe(subject);
                
                (async () => {
                    for await (const m of sub) {
                        let data = jc.decode(m.data);
                        
                        if(data.action == 'change') {
                            data.user_id = recipient;
                            store.dispatch('SET_STATUSES', data);
                        }
                        
                        if(data.action == "ping" && store.state.user.user_profile.id == recipient) {
                            let d = {
                                action: 'ping_response',
                                status: store.state.chat.statuses[recipient]
                            };
                            await natsIO.publishStatus(recipient, d);
                        }

                        if(data.action == 'ping_response' && store.state.user.user_profile.id == recipient) {
                            let k = {
                                action: 'change',
                                status: data.status
                              };
                              natsIO.publishStatus(recipient, k);
                        }

                        if(data.action == 'ping_response') {
                            let ping_data = {
                                user_id: recipient,
                                status: 'ping_response'
                              };
                              store.dispatch('SET_PING_STATUSES', ping_data);
                        }
                    }
                })();
            }
        }
    },

    async publishStatus(recipient, data) {
        let subject = process.env.VUE_APP_NATS_WS_KEY+'_statuses_'+recipient;
        const jc = JSONCodec();
        nc.publish(subject, jc.encode(data));
    },

    async newsubscribers(data) {
        if(data){
            data.team_members.forEach(element => {
                natsIO.subscribeStatusesChannel(element.to_user_id);
                natsIO.subscribeStatusesChannel(element.to_user?.id);
            });
            
            data.projects.forEach(element => {
                natsIO.subscribeStatusesChannel(element.to_user_id);
                natsIO.subscribeStatusesChannel(element.to_user?.id);
            });
    
            data.work_pages.forEach(element => {
                natsIO.subscribeStatusesChannel(element.to_user_id);
                natsIO.subscribeStatusesChannel(element.to_user?.id);
            });
        }
    },

    async checkStatus() {
        setInterval(async () => {
            let statuses = store.state.chat.statuses;
            for (const s in statuses) {
                if(store.state.user.user_profile.id == s) {
                    if (document.hidden) {
                        let upd_status = {
                            user_id: s,
                            status: 'offline'
                        };
                        store.dispatch('SET_STATUSES', upd_status);
                    }
                    continue;
                }
                
                let data = {
                    action: 'ping',
                };
                await natsIO.publishStatus(s, data);
                
                if(store.state.chat.ping_statuses[s] == 'ping_request') {
                    let upd_status = {
                        user_id: s,
                        status: 'disconnect'
                    };
                    store.dispatch('SET_STATUSES', upd_status);
                } 

                let ping_data = {
                    user_id: s,
                    status: 'ping_request'
                };
                store.dispatch('SET_PING_STATUSES', ping_data);
            }
          }, 10000);
    },

    // Messsage
    async initMsgChannel() {
        connectNatsMsg = nc;
        const subject = process.env.VUE_APP_NATS_WS_KEY+'_msg_'+store.state.user.user_profile.id;
        const jc = JSONCodec();
        let sub = connectNatsMsg.subscribe(subject);

        (async () => {
            for await (const m of sub) {
                let data = jc.decode(m.data);
                console.log('Msg: ',data)
                store.dispatch('CHAT_INCOMING_MESSAGE', data);
            }
        })();
        // await nc.drain();
    },

    // Chat state
    async initChatChannel() {
        connectNatsChat = nc;
        const subject = process.env.VUE_APP_NATS_WS_KEY+'_chat_'+store.state.user.user_profile.id;
        const jc = JSONCodec();
        let sub = connectNatsChat.subscribe(subject);

        (async () => {
            for await (const m of sub) {
                let data = jc.decode(m.data);
                console.log('Chat: ',data)
                store.dispatch('CHAT_STATE', data);
            }
        })();
        // await nc.drain();
    },

    // Notifications
    async initNotifyChannel() {
        connectNatsNotify = nc;
        const subject = process.env.VUE_APP_NATS_WS_KEY+'_notify_'+store.state.user.user_profile.id;
        const jc = JSONCodec();
        let sub = connectNatsNotify.subscribe(subject);

        (async () => {
            for await (const m of sub) {
                let data = jc.decode(m.data);
                console.log('Notify: ',data);
                if(store.state.user.user_profile.id == data.to_user) {
                    store.dispatch('SHOW_POPUP_NOTIFY', { type: data.params?.type || 'info', msg: data.message, show: true });

                    store.dispatch('NATS_GET_NOTIFICATIONS_COUNTS', data.notify_type);

                    if(data.notify_type == 'CalendarEvent') store.dispatch('GET_CALENDAR_EVENT');
                }
            }
        })();
        // await nc.drain();
    },

    // Event state
    async initEventChannel() {
        connectNatsEvent = nc;
        const subject = process.env.VUE_APP_NATS_WS_KEY+'_event';
        const jc = JSONCodec();
        let sub = connectNatsEvent.subscribe(subject);

        (async () => {
            for await (const m of sub) {
                let data = jc.decode(m.data);
                if(data.from !== store.state.user.user_profile.id) {
                    console.log('Event: ',data)
                    store.dispatch(data.action, data.info);
                }
            }
        })();
        // await nc.drain();
    },


    close() {
        if(connectNatsMsg) connectNatsMsg.close();
        if(connectNatsChat) connectNatsChat.close();
        if(connectNatsNotify) connectNatsNotify.close();
        if(connectNatsEvent) connectNatsEvent.close();
    }

}

export default natsIO;