<template>
    <div @click.prevent="closeSidebarPages" class="sidebar__header">
        <svg class="title-ico" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24408 4.4107C6.91864 4.73614 6.91864 5.26378 7.24408 5.58922L11.6548 9.99996L7.24408 14.4107C6.91864 14.7361 6.91864 15.2638 7.24408 15.5892C7.56951 15.9147 8.09715 15.9147 8.42259 15.5892L13.4226 10.5892C13.748 10.2638 13.748 9.73614 13.4226 9.4107L8.42259 4.4107C8.09715 4.08527 7.56951 4.08527 7.24408 4.4107Z" fill="#5D6167"/>
        </svg>
        <span class="title-text">Back</span>
    </div>
</template>

<script>
    import store from '../store'

    export default {
        name: "SidebarHeader",
        methods: {
            closeSidebarPages() {
                store.commit('SET_SIDEBAR_PAGES_OPEN', false);
            },
        }
    }
</script>
