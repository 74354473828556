<template>
  <iframe height="100%" width=100% :src="`${getFilePath}`" ></iframe>
</template>

<script>
export default {
  name: 'PDFJSViewer',
  props: {
    fileName: String
  },
  computed:{ 
    getFilePath() {
      const path = '/lib/pdfjs/web/viewer.html'; // ToDo add absolute path
      if(this.fileName!==''){
          return path +'?file=' + this.fileName
      }
      return path 
    }
  }
}
</script>
