<template>
<div :class="{ 'popup-visible' : this.popups.confirmPopup }">
<div class="popup-wrap" @click.self="closePopup">
    <div class="popup successfully-popup" :class="{ 'active' : this.popups.confirmPopup }">
        <form action="#" class="popup__form">
            <div class="popup__header">
                <div class="popup__title">
                    <span class="title-text">{{this.popups.confirmPopup}}</span>
                </div>
                <div @click.prevent="closePopup" class="popup-remove">
                    <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                              fill="#333333"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                              fill="#333333"/>
                    </svg>
                </div>
            </div>
            <div class="popup__content">
                <div class="popup__section">
                    <div class="wysiwyg">

                    </div>
                </div>
            </div>
            <div class="popup__footer">
                <div class="btn-group btn-group_secondary content-between">
                    <a href="#" @click.prevent="closeConfirmPopup" class="btn btn_secondary">
                        <span class="btn__text">No</span>
                    </a>
                    <a href="#" @click.prevent="confirmPopup" class="btn btn_primary">
                        <span class="btn__text">Yes</span>
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
</template>

<script>
import store from '@/store'
import { router } from '@/router'

export default {
    name: "ConfirmPopup",
    methods: {
        closePopup() {
            store.dispatch('SHOW_POPUP', { popup: 'confirmPopup', show: false});
            this.popups.confirmRemovePage = false;
            this.popups.confirmRemoveExp = false;
            this.popups.confirmRemoveEdu = false;
            this.popups.confirmRemoveMedia = false;
            this.popups.confirmRemoveCompanyMedia = false;
            this.popups.confirmRemovePost = false;
            this.popups.confirmRemoveComment = false;
            this.popups.confirmRemoveReview = false;
            this.popups.confirmClosePost = false;
        },
        async closeConfirmPopup() {
            let pathTo = this.popups.confirmRoute.path;
            await store.dispatch('SHOW_POPUP', { popup: 'confirmPopup', show: false, noguards: true });
            if(pathTo){
                router.push(pathTo);
            }
        },
        async confirmPopup() {
            if(this.popups.confirmUserProfile){
                await store.dispatch('UPDATE_USER_PROFILE', this.popups.confirmUserProfile);
            }
            if(this.popups.confirmCompanyProfile){
                await store.dispatch('SAVE_COMPANY', this.popups.confirmCompanyProfile);
            }
            if(this.popups.confirmRemovePage){
                await store.dispatch('REMOVE_PAGE', this.popups.confirmRemovePage);
                this.popups.confirmRemovePage = false;
            }
            if(this.popups.confirmRemoveExp){
                await store.dispatch('REMOVE_EXP', this.popups.confirmRemoveExp);
                this.popups.confirmRemoveExp = false;
            }
            if(this.popups.confirmRemoveEdu){
                await store.dispatch('REMOVE_EDU', this.popups.confirmRemoveEdu);
                this.popups.confirmRemoveEdu = false;
            }
            if(this.popups.confirmRemoveMedia){
                await store.dispatch('REMOVE_MEDIA', this.popups.confirmRemoveMedia);
                this.popups.confirmRemoveMedia = false;
            }
            if(this.popups.confirmRemoveCompanyMedia){
                await store.dispatch('REMOVE_COMPANY_MEDIA', this.popups.confirmRemoveCompanyMedia);
                this.popups.confirmRemoveCompanyMedia = false;
            }
            if(this.popups.confirmRemovePost){
                await store.dispatch('REMOVE_POST', this.popups.confirmRemovePost);
                this.popups.confirmRemovePost = false;
            }
            if(this.popups.confirmRemoveComment){
                await store.dispatch('REMOVE_COMMENT', this.popups.confirmRemoveComment);
                this.popups.confirmRemoveComment = false;
            }
            if(this.popups.confirmRemoveReview){
                await store.dispatch('REMOVE_REVIEW', this.popups.confirmRemoveReview);
                this.popups.confirmRemoveReview = false;
            }
            if(this.popups.confirmRemoveEvent){
                await store.dispatch('REMOVE_EVENT', this.calendar.calendarRemoveEventId);
                store.dispatch('GET_CALENDAR_EVENT');
                // this.$parent.getCalendarEvents();
            }
            if(this.popups.confirmRemoveJob){
                await store.dispatch('JOB_DELETE', this.popups.confirmRemoveJob);
            }
            if(this.popups.confirmRemoveNote){
                await store.dispatch('DELETE_NOTE', this.popups.confirmRemoveNote);
            }
            if(this.popups.confirmClosePost){
                store.dispatch('SHOW_POPUP', { popup: 'postAddPopup', show: false});
                this.popups.confirmClosePost = false;
            }
            let pathTo = this.popups.confirmRoute.path;
            await store.dispatch('SHOW_POPUP', { popup: 'confirmPopup', show: false,  noguards: true});
            if(pathTo){
                router.push(pathTo);
            }
        }
    },
    computed: {
        popups: () => store.state.popups,
        calendar: () => store.state.calendar,
    },
}
</script>
