<template>
    <aside class="sidebar sidebar_main" :class="{'sidebar_active': page.open_sidebar}">
        <sidebar-header></sidebar-header>
        <sidebar-user></sidebar-user>
        <sidebar-company></sidebar-company>
        <sidebar-content></sidebar-content>
    </aside>
</template>

<script>
    import SidebarHeader from "./SidebarHeader"
    import SidebarUser from "./SidebarUser"
    import SidebarCompany from "./SidebarCompany"
    import SidebarContent from "./SidebarContent"
    import store from '../store'

    export default {
        name: "Sidebar",
        components: {
            "sidebar-user": SidebarUser,
            "sidebar-company": SidebarCompany,
            "sidebar-header": SidebarHeader,
            "sidebar-content": SidebarContent,
        },
        computed: {
            page: () => store.state.page,
        },
    }
</script>
