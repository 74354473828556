<template>
  <div class="section">
    <div class="section__header">
      <div class="section__wysiwyg wysiwyg">
        <h2>Contacts</h2>
      </div>
      <div class="section__actions">
        <div class="search">
          <div class="form-field form-field_tertiary">
            <div class="form-field__content">
              <input type="text" v-model="cardsContact" v-on:input="SearchContactsHandler" placeholder="Search ...." class="form-field__input" required>
              <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333" />
              </svg>
            </div>
          </div><!-- //form-field -->
        </div>
      </div>
    </div>
    <div class="section__content">

      <div class="contact">
        <div class="scroll-wrap" id="crm_contacts">
          <div v-if="this.crm.contacts_all.length <= 0" class="section__content crm_contacts_empty">
            <h3>No contacts.</h3>
          </div>
          <div v-else v-for="item in this.crm.contacts_all" :key='item.id' :data-id="item.id" class="section crm_contact_card">
            <div class="section__content">
              <div class="sub-section main_section">
                <div class="box">
                  <div class="box__list">
                    <div class="box__item p_0 br_0 bg_0">
                      <div v-if="item.type == 'user'" class="box__avatar box__avatar_tertiary">
                        <img v-if="item.contact.avatar" :src="item.contact.avatar" alt="" />
                        <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                      </div>
                      <div v-if="item.type == 'company'" class="box__company__logo box__company__logo_tertiary">
                        <img v-if="item.contact.companylogo" :src="item.contact.companylogo.link" alt="" />
                        <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                      </div>

                      <div class="box__main">
                        <div class="box__header">

                          <div v-if="item.type == 'user'" class="wysiwyg wysiwyg_secondary mb_0">
                            <router-link :to="{name: 'profile', params: { id: item.contact.id}}" class="mr-10">
                              <h3>{{item.contact.name}}</h3>
                            </router-link>
                            <input type="checkbox" class="read-more-state" :id="'items_list_'+item.id" />
                            <ul class="w-100 overflow_items">
                              <li v-if="item.contact.position">{{item.contact.position}}</li>
                              <li v-if="item.contact.company.companyname">
                                <router-link :to="{name: 'company_profile', params: {id: item.contact.company.id }}">
                                  {{ item.contact.company.companyname }}
                                </router-link>
                              </li>
                              <li>{{item.contact.country}}, {{item.contact.city}}</li>
                            </ul>
                            <label :for="'items_list_'+item.id" class="read-more-trigger"></label>
                          </div>

                          <div v-if="item.type == 'company'" class="wysiwyg wysiwyg_secondary mb_0">
                            <router-link :to="{name: 'company_profile', params: { id: item.contact.id}}" class="mr-10">
                              <h3>{{item.contact.companyname}}</h3>
                            </router-link>
                            <input type="checkbox" class="read-more-state" :id="'items_list_'+item.id" />
                            <ul class="w-100 overflow_items">
                              <template v-if="item.contact.companyindustry">
                                <li v-for="industry in item.contact.companyindustry" :key="industry.id">{{industry.value}}</li>
                              </template>
                              <li>{{item.contact.companycountry}}, {{item.contact.companycity}}</li>
                            </ul>
                            <label :for="'items_list_'+item.id" class="read-more-trigger"></label>
                          </div>

                          <div v-if="item.type == 'user' && item.contact.account_type === 'expert'" class="box__actions contact_card_actions">
                            <div class="rating">
                              <Rating :reviewsCount="item.contact.reviews_count" :reviewsAvg="item.contact.reviews_avg"></Rating>
                            </div>
                            <a href="#" @click.prevent.stop="showReviews(item.contact.id, item.type)"
                              v-if="item.contact.reviews_count > 0"><strong>Read reviews </strong></a>
                          </div>

                          <div v-if="item.type == 'company' && item.contact.companytype == 'business'" class="box__actions contact_card_actions">
                            <div class="rating">
                              <Rating :reviewsCount="item.contact.reviews_count" :reviewsAvg="item.contact.reviews_avg"></Rating>
                            </div>
                            <a href="#" @click.prevent.stop="showReviews(item.contact.id, item.type)"
                              v-if="item.contact.reviews_count > 0"><strong>Read reviews </strong></a>
                          </div>

                        </div>
                        <div class="box__content">
                          <input type="checkbox" class="read-more-state" :id="'item_description_'+item.id" />
                          <div class="wysiwyg overflow_description">
                            <p v-if="item.type == 'user'">
                              {{item.contact.description}}
                            </p>
                            <p v-if="item.type == 'company'">
                              {{item.contact.companydescription}}
                            </p>
                          </div>
                          <label :for="'item_description_'+item.id" class="read-more-trigger mb_minus"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-section bottom_section section__content">
                <div class="btn-group btn-group_secondary" @click.prevent.stop>
                  <router-link :to="{ name: 'crmUser', params: { id: item.id }}"
                    class="btn btn_tiny-p btn_secondary">
                    <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.80109 3 2.61032 3.07902 2.46967 3.21967C2.32902 3.36032 2.25 3.55109 2.25 3.75V14.25C2.25 14.4489 2.32902 14.6397 2.46967 14.7803C2.61032 14.921 2.80109 15 3 15H15C15.1989 15 15.3897 14.921 15.5303 14.7803C15.671 14.6397 15.75 14.4489 15.75 14.25V6C15.75 5.80109 15.671 5.61032 15.5303 5.46967C15.3897 5.32902 15.1989 5.25 15 5.25H8.25C7.99924 5.25 7.76506 5.12467 7.62596 4.91602L6.34861 3H3ZM1.40901 2.15901C1.83097 1.73705 2.40326 1.5 3 1.5H6.75C7.00076 1.5 7.23494 1.62533 7.37404 1.83397L8.65139 3.75H15C15.5967 3.75 16.169 3.98705 16.591 4.40901C17.0129 4.83097 17.25 5.40326 17.25 6V14.25C17.25 14.8467 17.0129 15.419 16.591 15.841C16.169 16.2629 15.5967 16.5 15 16.5H3C2.40326 16.5 1.83097 16.2629 1.40901 15.841C0.987053 15.419 0.75 14.8467 0.75 14.25V3.75C0.75 3.15326 0.987053 2.58097 1.40901 2.15901Z" fill="#00A961" />
                    </svg>
                    <span class="btn__text">Open card</span>
                  </router-link>
                  <a href="#" @click.prevent.stop="sendMessageCard(item)" class="btn btn_tiny-p btn_secondary">
                    <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V13.9393L4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 11.921 14.7803 11.7803C14.921 11.6397 15 11.4489 15 11.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H3.75ZM2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V11.25C16.5 11.8467 16.2629 12.419 15.841 12.841C15.419 13.2629 14.8467 13.5 14.25 13.5H5.56066L2.78033 16.2803C2.56583 16.4948 2.24324 16.559 1.96299 16.4429C1.68273 16.3268 1.5 16.0533 1.5 15.75V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901Z" fill="#00A961" />
                    </svg>
                    <span class="btn__text">Send message</span>
                  </a>
                  <a href="" @click.prevent.stop="removeCard(item.id)"
                    class="btn btn_quaternary btn_tiny-p ml-auto btn_secondary-ico">
                    <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#EB5757" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#EB5757" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#EB5757" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#EB5757" />
                    </svg>
                    <span class="btn__text">Remove</span>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <FullReviewsViewPopup></FullReviewsViewPopup>
</template>

<script>
import store from '@/store'
import Rating from '@/components/profile/Rating'
import FullReviewsViewPopup from '@/components/popups/FullReviewsView'

export default {
  name: "Contacts",
  components: {
    Rating,
    FullReviewsViewPopup,
  },
  data() {
    return {
      cardsContact: '',
    }
  },
  beforeMount() {
    this.getCrmContacts();
  },
  methods: {
    SearchContactsHandler: function () {
      if (this.cardsContact.length >= 1) {
        store.dispatch('SEARCH_CRM_ALL_CONTACTS', this.cardsContact);
      } else {
        store.dispatch('GET_CRM_ALL_CONTACTS');
      }
    },
    getCrmContacts: function () {
      store.dispatch('GET_CRM_ALL_CONTACTS');
    },
    sendMessageCard(item) {
      if (item.contact.in_my_team) {
          store.dispatch('CHAT_USER_CONVERSATION_OPEN', item.contact.id);
          return;
      }
      let data = {
        recipient: item.contact.id,
        type: item.type
      };
      store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: true, data: data });
      return;
    },
    async removeCard(id) {
      await store.dispatch('REMOVE_CONTACT_FROM_CRM', { contact_id: id });
      store.dispatch('GET_CRM_ALL_CONTACTS');
      store.dispatch('GET_ALL_CONVERSTATIONS');
      store.dispatch('GET_MANAGER_CONVERSTATIONS');
    },

    showReviews(id, type) {
      store.dispatch('EXPERT_REVIEWS', {id: id, type: type});
    },
    checkHeight() {
      this.$nextTick(function () {
        document.querySelectorAll('.overflow_items').forEach(item => {
          if(item.scrollHeight <= 60 && item.nextSibling) item.nextSibling.remove();
        });
        document.querySelectorAll('.overflow_description').forEach(item => {
          if(item.scrollHeight <= 80 && item.nextSibling) item.nextSibling.remove();
        });
      })
    },

    doubleClick(e) {
      let card = e.target.closest(".crm_contact_card");
      if(card) {
        let id = card.dataset.id;
        if(id > 0) this.$router.push({ name: 'crmUser', params: {id: id}});
      }
    },
  },
  computed: {
    crm: () => store.state.crm,
  },
  updated() {
    this.checkHeight();
  },
  mounted() {
    this.checkHeight();
    document.querySelector("#crm_contacts").addEventListener('dblclick', this.doubleClick);
  },
  beforeUnmount() {
    document.querySelector("#crm_contacts").removeEventListener('dblclick', this.doubleClick);
  },
}
</script>