import instance from './index';
import suwAPI from '../config/config'

const forumService = {

  getTopics(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiForum+'/?type='+data.type+'&search='+data?.search+'&page='+data?.page);
  },

  storeForumTopic(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiForum, data);
  },

  followTopic(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiForum+'/follow/'+id);
  },

  getForumTopicPage(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiForum+'/'+id);
  },

  getTopicNotifications(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiNotifications+'/forum-topic/'+id);
  },

}

export default forumService;