import natsIO from '@/plugins/natsIO'
import chatService from '../services/service.chat'
import crmService from '../services/service.crm'

const chat = {
  state: {
    /* Chat state */
    team_members: [],
    work_pages: [],
    projects: [],

    unread: 0, /* open chat btn count */
    chat_open: false,
    conversation_open: false,

    search_new_contacts: '',
    new_contacts: {
      team_members: [],
      work_pages: [],
      projects: [],
    },

    search_chats: '',
    found_chats: {
      contacts: [],
      messages: [],
      files: [],
    },

    /* Conversation state */
    conversation_id: false,
    conversation: {
      user: [],
      messages: {
        data: [],
        meta_top: {},
        meta_bottom: {},
      },
      muted: false,
      unread: 0, /* down btn count */
    },

    put_files: [],

    emoji: {
      data: [],
      types: [],
    },
    emoji_recent: [],

    statuses: {},
    ping_statuses: {},

    search_message: '',
    found_message_id: '',
    found_messages_count: 0,
    found_counts: {},
    chat_search_mode: false,

  },
  mutations: {
    CHAT_TOGGLE_OPEN: (state, payload) => {
      state.chat_open = payload;
    },
    CHAT_SET_ALL_CONTACTS: (state, payload) => {
      state.unread = payload.unread;
      state.team_members = payload.team_members;
      state.work_pages = payload.work_pages;
      state.projects = payload.projects;
    },

    CHAT_SET_NEW_CONTACTS: (state, payload) => {
      state.new_contacts = payload;
    },

    CHAT_SET_SEARCH_NEW_CONTACTS: (state, payload) => {
      state.search_new_contacts = payload;
    },
    CHAT_SET_SEARCH_MESSAGE: (state, payload) => {
      state.search_message = payload;
      if(payload.length < 1) state.found_messages_count = 0;
      state.found_message_id = '';
    },
    CHAT_SET_SEARCH_CHATS: (state, payload) => {
      state.search_chats = payload;
    },
    CHAT_SET_FOUND_CHATS: (state, payload) => {
      state.found_chats = payload;
    },
    CHAT_SET_NEW_CONVERSATION: (state, payload) => {
      state.conversation_id = 0;
      state.conversation = {
        user: payload.user,
        messages: {
          data: [],
          meta_top: {},
          meta_bottom: {},
        },
        muted: false,
        unread: 0,
      };
      state.conversation_open = true;
      state.search_message = '';
    },
    CHAT_SET_CONVERSATION_PARAMS: (state, payload) => {
      state.conversation_id = payload?.conversation_id ?? state.conversation_id;
      if(payload?.found_message_id !== undefined) state.found_message_id = payload.found_message_id;
      if(payload?.chat_search_mode !== undefined) state.chat_search_mode = payload.chat_search_mode;
      if(payload?.muted !== undefined) state.conversation.muted = payload.muted;
      if(payload?.unread !== undefined) state.conversation.unread = payload.unread;
    },

    /* Emoji */
    SET_EMOJI: (state, payload) => {
      state.emoji = payload;
    },
    SET_EMOJI_RECENT: (state) => {
      if (localStorage.getItem('emoji_recent')) {
        try {
          state.emoji_recent = JSON.parse(localStorage.getItem('emoji_recent'));
        } catch(e) {
          localStorage.removeItem('emoji_recent');
          state.emoji_recent = [];
        }
      }
    },
    TOGGLE_EMOJI_RECENT: (state, payload) => {
      let index = state.emoji_recent.findIndex(element => element == payload);
      if (index < 0) state.emoji_recent.push(payload);
      if (state.emoji_recent.length > 10) state.emoji_recent.splice(0, 1);
      let parsed = JSON.stringify(state.emoji_recent);
      localStorage.setItem('emoji_recent', parsed);
    },

    CHAT_CONVERSATION_TOGGLE_OPEN: (state, payload) => {
      state.conversation_open = payload.show;
      state.conversation_id = payload.conversation_id ?? false;
      if(!state.conversation_id) state.conversation = {
        user: [],
        messages: {
          data: [],
          meta_top: {},
          meta_bottom: {},
        },
        muted: false,
        unread: 0,
      };
    },
    CHAT_INIT_SET_CONVERSATION: (state, payload) => {
      console.log(payload)
      state.conversation_id = payload.conversation_id;
      state.conversation.messages.data = payload.messages_bottom.data.concat(payload.messages_top.data);

      state.conversation.messages.meta_top = payload.messages_top.meta;
      state.conversation.messages.meta_bottom = payload.messages_bottom.meta;
      state.conversation.muted = payload.muted;
      state.conversation.unread = payload.unread;
      state.conversation.user = payload.user;

      state.found_message_id = payload.found_message_id;
      state.found_messages_count = payload.found_messages_count;
      state.found_counts = payload.found_counts;
      state.conversation_open = true;
      state.chat_open = true;
    },
    CHAT_SET_CONVERSATION: (state, payload) => {
      state.conversation.messages.data = payload.messages.data;
      if(payload?.user !== undefined && payload.user) state.conversation.user = payload.user;
      if(payload.messages?.meta_top) state.conversation.messages.meta_top = payload.messages.meta_top;
      if(payload.messages?.meta_bottom) state.conversation.messages.meta_bottom = payload.messages.meta_bottom;
      if(payload?.found_message_id !== undefined) state.found_message_id = payload.found_message_id;
      if(payload?.found_messages_count !== undefined) state.found_messages_count = payload.found_messages_count;
      if(payload?.found_counts !== undefined) state.found_counts = payload.found_counts;
      if(payload?.muted !== undefined) state.conversation.muted = payload.muted;
      if(payload?.unread !== undefined) state.conversation.unread = payload.unread;
    },
    CHAT_UPDATE_USER: (state, payload) => {
      state.conversation.user = payload;
    },
    CHAT_ADD_MESSAGE: (state, payload) => {
      if(!state.chat_search_mode) state.conversation.messages.data.unshift(payload);
    },
    CHAT_SET_MESSAGE_ID: (state, payload) => {
      state.found_message_id = payload;
    },
    SET_PUT_FILES: (state, payload) => {
      state.put_files = payload;
    },

    CHAT_TOGGLE_MUTE: (state) => {
      state.conversation.muted = state.conversation.muted == true ? false : true;
    },

    SET_USER_STATUS: (state, payload) => {
      state.statuses[payload.user_id] = payload.status;
    },
    SET_USER_PING_STATUS: (state, payload) => {
      state.ping_statuses[payload.user_id] = payload.status;
    },

    CHAT_SET_READ_MSG: (state, payload) => {
      let index = state.conversation.messages.data.findIndex(x => x.id == payload);
      let unread_count = state.conversation.messages.data.filter(x => x.unread && x.author_id === state.conversation.user.id).length;
      for (let i = index; i < state.conversation.messages.data.length; i++) {
        if(state.conversation.messages.data[i].author_id === state.conversation.user.id) state.conversation.messages.data[i].unread = false;
      }
      let viwed_count = unread_count - state.conversation.messages.data.filter(x => x.unread && x.author_id === state.conversation.user.id).length;
      state.conversation.unread -= viwed_count;
      state.unread -= viwed_count;
    },
  
  },
  actions: {
    CHAT_TOGGLE_OPEN({commit, dispatch}, payload) {
      commit('CHAT_TOGGLE_OPEN', payload);
      if(payload) document.body.classList.add('chat-open');
      if(!payload) document.body.classList.remove('chat-open');
      if(payload) dispatch('CHAT_GET_ALL_CONTACTS');
      if(payload) {
        natsIO.publishStatus(this.state.user.user_profile.id, {action: 'change',status: 'online'});
      } else {
        natsIO.publishStatus(this.state.user.user_profile.id, {action: 'change',status: 'offline'});
      }
    },
    async CHAT_GET_ALL_CONTACTS({commit, dispatch}) {
      const response = await chatService.getAllContacts();
      if(response.status === 200) {
        commit('CHAT_SET_ALL_CONTACTS', response.data);
        setTimeout(() => {
          natsIO.newsubscribers(response.data);
        }, 2000)
        
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async CHAT_GET_NEW_CONTACTS({commit, dispatch}) {
      const response = await chatService.getNewContacts(this.state.chat.search_new_contacts);
      if(response.status === 200) {
        commit('CHAT_SET_NEW_CONTACTS', response.data);
        setTimeout(() => {
          natsIO.newsubscribers(response.data);
        }, 2000)
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async CHAT_GET_SEARCH_CHATS({commit, dispatch}) {
      const response = await chatService.getSearchChats(this.state.chat.search_chats);
      if(response.status === 200) {
        commit('CHAT_SET_FOUND_CHATS', response.data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    /* Conversation */
    async CHAT_USER_CONVERSATION_OPEN({commit, dispatch}, payload) {
      const response = await chatService.findConversation(payload);
      if(response.status === 200) {
        commit('CHAT_INIT_SET_CONVERSATION', response.data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async CHAT_CONVERSATION_TOGGLE_OPEN({commit, dispatch}, payload) {
      if(payload.show) dispatch('CHAT_CONVERSATION_LOAD_MESSAGES', payload);
      commit('CHAT_CONVERSATION_TOGGLE_OPEN', payload);
      commit('CHAT_SET_SEARCH_MESSAGE', '');
      if(!payload.show) dispatch('CHAT_GET_ALL_CONTACTS');
    },
    async CHAT_CONVERSATION_LOAD_MESSAGES({commit, dispatch}, payload) {
      await commit('CHAT_SET_CONVERSATION_PARAMS', {
        conversation_id: payload.conversation_id,
        chat_search_mode: false
      });
      const response = await chatService.getConversation({
        id: payload.conversation_id,
        page: 1,
        type: payload?.view_all ?? 'all'
      });

      if(response.status === 200) {
        commit('CHAT_INIT_SET_CONVERSATION', response.data);
        natsIO.publishChat(this.state.user.user_profile.id, {conversation_id: this.state.chat.conversation_id,action: 'change'});
        dispatch('GET_NOTIFICATIONS_PERSONAL');
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async CHAT_CONVERSATION_LOAD_MORE({commit, dispatch}, payload) {
      let page = 1;
      if(payload === 'top') {
        page = this.state.chat.conversation.messages.meta_top?.current_page + 1;
        if(page > this.state.chat.conversation.messages.meta_top?.last_page) return;
      }
      if(payload === 'bottom') {
        page = this.state.chat.conversation.messages.meta_bottom?.current_page + 1;
        if(page > this.state.chat.conversation.messages.meta_bottom?.last_page) return;
      }
      const response = await chatService.getConversation({
        id: this.state.chat.conversation_id,
        type: payload,
        page: page
      });
      if(response.status === 200) {
        let data = {user: response.data.user, messages: {}};
        if(payload === 'top') {
          data.messages.data = this.state.chat.conversation.messages.data.concat(response.data.messages_top.data);
          data.messages.meta_top = response.data.messages_top.meta;
        }
        if(payload === 'bottom') {
          data.messages.data = response.data.messages_bottom.data.concat(this.state.chat.conversation.messages.data);
          data.messages.meta_bottom = response.data.messages_bottom.meta;
        }

        commit('CHAT_SET_CONVERSATION', data);
        natsIO.publishChat(this.state.user.user_profile.id, {conversation_id: this.state.chat.conversation_id,action: 'change'});
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    /* Search in Conversation */
    async CHAT_CONVERSATION_TOGGLE_OPEN_FROM({commit}, payload) {
      await commit('CHAT_SET_CONVERSATION_PARAMS', payload);
      await commit('CHAT_CONVERSATION_TOGGLE_OPEN', payload);
      commit('CHAT_SET_SEARCH_MESSAGE', this.state.chat.search_chats);
    },
    async CHAT_CONVERSATION_SEARCH_MESSAGE({commit, dispatch}, payload) {
      commit('CHAT_SET_CONVERSATION_PARAMS', {
        conversation_id: this.state.chat.conversation_id,
        chat_search_mode: true,
      });
      const response = await chatService.getConversationFrom({
        id: this.state.chat.conversation_id,
        message_id: payload?.found_message_id ?? this.state.chat.found_message_id,
        search: this.state.chat.search_message
      });
      if(response.status === 200) {
        commit('CHAT_INIT_SET_CONVERSATION', response.data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }

      // 
      if(!payload.show) dispatch('CHAT_GET_ALL_CONTACTS');
    },
    /* load more in search mode */
    async CHAT_CONVERSATION_LOAD_MORE_FROM({commit, dispatch}, payload) {
      console.log('CHAT_CONVERSATION_LOAD_MORE_FROM')
      let page = 1;
      if(payload === 'top') {
        page = this.state.chat.conversation.messages.meta_top?.current_page + 1;
        if(page > this.state.chat.conversation.messages.meta_top?.last_page) return;
      }
      if(payload === 'bottom') {
        page = this.state.chat.conversation.messages.meta_bottom?.current_page + 1;
        if(page > this.state.chat.conversation.messages.meta_bottom?.last_page) return;
      }
      const response = await chatService.getConversationLoadMoreFrom({
        id: this.state.chat.conversation_id,
        message_id: this.state.chat.found_message_id,
        type: payload,
        page: page
      });
      if(response.status === 200) {
        let data = {user: response.data.user, messages: {}};
        if(payload === 'top') {
          data.messages.data = this.state.chat.conversation.messages.data.concat(response.data.messages_top.data);
          data.messages.meta_top = response.data.messages_top.meta;
        }
        if(payload === 'bottom') {
          data.messages.data = response.data.messages_bottom.data.concat(this.state.chat.conversation.messages.data);
          data.messages.meta_bottom = response.data.messages_bottom.meta;
        }

        commit('CHAT_SET_CONVERSATION', data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async CHAT_ADD_CONTACT_TO_CRM({dispatch}, payload) {
      const response = await crmService.setContact(payload);
      if(response.status === 200) {
        dispatch('CHAT_RELOAD_USER');
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async CHAT_RELOAD_USER({commit, dispatch}) {
      const response = await chatService.getUserProfile(this.state.chat.conversation.user.id);
      if(response.status === 200) {
        commit('CHAT_UPDATE_USER', response.data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    /* send new msg to Conversation */
    async CHAT_NEW_MESSAGE({commit, dispatch}, payload) {
      const response = await chatService.postChatMessage({
        recipient: this.state.chat.conversation.user.id,
        conversation_id: this.state.chat.conversation_id,
        message: payload.mesage,
        attachment_ids: this.state.chat.put_files,
      });
      if(response.status === 200) {
        natsIO.publishMsg(this.state.chat.conversation.user.id, response.data.message);
        natsIO.publishChat(this.state.user.user_profile.id, {conversation_id: this.state.chat.conversation_id,action: 'new_msg',message: response.data.message});
        natsIO.publishStatus(this.state.user.user_profile.id, {action: 'change',status: 'online'});
        if (response.data.notify) natsIO.publishNotify(this.state.chat.conversation.user.id, response.data.notify);
        if(this.state.chat.chat_search_mode) {
          await dispatch('CHAT_CONVERSATION_LOAD_MESSAGES',{conversation_id: this.state.chat.conversation_id});
          return;
        }
        commit('CHAT_SET_MESSAGE_ID', response.data.message.id);
        commit('CHAT_ADD_MESSAGE', response.data.message);
        if (this.state.chat.conversation_id == 0) commit('CHAT_SET_CONVERSATION_PARAMS', {conversation_id: response.data.conversation_id});
        commit('SET_PUT_FILES', []);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async PUT_CHAT_FILES({commit, dispatch}, payload) {
      const response = await chatService.putFiles(payload.conversation_id, payload.files);
      if(response.status === 200) {
        commit('SET_PUT_FILES', response.data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async PUT_MSG_FILES({commit, dispatch}, payload) {
      const response = await chatService.putMultiFiles(payload);
      if(response.status === 200) {
        commit('SET_PUT_FILES', response.data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    CHAT_CLEAR_PUT_FILES({commit}) {
      commit('SET_PUT_FILES', []);
    },

    /* New Conversation with conversation_id = 0 */
    async CHAT_NEW_CONVERSATION_OPEN({commit}, payload) {
      commit('CHAT_SET_NEW_CONVERSATION', payload);
    },

    /* Emoji */
    async CHAT_GET_EMOJI({commit, dispatch}) {
      commit('SET_EMOJI_RECENT');
      if(Object.keys(this.state.chat.emoji.data).length > 0) return;
      const response = await chatService.getEmoji();
      if(response.status === 200) {
        commit('SET_EMOJI', response.data);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async CHAT_EMOJI_RECENT({commit}, payload) {
      await commit('SET_EMOJI_RECENT');
      commit('TOGGLE_EMOJI_RECENT', payload);
    },

    CHAT_TOGGLE_MUTE({commit}) {
      chatService.toggleMute(this.state.chat.conversation_id);
      commit('CHAT_TOGGLE_MUTE');
      natsIO.publishChat(this.state.user.user_profile.id, {conversation_id: this.state.chat.conversation_id,action: 'change',});
    },

    async CHAT_SET_UNREAD({dispatch}) {
      await chatService.setUnread(this.state.chat.conversation_id);
      dispatch('CHAT_CONVERSATION_TOGGLE_OPEN', {show: false, conversation_id: false});
    },

    async CHAT_DELETE_CONVERSATION({dispatch}, payload) {
      await chatService.delete({id: this.state.chat.conversation_id, only_for_me: payload.onlyForMe});
      /* Send via Nats to close conversation in oponent */
      if(!payload.onlyForMe) natsIO.publishChat(this.state.chat.conversation.user.id, {conversation_id: this.state.chat.conversation_id,action: 'delete',});
      dispatch('CHAT_CONVERSATION_TOGGLE_OPEN', {show: false, conversation_id: false});
      dispatch('GET_NOTIFICATIONS_PERSONAL');
    },
    /* set read in state while scroll */
    async CHAT_SET_READ_MSG({commit}, payload) {
      await commit('CHAT_SET_READ_MSG', payload);
    },
    /* set read on server */
    async CHAT_SET_READ_MSG_ON_SERVER({commit},payload) {
      const response = await chatService.setRead(payload);
      if(response.status === 200) {
        commit('CHAT_SET_CONVERSATION_PARAMS', response.data);
        natsIO.publishChat(this.state.user.user_profile.id, {conversation_id: this.state.chat.conversation_id,action: 'change'});
      }
    },
    async CHAT_CONVERSATION_GET_PARAMS({commit, dispatch},payload) {
      const response = await chatService.getConversationParams(payload);
      if(response.status === 200) {
        commit('CHAT_SET_CONVERSATION_PARAMS', response.data);
        if (response.data.unread < 1) dispatch('GET_NOTIFICATIONS_PERSONAL');
      }
    },

    async SET_STATUSES({commit}, payload) {
      commit('SET_USER_STATUS', payload);
    },
    async SET_PING_STATUSES({commit}, payload) {
      commit('SET_USER_PING_STATUS', payload);
    },

    /* from natsIO */
    CHAT_INCOMING_MESSAGE({commit, dispatch}, payload) {
      if(this.state.chat.conversation_id == payload.conversation_id) {
        commit('CHAT_ADD_MESSAGE', payload);
        dispatch('CHAT_CONVERSATION_GET_PARAMS', this.state.chat.conversation_id);
      }
      dispatch('CHAT_GET_ALL_CONTACTS');
      dispatch('ADD_TO_CARD_CONVERSATION', payload);
      if(!payload?.muted) document.querySelector('#msgAudio').play();
    },

    CHAT_STATE({commit, dispatch}, payload) {
      if(this.state.chat.conversation_id == payload.conversation_id) { /* if opened current chat */
        /* close opened chat after delete */
        if(payload?.action === 'delete') dispatch('CHAT_CONVERSATION_TOGGLE_OPEN', {show: false, conversation_id: false});
        /* add self msg on other device */
        if(payload?.action === 'new_msg') {
          if(this.state.chat.conversation.messages.data.filter(x => x.id === payload.message.id).length < 1) {
            commit('CHAT_ADD_MESSAGE', payload.message);
          }
        }
        if(payload?.action === 'change') {
          if(localStorage.route_name === 'crm') { /* when open CRM page */
            dispatch('GET_ALL_CONVERSTATIONS');
          }
        }
        dispatch('CHAT_CONVERSATION_GET_PARAMS', this.state.chat.conversation_id);
      }
      dispatch('CHAT_GET_ALL_CONTACTS');
    },


  },
  getters: {
  }
}
export default chat;