import { router } from '@/router'
import natsIO from '@/plugins/natsIO'
import crmService from '../services/service.crm'
import userService from '../services/service.user'

const crm = {
  state: {
    contacts_all: [],
    contacts: '',
    viewCard: false,
    cardInfo: {
      contact: {
        company: {}
      },
      type: 'user'
    },
    note_to_delete: '',
    mynotes: {},
    notes: {},
    notes_meta: {},
    mynotes_meta: {},
    all_conversations: [],
    manager_conversations: [],

    conversation: {
      data: [],
      page: 1,
      total_pages: 0,
    },
    conversation_id: '',
    contact_id: '',
    conversation_type: 'user',
    conversation_to_company_id: '',
    conversation_company: {},

    searchMSG: '',
    searchManagerMsg: '',
  },
  mutations: {
    SET_CRM_ALL_CONTACTS: (state, payload) => {
      state.contacts_all = payload;
    },
    SET_CRM_CONTACTS: (state, payload) => {
      state.contacts = payload;
    },
    VIEW_CARD: (state, payload) => {
      state.viewCard = payload;
    },
    SET_CARD_DATA: (state, payload) => {
      state.cardInfo = payload;
    },
    RESET_CARD_DATA: (state) => {
      state.cardInfo = {
        contact: {
          company: {}
        },
        type: 'user'
      };
    },
    SET_CARD_MYNOTES: (state, payload) => {
      state.mynotes = payload;
    },
    SET_CARD_NOTES: (state, payload) => {
      state.notes = payload;
    },
    SET_CARD_NOTES_META: (state, payload) => {
      state.notes_meta.page = payload.current_page;
      state.notes_meta.total_pages = payload.last_page;
    },
    SET_CARD_MYNOTES_META: (state, payload) => {
      state.mynotes_meta.page = payload.current_page;
      state.mynotes_meta.total_pages = payload.last_page;
    },
    SET_CARD_DATA_MEDIA: (state, payload) => {
      state.cardInfoMedia = payload;
    },
    SET_USER_REVIEW: (state, payload)=>{
        state.cardInfo.contact.reviews = payload;
    },
    SET_USER_REVIEW_STATUS: (state, payload) => {
        state.cardInfo.reviews_status = payload;
    },
    SET_CARD_CONVERSTATION: (state, payload) => {
      // state.conversation = payload.conversation;
      state.conversation.data = payload.messages.data;
      state.conversation.page = payload.messages.meta?.current_page;
      state.conversation.total_pages = payload.messages.meta?.last_page;
    },
    SET_CARD_CONVERSTATION_INFO: (state, payload) => {
      state.contact_id = payload.user_profile_id == payload.conversation?.init_id ? payload.conversation?.to_user_id : payload.conversation?.init_id;
      state.conversation_type = payload.conversation?.type;
      state.conversation_to_company_id = payload.conversation?.to_company_id;
      state.conversation_id = payload.conversation?.id;
      state.conversation_company = payload.conversation?.company;
    },
    ADD_TO_CARD_CONVERSATION: (state, payload) => {
      state.conversation.data.unshift(payload);
    },
    SET_ALL_CONVERSTATIONS: (state, payload) => {
      state.all_conversations = payload;
    },
    PLUS_UNREAD: (state, payload) => {
      state.all_conversations[payload.index].unread +=1;
    },

    SET_MANAGER_CONVERSTATIONS: (state, payload) => {
      state.manager_conversations = payload;
    },

    SET_CRM_MESSAGE_SEARCH: (state, payload) => {
      state.searchMSG = payload;
    },
    SET_CRM_MANAGER_MESSAGE_SEARCH: (state, payload) => {
      state.searchManagerMsg = payload;
    },
    SET_NOTE_TO_DELETE: (state, payload) => {
      state.note_to_delete = payload;
    },
  },
  actions: {
    async GET_CRM_ALL_CONTACTS({commit}) {
      const response = await crmService.getAllContacts();
      if(response.status === 200) {
        commit('SET_CRM_ALL_CONTACTS', response.data.data);
      }
    },
    async SEARCH_CRM_ALL_CONTACTS({commit}, payload) {
      const response = await crmService.getSearchAllContacts(payload);
      if(response.status === 200) {
        commit('SET_CRM_ALL_CONTACTS', response.data.data);
      }
    },


    async GET_CRM_CONTACTS({commit}) {
      const response = await crmService.getContacts();
      if(response.status === 200) {
        commit('SET_CRM_CONTACTS', response.data.data);
      }
    },
    async SEARCH_CRM_CONTACTS({commit}, payload) {
      const response = await crmService.getSearchContacts(payload);
      if(response.status === 200) {
        commit('SET_CRM_CONTACTS', response.data.data);
      }
    },
    async ADD_CONTACT_TO_CRM({dispatch}, payload) {
      const response = await crmService.setContact(payload);
      if(response.status === 200) {
        if (payload.type != 'company')
          dispatch('SET_VIEW_USER_PROFILE', payload.contact_id);
        return true;
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async REMOVE_CONTACT_FROM_CRM({dispatch}, payload) {
        const response = await crmService.removeContact(payload);
        if(response.status === 200) {
          return true;
        } else if(response.status === 422) {
          dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        }
    },

    VIEW_CARD({commit}, payload) {
      commit('VIEW_CARD', payload);
    },
    async  GET_CRM_CARD({commit, dispatch}, payload) {
      commit('RESET_CARD_DATA');
      const response = await crmService.getCardInfo(payload);
      if(response.status === 200) {
        await commit('SET_CARD_DATA', response.data);
      } else {
        router.push({ name: 'crm'});
      }

      const responseNotesMedia = await crmService.getCardNotesMedia(payload);
      if(responseNotesMedia.status === 200) {
        await commit('SET_CARD_DATA_MEDIA', responseNotesMedia.data);
      }

      const responseNotes = await crmService.getCardMynotes(payload);
      if(responseNotes.status === 200) {
        await commit('SET_CARD_MYNOTES', responseNotes.data.data);
        await commit('SET_CARD_MYNOTES_META', responseNotes.data.meta);
      }

      const responseTeamNotes = await crmService.getCardNotes(payload);
      if(responseTeamNotes.status === 200) {
        await commit('SET_CARD_NOTES_META', responseTeamNotes.data.meta);
        await commit('SET_CARD_NOTES', responseTeamNotes.data.data);
      }

      const responseConverstation = await crmService.getCrmConverstationMessage(
        this.state.crm.cardInfo.contact.id,
        this.state.crm.cardInfo.type
      );
      if(responseTeamNotes.status === 200) {
        await commit('SET_CARD_CONVERSTATION', responseConverstation.data);
        responseConverstation.data.user_profile_id = this.state.user.user_profile.id;
        await commit('SET_CARD_CONVERSTATION_INFO', responseConverstation.data);
      }

      dispatch('VIEW_CARD', true);
    },

    async OPEN_CONVERSATION({commit, dispatch}, payload) {
      const responseConverstation = await crmService.getConverstationMessage(payload.id);
      if(responseConverstation.status === 200) {
        commit('SET_CARD_CONVERSTATION', responseConverstation.data);
        responseConverstation.data.user_profile_id = this.state.user.user_profile.id;
        commit('SET_CARD_CONVERSTATION_INFO', responseConverstation.data);
        dispatch('SHOW_POPUP', { popup: 'fullMessagesViewPopup', show: true });
        dispatch('GET_ALL_CONVERSTATIONS');
        dispatch('GET_MANAGER_CONVERSTATIONS');
        dispatch('GET_NOTIFICATIONS_PERSONAL');
      }
    },

    async CONVERSATION_LOAD_MORE({commit}) {
      const responseConverstation = await crmService.getConverstationMessage(
        this.state.crm.conversation_id,
        this.state.crm.conversation.page + 1
      );
      if(responseConverstation.status === 200) {
        responseConverstation.data.messages.data = this.state.crm.conversation.data.concat(responseConverstation.data.messages.data),
        commit('SET_CARD_CONVERSTATION', responseConverstation.data);
        responseConverstation.data.user_profile_id = this.state.user.user_profile.id;
        commit('SET_CARD_CONVERSTATION_INFO', responseConverstation.data);
      }
    },

    async CARD_NOTES_LOAD_MORE({commit}, payload) {
      const responseTeamNotes = await crmService.getCardNotes(payload.id, payload.page);
      if(responseTeamNotes.status === 200) {
        await commit('SET_CARD_NOTES_META', responseTeamNotes.data.meta);
        await commit('SET_CARD_NOTES', this.state.crm.notes.concat(responseTeamNotes.data.data));
      }
    },

    async CARD_MYNOTES_LOAD_MORE({commit}, payload) {
      const responseNotes = await crmService.getCardMynotes(payload.id, payload.page);
      if(responseNotes.status === 200) {
        await commit('SET_CARD_MYNOTES_META', responseNotes.data.meta);
        await commit('SET_CARD_MYNOTES', this.state.crm.mynotes.concat(responseNotes.data.data));
      }
    },

    async GET_CARD_MEDIA({commit}, payload) {
      const responseNotesMedia = await crmService.getCardNotesMedia(payload);
      if(responseNotesMedia.status === 200) {
        await commit('SET_CARD_DATA_MEDIA', responseNotesMedia.data);
      }
    },
    async ADD_NOTE({commit, dispatch}, payload) {
      let card_id = payload.card_id;
      const response = await crmService.postCardMyNote(card_id, payload);
      if(response.status === 200) {
        const responseNotes = await crmService.getCardMynotes(card_id);
        if(responseNotes.status === 200) {
          await commit('SET_CARD_MYNOTES', responseNotes.data.data);
        }

        const responseTeamNotes = await crmService.getCardNotes(card_id);
        if(responseTeamNotes.status === 200) {
          await commit('SET_CARD_NOTES', responseTeamNotes.data.data);
        }

      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async UPDATE_NOTE({commit, dispatch}, payload) {
      let card_id = payload.card_id;
      const response = await crmService.editCardMyNote(card_id, payload);
      if(response.status === 200) {
        const responseNotes = await crmService.getCardMynotes(card_id);
        if(responseNotes.status === 200) {
          await commit('SET_CARD_MYNOTES', responseNotes.data.data);
        }

        const responseTeamNotes = await crmService.getCardNotes(card_id);
        if(responseTeamNotes.status === 200) {
          await commit('SET_CARD_NOTES', responseTeamNotes.data.data);
        }

      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async DELETE_NOTE({commit, dispatch, state}) {
      let card_id = state.cardInfo.id;
      const response = await crmService.deleteCardMyNote(card_id, this.state.crm.note_to_delete);
      if(response.status === 200) {
        const responseNotes = await crmService.getCardMynotes(card_id);
        if(responseNotes.status === 200) {
          await commit('SET_CARD_MYNOTES', responseNotes.data.data);
        }

        const responseTeamNotes = await crmService.getCardNotes(card_id);
        if(responseTeamNotes.status === 200) {
          await commit('SET_CARD_NOTES', responseTeamNotes.data.data);
        }

      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async ADD_TEAM_NOTE({commit, dispatch}, payload) {
      let card_id = payload.card_id;
      const response = await crmService.postCardNote(card_id, payload);
      if(response.status === 200) {
        const responseTeamNotes = await crmService.getCardNotes(card_id);
        if(responseTeamNotes.status === 200) {
          await commit('SET_CARD_NOTES', responseTeamNotes.data.data);
        }
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async SET_USER_REVIEW({commit, dispatch, state }, payload){
      let response = '';
      if(payload.review_id) response = await userService.updateReview(payload);
      else response = await userService.addreview(payload);
        if(response.status === 200) {
          let cardInfo = state.cardInfo;
            cardInfo.contact.my_review = response.data.my_review;
            cardInfo.contact.reviews = response.data.items;

            commit('SET_CARD_DATA', cardInfo);
            commit('SET_USER_REVIEW_STATUS', 'success');
            dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Review added successfully.'});
            return true;
        } else {
            commit('SET_USER_REVIEW_STATUS', 'server_error');
        }
    },
    async ADD_MESSAGE({commit, dispatch}, payload) {
      const response = await crmService.postConverstationMessage(payload);
      if(response.status === 201) {
        // console.log('ADD_MESSAGE:', payload, response.data);
        commit('ADD_TO_CARD_CONVERSATION', response.data.message);
        response.data.user_profile_id = this.state.user.user_profile.id;
        commit('SET_CARD_CONVERSTATION_INFO', response.data);
        if (payload.type == 'company' && payload.sender === response.data.conversation.init_id) {
          if (response.data.conversation.to_user_id) // send to Manager
            natsIO.publishMsg(response.data.conversation.to_user_id, response.data.message);
          else // send to all managers
            natsIO.publishEvent({action: 'NATS_NOTIFY_MANAGERS', from: this.state.user.user_profile.id, info: {type: 'CompanyMessage', company: response.data.notify.to_company}});
        } else {
          natsIO.publishMsg(payload.recipient, response.data.message);
        }
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async ADD_TO_CARD_CONVERSATION({commit}, payload) {
      if(this.state.crm.conversation_id == payload.conversation_id) commit('ADD_TO_CARD_CONVERSATION', payload);
      for (let index = 0; index < this.state.crm.all_conversations.length; index++) {
        if(payload.partition == this.state.crm.all_conversations[index].partition) {
          payload.index = index;
          commit('PLUS_UNREAD', payload);
        }
      }
    },
    async GET_ALL_CONVERSTATIONS({commit}) {
      const response = await crmService.getAllConverstations(this.state.crm.searchMSG);
      if(response.status === 200) {
        commit('SET_ALL_CONVERSTATIONS', response.data.data);
      }
    },
    async GET_MANAGER_CONVERSTATIONS({commit}) {
      const response = await crmService.getManagerConverstations(this.state.crm.searchManagerMsg);
      if(response.status === 200) {
        commit('SET_MANAGER_CONVERSTATIONS', response.data.data);
      }
    },
  },
  getters: {
  }
}
export default crm;