<template>
  <span class="tooltip" :class="{'active': active}">
    <span class="bg-tooltip" @click="close"></span>
    <span class="ico-tooltip" @click="toggleActive"></span>
    <span class="tooltiptext" :class="type">
      <span class="ico-close" @click="close"></span>
      <span class="text" v-html="msg"></span>
    </span>
  </span>
</template>

<script>
export default {
  name: "ToolTip",
  props: {
    msg: {
      type:String,
      required: true
    },
    type: {
      type:String,
      default: ''
    },
  },
  data() {
    return {
      active: false,
    }
  },

  methods: {
    close() {
      this.active = false;
      document.body.classList.remove('modal-open');
    },
    toggleActive() {
      this.active = !this.active;
      if(this.active && window.innerWidth <= 1023) document.body.classList.add('modal-open');
      if(!this.active) document.body.classList.remove('modal-open');
    },
    keyEvent(e) {
      if ( e.keyCode == 27 ) this.close();
    },
  },
  created() {
    window.addEventListener('keydown', this.keyEvent);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyEvent);
  },
}
</script>