<template>
  <div class="post__item">
    <div class="post-item__header header_flex_wrap">
      <div class="post-company__logo">
        <img v-if="company.companylogo" :src="company.companylogo.link" alt="" />
        <img v-else src="@/assets/images/no-logo.png" alt="">
      </div>
      <div class="wysiwyg">
        <h3>
          <router-link :to="{name: 'company_profile', params: {id: company.id}}" >{{company.companyname}}</router-link>
        </h3>
        <ul>
          <li>{{company.location}}</li>
          <li v-if="company.investment">{{company.investment}}</li>
          <template v-if="company.companyindustry && !moreIndustry">
            <li v-for="industry in company.companyindustry.slice(0, 4)" :key="industry.id">{{industry.value}}</li>
            <li v-if="company.companyindustry.length > 4" @click.prevent="toggleMoreIndustry"><a href="#">. . .</a></li>
          </template>
          <template v-if="company.companyindustry && moreIndustry">
            <li v-for="industry in company.companyindustry" :key="industry.id">{{industry.value}}</li>
            <li @click.prevent="toggleMoreIndustry"><a href="#">. . .</a></li>
          </template>
        </ul>

        <div v-if="type == 'favorites'" class="box align-self-left">
          <template v-if="company.companytype != 'business'">
            <a v-if="company.like_active" @click.prevent="changeCompanyLike" href="#" class="rating like">
              <div class="rating__media">
                <svg class="ico" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7125 1.49902C12.0721 1.49902 11.438 1.62519 10.8464 1.87033C10.2548 2.11544 9.7172 2.47482 9.26451 2.92769L8.99997 3.19223L8.7353 2.92756C7.82088 2.01314 6.58065 1.49942 5.28747 1.49942C3.99428 1.49942 2.75406 2.01314 1.83964 2.92756C0.925216 3.84198 0.411499 5.08221 0.411499 6.37539C0.411499 7.66858 0.925216 8.9088 1.83964 9.82322L8.46964 16.4532C8.76253 16.7461 9.2374 16.7461 9.5303 16.4532L16.1603 9.82322C16.6132 9.37053 16.9724 8.83306 17.2175 8.2415C17.4627 7.64989 17.5888 7.01578 17.5888 6.37539C17.5888 5.735 17.4627 5.10089 17.2175 4.50928C16.9724 3.91778 16.613 3.3801 16.1602 2.92744C15.7075 2.47463 15.1701 2.11542 14.5786 1.87033C13.987 1.62519 13.3529 1.49902 12.7125 1.49902Z"
                    fill="#00A961"></path>
                </svg>
              </div>
              <div class="rating__text">
                <span class="title-text">Liked </span><span class="title-text">({{company.like}})</span>
              </div>
            </a>

            <a v-if="!company.like_active && company.companytype != 'business'" @click.prevent="changeCompanyLike" href="#" class="rating like">
              <div class="rating__media">
                <svg class="ico" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8464 0.871306C11.438 0.626171 12.0721 0.5 12.7125 0.5C13.3529 0.5 13.987 0.626171 14.5786 0.871306C15.1701 1.1164 15.7075 1.4756 16.1602 1.92841C16.613 2.38108 16.9724 2.91876 17.2175 3.51026C17.4627 4.10187 17.5888 4.73598 17.5888 5.37637C17.5888 6.01676 17.4627 6.65087 17.2175 7.24248C16.9724 7.83404 16.6132 8.37151 16.1603 8.8242C16.1603 8.82424 16.1603 8.82416 16.1603 8.8242L9.5303 15.4542C9.2374 15.7471 8.76253 15.7471 8.46964 15.4542L1.83964 8.8242C0.925216 7.90978 0.411499 6.66956 0.411499 5.37637C0.411499 4.08318 0.925216 2.84296 1.83964 1.92854C2.75406 1.01412 3.99428 0.500401 5.28747 0.500401C6.58065 0.500401 7.82088 1.01412 8.7353 1.92854L8.99997 2.19321L9.26451 1.92866C9.26455 1.92862 9.26447 1.92871 9.26451 1.92866C9.7172 1.47579 10.2548 1.11642 10.8464 0.871306ZM12.7125 2C12.2691 2 11.8301 2.08735 11.4205 2.25706C11.011 2.42677 10.6388 2.67551 10.3254 2.98908L9.5303 3.7842C9.2374 4.07709 8.76253 4.07709 8.46964 3.7842L7.67464 2.9892C7.04152 2.35608 6.18283 2.0004 5.28747 2.0004C4.3921 2.0004 3.53341 2.35608 2.9003 2.9892C2.26718 3.62232 1.9115 4.48101 1.9115 5.37637C1.9115 6.27173 2.26718 7.13042 2.9003 7.76354L8.99997 13.8632L15.0996 7.76354C15.4132 7.45012 15.6621 7.07787 15.8318 6.66829C16.0015 6.25871 16.0888 5.81971 16.0888 5.37637C16.0888 4.93303 16.0015 4.49402 15.8318 4.08445C15.6621 3.67487 15.4133 3.30274 15.0998 2.98932C14.7863 2.67576 14.414 2.42677 14.0044 2.25706C13.5948 2.08735 13.1558 2 12.7125 2Z"
                    fill="#00A961"></path>
                </svg>
              </div>
              <div class="rating__text">
                <span class="title-text">Like </span><span class="title-text">({{company.like}})</span>
              </div>
            </a>
          </template>

          <template v-if="company.companytype == 'business'">
            <a class="box align-self-left" href="#"
              @click.prevent="addOrViewReview">
              <Rating :reviewsCount="company.reviews_count" :reviewsAvg="company.reviews_avg"></Rating>
            </a>
          </template>

        </div>

      </div>

      <div v-if="type == 'normal'" class="post-action ml-auto box align-self-center">
        <template v-if="company.companytype != 'business'">
          <a v-if="company.like_active" @click.prevent="changeCompanyLike" href="#" class="rating like">
            <div class="rating__media">
              <svg class="ico" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7125 1.49902C12.0721 1.49902 11.438 1.62519 10.8464 1.87033C10.2548 2.11544 9.7172 2.47482 9.26451 2.92769L8.99997 3.19223L8.7353 2.92756C7.82088 2.01314 6.58065 1.49942 5.28747 1.49942C3.99428 1.49942 2.75406 2.01314 1.83964 2.92756C0.925216 3.84198 0.411499 5.08221 0.411499 6.37539C0.411499 7.66858 0.925216 8.9088 1.83964 9.82322L8.46964 16.4532C8.76253 16.7461 9.2374 16.7461 9.5303 16.4532L16.1603 9.82322C16.6132 9.37053 16.9724 8.83306 17.2175 8.2415C17.4627 7.64989 17.5888 7.01578 17.5888 6.37539C17.5888 5.735 17.4627 5.10089 17.2175 4.50928C16.9724 3.91778 16.613 3.3801 16.1602 2.92744C15.7075 2.47463 15.1701 2.11542 14.5786 1.87033C13.987 1.62519 13.3529 1.49902 12.7125 1.49902Z"
                  fill="#00A961"></path>
              </svg>
            </div>
            <div class="rating__text">
              <span class="title-text">Liked </span><span class="title-text">({{company.like}})</span>
            </div>
          </a>

          <a v-if="!company.like_active" @click.prevent="changeCompanyLike" href="#" class="rating like">
            <div class="rating__media">
              <svg class="ico" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8464 0.871306C11.438 0.626171 12.0721 0.5 12.7125 0.5C13.3529 0.5 13.987 0.626171 14.5786 0.871306C15.1701 1.1164 15.7075 1.4756 16.1602 1.92841C16.613 2.38108 16.9724 2.91876 17.2175 3.51026C17.4627 4.10187 17.5888 4.73598 17.5888 5.37637C17.5888 6.01676 17.4627 6.65087 17.2175 7.24248C16.9724 7.83404 16.6132 8.37151 16.1603 8.8242C16.1603 8.82424 16.1603 8.82416 16.1603 8.8242L9.5303 15.4542C9.2374 15.7471 8.76253 15.7471 8.46964 15.4542L1.83964 8.8242C0.925216 7.90978 0.411499 6.66956 0.411499 5.37637C0.411499 4.08318 0.925216 2.84296 1.83964 1.92854C2.75406 1.01412 3.99428 0.500401 5.28747 0.500401C6.58065 0.500401 7.82088 1.01412 8.7353 1.92854L8.99997 2.19321L9.26451 1.92866C9.26455 1.92862 9.26447 1.92871 9.26451 1.92866C9.7172 1.47579 10.2548 1.11642 10.8464 0.871306ZM12.7125 2C12.2691 2 11.8301 2.08735 11.4205 2.25706C11.011 2.42677 10.6388 2.67551 10.3254 2.98908L9.5303 3.7842C9.2374 4.07709 8.76253 4.07709 8.46964 3.7842L7.67464 2.9892C7.04152 2.35608 6.18283 2.0004 5.28747 2.0004C4.3921 2.0004 3.53341 2.35608 2.9003 2.9892C2.26718 3.62232 1.9115 4.48101 1.9115 5.37637C1.9115 6.27173 2.26718 7.13042 2.9003 7.76354L8.99997 13.8632L15.0996 7.76354C15.4132 7.45012 15.6621 7.07787 15.8318 6.66829C16.0015 6.25871 16.0888 5.81971 16.0888 5.37637C16.0888 4.93303 16.0015 4.49402 15.8318 4.08445C15.6621 3.67487 15.4133 3.30274 15.0998 2.98932C14.7863 2.67576 14.414 2.42677 14.0044 2.25706C13.5948 2.08735 13.1558 2 12.7125 2Z"
                  fill="#00A961"></path>
              </svg>
            </div>
            <div class="rating__text">
              <span class="title-text">Like </span><span class="title-text">({{company.like}})</span>
            </div>
          </a>
        </template>

        <template v-if="company.companytype == 'business'">
          <a class="box align-self-center" href="#"
            @click.prevent="addOrViewReview">
            <Rating :reviewsCount="company.reviews_count" :reviewsAvg="company.reviews_avg"></Rating>
          </a>
        </template>

      </div>

      <div v-if="type == 'favorites'" class="post-action ml-auto">
        <div @click.prevent="toggleFavorites" class="post-action__item post-action__item_trash">
          <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#5D6167"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#5D6167"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#5D6167"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#5D6167"></path>
          </svg>
        </div>
      </div>

    </div>
    <div class="post-item__content">
      <div class="wysiwyg">
        <p>{{company.companydescription}}</p>
      </div>
    </div>
    <div class="post-item__footer">
      <div class="btn-group">
        <div class="btn-group btn-group_secondary">
          <a href="#" class="btn btn_secondary"
            v-if="company.companytype === 'business' && company.reviews_count"
            @click.prevent="showReviews">
            <span class="btn__text">Read reviews</span>
          </a>

          <a v-if="company.in_my_crm" @click.prevent="removeFromCRM" href="#" class="btn btn_secondary d-none d-flex-tab">
            <span class="btn__text">Remove from CRM</span>
          </a>
          <a v-else @click.prevent="addToCRM" href="#" class="btn btn_secondary">
            <span class="btn__text">Add to CRM</span>
          </a>
          <router-link :to="{ name: 'crmUser', params: { id: company.crm_id }}" v-if="company.in_my_crm" class="btn btn_secondary d-none d-flex-tab">
            <span class="btn__text">Open CRM Card</span>
          </router-link>
          <a @click.prevent="sendMSG" class="btn btn_secondary"
            :class="{'d-none d-flex-tab': company.companytype === 'business' && !company.in_my_crm && company.reviews_count}">
            <span class="btn__text">Send Message</span>
          </a>
          <a v-if="type == 'normal'" @click.prevent="toggleFavorites" href="#" class="btn d-none d-flex-tab" :class="{'btn_ninefold': company.favorite}">
            <template v-if="company.favorite">
              <svg class="btn__ico" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H11.25C11.6642 0.25 12 0.585786 12 1C12 1.41421 11.6642 1.75 11.25 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z" fill="#5D6167" />
              </svg>
              <span class="btn__text">Remove From Favorites</span>
            </template>
            <template v-else>
              <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="#00A961" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="#00A961" />
              </svg>
              <span class="btn__text">Add to favorites</span>
            </template>
          </a>
          <a href="#" v-else>&nbsp;</a>

          <div class="form-field__content d-none-tab ml-auto">
            <div class="dropdown dropdown_secondary" :class="{'dropdown_active': open_dropdown}">
              <div class="dropdown__init">
                <a @click.prevent="open_dropdown = !open_dropdown" href="javascript:;" class="btn btn_secondary">
                  <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9Z" fill="#00A961"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C10.5 4.57843 9.82843 5.25 9 5.25C8.17157 5.25 7.5 4.57843 7.5 3.75Z" fill="#00A961"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14.25C7.5 13.4216 8.17157 12.75 9 12.75C9.82843 12.75 10.5 13.4216 10.5 14.25C10.5 15.0784 9.82843 15.75 9 15.75C8.17157 15.75 7.5 15.0784 7.5 14.25Z" fill="#00A961"></path>
                  </svg>
                </a>
              </div>
              <div class="dropdown__content">
                <ul class="dropdown__list">
                  <li v-if="company.in_my_crm" class="dropdown__item">
                    <a @click.prevent="removeFromCRM" href="#" class="dropdown__link">Remove from CRM</a>
                  </li>
                  <li v-if="company.in_my_crm" class="dropdown__item">
                    <router-link :to="{ name: 'crmUser', params: { id: company.crm_id }}" class="dropdown__link">
                      Open CRM Card
                    </router-link>
                  </li>
                  <li v-if="type == 'normal'" class="dropdown__item">
                    <a href="#" @click.prevent="toggleFavorites" class="dropdown__link">
                      <span v-if="company.favorite">Remove From Favorites</span>
                      <span v-else>Add to favorites</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import Rating from '@/components/profile/Rating'

export default {
  name: "CardCompany",
  components: {
    Rating,
  },
  props: {
    company: Object,
    tab: String,
    type: {
      default: "normal",
      type: String
    }
  },
  data() {
    return {
      open_dropdown: false,
      moreIndustry: false,
    }
  },
  methods: {
    async changeCompanyLike() {
      await store.dispatch('COMPANY_LIKE', this.company.id);
      store.dispatch('EMPLOYEE_COMPANY_RELOAD', {tab: this.tab, id: this.company.id});
    },
    async toggleFavorites() {
      await store.dispatch('EMPLOYEE_FAVORITE_TOGGLE', {tab: this.tab, item_id: this.company.id, type: 'company'});
      store.dispatch('EMPLOYEE_COMPANY_RELOAD', {tab: this.tab, id: this.company.id});
      this.open_dropdown = false;
    },
    async removeFromCRM() {
      await store.dispatch('REMOVE_CONTACT_FROM_CRM', { contact_id: this.company.crm_id });
      store.dispatch('EMPLOYEE_COMPANY_RELOAD', {tab: this.tab, id: this.company.id});
      this.open_dropdown = false;
    },
    async addToCRM() {
      await store.dispatch('ADD_CONTACT_TO_CRM', { contact_id: this.company.id, type: 'company' });
      store.dispatch('EMPLOYEE_COMPANY_RELOAD', {tab: this.tab, id: this.company.id});
      this.open_dropdown = false;
    },

    sendMSG() {
        let data = {
            recipient: this.company.id,
            type: 'company'
        };
        store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: true, data: data});
        return;
    },

    addOrViewReview() {
      if(!this.company.my_review){
        let data = {
          id: this.company.id,
          type: 'company'
        };
          store.dispatch('SHOW_POPUP', { popup: 'fullReviewAddPopup', show: true, data: data });
      } else {
          this.showReviews();
      }
    },
    showReviews() {
      store.dispatch('EXPERT_REVIEWS', {id: this.company.id, type: 'company'});
    },
    toggleMoreIndustry() {
      this.moreIndustry = !this.moreIndustry;
      console.log(this.moreIndustry);
    },
  },
  computed: {
  },
}
</script>
