import instance from './index';
import suwAPI from '../config/config'

const notificationsService = {

  getNotifications(type) {
    return instance({ requiresAuth: true }).get(suwAPI.apiNotifications+'/'+type);
  },

  getCountsNotifications() {
    return instance({ requiresAuth: true }).get(suwAPI.apiNotifications+'/counts');
  },

  allowAccess(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/allow-access', {hours: data.hours});
  },

  rejectAccess(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/reject-access');
  },

  rejectInvite(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/reject-invite');
  },

  acceptInvite(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/allow-invite');
  },

  joinReject(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/reject-join');
  },

  joinAccept(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/allow-join');
  },

  rejectInviteWorkPage(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/reject-invite-work-page');
  },

  acceptInviteWorkPage(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+data.id+'/allow-invite-work-page');
  },

  setNotificationViewed(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiNotifications+'/'+id+'/set-viewed');
  },
}

export default notificationsService;