import jobService from '@/services/service.job'

const job = {
  state: {
    searchJobs: '',

    field_of_expertise: [], // All, Accountants, Developers,.... (multiple choice, All is set by default). Field of work
    selected_foe: [],
    all_foe: true,

    type_of_employment: [], // All,  Freelancer's jobs, Job positions,  Firm's jobs (multiple choice, All is set by default).
    selected_toe: [],
    all_toe: true,

    hiring: [], // (who posted the job): All, Investors, Experts, StartUps (multiple choice, All option is set up by default).
    selected_hiring: [],
    all_hiring: true,

    data: [],
    meta: {},

    current_page: 1,

    my_jobs: [],

    type_popup: 'view',

    current_job: {
      id: '',
      title: '',
      description: '',
      field_of_expertise: [],
      type_of_employment: [],
      last_update: '',
    },
    job_to_delete: '',
    my_field_of_expertise: [],
    my_type_of_employment: [],

  },
  mutations: {
    SET_JOBS_SEARCH: (state, payload) => {
      state.searchJobs = payload;
      state.current_page = 1;
    },

    SET_JOBS_DATA: (state, payload) => {
      state.data = payload;
    },
    SET_MY_JOBS_DATA: (state, payload) => {
      state.my_jobs = payload;
    },
    SET_JOBS_META: (state, payload) => {
      state.meta = payload.data.meta;
      state.type_of_employment = payload.type_of_employment;
      state.field_of_expertise = payload.field_of_expertise;
      state.hiring = payload.hiring;

      state.my_type_of_employment = payload.my_type_of_employment;
      state.my_field_of_expertise = payload.my_field_of_expertise;
    },
    JOBS_LOAD_MORE: (state) => {
      state.current_page = state.meta.current_page + 1;
    },

    JOB_EDIT: (state, payload) => {
      state.current_job = payload;

      state.my_field_of_expertise.forEach((item, index) => {
        if (payload.industry_assoc.includes(item.label))
          state.my_field_of_expertise[index].selected = true;
      });
      state.my_type_of_employment.forEach((item, index) => {
        if (payload.type_assoc.includes(item.label))
          state.my_type_of_employment[index].selected = true;
      });

      state.type_popup = 'edit';
    },
    JOB_CLEAR_CURRENT: (state) => {
      state.current_job = {
        id: '',
        title: '',
        description: '',
        field_of_expertise: [],
        type_of_employment: [],
        last_update: '',
      };
      state.type_popup = 'view';
      state.my_field_of_expertise.forEach((item, index) => {
          state.my_field_of_expertise[index].selected = false;
      });
      state.my_type_of_employment.forEach((item, index) => {
          state.my_type_of_employment[index].selected = false;
      });
    },
    JOB_TYPE_POPUP_SET: (state, payload) => {
      state.type_popup = payload;
    },


    /* field_of_expertise */
    JOB_FILTER_FOE: (state) => {
      state.selected_foe = state.field_of_expertise.filter(a => a.selected);
      state.all_foe = (state.selected_foe.length > 0) ? false : true;
      state.current_page = 1;
    },
    JOB_SELECT_ALL_FOE: (state) => {
      if (state.all_foe)
        state.field_of_expertise.forEach((item, index) => {
          state.field_of_expertise[index].selected = false;
        });
      state.current_page = 1;
    },

    /* type_of_employment */
    JOB_FILTER_TOE: (state) => {
      state.selected_toe = state.type_of_employment.filter(a => a.selected);
      state.all_toe = (state.selected_toe.length > 0) ? false : true;
      state.current_page = 1;
    },
    JOB_SELECT_ALL_TOE: (state) => {
      if (state.all_toe)
        state.type_of_employment.forEach((item, index) => {
          state.type_of_employment[index].selected = false;
        });
      state.current_page = 1;
    },

    /* hiring */
    JOB_FILTER_HIRING: (state) => {
      state.selected_hiring = state.hiring.filter(a => a.selected);
      state.all_hiring = (state.selected_hiring.length > 0) ? false : true;
      state.current_page = 1;
    },
    JOB_SELECT_ALL_HIRING: (state) => {
      if (state.all_hiring)
        state.hiring.forEach((item, index) => {
          state.hiring[index].selected = false;
        });
      state.current_page = 1;
    },





    JOB_RELOAD: (state, payload) => {
      let index = state.data.findIndex(x => x.id == payload.data.id);
      state.data[index] = payload.data;
      if (state.my_jobs.length) { // update my_jobs
        let index = state.my_jobs.findIndex(x => x.id == payload.data.id);
        state.my_jobs[index] = payload.data;
      }
    },

    SET_JOB_TO_DELETE: (state, payload) => {
      state.job_to_delete = payload;
    },


  },

  actions: {
    async GET_DATA_JOBS({commit, dispatch}, payload) {
      if (payload == 'first') {
        this.state.job.searchJobs = '';
      }
      const response = await jobService.getJobs({
        search: this.state.job.searchJobs,
        page: this.state.job.current_page,
        type_of_employment: this.state.job.type_of_employment.filter(a => a.selected).map(a => a.id),
        field_of_expertise: this.state.job.field_of_expertise.filter(a => a.selected).map(a => a.id),
        hiring: this.state.job.hiring.filter(a => a.selected).map(a => a.id),
      });
      if(response.status === 200) {
        if (payload == 'loadmore') {
          commit('SET_JOBS_DATA', this.state.job.data.concat(response.data.data.data));
        } else {
          commit('SET_JOBS_DATA', response.data.data.data);
        }
        commit('SET_JOBS_META', response.data);
        commit('JOB_CLEAR_CURRENT');
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    JOBS_LOAD_MORE({commit, dispatch}) {
      commit('JOBS_LOAD_MORE');
      dispatch('GET_DATA_JOBS', 'loadmore');
    },

    /* field_of_expertise */
    JOB_FILTER_FOE({commit, dispatch}) {
      commit('JOB_FILTER_FOE');
      dispatch('GET_DATA_JOBS');
    },
    JOB_SELECT_ALL_FOE({commit, dispatch}) {
      commit('JOB_SELECT_ALL_FOE');
      dispatch('GET_DATA_JOBS');
    },

    /* type_of_employment */
    JOB_FILTER_TOE({commit, dispatch}) {
      commit('JOB_FILTER_TOE');
      dispatch('GET_DATA_JOBS');
    },
    JOB_SELECT_ALL_TOE({commit, dispatch}) {
      commit('JOB_SELECT_ALL_TOE');
      dispatch('GET_DATA_JOBS');
    },

    /* hiring */
    JOB_FILTER_HIRING({commit, dispatch}) {
      commit('JOB_FILTER_HIRING');
      dispatch('GET_DATA_JOBS');
    },
    JOB_SELECT_ALL_HIRING({commit, dispatch}) {
      commit('JOB_SELECT_ALL_HIRING');
      dispatch('GET_DATA_JOBS');
    },




    async JOB_RELOAD({commit, dispatch}, payload) {
      const response = await jobService.getJob(payload.id);
      if(response.status === 200) {
        await commit('JOB_RELOAD', {type: payload.tab+'_users', data: response.data});
        await commit('JOB_FAVORITE_RELOAD', response.data);
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },




    async JOB_FAVORITE_TOGGLE({dispatch}, payload) {
      const response = await jobService.toggleFavorite(payload);
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async JOBS_SHOW({commit,dispatch}) {
      await dispatch('GET_MY_JOBS');
      commit('JOB_CLEAR_CURRENT');
      dispatch('SHOW_POPUP', { popup: 'fullJobPopup', show: true });
    },

    async GET_MY_JOBS({commit, dispatch}) {
      const response = await jobService.getMyJobs();
      if(response.status === 200) {
        commit('SET_MY_JOBS_DATA', response.data.data);
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async SAVE_JOB({commit,dispatch}) {
      this.state.job.current_job.field_of_expertise = this.state.job.my_field_of_expertise.filter(a => a.selected).map(a => a.label);
      this.state.job.current_job.type_of_employment = this.state.job.my_type_of_employment.filter(a => a.selected).map(a => a.label);
      const response = await jobService.createJob(this.state.job.current_job);
      if(response.status === 200) {
        await dispatch('GET_DATA_JOBS');
        await dispatch('GET_MY_JOBS');
        commit('JOB_CLEAR_CURRENT');
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async JOB_EDIT({commit,dispatch},payload) {
      const response = await jobService.getJob(payload);
      if(response.status === 200) {
        commit('JOB_CLEAR_CURRENT');
        commit('JOB_EDIT', response.data);
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async JOB_DELETE({commit,dispatch}) {
      const response = await jobService.deleteJob(this.state.job.job_to_delete);
      if(response.status === 200) {
        await dispatch('GET_DATA_JOBS');
        await dispatch('GET_MY_JOBS');
        commit('JOB_CLEAR_CURRENT');
        this.state.job.current_job.type_popup = 'view';
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },


  },
  getters: {}
}
export default job;
