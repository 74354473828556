<template>
   <div class="main__footer footer">
        <div class="footer__wysiwyg text-center">
            <p>
                © {{year}} Start-Up World. All rights reserved.
            </p>
            <p>
                &nbsp;
                <!-- <v-link href="/about">About Start-up Word</v-link> -->
            </p>
        </div>
        <SystemMessage></SystemMessage>
    </div>
</template>

<script>
// import VLink from "./VLink.vue"
import SystemMessage from '@/components/general/SystemMessage'

export default {
    name: "Mainfooter",
    components: {
        // VLink,
        SystemMessage
    },
    data() {
        return {
            year: 2022,
        }
    },
    created() {
        this.year = new Date().getFullYear();
    }
}
</script>
