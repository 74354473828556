import axios from 'axios';
import suwAPI from '../config/config'
import { router } from '@/router'

export default ( { requiresAuth = false, email = false, password = false, formData = false } = {} ) => {

        const options = {};

        options.baseURL = suwAPI.apiBaseUrl;
         
        if (requiresAuth) {
            if(formData) {
                options.headers = {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer '+localStorage.access_token+''
                }
            } else {
                options.headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.access_token+''
                }
            }
            
        }

        if (email && password) {
            options.headers = {
                'email': email,
                'password': password,
            }
        }
        
        const instance = axios.create(options);

        instance.interceptors.response.use(response => {
            return response;
        }, error => {
            if(error.response.status === 401) {
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                router.push({name: "cover"});
            }
            return error.response;
        });

        return instance;
};