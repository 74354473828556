<template>
  <div class="section">
    <ToolTip style="margin-left: 20px;margin-top: 20px; position: absolute;z-index: 2;"
        :type="'top mw-460'"
        :msg="'This space is reserved for a personalized team banner photo! It will be represented on your company page (viewable by visitors to your page) as well as on your home and work pages (viewable by your team and those you invite to the specific work pages).<br><br>To change banner photo please head over to your company profile page, there you will have the option to post your photo and better represent your organization!<br><br>Only company administrators can edit the team’s banner photo so if you can’t make the edit<br><br>- Request from your administrator to add it <br><br>- Request to be added as an administrator for your team<br><br>- If you are an administrator and it still is not working please reach out to our team so that we can be aware of your issue and solve it as soon as possible.'"
    />
    <div class="section__banner"
            v-bind:style="{ backgroundImage: 'url(' + userdata.user_profile.company.companybanner?.link + ')' }">
    </div>

  </div>
</template>

<script>
import store from '@/store'
import ToolTip from '@/components/ToolTip'

export default {
  name: "Banner",
  components: {
      ToolTip,
  },
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
    userdata: () => store.state.user,
  }
}
</script>