<template>
<div class="sidebar__content with-tooltip">
    <div class="section section_mobile-secondary">
        <div class="section__header">
            <div class="section__wysiwyg wysiwyg">
                <h2>Notifications
                    <ToolTip
                        :type="'top-right'"
                        :msg="'This notification box will give you specific updates from within the Community Forum. Comments, replies, likes, etc. from this forum will be displayed here.'"
                    />
                </h2>
            </div>
        </div>
        <div class="section__content overflow-content">
            <div class="notifications">
                <div class="notifications__list">

                    <div v-for="note in forum.notifications" :key="note.id" class="notifications__item">
                        <div class="notifications__media">
                            <img v-if="note.author.avatar" :src="note.author.avatar" style="border-radius:50%;" />
                            <img v-else src="@/assets/images/avatar-default.png" alt="Start-Up World" />
                            <div v-if="note.params.type == 'comment'" class="notifications__ico">
                                <svg class="ico" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.91663 2.33268C2.76192 2.33268 2.61354 2.39414 2.50415 2.50354C2.39475 2.61293 2.33329 2.76131 2.33329 2.91602V10.8411L3.67081 9.50354C3.78021 9.39414 3.92858 9.33268 4.08329 9.33268H11.0833C11.238 9.33268 11.3864 9.27122 11.4958 9.16183C11.6052 9.05243 11.6666 8.90406 11.6666 8.74935V2.91602C11.6666 2.76131 11.6052 2.61293 11.4958 2.50354C11.3864 2.39414 11.238 2.33268 11.0833 2.33268H2.91663ZM1.67919 1.67858C2.00738 1.35039 2.4525 1.16602 2.91663 1.16602H11.0833C11.5474 1.16602 11.9925 1.35039 12.3207 1.67858C12.6489 2.00677 12.8333 2.45189 12.8333 2.91602V8.74935C12.8333 9.21348 12.6489 9.6586 12.3207 9.98678C11.9925 10.315 11.5474 10.4993 11.0833 10.4993H4.32492L2.16244 12.6618C1.99561 12.8287 1.7447 12.8786 1.52673 12.7883C1.30875 12.698 1.16663 12.4853 1.16663 12.2493V2.91602C1.16663 2.45189 1.351 2.00677 1.67919 1.67858Z" fill="white"/>
                                </svg>
                            </div>
                            <div v-if="note.params.type == 'reply'" class="notifications__ico">
                                <svg class="ico" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47718 1.95406C5.70498 2.18186 5.70498 2.55121 5.47718 2.77902L2.97299 5.2832L5.47718 7.78739C5.70498 8.0152 5.70498 8.38454 5.47718 8.61235C5.24937 8.84015 4.88002 8.84015 4.65222 8.61235L1.73555 5.69568C1.50775 5.46788 1.50775 5.09853 1.73555 4.87072L4.65222 1.95406C4.88002 1.72625 5.24937 1.72625 5.47718 1.95406Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5647 5.28255C1.5647 4.96039 1.82586 4.69922 2.14803 4.69922H9.14803C9.92158 4.69922 10.6634 5.00651 11.2104 5.55349C11.7574 6.10047 12.0647 6.84234 12.0647 7.61589V11.6992C12.0647 12.0214 11.8035 12.2826 11.4814 12.2826C11.1592 12.2826 10.898 12.0214 10.898 11.6992V7.61589C10.898 7.15176 10.7137 6.70664 10.3855 6.37845C10.0573 6.05026 9.61216 5.86589 9.14803 5.86589H2.14803C1.82586 5.86589 1.5647 5.60472 1.5647 5.28255Z" fill="white"/>
                                </svg>
                            </div>
                            <div v-if="note.params.type == 'like'" class="notifications__ico">
                                <svg class="ico" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.88365 0.930404C5.97728 0.719746 6.18618 0.583984 6.41671 0.583984C7.03555 0.583984 7.62904 0.829817 8.06662 1.2674C8.50421 1.70499 8.75004 2.29848 8.75004 2.91732V4.66732H11.4654C11.7178 4.66498 11.9678 4.71729 12.1981 4.82067C12.4296 4.92455 12.6357 5.0775 12.8021 5.26893C12.9686 5.46036 13.0915 5.6857 13.1622 5.92933C13.2329 6.17295 13.2498 6.42904 13.2118 6.67986L12.4068 11.9298C12.4068 11.9299 12.4068 11.9296 12.4068 11.9298C12.3434 12.3469 12.1314 12.7275 11.8099 13.0008C11.4893 13.2734 11.0811 13.4212 10.6603 13.4173H2.33337C1.86925 13.4173 1.42413 13.2329 1.09594 12.9048C0.767748 12.5766 0.583374 12.1314 0.583374 11.6673V7.58398C0.583374 7.11986 0.767748 6.67474 1.09594 6.34655C1.42413 6.01836 1.86925 5.83398 2.33337 5.83398H3.70428L5.88365 0.930404ZM4.66671 6.54111L6.77127 1.80583C6.94708 1.86192 7.10876 1.95945 7.24167 2.09236C7.46046 2.31115 7.58337 2.6079 7.58337 2.91732V5.25065C7.58337 5.57282 7.84454 5.83398 8.16671 5.83398H11.4684L11.475 5.83395C11.5595 5.83299 11.6433 5.85043 11.7204 5.88505C11.7976 5.91968 11.8663 5.97066 11.9218 6.03447C11.9773 6.09828 12.0182 6.1734 12.0418 6.2546C12.0653 6.33574 12.071 6.42102 12.0583 6.50455C12.0584 6.50447 12.0583 6.50463 12.0583 6.50455L11.2533 11.7549C11.2322 11.894 11.1616 12.0207 11.0544 12.1119C10.9472 12.203 10.8106 12.2523 10.67 12.2507L4.66671 12.2507V6.54111ZM3.50004 12.2507V7.00065H2.33337C2.17866 7.00065 2.03029 7.06211 1.9209 7.17151C1.8115 7.2809 1.75004 7.42928 1.75004 7.58398V11.6673C1.75004 11.822 1.8115 11.9704 1.9209 12.0798C2.03029 12.1892 2.17866 12.2507 2.33337 12.2507H3.50004Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div class="notifications__content">
                            <div class="notifications__wisywig wisywig">
                                <router-link :to="{name: 'profile', params: { id: note.author.id}}"><h4>{{note.author.name}}</h4></router-link>
                                <p>
                                    <a @click.prevent="goToTopic(note)" href="#">
                                        <strong v-if="note.state == 1">{{note.title}}</strong>
                                        <span v-else>{{note.title}}</span>
                                    </a>
                                </p>
                            </div>
                            <div class="notifications__date">
                                <span class="date-text">{{note.date}}</span>
                            </div>
                        </div>
                    </div>

                    <div v-show="!forum.notifications.length" class="notifications__item">
                        There are no updates
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ToolTip from '@/components/ToolTip'
import store from '@/store'
import { router } from '../../router'

  export default {
      name: "Notifications",
      components: {
        ToolTip,
      },
      data() {
          return {
          }
      },
      methods: {
        goToTopic(note) {
            store.commit('SET_COMMENT_TO_HIGHLIGHT', note.params.comment_id);
            store.dispatch('SET_NOTIFICATION_VIEWED', {id: note.id, type: 'personal'});
            router.push({name: "post", params: {id: note.params.post_id}})
        },
      },
      watch: {
      },
      created() {
      },
      computed: {
        forum: () => store.state.forum,
        page: () => store.state.page,
      },
  }
</script>