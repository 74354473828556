<template>
  <div class="post-item__comments">
    <h3>Comments</h3>

    <div v-for="(comment, indexComment) in page.post_comments[this.post]" :key="comment.id" class="post-item__comment"
      >
      <div :class="{'highlight' : comment.highlight}">
        <div class="post-item__header">
          <div class="post-item__avatar">
            <img v-if="comment.author.avatar" :src="comment.author.avatar" alt="" />
            <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
          </div>
          <div class="wysiwyg">
            <router-link :to="{name: 'profile', params: { id: comment.author.id}}" class="">
              <h3>{{comment.author.name}}</h3>
            </router-link>
            <ul>
              <li>{{comment.author.position}}</li>
              <li>
                <router-link :to="{name: 'company_profile', params: {id: comment.author.company_id }}">
                  {{ comment.author.companyname }}
                </router-link>
              </li>
              <li>{{comment.author.country}}, {{comment.author.city}}</li>
              <li>{{comment.date}}</li>
            </ul>
          </div>

          <div v-if="userdata.user_profile.id == comment.author.id" class="post-action ml-auto">
            <a @click.prevent="editComment(indexComment)" href="#" class="post-action__item post-action__item_edit">
              <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z"
                  fill="#00A961"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15 2.1582C14.777 2.1582 14.5631 2.24681 14.4053 2.40452L7.42724 9.38263L7.03079 10.9684L8.61658 10.572L15.5947 3.59386C15.7524 3.43615 15.841 3.22224 15.841 2.99919C15.841 2.77615 15.7524 2.56224 15.5947 2.40452C15.437 2.24681 15.2231 2.1582 15 2.1582ZM13.3447 1.34386C13.7837 0.904842 14.3791 0.658203 15 0.658203C15.6209 0.658203 16.2163 0.904842 16.6553 1.34386C17.0944 1.78288 17.341 2.37832 17.341 2.99919C17.341 3.62006 17.0944 4.2155 16.6553 4.65452L9.53035 11.7795C9.43423 11.8756 9.31379 11.9438 9.18192 11.9768L6.18192 12.7268C5.92634 12.7907 5.65597 12.7158 5.46969 12.5295C5.2834 12.3432 5.20851 12.0729 5.27241 11.8173L6.02241 8.81729C6.05538 8.68542 6.12357 8.56498 6.21969 8.46886L13.3447 1.34386Z"
                  fill="#00A961"></path>
              </svg>
            </a>
            <a @click.prevent="deleteComment(indexComment)" href="#" class="post-action__item post-action__item_trash">
              <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z"
                  fill="#00A961"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z"
                  fill="#00A961"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                  fill="#00A961"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z"
                  fill="#00A961"></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="post-item__content">
          <div class="wysiwyg">
            <div class="pre_wrap">{{comment.text}}</div>
          </div>
          <div v-show="comment.files.length" class="files">
            <div class="files__list">
              <div v-for="file in comment.files" :key="file.id" class="files__item">
                  <a href="#" @click.prevent="showAttach(file)" class="files__link">
                    <svg v-show="file.ext_type === 'video'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5932 4.58313C17.843 4.71168 18 4.96906 18 5.25V12.75C18 13.0309 17.843 13.2883 17.5932 13.4169C17.3434 13.5454 17.0427 13.5236 16.8141 13.3603L11.5641 9.6103C11.367 9.46952 11.25 9.24222 11.25 9C11.25 8.75779 11.367 8.53049 11.5641 8.3897L16.8141 4.6397C17.0427 4.47641 17.3434 4.45457 17.5932 4.58313ZM13.2903 9L16.5 11.2926V6.70739L13.2903 9Z" fill="#00A961" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V12.75C1.5 13.1642 1.83579 13.5 2.25 13.5H10.5C10.9142 13.5 11.25 13.1642 11.25 12.75V5.25C11.25 4.83579 10.9142 4.5 10.5 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H10.5C11.7426 3 12.75 4.00736 12.75 5.25V12.75C12.75 13.9926 11.7426 15 10.5 15H2.25C1.00736 15 0 13.9926 0 12.75V5.25Z" fill="#00A961" />
                  </svg>
                  <svg v-show="file.ext_type === 'document'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
                  </svg>
                  <svg v-show="file.ext_type === 'image'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961" />
                  </svg>
                  <span class="files__text">{{file.user_defined_filename}}</span>
                </a>
              </div>
            </div>
          </div>

          <div class="btn-group">
            <div class="btn-group btn-group_secondary">
              <a v-if="comment.show_reply" @click.prevent="showComments(indexComment, false)" href="#"
                class="btn btn_ninefold">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.04205 2.51264C7.33494 2.80553 7.33494 3.28041 7.04205 3.5733L3.82238 6.79297L7.04205 10.0126C7.33494 10.3055 7.33494 10.7804 7.04205 11.0733C6.74916 11.3662 6.27428 11.3662 5.98139 11.0733L2.23139 7.3233C1.9385 7.03041 1.9385 6.55553 2.23139 6.26264L5.98139 2.51264C6.27428 2.21975 6.74916 2.21975 7.04205 2.51264Z"
                    fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.01172 6.79297C2.01172 6.37876 2.34751 6.04297 2.76172 6.04297H11.7617C12.7563 6.04297 13.7101 6.43806 14.4134 7.14132C15.1166 7.84458 15.5117 8.79841 15.5117 9.79297V15.043C15.5117 15.4572 15.1759 15.793 14.7617 15.793C14.3475 15.793 14.0117 15.4572 14.0117 15.043V9.79297C14.0117 9.19623 13.7747 8.62394 13.3527 8.20198C12.9308 7.78002 12.3585 7.54297 11.7617 7.54297H2.76172C2.34751 7.54297 2.01172 7.20718 2.01172 6.79297Z"
                    fill="#00A961" />
                </svg>
                <span v-if="comment.replies > 0" class="btn__text">Hide Reply ({{comment.replies}})</span>
                <span v-else class="btn__text">Hide Reply</span>
              </a>
              <a v-else @click.prevent="showComments(indexComment, true)" href="#" class="btn">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.04205 2.51264C7.33494 2.80553 7.33494 3.28041 7.04205 3.5733L3.82238 6.79297L7.04205 10.0126C7.33494 10.3055 7.33494 10.7804 7.04205 11.0733C6.74916 11.3662 6.27428 11.3662 5.98139 11.0733L2.23139 7.3233C1.9385 7.03041 1.9385 6.55553 2.23139 6.26264L5.98139 2.51264C6.27428 2.21975 6.74916 2.21975 7.04205 2.51264Z"
                    fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.01172 6.79297C2.01172 6.37876 2.34751 6.04297 2.76172 6.04297H11.7617C12.7563 6.04297 13.7101 6.43806 14.4134 7.14132C15.1166 7.84458 15.5117 8.79841 15.5117 9.79297V15.043C15.5117 15.4572 15.1759 15.793 14.7617 15.793C14.3475 15.793 14.0117 15.4572 14.0117 15.043V9.79297C14.0117 9.19623 13.7747 8.62394 13.3527 8.20198C12.9308 7.78002 12.3585 7.54297 11.7617 7.54297H2.76172C2.34751 7.54297 2.01172 7.20718 2.01172 6.79297Z"
                    fill="#00A961" />
                </svg>
                <span v-if="comment.replies > 0" class="btn__text">Show Reply ({{comment.replies}})</span>
                <span v-else class="btn__text">Add Reply</span>
              </a>
              <a @click.prevent="likeComment(comment.id)" href="#" class="btn" :class="{'btn_ninefold': !comment.liked}">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.56464 1.1954C7.68502 0.92455 7.95361 0.75 8.25 0.75C9.04565 0.75 9.80871 1.06607 10.3713 1.62868C10.9339 2.19129 11.25 2.95435 11.25 3.75V6H14.7412C15.0657 5.997 15.3871 6.06425 15.6833 6.19717C15.9808 6.33072 16.2458 6.52737 16.4598 6.7735C16.6739 7.01963 16.8318 7.30935 16.9228 7.62258C17.0137 7.93582 17.0355 8.26508 16.9865 8.58755L15.9515 15.3375C15.9515 15.3376 15.9516 15.3373 15.9515 15.3375C15.87 15.8737 15.5975 16.3631 15.1842 16.7145C14.7719 17.0649 14.2471 17.255 13.7061 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V9.75C0.75 9.15326 0.987053 8.58097 1.40901 8.15901C1.83097 7.73705 2.40326 7.5 3 7.5H4.7626L7.56464 1.1954ZM6 8.40916L8.70587 2.32095C8.93191 2.39306 9.13978 2.51846 9.31066 2.68934C9.59196 2.97064 9.75 3.35218 9.75 3.75V6.75C9.75 7.16421 10.0858 7.5 10.5 7.5H14.745L14.7535 7.49995C14.8622 7.49872 14.9699 7.52114 15.0691 7.56566C15.1683 7.61018 15.2566 7.67573 15.3279 7.75777C15.3993 7.83981 15.4519 7.93639 15.4822 8.0408C15.5125 8.14511 15.5198 8.25476 15.5035 8.36215C15.5036 8.36205 15.5035 8.36225 15.5035 8.36215L14.4685 15.1125C14.4414 15.2914 14.3505 15.4544 14.2127 15.5715C14.0749 15.6887 13.8994 15.7521 13.7185 15.75L6 15.75V8.40916ZM4.5 15.75V9H3C2.80109 9 2.61032 9.07902 2.46967 9.21967C2.32902 9.36032 2.25 9.55109 2.25 9.75V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H4.5Z"
                    fill="#00A961" />
                </svg>
                <span class="btn__text">{{ comment.liked ? 'Liked' : 'Like' }} {{comment.likes ? '('+comment.likes+')' : ''}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Replies -->

      <Replies v-show="comment.show_reply" :comment="comment.id" :post="post"></Replies>

    </div>


    <!-- Add Comment -->
    <div class="post-item__comment your">
      <div class="post-item__header">
        <div class="post-item__avatar">
          <img v-if="userdata.user_profile.avatar" :src="userdata.user_profile.avatar" alt="" />
          <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
        </div>
      </div>
      <div class="post-item__content">
        <form action="#">
          <div class="form-field">
            <div class="form-field__content">
              <textarea placeholder="Write a comment ..." v-model="add_comment.text"></textarea>
            </div>
          </div>
          <div class="form-field mb_0">

            <div class="attach">
              <div class="attach-item__input">
                <input type="file" id="file" class="input" multiple
                  accept=".pdf,.doc,.docx,.odt,.xls,.xlsx,.ods,.ppt,.ppts,.odp,.jpeg,.jpg,.png,.gif,.mp4"
                  ref="fileAddComment" @change.prevent="handleFileUpload">
              </div>
              <div v-show="this.files" class="attach__list">
                <div v-for="(file, index) in this.files" :key="file.id"
                  class="attach__item attach-item">
                  <div class="attach-item__ico">
                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="#BFC8D1"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z"
                        fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z"
                        fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z"
                        fill="#00A961" />
                    </svg>
                  </div>

                  <div class="attach-item__title">
                    <span class="title-text">{{file.name}}</span>
                  </div>
                  <div @click="removeFile(index)" class="attach-item__remove">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="#BFC8D1" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z"
                        fill="#333333" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z"
                        fill="#333333" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                        fill="#333333" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z"
                        fill="#333333" />
                    </svg>
                  </div>
                </div>
              </div>


              <div class="btn-group btn-group_secondary">
                <a @click.prevent="addComment" href="#" class="btn btn_primary">
                  <span class="btn__text">Add comment</span>
                </a>
                <div @click="uploadInit" class="attach__init attach__init_secondary">
                  <svg class="ico" width="20" height="20" viewBox="0 0 20 20" fill="#BFC8D1"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.1499 1.98991C12.4864 1.98991 11.85 2.2535 11.3809 2.72268L3.72253 10.381C2.9406 11.1629 2.50132 12.2235 2.50132 13.3293C2.50132 14.4351 2.9406 15.4956 3.72253 16.2775C4.50445 17.0594 5.56496 17.4987 6.67077 17.4987C7.77658 17.4987 8.83709 17.0594 9.61901 16.2775L17.2773 8.61917C17.6028 8.29374 18.1304 8.29374 18.4559 8.61917C18.7813 8.94461 18.7813 9.47225 18.4559 9.79768L10.7975 17.456C9.70304 18.5505 8.2186 19.1654 6.67077 19.1654C5.12294 19.1654 3.6385 18.5505 2.54401 17.456C1.44953 16.3615 0.834656 14.8771 0.834656 13.3293C0.834656 11.7814 1.44953 10.297 2.54401 9.20251L10.2023 1.54417C10.9841 0.762424 12.0444 0.323242 13.1499 0.323242C14.2555 0.323242 15.3158 0.762424 16.0975 1.54417C16.8793 2.32592 17.3185 3.3862 17.3185 4.49176C17.3185 5.59732 16.8793 6.6576 16.0975 7.43935L8.43086 15.0977C7.96184 15.5667 7.32572 15.8302 6.66244 15.8302C5.99915 15.8302 5.36303 15.5667 4.89401 15.0977C4.425 14.6287 4.16151 13.9925 4.16151 13.3293C4.16151 12.666 4.425 12.0299 4.89401 11.5608L11.9694 4.49383C12.295 4.16858 12.8226 4.16889 13.1479 4.49452C13.4731 4.82015 13.4728 5.34779 13.1472 5.67303L6.07253 12.7394C5.91629 12.8958 5.82818 13.1082 5.82818 13.3293C5.82818 13.5505 5.91607 13.7627 6.07253 13.9192C6.22898 14.0756 6.44118 14.1635 6.66244 14.1635C6.8837 14.1635 7.09589 14.0756 7.25235 13.9192L14.919 6.26084C15.388 5.79168 15.6518 5.15514 15.6518 4.49176C15.6518 3.82823 15.3882 3.19187 14.919 2.72268C14.4498 2.2535 13.8135 1.98991 13.1499 1.98991Z"
                      fill="#BFC8D1" />
                  </svg>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import store from '@/store'
import Replies from '@/components/page/Replies'

export default {
  name: "Comments",
  components: {
    Replies,
  },
  props: ['post'],
  data() {
    return {
      add_comment: {
        text: '',
        files: [],
      },
      files:[],
    }
  },
  methods: {
    uploadInit() {
        const elem = this.$refs.fileAddComment;
        elem.click();
    },
    handleFileUpload() {
        // this.add_comment.files.push(this.$refs.fileAddComment.files[0]);
        this.$refs.fileAddComment.files;
        for (let i = 0; i < this.$refs.fileAddComment.files.length; i++) {
            this.files.push(this.$refs.fileAddComment.files[i]);
        }
    },
    removeFile(index) {
        // this.add_comment.files.splice(index, 1);
        this.files.splice(index, 1);
    },
    async addComment() {
      store.dispatch('SET_POST_TO_SHOW', this.post);
      store.dispatch('SET_COMMENT_TO_SHOW', false);
      if(this.files) {
          await store.dispatch('PUT_PAGE_FILES', this.files);
      }
      let data = {
          user: this.userdata.user_profile.id,
          post: this.post,
          text: this.add_comment.text,
      };
      if(this.files) data.files = this.page.put_files;
      console.log(data)
      store.dispatch('STORE_COMMENT', data);
      this.files = [];
      this.add_comment.text = '';
    },

    editComment(index) {
        store.dispatch('SET_POST_TO_SHOW', this.post);
        store.dispatch('SET_COMMENT_TO_SHOW', false);
        let data = {
            text: this.page.post_comments[this.post][index].text,
            files: this.page.post_comments[this.post][index].files,
            comment_id: this.page.post_comments[this.post][index].id,
        };
        store.dispatch('SHOW_POPUP', { popup: 'postCommentEditPopup', show: true, data: data});
    },

    deleteComment(index) {
        store.dispatch('SET_POST_TO_SHOW', this.post);
        store.dispatch('SET_COMMENT_TO_SHOW', false);
        store.dispatch('SET_COMMENT_TO_DELETE', this.page.post_comments[this.post][index].id);
        store.dispatch('SHOW_POPUP', {
            popup: 'confirmPopup',
            show: 'Are you sure you want to delete this comment?',
            route: '',
            name: 'REMOVE_COMMENT',
            data: '',
        });
    },

    likeComment(id) {
        store.dispatch('LIKE_COMMENT', id);
    },

    showAttach: function(attachment) {
        store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
    },

    showComments(index, status) {
        if(status) {
            store.dispatch('GET_COMMENT_REPLIES', this.page.post_comments[this.post][index].id);
        }
        this.page.post_comments[this.post][index].show_reply = status;
    },
  },
  computed: {
    page: () => store.state.page,
    userdata: () => store.state.user,
  }
}
</script>