// import axios from 'axios'
import suwAPI from '@/config/config'
import { router } from '@/router'
import userService from '@/services/service.user'
import userProfileService from '@/services/service.userprofile'
import natsIO from '@/plugins/natsIO'

const user = {
  state: {
    access_token: '',
    refresh_token: '',
    social_login: false,
    social_names: false,
    login_error: false,
    login_not_confirm: false,
    register_role: '',
    forgot_confirm: false,
    forgot_error: false,
    forgot_error_message: '',
    restore_success: false,
    register_error: false,
    reg_ind_error: false,
    put_file: false,
    confirmed_email: false,
    profile_pdf: {
      id: '',
      description: '',
      experience: [],
      education: [],
    },
    experiences: [],
    educations: [],
    media: [],
    exp_to_remove: '',
    edu_to_remove: '',
    media_to_remove: '',
    user_profile: {
      avatar: '',
      avatar_id: '',
      email: '',
      id: 0,
      account_type: '',
      firstname: '',
      lastname: '',
      position: '',
      in_company_role: '',
      description: '',
      company: {},
      companyid: 0,
      companycity: '',
      companycountry: '',
      companyname: '',
      companyindustry: [],
      userindustry: [],
      type_of_investment: '',
      google_calendar: false,
      google_email: false,
      is_messages_private: false,
      is_profile_private: false,
      company_user_type: '',
    },
    view_user_profile: {
      avatar:'',
      city: '',
      country: '',
      email: '',
      id: '',
      is_messages_private: false,
      is_profile_private: false,
      companyid: 0,
      lastname: '',
      firstname: '',
      position: '',
      description: '',
      blocked_me: false,
      in_my_crm: false,
      type_of_investment: '',
      company_user_type: '',
    },
    invitation: '',
    ws_connection: false,
    blockSearchResult: {},
    blockedUsers: {},
    feedback_types: {},
  },
  mutations: {
    SET_USER_TOKEN: (state, payload) => {
      state.access_token = payload.data.authorisation.token;
      state.refresh_token = payload.data.authorisation.token;
    },
    USER_LOGIN_ERROR: (state, payload) => {
      state.login_error = payload;
    },
    USER_LOGIN_NOT_CONFIRM_ERROR: (state, payload) => {
      state.login_not_confirm = payload;
    },
    SET_USER_CONFIRMED: (state) => {
      state.confirmed_email = true;
    },
    SET_USER_REGISTER_ROLE: (state, payload) => {
      state.register_role = payload;
    },
    SET_FORGOT_CONFIRM: (state, payload) => {
      state.forgot_confirm = payload;
    },
    SET_FORGOT_ERROR: (state, payload) => {
      state.forgot_error = payload.show;
      state.forgot_error_message = payload?.message;
    },
    SET_FORGOT_SUCCESS: (state, payload) => {
      state.restore_success = payload;
    },
    USER_REG_ERROR: (state, payload) => {
      state.register_error = payload;
    },
    USER_SOC_TOKEN: (state, payload) => {
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.social_login = true;
    },
    USER_CLEAR_SOC_TOKEN: (state) => {
      state.access_token = '';
      state.refresh_token = '';
      state.social_login = false;
    },
    SET_REGISTER_PROFILE: (state, payload) => {
      state.user_profile = payload
    },
    SET_USER_PROFILE: (state, payload) => {
      state.user_profile = payload
      state.user_profile.companycountry = payload.country;
      state.user_profile.companycity = payload.city;
      state.user_profile.companyname = payload.company.companyname;
      if(payload.avatar) state.user_profile.avatar_id = payload.avatar.id;
    },
    SET_USER_FEEDBACK_TYPES: (state, payload) => {
      state.feedback_types = payload;
    },

    USER_REG_IND_ERROR: (state, payload) => {
      state.reg_ind_error = payload;
    },
    USER_SOC_NAMES: (state, payload) => {
      state.social_names = payload;
    },
    PUT_FILE: (state, payload) => {
      state.put_file = payload;
    },
    USER_SET_PDF_PROFILE: (state, payload) => {
      state.profile_pdf = payload;
    },
    SET_USER_EXPERIENCES: (state, payload) => {
      state.experiences = payload;
      state.put_file = '';
    },
    SET_USER_EDUCATIONS: (state, payload) => {
      state.educations = payload;
      state.put_file = '';
    },
    SET_USER_MEDIA: (state, payload) => {
      state.media = payload;
      state.put_file = '';
    },
    USER_SET_EXP_TO_DELETE: (state, payload) => {
      state.exp_to_remove = payload;
    },
    USER_SET_EDU_TO_DELETE: (state, payload) => {
      state.edu_to_remove = payload;
    },
    USER_SET_MEDIA_TO_DELETE: (state, payload) => {
      state.media_to_remove = payload;
    },
    SET_VIEW_USER_PROFILE: (state, payload) => {
      state.view_user_profile = payload.user;
      state.view_user_profile.companyindustry = payload.companyindustry;
      state.view_user_profile.userindustry = payload.userindustry;
      state.view_user_profile.companyname = payload.companyname;
    },
    RESET_VIEW_USER_PROFILE: (state) => {
      state.view_user_profile.avatar = '';
      state.view_user_profile.city = '';
      state.view_user_profile.country = '';
      state.view_user_profile.email = '';
      state.view_user_profile.id = '';
      state.view_user_profile.is_messages_private = false;
      state.view_user_profile.is_profile_private = false;
      state.view_user_profile.lastname = '';
      state.view_user_profile.firstname = '';
      state.view_user_profile.description = '';
      state.view_user_profile.position = '';
      state.view_user_profile.companyindustry = '';
      state.view_user_profile.userindustry = '';
      state.view_user_profile.type_of_investment = '';
      state.view_user_profile.companyname = '';
      state.view_user_profile.blocked_me = false;
      state.view_user_profile.in_my_crm = false;
      state.view_user_profile.company_user_type = '';
    },
    USER_LOGOUT: (state) => {
      state.access_token = '';
      state.user_profile.avatar = '';
      state.social_login = false;
    },
    SET_INVITATION: (state, payload) => {
      state.invitation = payload;
    },
    SET_WS_CONNECTION: (state, payload) => {
      state.ws_connection = payload;
    },
    SET_USER_SEARCH_TO_BLOCK: (state, payload) => {
      state.blockSearchResult = payload;
    },
    RESET_USER_SEARCH_TO_BLOCK:(state) => {
      state.blockSearchResult = {};
    },
    SET_BLOCKED_USERS:(state, payload) => {
      state.blockedUsers = payload;
    },
  },
  actions: {
    async USER_LOGIN({commit, dispatch}, payload) {
      const response = await userService.login(payload);

      if(response.status == 200 && response.data.authorisation.token) {
        localStorage.access_token = response.data.authorisation.token;
        localStorage.refresh_token = response.data.authorisation.token;
        commit('SET_USER_TOKEN', response);
        dispatch('USER_GET_INIT_APP');
        router.push({name: "home"});
      } else {
        if(response.status === 402 || response.status === 403 ) {
          commit('USER_LOGIN_NOT_CONFIRM_ERROR', true);
        }
        commit('USER_LOGIN_ERROR', true);
      }
    },

    async USER_GET_INIT_APP({commit, dispatch}){
      dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: false, data: '' });
      const response = await userService.check();
      if(response.data.valid) {
        dispatch('RESET_VIEW_USER_PROFILE');
        dispatch('RESET_COMPANY_INFO');
        const responseUser = await userService.user();
        let industry = responseUser.data.user.company.companyindustry;
        let industryArr = [];
        for (let index = 0; index < industry.length; index++) {
          industryArr.push(industry[index]['value']);
        }
        responseUser.data.user.companyindustry = industryArr.join(', ');

        commit('SET_USER_PROFILE', responseUser.data.user);
        commit('SET_USER_FEEDBACK_TYPES', responseUser.data.feedback_types);
        dispatch('GET_MY_COMPANY_INFO');
        dispatch('GET_NOTIFICATIONS_PERSONAL');
        await natsIO.initialNatsIO(this.$root);
        await natsIO.subscribeStatusesChannel(responseUser.data.user.id);
        let data = {
          action: 'change',
          status: 'offline'
        };
        await natsIO.publishStatus(responseUser.data.user.id, data);
        await natsIO.checkStatus();
      }
    },

    async SET_USER_PROFILE({commit, dispatch}){
      dispatch('RESET_VIEW_USER_PROFILE');
      const responseUser = await userService.user();
      if(responseUser.status === 200) {
        if(!responseUser.data.user.account_type) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          router.push({name: "home"});
        }
        let industry = responseUser.data.user.company.companyindustry;
        let industryArr = [];
        for (let index = 0; index < industry.length; index++) {
          industryArr.push(industry[index]['value']);
        }
        responseUser.data.user.companyindustry = industryArr.join(', ');

        commit('SET_USER_PROFILE', responseUser.data.user);
        commit('SET_USER_FEEDBACK_TYPES', responseUser.data.feedback_types);
      }
    },

    async SET_VIEW_USER_PROFILE({commit}, payload) {
      const responseProfile = await userProfileService.viewUserProfile(payload);
      if(responseProfile.status === 200) {
        let industry = responseProfile.data.user.company.companyindustry;
        let industryArr = [];
        for (let index = 0; index < industry.length; index++) {
          industryArr.push(industry[index]['value']);
        }
        responseProfile.data.companyindustry = industryArr.join(', ');
        responseProfile.data.companyname = responseProfile.data.user.company.companyname;

        commit('SET_VIEW_USER_PROFILE', responseProfile.data);
      } else {
        router.push({ name: 'profile_edit'});
      }
    },

    RESET_VIEW_USER_PROFILE({commit}) {
      commit('RESET_VIEW_USER_PROFILE');
    },

    USER_LOGIN_RESET({commit}) {
      commit('USER_LOGIN_ERROR', false);
      commit('USER_LOGIN_NOT_CONFIRM_ERROR', false);
      commit('USER_SOC_NAMES', false);
    },

    USER_REGISTER_ROLE({commit}, payload) {
      commit('SET_USER_REGISTER_ROLE', payload);
      router.push({name: "register"});
    },

    async REG_NEW_ACCOUNT({commit, dispatch}, payload) {
      commit('USER_REG_ERROR', '');
      let response = false;

      if(this.state.user.access_token) {
        localStorage.access_token = this.state.user.access_token;
        localStorage.refresh_token = this.state.user.refresh_token;
        delete payload.email;
        delete payload.password;
        response = await userService.completeaccount(payload);
      } else {
        response = await userService.createaccount(payload);
      }

      if(response.status === 200) {
        commit('SET_REGISTER_PROFILE', response.data.user);
        commit('SET_FORGOT_CONFIRM', false);
        await dispatch('SET_USER_LOGGED_IN');
        if (response.data.notify) {
          response.data.notify.params.to_users.forEach(user => {
            response.data.notify.to_user = user;
            natsIO.publishNotify(user, response.data.notify);
          });
        }
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        // commit('USER_REG_ERROR', response.data.errors);
      }
    },

    SET_USER_LOGGED_IN({dispatch}) {
      if(this.state.user.social_login) {
        if(this.state.user.confirmed_email == false) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          router.push({name: "confirm_email"});
        } else {
          localStorage.access_token = this.state.user.access_token;
          localStorage.refresh_token = this.state.user.refresh_token;
          // commit('USER_CLEAR_SOC_TOKEN');
          dispatch('USER_GET_INIT_APP');
          router.push({name: "home"});
        }
      } else {
        router.push({name: "confirm_email"});
      }
    },

    async ACCOUNT_FORGOT_PASSWORD({commit}, payload) {
      commit('SET_FORGOT_ERROR', {show: false});
      commit('SET_FORGOT_CONFIRM', false);

      const response = await userService.forgotpassword({ email: payload });

      if(response.status === 200) {
        commit('SET_FORGOT_CONFIRM', true);
        router.push({name: "confirm_email"});
      } else {
        commit('SET_FORGOT_ERROR', {show: true, message: response?.data.message});
      }
    },

    SET_FORGOT_ERROR({commit}, payload) {
      commit('SET_FORGOT_ERROR', {show: payload});
    },

    async RESET_PASSWORD({commit}, payload) {
      const response = await userService.restorepassword(payload);
      if(response.status === 200) {
        commit('SET_FORGOT_SUCCESS', true);
      }
    },

    USER_SOCIAL_LOGIN({commit, dispatch}, payload) {
      commit('USER_CLEAR_SOC_TOKEN', true);
      window.open(suwAPI.oAuthProviders[payload], '', 'width=600,height=800');

      let receiveMessage = async (e) => {
        window.removeEventListener('message', receiveMessage);
        if (e.origin+"/" === process.env.VUE_APP_BACKEND_BASEURL) {
          var payload = {};
          payload.social_login = true;
          payload.access_token = e.data.access_token;
          payload.refresh_token = e.data.refresh_token;
          commit('USER_SOC_TOKEN', payload);
          localStorage.access_token = e.data.access_token;
          localStorage.refresh_token = e.data.refresh_token;
          const response = await userService.user();
          if(response.status === 200) {
            var datanames = {};
            datanames.firstname = response.data.user.firstname;
            datanames.lastname = response.data.user.lastname;
            commit('USER_SOC_NAMES', datanames);
          } else {
            return;
          }

          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");

          if(e.data.location == location.origin+"/account_create") {
            console.log("account_create");
            router.push({name: "account_create"});
          }
          if(e.data.location == location.origin+"/confirm_email") {
            console.log("confirm_email");
            router.push({name: "confirm_email"});
          }
          

          if(e.data.location == location.origin) {
            localStorage.access_token = e.data.access_token;
            localStorage.refresh_token = e.data.refresh_token;
            // commit('USER_CLEAR_SOC_TOKEN');
            dispatch('USER_GET_INIT_APP');
            router.push({name: "home"});
          }
        }
      }

      window.addEventListener('message', receiveMessage, false);
    },

    USER_CALENDAR_ACCESS({dispatch}) {
      window.open(suwAPI.oAuthProviders['calendar'], '', 'width=600,height=800');
      this.state.user.user_profile.google_calendar = false;

      let receiveMessage = (e) => {
        window.removeEventListener('message', receiveMessage);
          console.log('popup event',e.data)
        if(e.data.google_calendar) {
          dispatch('USER_UPDATE_GOOGLE_CALENDAR', e.data);
          // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Please go to your Google mail. <br><br>There should be an email with the subject "Important Security Alert", open it. <br><br>Click “View actions” and confirm that it was you'});
        }
      }
      window.addEventListener('message', receiveMessage, false);
    },

    async USER_UPDATE_GOOGLE_CALENDAR({commit}, payload) {
      const response = await userService.updateGoogleCalendar(payload);
      if(response.status == 200) {
        commit('SET_USER_PROFILE', response.data);
      }
    },

    USER_LOGOUT({commit}) {
      commit('USER_LOGOUT');
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      commit('RESET_COMPANY_INFO');
      natsIO.close();
      router.push({name: "cover"});
    },

    // ToDo remove this
    USER_REG_ERROR({commit}, payload) {
      commit('USER_REG_ERROR', payload);
    },

    async USER_CONFIRM({commit, dispatch}, payload) {
      const data = payload.confirmation_key+"?expires="+payload.expires+"&signature="+payload.signature;
      const response = await userService.confirm(data);
      if(response.status === 200) {
        localStorage.access_token = response.data.authorisation.token;
        localStorage.refresh_token = response.data.authorisation.token;
        commit('SET_USER_TOKEN', response);
        commit('SET_USER_CONFIRMED');
        commit('SET_USER_PROFILE', response.data.user);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async CANCEL_REQUEST_JOINING({commit, dispatch}, payload) {
      console.log(commit);
      const response = await userService.cancelRequestJoining(payload);
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      } else {
        return;
      }
    },

    USER_REG_IND_ERROR({commit}, payload) {
      if(payload) {
        commit('USER_REG_IND_ERROR', 'Please fill in all required fields.');
      } else {
        commit('USER_REG_IND_ERROR', false);
      }

    },

    async PUT_AVATAR({dispatch}, payload) {
      const response = await userProfileService.postUserProfilePutAvatar(payload);
      if(response.status === 200) {
        dispatch('SET_USER_PROFILE');
        dispatch('SHOW_POPUP', { popup: 'userEditLogoPopup', show: false});
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async PUT_FILE({commit, dispatch}, payload) {
      const response = await userProfileService.postUserProfilePutFile(payload);
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
      if(response.status === 200) {
        commit('PUT_FILE', response.data);
      }
    },

    async USER_UPLOAD_PROFILE({commit, dispatch}, payload) {
      const response = await userProfileService.uploadProfile(payload);
      if(response.status === 200) {
        await commit('USER_SET_PDF_PROFILE', response.data);
        dispatch('SHOW_POPUP', { popup: 'PreviewProfilePopup', show: true});
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async USER_UPDATE_FROM_PDF({dispatch}, payload) {
      const response = await userProfileService.updateProfileFromPDF(payload);
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'PreviewProfilePopup', show: false});
        return true;
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async SAVE_EXPERIENCE({commit, dispatch}, payload) {

      if(!payload.attachment){
        payload.attachment = "";
      }

      let response = '';
      console.log(commit);
      if(payload.id){
        response = await userProfileService.putExperience(payload);
      } else {
        response = await userProfileService.postExperience(payload);
      }
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        dispatch('GET_USER_EXPERIENCES');
      }
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'experiencePopup', show: false});
        dispatch('GET_USER_EXPERIENCES');
      }


    },

    async GET_USER_EXPERIENCES({commit}) {
      let response = {};
      if(router.currentRoute.value.params.id){
        response = await userProfileService.viewUserExperience(router.currentRoute._value.params.id);
      } else {
        response = await userProfileService.getExperiences();
      }
      if(response.status === 200) {
        commit('SET_USER_EXPERIENCES', response.data.data.items);
      }
    },

    async REMOVE_EXP({commit, dispatch}) {
      const response = await userProfileService.removeExperience(this.state.user.exp_to_remove);
      if(response.status === 200) {
        commit('USER_SET_EXP_TO_DELETE', '');
        dispatch('GET_USER_EXPERIENCES');
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async REMOVE_EDU({commit, dispatch}) {
      const response = await userProfileService.removeEducation(this.state.user.edu_to_remove);
      if(response.status === 200) {
        commit('USER_SET_EDU_TO_DELETE', '');
        dispatch('GET_USER_EDUCATIONS');
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },
    async REMOVE_MEDIA({commit, dispatch}) {
      const response = await userProfileService.removeMedia(this.state.user.media_to_remove);
      if(response.status === 200) {
        commit('USER_SET_MEDIA_TO_DELETE', '');
        dispatch('GET_USER_MEDIA');
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async UPDATE_USER_PROFILE({commit, dispatch}, payload) {
      console.log(commit);
      const response = await userProfileService.postUserProfile(payload);
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
      if(response.status === 200) {
        dispatch('SET_USER_PROFILE');
        dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your data has been successfully saved.'});
      }
    },

    async UPDATE_USER_SETTINGS({dispatch}, payload) {
      const response = await userProfileService.postUserSettings(payload);
      if(response.status === 200) {
        await dispatch('SET_USER_PROFILE');
        dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your data has been successfully saved.'});
        dispatch('SHOW_POPUP', { popup: 'settingsPopup', show: false});
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async SAVE_EDUCATION({commit, dispatch}, payload) {

      if(!payload.attachment){
        payload.attachment = "";
      }

      let response = '';
      console.log(commit);
      if(payload.id){
        response = await userProfileService.putEducation(payload);
      } else {
        response = await userProfileService.postEducation(payload);
      }
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        dispatch('GET_USER_EDUCATIONS');
      }
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'educationPopup', show: false});
        dispatch('GET_USER_EDUCATIONS');
      }


    },

    async GET_USER_EDUCATIONS({commit}) {
      let response = {};
      if(router.currentRoute.value.params.id){
        response = await userProfileService.viewUserEducation(router.currentRoute.value.params.id);
      } else {
        response = await userProfileService.getEducations();
      }

      if(response.status === 200) {
        commit('SET_USER_EDUCATIONS', response.data.data.items);
      }
    },

    async SAVE_MEDIA({commit, dispatch}, payload) {

      if(!payload.attachment){
        payload.attachment = "";
      }

      let response = '';
      console.log(commit);
      if(payload.id){
        response = await userProfileService.putMedia(payload);
      } else {
        response = await userProfileService.postMedia(payload);
      }
      if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        dispatch('GET_USER_MEDIA');
      }
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'mediaPopup', show: false});
        dispatch('GET_USER_MEDIA');
      }
    },

    async GET_USER_MEDIA({commit}) {
      let response = {};
      if(router.currentRoute.value.params.id) {
        response = await userProfileService.viewUserMedia(router.currentRoute.value.params.id);
      } else {
        response = await userProfileService.getMedia();
      }

      if(response.status === 200) {
        commit('SET_USER_MEDIA', response.data.data.items);
      }
    },

    async ACCEPT_INVITATION({commit}, payload) {
      let response = await userService.acceptinvitation(payload);
      let allowRoles = [];
      if(response.status === 200){
        for (let index = 0; index < response.data.data.account_type.length; index++) {
          allowRoles.push(response.data.data.account_type[index]);
        }
        response.data.data.allowRoles = allowRoles;
        commit('SET_INVITATION', response.data.data);
      }
    },

    USER_INVITATION_ROLE({commit}, payload) {
      commit('SET_USER_REGISTER_ROLE', payload);
      router.push({name: "register_invitation"});
    },

    async INVITATION_SIGNUP({commit, dispatch}, payload) {
      commit('USER_REG_ERROR', '');

      const response = await userService.invitationsignup(payload);

      if(response.status === 200) {
        commit('SET_FORGOT_CONFIRM', false);

        let creds = {}
        creds.email = payload.email;
        creds.password = payload.password;
        const responseLogin = await userService.login(creds);

        if(responseLogin.data.authorisation.token) {
          console.log(responseLogin.data)
          localStorage.access_token = responseLogin.data.authorisation.token;
          localStorage.refresh_token = responseLogin.data.authorisation.token;

          commit('SET_USER_TOKEN', responseLogin);
          await dispatch('USER_GET_INIT_APP');
          await userService.invitationDone({id: payload.invitation_id, user: responseLogin.data.user.id});
          if (payload.type == "page") {
            router.push({name: "page", params: {id: payload.params.page}});
          } else {
            router.push({name: "company_profile", params: {id: payload.company_id}});
          }
        } else {
          if(responseLogin.status === 403) {
            commit('USER_LOGIN_NOT_CONFIRM_ERROR', true);
          }
          commit('USER_LOGIN_ERROR', true);
        }
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        // commit('USER_REG_ERROR', response.data.errors);
      }
    },

    async SEARCH_USER_FOR_BLOCK({commit}, payload) {
      const response = await userProfileService.searchForBlock(payload);

      if(response.status === 404) {
        console.log('404')
      }

      if(response.status === 200) {
        if(response.data.length <= 0) {
          commit('SET_USER_SEARCH_TO_BLOCK', {});
        } else {
          commit('SET_USER_SEARCH_TO_BLOCK', response.data.data);
        }
      }
    },

    RESET_USER_SEARCH_TO_BLOCK({commit}) {
      commit('RESET_USER_SEARCH_TO_BLOCK');
    },

    async CHANGE_USER_BLOCK_STATUS({commit, dispatch}, payload) {
      const response = await userProfileService.toggleBlockUserStatus(payload);
      if(response.status === 200) {
        const responseUsers = await userProfileService.getBlockedUsers();
        if(responseUsers.status === 200) {
          await commit('SET_BLOCKED_USERS', responseUsers.data.data);
        }
      } else if(response.status === 422) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async GET_BLOCKED_USERS({commit}) {
      const responseUsers = await userProfileService.getBlockedUsers();
      if(responseUsers.status === 200) {
        await commit('SET_BLOCKED_USERS', responseUsers.data.data);
      }
    },

    async SET_COMPANY_ROLE({dispatch}, payload) {
      let response = await userProfileService.setCompanyRole(payload);
      if(response.status === 200) {
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    WS_CONNECTION({commit}, payload) {
      commit('SET_WS_CONNECTION', payload);
    },

    async USER_RESET_SOCIAL_LOGIN({commit}) {
      commit('USER_CLEAR_SOC_TOKEN', true);
    },

    async USER_SEND_FEEDBACK({dispatch}, payload) {
      const response = await userService.saveFeedback(payload);
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your message has been successfully sent.'});
        dispatch('SHOW_POPUP', { popup: 'feedbackPopup', show: false});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },
    
    TEST_IMAGE() {
      // axios({
      //   method: 'get',
      //   url: 'https://suw.abovebits.com/files/157bec0c7fa04563a01077406cc41eec.png',
      //   headers: {
      //     'Authorization': 'Bearer '+localStorage.access_token+''
      //   },
      // }).then(function(response) {
      //   console.log(response)
      // }).catch(function(error) {
      //   console.log(error);
      // })

      /* axios({
        method: 'get',
        url: 'https://suw.abovebits.com/files/157bec0c7fa04563a01077406cc41eec.png',
        responseType: 'arraybuffer',
        headers: {
          'Authorization': 'Bearer '+localStorage.access_token+''
        }
      })
      .then(function(resp) {
        var mimeType = resp.headers['content-type'].toLowerCase();
        var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
        var src = 'data:' + mimeType + ';base64,' + imgBase64;
        console.log(src);
      }).catch((function() {
        var src = 'https://suw.abovebits.com/files/157bec0c7fa04563a01077406cc41eec.png';
        console.log(src);
      })); */

      // userService.user();

    },
  },
  getters: {

  }
}
export default user;
