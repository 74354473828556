<template>
<div class="post__item">
  <div class="post-item__header">
    <div class="post-company__logo">
      <img v-if="job.logo" :src="job.logo.link" alt="">
      <img v-else src="@/assets/images/no-logo.png" alt="">
    </div>
    <div class="wysiwyg">
      <h3 v-if="job.link_to == 'company'">
        <router-link :to="{name: 'company_profile', params:{id:job.company_id}}">{{job.name}}</router-link>
      </h3>
      <h3 v-else>
        <router-link :to="{name: 'profile', params:{id:job.user_id}}">{{job.name}}</router-link>
      </h3>
      <ul>
        <li v-for="(foe, index) in job.foe" :key="index">{{foe}}</li>
        <li v-for="(toe, index) in job.toe" :key="index">{{toe}}</li>
        <li>{{job.location}}</li>
        <li>Posted {{job.date}}</li>
      </ul>
    </div>

    <div class="post-action ml-auto">
      <div v-if="type == 'my_jobs'" @click.prevent="editJob" class="post-action__item post-action__item_edit">
        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2151_21684)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z" fill="#5D6167"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.16016C14.7769 2.16016 14.563 2.24876 14.4053 2.40648L7.42722 9.38458L7.03077 10.9704L8.61656 10.5739L15.5947 3.59582C15.7524 3.4381 15.841 3.22419 15.841 3.00115C15.841 2.7781 15.7524 2.56419 15.5947 2.40648C15.4369 2.24876 15.223 2.16016 15 2.16016ZM13.3447 1.34582C13.7837 0.906796 14.3791 0.660156 15 0.660156C15.6209 0.660156 16.2163 0.906796 16.6553 1.34582C17.0943 1.78484 17.341 2.38028 17.341 3.00115C17.341 3.62202 17.0943 4.21746 16.6553 4.65648L9.53032 11.7815C9.4342 11.8776 9.31377 11.9458 9.18189 11.9788L6.18189 12.7288C5.92631 12.7926 5.65595 12.7178 5.46966 12.5315C5.28338 12.3452 5.20849 12.0748 5.27239 11.8192L6.02239 8.81925C6.05535 8.68737 6.12354 8.56694 6.21966 8.47082L13.3447 1.34582Z" fill="#5D6167"></path>
          </g>
          <defs>
            <clipPath id="clip0_2151_21684">
              <rect width="18" height="18" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>

      </div>
      <div v-if="type == 'my_jobs'" @click.prevent="deleteJob" class="post-action__item post-action__item_trash">
        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#5D6167"></path>
        </svg>
      </div>
      <div v-if="type == 'favorites'" @click.prevent="toggleFavorites" class="post-action__item post-action__item_trash">
        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#5D6167"></path>
        </svg>
      </div>
    </div>

  </div>
  <div class="post-item__content">
    <div class="wysiwyg">
      <h3>{{job.title}}</h3>
      <p>{{job.description}}</p>
    </div>
  </div>
  <div v-if="type == 'normal' || type == 'favorites'" class="post-item__footer">
    <div class="btn-group">
      <div class="btn-group btn-group_secondary">
        <a v-if="job.in_my_crm" @click.prevent="removeFromCRM" href="#" class="btn btn_secondary d-none d-flex-tab">
          <span class="btn__text">Remove from CRM</span>
        </a>
        <a v-else @click.prevent="addToCRM" href="#" class="btn btn_secondary">
          <span class="btn__text">Add to CRM</span>
        </a>
        <router-link :to="{ name: 'crmUser', params: { id: job.crm_id }}" v-if="job.in_my_crm" class="btn btn_secondary">
          <span class="btn__text">Open CRM Card</span>
        </router-link>

        <a @click.prevent="sendMSG" href="#" class="btn btn_secondary">
          <span class="btn__text">Send Message</span>
        </a>
        <a v-if="job.favorite && type == 'normal'" @click.prevent="toggleFavorites" href="#" class="btn btn_ninefold d-none d-flex-tab">
          <svg class="btn__ico" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H11.25C11.6642 0.25 12 0.585786 12 1C12 1.41421 11.6642 1.75 11.25 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z" fill="#5D6167" />
          </svg>
          <span class="btn__text">Remove From Favorites</span>
        </a>
        <a v-if="!job.favorite" @click.prevent="toggleFavorites" href="#" class="btn d-none d-flex-tab">
          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="#00A961" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="#00A961" />
          </svg>
          <span class="btn__text">Add to favorites</span>
        </a>

        <div v-if="type == 'normal' || type == 'favorites'" class="form-field__content d-none-tab">
          <div class="dropdown dropdown_secondary" :class="{'dropdown_active': open_dropdown}">
            <div class="dropdown__init">
              <a @click.prevent="open_dropdown = !open_dropdown" href="javascript:;" class="btn btn_secondary">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C10.5 4.57843 9.82843 5.25 9 5.25C8.17157 5.25 7.5 4.57843 7.5 3.75Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14.25C7.5 13.4216 8.17157 12.75 9 12.75C9.82843 12.75 10.5 13.4216 10.5 14.25C10.5 15.0784 9.82843 15.75 9 15.75C8.17157 15.75 7.5 15.0784 7.5 14.25Z" fill="#00A961" />
                </svg>
              </a>
            </div>
            <div class="dropdown__content">
              <ul class="dropdown__list">
                <li v-if="job.in_my_crm" class="dropdown__item">
                  <a @click.prevent="removeFromCRM" href="#" class="dropdown__link">Remove from CRM</a>
                </li>
                <li class="dropdown__item">
                  <a v-if="type == 'normal'" @click.prevent="toggleFavorites" href="#" class="dropdown__link">
                    <span v-if="job.favorite" class="btn__text">Remove From Favorites</span>
                    <span v-else class="btn__text">Add to favorites</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
import store from '../store'

export default {
  name: "CardJob",
  components: {
  },
  props: {
    job: Object,
    type: {
      default: "normal",
      type: String
    }
  },
  data() {
    return {
      open_dropdown: false,
    }
  },
  methods: {
    async removeFromCRM() {
      await store.dispatch('REMOVE_CONTACT_FROM_CRM', { contact_id: this.job.crm_id });
      store.dispatch('GET_DATA_JOBS');
      this.open_dropdown = false;
    },
    async addToCRM() {
      await store.dispatch('ADD_CONTACT_TO_CRM', { contact_id: this.job.company_id, type: 'company' });
      store.dispatch('GET_DATA_JOBS');
      this.open_dropdown = false;
    },
    async toggleFavorites() {
      await store.dispatch('JOB_FAVORITE_TOGGLE', {tab: 'job', item_id: this.job.id, type: 'job'});
      store.dispatch('JOB_RELOAD', {tab: 'job', id: this.job.id});
      this.open_dropdown = false;
    },

    editJob() {
      store.dispatch('JOB_EDIT', this.job.id);
    },
    deleteJob() {
      store.commit('SET_JOB_TO_DELETE', this.job.id);
      store.dispatch('SHOW_POPUP', {
          popup: 'confirmPopup',
          show: 'Are you sure you want to delete this job?',
          route: '',
          name: 'JOB_DELETE',
          data: '',
      });
    },

    sendMSG() {
        let data = {
            recipient: this.job.company_id,
            type: 'company'
        };
        store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: true, data: data});
        return;
    },


  },
  computed: {
  },
}
</script>
