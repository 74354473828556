import { router } from '@/router'
import pageService from '@/services/service.page'
import natsIO from '@/plugins/natsIO'

const page = {
  state: {
    open_sidebar: false,
    open_sidebar_media: false,

    id: '',
    title: '',
    public: false,
    auto_join: false,
    role: '',
    type: '',
    pageMembers: {},
    page_users: {},

    pages: {},
    count_pages: 0,
    projects: {},
    count_projects: 0,
    searchResult: [],
    getAllMembers: [],

    files: [],
    posts: [],
    meta: {},
    post_comments: [],
    comment_replies: [],

    current_post_id: 0,
    current_comment_id: 0,

    page_to_remove: '',
    post_to_remove: '',
    comment_to_remove: '',
    post_to_show: '',
    comment_to_show: '',

    put_files: [],

    posts_type: 'all',
    current_posts_page: 1,
    current_files_page: 1,
    searchPosts: '',
    searchFiles: '',
    comment_to_highlight: '',

    current_topic_post: {
      author: {
        avatar: {}
      },
      files: [],
    },
  },
  mutations: {
    PAGE_RESET: (state) => {
      state.id = false;
    },
    SET_SIDEBAR_PAGES_OPEN: (state, payload) => {
      state.open_sidebar = payload;
    },
    SET_SIDEBAR_MEDIA_OPEN: (state, payload) => {
      state.open_sidebar_media = payload;
    },
    SET_PAGE_DATA: (state, payload) => {
      state.id = payload.id;
      state.title = payload.title;
      state.role = payload.role;
      state.type = payload.type;
      state.pageMembers = payload.members;
    },
    SET_PAGE_FILES: (state, payload) => {
      state.files = payload;
    },
    SET_PAGES_DATA: (state, payload) => {
      state.pages = payload;
      state.count_pages = payload.length;
    },
    SET_PROJECTS_DATA: (state, payload) => {
      state.projects = payload;
      state.count_projects = payload.length;
    },
    SET_USER_SEARCH: (state, payload) => {
      state.searchResult = payload;
    },
    SET_ALL_MEMBERS: (state, payload) => {
      state.getAllMembers = payload;
    },
    RESET_USER_SEARCH:(state) => {
      state.searchResult = [];
    },

    SET_CURRENT_POST_PAGE: (state, payload) => {
      state.current_posts_page = payload;
    },
    SET_CURRENT_FILES_PAGE: (state, payload) => {
      state.current_files_page = payload;
    },
    SET_POSTS_SEARCH: (state, payload) => {
      state.searchPosts = payload;
      if (payload) state.comment_to_highlight = '';
    },
    SET_FILES_SEARCH: (state, payload) => {
      state.searchFiles = payload;
    },
    RESET_POSTS_SEARCH:(state) => {
      state.posts_type = 'all';
      state.current_posts_page = 1;
      state.searchPosts = '';
    },
    RESET_FILES_SEARCH:(state) => {
      state.posts_type = 'all';
      state.current_files_page = 1;
      state.searchFiles = '';
    },

    SET_POSTS_DATA: (state, payload) => {
      state.posts = payload;
    },
    SET_POST_DATA: (state, payload) => {
      let index = state.posts.findIndex(x => x.id == payload.id);
      if(index == -1) return;
      let show_comments = state.posts[index].show_comments;
      state.posts[index] = payload;
      state.posts[index].show_comments = show_comments;
    },
    SET_POSTS_META: (state, payload) => {
      state.meta.page = payload.current_page;
      state.meta.total_pages = payload.last_page;
    },
    SET_PAGE_ID_TO_REMOVE: (state, payload) => {
      state.page_to_remove = payload;
    },
    SET_POST_ID_TO_REMOVE: (state, payload) => {
      state.post_to_remove = payload;
    },
    SET_COMMENT_ID_TO_REMOVE: (state, payload) => {
      state.comment_to_remove = payload;
    },
    SET_POST_ID_TO_SHOW: (state, payload) => {
      state.post_to_show = payload;
    },
    SET_COMMENT_ID_TO_SHOW: (state, payload) => {
      state.comment_to_show = payload;
    },
    SET_PUT_FILES: (state, payload) => {
      state.put_files = payload;
    },
    SET_COMMENTS_DATA: (state, payload) => {
      let open_ids = [];
      if(state.post_comments[state.current_post_id]) {
        open_ids = state.post_comments[state.current_post_id].map(elm => elm.show_reply ? elm.id : '').filter(String);
      }
      if(open_ids.length > 0) {
        state.post_comments[state.current_post_id] = payload.map(elm => {
          if(open_ids.includes(elm.id)) elm.show_reply = true;
          return elm;
        });
      } else {
        state.post_comments[state.current_post_id] = payload;
      }
    },
    SET_COMMENT_DATA: (state, payload) => {
      if(!state.post_comments[payload.post_id]) return;
      let index = state.post_comments[payload.post_id].findIndex(x => x.id == payload.id);
      if(index == -1) return;
      let show_reply = state.post_comments[payload.post_id][index].show_reply;
      state.post_comments[payload.post_id][index] = payload;
      state.post_comments[payload.post_id][index].show_reply = show_reply;
    },
    SET_COMMENT_REPLIES_DATA: (state, payload) => {
      state.comment_replies[state.current_comment_id] = payload;
    },
    SET_COMMENT_REPLY_DATA: (state, payload) => {
      let index = state.comment_replies[payload.comment_id].findIndex(x => x.id == payload.id);
      state.comment_replies[payload.comment_id][index] = payload;
    },
    SET_POSTS_TYPE: (state, payload) => {
      state.posts_type = payload;
    },
    SET_COMMENT_TO_HIGHLIGHT: (state, payload) => {
      state.comment_to_highlight = payload;
      if (payload) state.searchPosts = '';
    },
    SET_TOPIC_POST: (state, payload) => {
      state.current_topic_post = payload.post;
      state.id = payload.page.id;
      state.title = payload.page.title;
      state.role = payload.page.role;
      state.type = payload.page.type;
      state.pageMembers = payload.page.members;
    },
  },

  actions: {
    async SET_PAGE_TO_DELETE({commit}, payload) {
      commit('SET_PAGE_ID_TO_REMOVE', payload);
    },
    async SET_POST_TO_DELETE({commit}, payload) {
      commit('SET_POST_ID_TO_REMOVE', payload);
    },
    SET_COMMENT_TO_DELETE({commit}, payload) {
      commit('SET_COMMENT_ID_TO_REMOVE', payload);
    },
    SET_POST_TO_SHOW({commit}, payload) {
      commit('SET_POST_ID_TO_SHOW', payload);
    },
    SET_COMMENT_TO_SHOW({commit}, payload) {
      commit('SET_COMMENT_ID_TO_SHOW', payload);
    },
    RESET_USER_SEARCH({commit}) {
      commit('RESET_USER_SEARCH');
    },

    SET_POSTS_TYPE({commit, dispatch}, payload) {
      commit('SET_POSTS_TYPE', payload);
      dispatch('GET_POSTS_DATA');
    },
    SET_POSTS_SEARCH({commit}, payload) {
      commit('SET_CURRENT_POST_PAGE', 1);
      commit('SET_POSTS_SEARCH', payload);
    },
    SET_FILES_SEARCH({commit}, payload) {
      commit('SET_CURRENT_FILES_PAGE', 1);
      commit('SET_FILES_SEARCH', payload);
    },

    async GET_PAGE_DATA({commit, dispatch}, payload) {
      const response = await pageService.getPage(payload);
      if(response.status === 200) {
        commit('SET_PAGE_DATA', response.data);
        dispatch('GET_POSTS_DATA', 'empty');
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        router.push({ name: 'home'});
      }
    },

    async GET_HOME_PAGE_DATA({commit, dispatch}) {
      const response = await pageService.getHomePage();
      if(response.status === 200) {
        commit('SET_PAGE_DATA', response.data);
        dispatch('GET_POSTS_DATA', 'empty');
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async GET_PAGES_DATA({commit, dispatch}) {
      const response = await pageService.getPages();
      if(response.status === 200) commit('SET_PAGES_DATA', response.data.data);
      else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async GET_PROJECTS_DATA({commit, dispatch}) {
      const response = await pageService.getProjects();
      if(response.status === 200) commit('SET_PROJECTS_DATA', response.data.data);
      else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async GET_PAGE_FILES({commit, dispatch}) {
      const response = await pageService.getPageFiles({
        id: this.state.page.id,
        page: this.state.page.current_files_page,
        search:  this.state.page.searchFiles,
        posts_type: this.state.page.posts_type
      });
      if(response.status === 200) commit('SET_PAGE_FILES', response.data.data);
      else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async STORE_WORK_PAGE({dispatch}, payload) {
      const response = await pageService.storePage(payload);
      if(response.status === 200) {
        const res = await pageService.getPages();
        if(res.status === 200) {
          dispatch('GET_PAGES_DATA');
          dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your data has been successfully saved.'});
          dispatch('SHOW_POPUP', { popup: 'pageAddPopup', show: false});
          if (response.data.notifies) {
            response.data.notifies.forEach(notify => {
              natsIO.publishNotify(notify.to_user, notify);
            });
          }
          return true;
        }
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async ADD_USERS_TO_PAGE({dispatch}, payload) {
      const response = await pageService.addUsers(payload);
      if(response.status === 200) {
        let name = Object.keys(payload.users).length > 1 ? 'Members' : 'Member';
        dispatch('GET_PAGE_DATA', payload.id);
        dispatch('SHOW_POPUP', { popup: 'successPopup', show: name+' has been successfully invited.'});
        dispatch('SHOW_POPUP', { popup: 'pageInviteMembersPopup', show: false});
        if (response.data.notifies) {
          response.data.notifies.forEach(notify => {
            natsIO.publishNotify(notify.to_user, notify);
          });
        }
        return true;
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async REMOVE_USERS_FROM_PAGE({commit, dispatch}, payload) {
      const response = await pageService.removeUsers(payload);
      if(response.status === 200) {
        commit('SET_PAGE_DATA', response.data);
        dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your data has been successfully saved.'});
        dispatch('SHOW_POPUP', { popup: 'pageRemoveMembersPopup', show: false});
        natsIO.publishEvent({action: 'NATS_REMOVE_USERS_FROM_PAGE', from: this.state.user.user_profile.id, info: {page: payload.id, users: payload.users}});
        return true;
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async SEARCH_PAGE_USER({commit, dispatch}, payload) {
      const response = await pageService.searchUsersForInvite(payload);
      if(response.status === 200) {
        if(response.data.length <= 0) {
          commit('SET_USER_SEARCH', {});
        } else {
          commit('SET_USER_SEARCH', response.data.data);
        }
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async PAGE_GET_ALL_MEMBERS({commit, dispatch}) {
      const response = await pageService.getAllMembers();
      if(response.status === 200) {
        if(response.data.length <= 0) {
          commit('SET_ALL_MEMBERS', {});
        } else {
          commit('SET_ALL_MEMBERS', response.data.data);
        }
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async UPDATE_PAGE({dispatch}, payload) {
      const response = await pageService.updatePage(payload);
      if(response.status === 200) {
        await dispatch('GET_PAGES_DATA');
        // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your page has been successfully updated.'});
        dispatch('SHOW_POPUP', { popup: 'pageAddPopup', show: false});
        natsIO.publishEvent({action: 'NATS_GET_PAGES_DATA', from: this.state.user.user_profile.id, info: {page: payload.id}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async REMOVE_PAGE({commit, dispatch}) {
      let redirect = (this.state.forum.current_route == 'page' && this.state.forum.current_route_param == this.state.page.page_to_remove) ? true : false;
      const response = await pageService.removePage(this.state.page.page_to_remove);
      if(response.status === 200) {
        natsIO.publishEvent({action: 'NATS_REMOVE_PAGE', from: this.state.user.user_profile.id, info: {page: this.state.page.page_to_remove}});
        commit('SET_PAGE_ID_TO_REMOVE', '');
        dispatch('GET_PAGES_DATA');
        dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your page has been successfully deleted.'});
        if (redirect) router.push({ name: 'home'});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    /* Posts */
    async GET_POSTS_DATA({commit, dispatch}, payload) {
      if (payload == 'empty') {
        commit('RESET_POSTS_SEARCH');
        commit('RESET_FILES_SEARCH');
      }
      const response = await pageService.getPosts({
        id: this.state.page.id,
        page: this.state.page.current_posts_page,
        search:  this.state.page.searchPosts,
        posts_type: this.state.page.posts_type
      });
      if(response.status === 200) {
        if (payload == 'loadmore') {
          commit('SET_POSTS_DATA', this.state.page.posts.concat(response.data.data));
        } else {
          commit('SET_POSTS_DATA', response.data.data);
        }
        commit('SET_POSTS_META', response.data.meta);
        dispatch('GET_PAGE_FILES');
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    POSTS_LOAD_MORE({commit,dispatch}) {
      commit('SET_CURRENT_POST_PAGE', this.state.page.meta.page + 1);
      dispatch('GET_POSTS_DATA', 'loadmore');
    },

    async STORE_POST({dispatch}, payload) {
      const response = await pageService.storePost(payload);
      if(response.status === 200) {
        await dispatch('GET_POSTS_DATA', 'empty');
        // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your post has been successfully saved.'});
        dispatch('SHOW_POPUP', { popup: 'postAddPopup', show: false});
        if (response.data.notifies) {
          response.data.notifies.forEach(notify => {
            natsIO.publishNotify(notify.to_user, notify);
          });
        }
        natsIO.publishEvent({action: 'NATS_GET_POSTS_DATA', from: this.state.user.user_profile.id, info: {page: this.state.page.id}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async UPDATE_POST({dispatch}, payload) {
      const response = await pageService.updatePost(payload);
      if(response.status === 200) {
        await dispatch('GET_POSTS_DATA', 'empty');
        // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your post has been successfully updated.'});
        dispatch('SHOW_POPUP', { popup: 'postAddPopup', show: false});
        natsIO.publishEvent({action: 'NATS_GET_POSTS_DATA', from: this.state.user.user_profile.id, info: {page: this.state.page.id}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async REMOVE_POST({commit, dispatch}) {
      const response = await pageService.removePost(this.state.page.post_to_remove);
      if(response.status === 200) {
        commit('SET_POST_ID_TO_REMOVE', '');
        dispatch('GET_POSTS_DATA', 'empty');
        // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your post has been successfully deleted.'});
        natsIO.publishEvent({action: 'NATS_GET_POSTS_DATA', from: this.state.user.user_profile.id, info: {page: this.state.page.id}});
        natsIO.publishEvent({action: 'NATS_GET_NOTIFICATIONS_COUNTS', from: this.state.user.user_profile.id, info: 'WorkPageUpdate'});
        dispatch('NATS_GET_NOTIFICATIONS_COUNTS', '');
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async PUT_PAGE_FILES({commit, dispatch}, payload) {
      const response = await pageService.putFiles(payload);
      if(response.status === 200) {
        commit('SET_PUT_FILES', response.data);
        dispatch('GET_POSTS_DATA', 'empty');
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async REMOVE_POST_FILE({dispatch}, payload) {
      const response = await pageService.removePostFile(payload);
      if(response.status === 200) {
        dispatch('GET_POSTS_DATA', 'empty');
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async LIKE_POST({commit, dispatch}, payload) {
      const response = await pageService.likePost(payload);
      if(response.status === 200) {
        commit('SET_POST_DATA', response.data.post);
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
        natsIO.publishEvent({action: 'NATS_GET_POSTS_DATA', from: this.state.user.user_profile.id, info: {page: this.state.page.id}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async SAVED_POST({commit, dispatch}, payload) {
      const response = await pageService.savedPost(payload);
      if(response.status === 200) {
        commit('SET_POST_DATA', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    /* Comments */

    async GET_POST_COMMENTS({commit, dispatch}, payload) {
      const response = await pageService.getComments(payload);
      if(response.status === 200) {
        this.state.page.current_post_id = payload;
        commit('SET_COMMENTS_DATA', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async STORE_COMMENT({dispatch}, payload) {
      const response = await pageService.storeComment(payload);
      if(response.status === 200) {
        if(this.state.page.post_to_show) await dispatch('GET_POST_COMMENTS', this.state.page.post_to_show);
        if(this.state.page.comment_to_show) { // opened comments ID
          dispatch('GET_COMMENT_REPLIES', this.state.page.comment_to_show);
          natsIO.publishEvent({action: 'NATS_GET_COMMENT_REPLIES', from: this.state.user.user_profile.id, info: {page: this.state.page.id, comment: this.state.page.comment_to_show}});
        }
        dispatch('GET_POSTS_DATA','');
        // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your comment has been successfully saved.'});
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
        natsIO.publishEvent({action: 'NATS_UPDATE_COMMENT', from: this.state.user.user_profile.id, info: {page: this.state.page.id, post: payload.post}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async UPDATE_COMMENT({dispatch}, payload) {
      const response = await pageService.updateComment(payload);
      if(response.status === 200) {
        if(this.state.page.post_to_show) await dispatch('GET_POST_COMMENTS', this.state.page.post_to_show);
        if(this.state.page.comment_to_show) { // opened comments ID
          dispatch('GET_COMMENT_REPLIES', this.state.page.comment_to_show);
          natsIO.publishEvent({action: 'NATS_GET_COMMENT_REPLIES', from: this.state.user.user_profile.id, info: {page: this.state.page.id, comment: this.state.page.comment_to_show}});
        }
        // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your comment has been successfully saved.'});
        dispatch('SHOW_POPUP', { popup: 'postCommentEditPopup', show: false});
        natsIO.publishEvent({action: 'NATS_UPDATE_COMMENT', from: this.state.user.user_profile.id, info: {page: this.state.page.id, post: this.state.page.post_to_show}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async REMOVE_COMMENT({commit, dispatch}) {
      const response = await pageService.removeComment(this.state.page.comment_to_remove);
      if(response.status === 200) {
        if(this.state.page.post_to_show) {
          await dispatch('GET_POST_COMMENTS', this.state.page.post_to_show);
          natsIO.publishEvent({action: 'NATS_UPDATE_COMMENT', from: this.state.user.user_profile.id, info: {page: this.state.page.id, post: this.state.page.post_to_show}});
        }
        if(this.state.page.comment_to_show) { // opened comments ID
          dispatch('GET_COMMENT_REPLIES', this.state.page.comment_to_show);
          natsIO.publishEvent({action: 'NATS_GET_COMMENT_REPLIES', from: this.state.user.user_profile.id, info: {page: this.state.page.id, comment: this.state.page.comment_to_show}});
        }
        dispatch('GET_POSTS_DATA','');
        dispatch('NATS_GET_NOTIFICATIONS_COUNTS', '');
        commit('SET_COMMENT_ID_TO_REMOVE', false);
        // dispatch('SHOW_POPUP', { popup: 'successPopup', show: 'Your comment has been successfully deleted.'});
        natsIO.publishEvent({action: 'NATS_GET_NOTIFICATIONS_COUNTS', from: this.state.user.user_profile.id, info: 'WorkPageUpdate'});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async REMOVE_COMMENT_FILE({dispatch}, payload) {
      const response = await pageService.removeCommentFile(payload);
      if(response.status !== 200) dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async LIKE_COMMENT({commit, dispatch}, payload) {
      const response = await pageService.likeComment(payload);
      if(response.status === 200) {
        commit('SET_COMMENT_DATA', response.data.comment);
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
        natsIO.publishEvent({action: 'NATS_UPDATE_COMMENT', from: this.state.user.user_profile.id, info: {page: this.state.page.id, post: response.data.comment.post_id}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async GET_POST_COMMENTS_BY_SEARCH({commit, dispatch}, payload) {
      const response = await pageService.getCommentsWithSearch(payload);
      if(response.status === 200) {
        this.state.page.current_post_id = payload.id;
        // open Replies if has search result
        for (const [index, item] of response.data.entries()) {
          if (item.search_exist) {
            const responseReplies = await pageService.getRepliesWithSearch({
              id: item.id,
              search: payload.search,
              comment_id: this.state.page.comment_to_highlight
            });
            this.state.page.current_comment_id = item.id;
            commit('SET_COMMENT_REPLIES_DATA', responseReplies.data);
            response.data[index].show_reply = true;
          }
        }
        commit('SET_COMMENTS_DATA', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    /* Replies */

    async GET_COMMENT_REPLIES({commit, dispatch}, payload) {
      const response = await pageService.getReplies(payload);
      if(response.status === 200) {
        this.state.page.current_comment_id = payload;
        commit('SET_COMMENT_REPLIES_DATA', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async LIKE_COMMENT_REPLY({commit, dispatch}, payload) {
      const response = await pageService.likeComment(payload);
      if(response.status === 200) {
        commit('SET_COMMENT_REPLY_DATA', response.data.comment);
        if (response.data.notify) natsIO.publishNotify(response.data.notify.to_user, response.data.notify);
        natsIO.publishEvent({action: 'NATS_GET_COMMENT_REPLIES', from: this.state.user.user_profile.id, info: {page: this.state.page.id, comment: response.data.comment.comment_id}});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },


    async GET_TOPIC_POST_DATA({commit, dispatch}, payload) {
      commit('RESET_POSTS_SEARCH');
      const response = await pageService.getTopicPost(payload);
      if(response.status === 200) {
        commit('SET_TOPIC_POST', response.data);
        dispatch('GET_FORUM_TOPIC_NOTIFICATIONS', response.data.page.id);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    /* Nats */
    NATS_GET_POSTS_DATA({dispatch},payload) {
      if(payload.page === this.state.page.id) dispatch('GET_POSTS_DATA','');
    },
    NATS_GET_PAGES_DATA({dispatch},payload) {
      if(payload.page === this.state.page.id) dispatch('GET_PAGE_DATA',payload.page);
      dispatch('GET_PAGES_DATA');
      dispatch('GET_PROJECTS_DATA');
    },
    NATS_REMOVE_PAGE({dispatch},payload) {
      if(payload.page === this.state.page.id) router.push({ name: 'home'});
      dispatch('NATS_GET_NOTIFICATIONS_COUNTS', '');
      dispatch('GET_PAGES_DATA');
      dispatch('GET_PROJECTS_DATA');
    },
    NATS_REMOVE_USERS_FROM_PAGE({dispatch},payload) {
      if(payload.page === this.state.page.id && payload.users.includes(this.state.user.user_profile.id)) router.push({ name: 'home'});
      dispatch('NATS_GET_NOTIFICATIONS_COUNTS', '');
      dispatch('GET_PAGES_DATA');
      dispatch('GET_PROJECTS_DATA');
    },
    NATS_UPDATE_COMMENT({dispatch},payload) {
      if(payload.page === this.state.page.id) {
        dispatch('GET_POST_COMMENTS', payload.post);
        dispatch('GET_POSTS_DATA','');
      }
    },
    NATS_GET_COMMENT_REPLIES({dispatch},payload) {
      if(payload.page === this.state.page.id) dispatch('GET_COMMENT_REPLIES', payload.comment);
    },

  },
  getters: {

  }
}
export default page;
