import instance from './index';
import suwAPI from '../config/config'

const userProfileService = {

  postUserProfile(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserProfile, data);
  },

  postUserSettings(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserSettings, data);
  },

  postUserProfilePutAvatar(data) {
    let formData = new FormData();
    formData.append('avatar', data);
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiUserProfilePutAvatar, formData);
  },

  postUserProfilePutFile(data) {
    let formData = new FormData();
    formData.append('file', data);
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiUserProfilePutFile, formData);
  },

  uploadProfile(data) {
    let formData = new FormData();
    formData.append('file', data);
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiUploadPDF, formData);
  },

  updateProfileFromPDF(id) {
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiUpdateFromPDF+'/'+id);
  },

  // Experience
  postExperience(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserProfileExperience, data);
  },

  putExperience(data) {
    let Experienceid = data.id;
    delete data.id;
    return instance({ requiresAuth: true }).put(suwAPI.apiUserProfileExperience+'/'+Experienceid, data);
  },

  getExperiences() {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfileExperience);
  },

  removeExperience(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiUserProfileExperience+'/'+id);
  },

  // Education
  postEducation(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserProfileEducation, data);
  },

  putEducation(data) {
    let Educationid = data.id;
    delete data.id;
    console.log(data);
    return instance({ requiresAuth: true }).put(suwAPI.apiUserProfileEducation+'/'+Educationid, data);
  },

  getEducations() {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfileEducation);
  },

  removeEducation(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiUserProfileEducation+'/'+id);
  },

  // Media
  postMedia(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserProfileMedia, data);
  },

  putMedia(data) {
    let Mediaid = data.id;
    delete data.id;
    console.log(data);
    return instance({ requiresAuth: true }).put(suwAPI.apiUserProfileMedia+'/'+Mediaid, data);
  },

  getMedia() {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfileMedia);
  },

  removeMedia(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiUserProfileMedia+'/'+id);
  },

  viewUserProfile(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/'+id+'/view');
  },

  viewUserExperience(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/'+id+'/view/experience');
  },

  viewUserEducation(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/'+id+'/view/education');
  },

  viewUserMedia(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/'+id+'/view/media');
  },

  search(search) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/search/'+search);
  },

  searchInvite(search) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/search_platform_invite/'+search);
  },

  searchPlatform(search) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/search_platform/'+search);
  },

  searchForBlock(search) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/search_for_block/'+search);
  },

  toggleBlockUserStatus(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserProfile+'/toggle_block_status/'+id);
  },

  getBlockedUsers() {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/get_blocked_users');
  },

  setCompanyRole(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserProfile+'/set_company_role/'+data.id, {role: data.role});
  },

}

export default userProfileService;