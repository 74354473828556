<template>
<div :class="{ 'popup-visible': this.popups.fullReviewAddPopup }">
  <div class="popup-wrap" @click.self="closePopup">
    <div :class="{'active':this.popups.fullReviewAddPopup}" class="popup favorites-full-popup popup_tertiary">
      <div class="popup__form">

        <div class="popup__header">
          <div class="popup__title">
            <span class="title-text">Leave a review</span>
          </div>
          <div class="popup-action ml-auto">
            <div class="popup-action__item popup-action__item_remove"  @click="closePopup">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333" />
              </svg>
            </div>
          </div>
        </div>

        <div class="popup__content">
          <div class="row">
            <div class="col col_12">
              <div class="form-field">
                <div class="form-field__header">
                  <label class="form-field__title">Stars </label>
                  <RatingStars v-model:stars="stars"></RatingStars>
                </div>
                <p class="form-errors" v-if="errors.rating">{{errors.rating}}</p>
              </div>
            </div>
            <div class="col col_12">
              <div class="form-field">
                <div class="form-field__header">
                  <label class="form-field__title">Review</label>
                </div>
                <div class="form-field__content border-bug" :class="{ 'input-errors' : this.errors.comment }">
                  <textarea class="form-field__input" v-model="comment" @input="validate" ref="inputField" ></textarea>
                </div>
                <p class="form-errors" v-show="errors.comment">{{errors.comment}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="popup__footer">
          <div class="btn-group btn-group_secondary content-between">
            <a @click.prevent="closePopup" href="#" class="btn btn_secondary popup-init">
              <span class="btn__text">Cancel</span>
            </a>
            <a @click.prevent="sendReview" href="#" class="btn btn_primary">
              <span v-if="popups.fullReviewData.review_id" class="btn__text">Update review</span>
              <span v-else class="btn__text">Send review</span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
import store from '@/store'
import RatingStars from '@/components/profile/RatingStars'
import func from '@/plugins/functions'

export default {
  name: 'FullReviewAdd',
  components: {
    RatingStars
  },
  props: {},
  data() {
    return {
      stars: 0,
      comment: '',
      settings: {
        comment: {
          min: 30,
          max: 3000
        },
      },
      errors: {
        comment: '',
        rating: '',
      },
    }
  },
  methods: {
    closePopup() {
      store.dispatch('SHOW_POPUP', { popup: 'fullReviewAddPopup', show: false });
      this.errors.comment = '';
      this.errors.rating = '';
      this.stars = 0;
      this.comment = '';
    },

    validate() {
      let status = this.validate_stars() && this.validate_comment();
      return status;
    },
    validate_stars() {
      if (this.stars == 0) {
        this.errors.rating = 'Choose the number of stars';
        return false;
      } else {
        this.errors.rating = '';
      }
      return true;
    },
    validate_comment() {
      if (this.comment.length > this.settings.comment.max) {
        this.errors.comment = 'The Comment must not be greater than 3000 characters.';
        return false;
      } else {
        this.errors.comment = '';
      }

      if (this.comment.length < this.settings.comment.min) {
        this.errors.comment = 'The Comment must not be less than 30 characters.';
        return false;
      } else {
        this.errors.comment = '';
      }
      return true;
    },
    async sendReview(){
      if( this.validate()) {
        let data = {
          id: this.popups.fullReviewData.id,
          review_id: this.popups.fullReviewData.review_id ?? false,
          stars: this.stars,
          comment: this.comment,
          type: this.popups.fullReviewData.type
        };
        let status = await store.dispatch('SET_USER_REVIEW', data);
        if (status) {
          if(data.review_id) store.dispatch('EXPERT_REVIEWS', {id: data.id, type: data.type});
          if(data.type === 'user') {
            store.dispatch('EMPLOYEE_USER_RELOAD', {tab: 'expert', id: data.id});
            store.dispatch('SET_VIEW_USER_PROFILE', data.id);
          }
          if(data.type === 'company') {
            store.dispatch('EMPLOYEE_COMPANY_RELOAD', {tab: 'expert', id: data.id});
            store.dispatch('GET_COMPANY_INFO', data.id);
          }
          store.dispatch('SHOW_POPUP', { popup: 'fullReviewAddPopup', show: false });
          this.stars = 0;
          this.comment = '';
          this.errors.comment = '';
          this.errors.rating = '';
          if(this.$route.name === 'crmUser') store.dispatch('GET_CRM_CARD',this.$route.params.id);
        }
      }
    },
    inputEvent(event){
      func.inputEvent(event, this.sendReview, (()=>this.comment += "\n"))
    },
  },
  computed: {
    popups: () => store.state.popups,
    crm: () => store.state.crm,
  },
  watch: {
    stars() {
      this.validate_stars()
    },
    comment() {
      this.validate_comment()
    },
    'popups.fullReviewAddPopup'(newVal) {
      if (newVal) {
        this.$refs.inputField.addEventListener('keydown', this.inputEvent);
        this.stars = this.popups.fullReviewData.stars ?? 0;
        this.comment = this.popups.fullReviewData.comment ?? '';
        setTimeout(() => this.$refs.inputField.focus(), "500");
      } else {
        this.$refs.inputField.removeEventListener('keydown', this.inputEvent);
      }
      this.errors.comment = '';
      this.errors.rating = '';
    },
  }
}
</script>
