const func = {

  debounce (func, timeout = 500){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  },

  inputEvent(event, sendMsg, addBreak){
    if(event.which === 13 && !event.shiftKey && !event.ctrlKey && !event.metaKey){
      event.preventDefault();
      sendMsg.apply();
    }
    if(event.which === 10 || (event.metaKey && event.which === 13) || (event.ctrlKey && event.which === 13)){
      addBreak.apply()
    }
  },

  highLight(text, search_message = false, tag = 'b') {
    if (!text) return;

    text = text.replace(/</g,'&lt;')
    text = text.replace(/>/g,'&gt;')
    text = text.replace(/\n/g, '<br />')

    if(search_message) {
      let keywords = search_message.replace(/\\|\[|\(|\)/g, match => `\\${match}`).split(/\s/);
      let check = new RegExp(`(${keywords.join('|')})`, 'gi');
      text = text.replace(check, match => `<${tag}>${match}</${tag}>`);
    }

    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  },

}

export default func;