<template>
    <main-layout>
        <div class="container">
            <h2>Page "{{ msg }}" under development.</h2>
        </div>
    </main-layout>
</template>

<script>
    import MainLayout from "../layouts/Main.vue"

    export default {
        name: "Payments",
        components: {
            MainLayout,
            //
        },
        props: {
            msg: {type: String, default: "Payments",},
        }
    }
</script>
