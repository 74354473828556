<template>
    <main-layout>
        <div class="main">
            <div class="main__header header">
                <div class="header__wysiwyg wysiwyg">
                    <h2>CRM
                        <ToolTip 
                            :type="'top'"
                            :msg="'This space is used as a Community Relationship Management system. <br><br>It will help you organize and manage all of your relationships across the platform.'"
                        />
                    </h2>
                </div>
            </div>

            <!-- main-content -->
            <div class="main__content">
                <div class="entrepreneur">

                    <Banner></Banner>

                    <Calendar></Calendar>

                    <Contacts></Contacts>

                    <Messages></Messages>

                    <ManagersBox
                        v-if="['administrator', 'community_manager'].includes(userdata.user_profile.in_company_role)"
                    ></ManagersBox>

                </div>
            </div>
        </div>

        <FullMessagesViewPopup></FullMessagesViewPopup>
        <ViewfilePopup></ViewfilePopup>
    </main-layout>
</template>

<script>
    import MainLayout from "../layouts/Main.vue"
    import store from '../store'
    import Banner from '@/components/general/Banner'
    import Calendar from '@/components/general/Calendar'
    import Contacts from '@/components/crm/Contacts'
    import Messages from '@/components/crm/Messages'
    import ManagersBox from '@/components/notification/ManagersBox'
    import ViewfilePopup from '@/components/popups/Viewfile'
    import FullMessagesViewPopup from '@/components/popups/FullMessagesView'
    import ToolTip from '@/components/ToolTip'

    export default {
        name: "CRM",
        components: {
            MainLayout,
            Banner,
            Calendar,
            Contacts,
            Messages,
            ManagersBox,
            ViewfilePopup,
            FullMessagesViewPopup,
            ToolTip,
        },
        mounted() {
            this.getLoaded();
        },
        updated(){
        },
        methods: {
            getLoaded: function() {
                jcf.setOptions('Select', {
                    wrapNative: false,
                    useCustomScroll: false,
                    fakeDropInBody: false,
                    multipleCompactStyle: true
                });
                jcf.replaceAll();
            },
        },
        computed: {
            company: () => store.state.company,
            userdata: () => store.state.user,
            popups: () => store.state.popups,
            crm: () => store.state.crm,
        },
    }
</script>
