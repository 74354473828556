import instance from './index';
import suwAPI from '../config/config'

const userService = {

  check() {
    return instance({ requiresAuth: true }).post(suwAPI.apiUser+'/check-auth', {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  },

  user() {
    return instance({ requiresAuth: true }).get(suwAPI.apiUser);
  },

  completeaccount(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiCompleteAccount, data);
  },

  confirm(data) {
    return instance({ requiresAuth: false }).get(suwAPI.apiUserConfirm+data);
  },

  createaccount(data) {
    data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return instance({ requiresAuth: false }).post(suwAPI.apiSignUp, data);
  },

  forgotpassword(data) {
    return instance({ requiresAuth: false }).post(suwAPI.apiForgotPassword, data);
  },

  login(data) {
    return instance({ requiresAuth: false, email: data.email, password: data.password }).post(suwAPI.apiLoginUrl, data);
  },

  logout(data) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiLogout, data);
  },

  refresh() {
    return instance({ requiresAuth: true }).delete(suwAPI.apiRefresh);
  },

  restorepassword(data) {
    return instance({ requiresAuth: false }).post(suwAPI.apiRestorePassword, data);
  },

  getImage(imgUrl) {
    return instance({ requiresAuth: true }).get(imgUrl);
  },

  acceptinvitation(data) {
    return instance({ requiresAuth: false }).post(suwAPI.apiAcceptInvitation, data);
  },

  invitationsignup(data) {
    return instance({ requiresAuth: false }).post(suwAPI.apiInvitationSignup, data);
  },

  invitationDone(data) {
    return instance({ requiresAuth: false }).post(suwAPI.apiAcceptInvitation+'/done/'+data.id, data);
  },

  addreview(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserReview, data);
  },

  updateReview(data) {
    return instance({ requiresAuth: true }).patch(suwAPI.apiUserReview+'/'+data.review_id, data);
  },

  updateGoogleCalendar(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiUserUpdateCalendar, data);
  },

  cancelRequestJoining(data) {
    return instance({ requiresAuth: false }).post(suwAPI.apiUserCancelRequestJoining, data);
  },

  saveFeedback(data) {
    return instance({ requiresAuth: true }).post(`${suwAPI.apiBase}save-feedback`, data);
  },
}

export default userService;