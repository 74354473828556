<template>
  <main-layout>
    <div class="main">
      <!-- main-header -->
      <div v-if="['page'].includes($route.name)" class="main__header header">
        <div class="header__wysiwyg wysiwyg">
          <h2>{{ this.page.title }}</h2>
        </div>
        <div class="header__actions">
          <div v-show="this.page.role === 'administrator'" class="btn-group btn-group_secondary">
            <a @click.prevent="showRemovePopup" href="#" class="btn btn_tertiary">
              <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="#00A961" />
              </svg>
              <span class="btn__text">Remove from Page</span>
            </a>
            <a @click.prevent="showInvitePopup" href="#" class="btn btn_tertiary">
              <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="#00A961" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="#00A961" />
              </svg>
              <span class="btn__text">Invite to Page</span>
            </a>
          </div>
        </div>
      </div>
      <!-- main-content -->
      <div class="main__content">
        <div class="entrepreneur">
          <Banner></Banner>
          <Calendar></Calendar>
        </div>
      </div>
    </div>

    <Posts></Posts>

    <PageInviteMembersPopup></PageInviteMembersPopup>
    <PageRemoveMembersPopup></PageRemoveMembersPopup>


  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue"
import store from '../store'
import Banner from '@/components/general/Banner'
import Calendar from '@/components/general/Calendar'
import Posts from '@/components/page/Posts'
import PageInviteMembersPopup from '@/components/popups/PageInviteMembers'
import PageRemoveMembersPopup from '@/components/popups/PageRemoveMembers'


export default {
  name: "Page",
  components: {
    MainLayout,
    Banner,
    Calendar,
    Posts,
    PageInviteMembersPopup,
    PageRemoveMembersPopup,

  },
  props: {
    msg: { type: String, default: "Page", },
  },
  data() {
    return {
      openSelect: false,
    }
  },
  methods: {
    getPage(id) {
      store.dispatch('GET_PAGE_DATA', id);
    },
    showInvitePopup: function () {
      if (this.page.role != 'administrator') return false;
      store.dispatch('SHOW_POPUP', { popup: 'pageInviteMembersPopup', show: true });
    },
    showRemovePopup: function () {
      if (this.page.role != 'administrator') return false;
      store.dispatch('SHOW_POPUP', { popup: 'pageRemoveMembersPopup', show: true });
    },
  },
  created() {
    this.getPage(this.$route.params.id);
  },
  computed: {
    page: () => store.state.page,
  },
  beforeRouteUpdate(to, from) {
    if (from.params.id != to.params.id) {
      this.getPage(to.params.id);
    }
  },
  beforeRouteLeave() {
    store.commit('PAGE_RESET')
  },
}
</script>
