import instance from './index';
import suwAPI from '../config/config'

const jobService = {

  getJobs(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiJob+'?page='+(data?.page ?? 1)
      +'&search='+data?.search
      +'&type_of_employment='+data.type_of_employment
      +'&field_of_expertise='+data.field_of_expertise
      +'&hiring='+data?.hiring);
  },

  getMyJobs(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiJob+'/my?page='+(data?.page ?? 1));
  },

  getJob(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiJob+'/'+id);
  },

  createJob(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiJob, data);
  },

  deleteJob(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiJob+'/'+id);
  },


  toggleFavorite(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiEmployee+'/favorite', data);
  },

  getFavorites() {
    return instance({ requiresAuth: true }).get(suwAPI.apiEmployee+'/favorites/job');
  },


}

export default jobService;