<template>
<div :class="{ 'popup-visible' : this.popups.settingsPopup }">
    <div class="popup-wrap" @click.self="closePopup">

        <!-- // settings -->
        <div class="popup settings-popup" :class="{ 'active' : this.popups.settingsPopup }">
            <form action="#" class="popup__form">
                <div class="popup__header">
                    <div class="popup__title">
                        <span class="title-text">Personal settings</span>
                    </div>
                    <div class="popup-action ml-auto">
                        <div class="popup-action__item popup-action__item_remove" @click.prevent="closePopup">
                            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"/>
                            </svg>
                        </div>
                    </div>
                </div><!-- //popup__header -->
                <div class="popup__content">
                    <div class="popup__section">
                        <div class="wysiwyg">
                            <h3>Privacy</h3>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">
                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="this.userdata.user_profile.is_messages_private"
                                            true-value="0"
                                            false-value="1"
                                            type="checkbox" required>
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/>
                                                <g>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span class="custom-check__text">Recieve messages from anyone</span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div class="form-field">
                            <div class="form-field__content">

                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="this.userdata.user_profile.is_messages_private"
                                            true-value="1"
                                            false-value="0"
                                            type="checkbox" required>
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/>
                                                <g>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span class="custom-check__text">Recieve messages only from CRM contacts, Work /Poject page members and Team</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">
                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="this.userdata.user_profile.is_profile_private"
                                            true-value="0"
                                            false-value="1"
                                            type="checkbox" required>
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/>
                                                <g>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span class="custom-check__text">Anyone on the platform can see your profile</span>
                                    </label>
                                </div>


                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">
                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="this.userdata.user_profile.is_profile_private"
                                            true-value="1"
                                            false-value="0"
                                            type="checkbox" required>
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g >
                                                <rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/>
                                                <g>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span class="custom-check__text">Only your CRM Contacts, Work /Project page members and Team can see your profile</span>
                                    </label>
                                </div>


                            </div>
                        </div>

                        <div class="wysiwyg">
                            <h3>Link Google account</h3>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">
                                <input type="text" class="form-field__input" :value="this.userdata.user_profile.google_email" disabled>
                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">
                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="this.userdata.user_profile.google_calendar"
                                            @change="calendarInit"
                                            type="checkbox">
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                        </svg>
                                        <span class="custom-check__text">Link CRM Calendar to Google Calendar?</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-field">
                            <div class="form-field__content">
                                <div class="custom-check">
                                    <label class="custom-check__label">
                                        <input class="custom-check__input"
                                            v-model="this.userdata.user_profile.email_notification"
                                            @change="notificationSelect"
                                            type="checkbox">
                                        <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                        </svg>
                                        <span class="custom-check__text">Email Notifications?</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div v-if="this.userdata.user_profile.email_notification" class="" style="padding-left: 10px;">
                            <div class="form-field">
                                <div class="form-field__content">
                                    <input type="text" class="form-field__input" v-model="this.userdata.user_profile.notify_email">
                                </div>
                            </div>
                            <div class="form-field">
                                <div class="form-field__content">
                                    <div class="custom-check">
                                        <label class="custom-check__label">
                                            <input class="custom-check__input"
                                                @change="notificationSelect('all')"
                                                v-model="this.userdata.user_profile.notify_all" type="checkbox">
                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                            </svg>
                                            <span class="custom-check__text">Email All Notifications</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-field">
                                <div class="form-field__content">
                                    <div class="custom-check">
                                        <label class="custom-check__label">
                                            <input class="custom-check__input"
                                                @change="notificationSelect"
                                                v-model="this.userdata.user_profile.notify_team" type="checkbox">
                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                            </svg>
                                            <span class="custom-check__text">Email Team Notifications</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-field">
                                <div class="form-field__content">
                                    <div class="custom-check">
                                        <label class="custom-check__label">
                                            <input class="custom-check__input"
                                                @change="notificationSelect"
                                                v-model="this.userdata.user_profile.notify_personal" type="checkbox">
                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                            </svg>
                                            <span class="custom-check__text">Email Platform Notifications</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-field"
                                v-show="userdata.user_profile.in_company_role == 'administrator' || userdata.user_profile.in_company_role == 'community_manager'">
                                <div class="form-field__content">
                                    <div class="custom-check">
                                        <label class="custom-check__label">
                                            <input class="custom-check__input"
                                                @change="notificationSelect"
                                                v-model="this.userdata.user_profile.notify_manager" type="checkbox">
                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                                            </svg>
                                            <span class="custom-check__text">Email Comms Manager's Notifications</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="wysiwyg">
                            <h3>Do you want to un/block a user from contacting you?</h3>
                        </div>

                        <div class="form-field">
                            <div class="form-field__header">
                                <label class="form-field__title">Users</label>
                            </div>
                            <div class="form-field__content">

                                <div class="select-smart select">
                                    <div class="select__header select-header">
                                        <div class="select-header__content">
                                            <div class="select-header__list">
                                                <div v-for="(blocked) in this.userdata.blockedUsers" :key='blocked.id' class="select-header__item select-item">
                                                    <div class="select-item__title">
                                                        <span class="title-text">{{blocked.firstname}} {{blocked.lastname}}</span>
                                                    </div>
                                                    <div @click="unBlock(blocked.id)" class="select-item__action">
                                                        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0303 3.96967C14.3232 4.26256 14.3232 4.73744 14.0303 5.03033L5.03033 14.0303C4.73744 14.3232 4.26256 14.3232 3.96967 14.0303C3.67678 13.7374 3.67678 13.2626 3.96967 12.9697L12.9697 3.96967C13.2626 3.67678 13.7374 3.67678 14.0303 3.96967Z" fill="#00A961"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 3.96967C4.26256 3.67678 4.73744 3.67678 5.03033 3.96967L14.0303 12.9697C14.3232 13.2626 14.3232 13.7374 14.0303 14.0303C13.7374 14.3232 13.2626 14.3232 12.9697 14.0303L3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967Z" fill="#00A961"/>
                                                        </svg>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div @click="show_search = !show_search" class="select-header__init">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 11.7803C4.26256 12.0732 4.73744 12.0732 5.03033 11.7803L9 7.81066L12.9697 11.7803C13.2626 12.0732 13.7374 12.0732 14.0303 11.7803C14.3232 11.4874 14.3232 11.0126 14.0303 10.7197L9.53033 6.21967C9.23744 5.92678 8.76256 5.92678 8.46967 6.21967L3.96967 10.7197C3.67678 11.0126 3.67678 11.4874 3.96967 11.7803Z" fill="#5D6167"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-show="show_search" class="select__dropdown">
                                        <div class="select__search">
                                            <div class="form-field  form-field_tertiary">
                                                <div class="form-field__content">
                                                    <input type="text" placeholder="Search ...."
                                                        v-model="searchuser"
                                                        class="form-field__input" required>
                                                    <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="select__list">

                                            <div v-for="(contact, contact_id) in this.userdata.blockSearchResult" :key='contact.id' class="select__item select-item">
                                                <div class="select-item__avatar">
                                                    <img v-if="contact.avatar" :src="contact.avatar" alt="" />
                                                    <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                                </div>
                                                <div class="select-item__wysiwyg wysiwyg">
                                                    <span class="title-text">{{ contact.firstname }} {{ contact.lastname }}</span>
                                                    <ul>
                                                        <li class="list__item">{{contact.position}}</li>
                                                        <li class="list__item">{{contact.companyname}}</li>
                                                        <li class="list__item">{{contact.country}}, {{contact.city}}</li>
                                                    </ul>
                                                </div>
                                                <div class="select-item__action">
                                                    <span v-if="contact.blocked" class="btn btn_octal btn_tiny" @click="toggleBlock(contact_id)">Unblock</span>
                                                    <span v-else class="btn btn_sevenfold btn_tiny" @click="toggleBlock(contact_id)">Block</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__footer">
                    <div class="btn-group btn-group_secondary content-between">
                        <a href="#" class="btn btn_secondary" @click.prevent="closePopup">
                            <span class="btn__text">Cancel</span>
                        </a>
                        <a href="#" class="btn btn_primary" @click.prevent="saveSettings">
                            <span class="btn__text">Save</span>
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store'
import func from '@/plugins/functions'

export default {
    name: "SettingsPopup",
    data() {
        return {
            searchuser: '',
            show_search: false,
        }
    },
    methods: {
        closePopup() {
            store.dispatch('SHOW_POPUP', { popup: 'settingsPopup', show: false});
            store.dispatch('RESET_USER_SEARCH_TO_BLOCK');
        },
        saveSettings() {
            let data = {};
            data.is_messages_private = this.userdata.user_profile.is_messages_private;
            data.is_profile_private = this.userdata.user_profile.is_profile_private;
            data.google_calendar = this.userdata.user_profile.google_calendar;
            data.notify_email = this.userdata.user_profile.notify_email;
            data.notify_personal = this.userdata.user_profile.notify_personal;
            data.notify_team = this.userdata.user_profile.notify_team;
            data.notify_manager = this.userdata.user_profile.notify_manager;
            store.dispatch('UPDATE_USER_SETTINGS', data);
        },
        searchUserHandler() {
            if(this.searchuser.length >= 1) {
                store.dispatch('SEARCH_USER_FOR_BLOCK', this.searchuser);
            } else {
                store.dispatch('RESET_USER_SEARCH_TO_BLOCK');
            }
        },
        toggleBlock(index) {
            this.userdata.blockSearchResult[index].blocked = !this.userdata.blockSearchResult[index].blocked;
            store.dispatch('CHANGE_USER_BLOCK_STATUS', this.userdata.blockSearchResult[index].id);
        },
        unBlock(id) {
            store.dispatch('CHANGE_USER_BLOCK_STATUS', id);
            let index = this.userdata.blockSearchResult.findIndex(x => x.id == id);
            if (index !== -1) this.userdata.blockSearchResult[index].blocked = false;
        },

        calendarInit() {
            if(this.userdata.user_profile.google_calendar)
                store.dispatch('USER_CALENDAR_ACCESS');
        },

        notificationSelect(type = '') {
            if(!this.userdata.user_profile.email_notification) {
                // this.userdata.user_profile.notify_email = '';
                this.userdata.user_profile.notify_personal = false;
                this.userdata.user_profile.notify_team = false;
                this.userdata.user_profile.notify_manager = false;
            }
            if(type === 'all' && this.userdata.user_profile.notify_all) {
                this.userdata.user_profile.notify_personal = true;
                this.userdata.user_profile.notify_team = true;
                this.userdata.user_profile.notify_manager = true;
            }
            if(!(this.userdata.user_profile.notify_personal && this.userdata.user_profile.notify_team && this.userdata.user_profile.notify_manager)) {
                this.userdata.user_profile.notify_all = false;
            }
        },
    },
    computed: {
        popups: () => store.state.popups,
        userdata: () => store.state.user,
    },
    watch: {
        searchuser: function () {
            this.debouncedSearch();
        },
    },
    created() {
        this.debouncedSearch = func.debounce(this.searchUserHandler, 500);
        store.dispatch('GET_BLOCKED_USERS');
    },
}
</script>

<style>
    .settings-popup .custom-check__label {
        align-items: flex-start;
        padding: 0;
    }
    .settings-popup .custom-check__ico {
        margin-top: 2px;
    }
    .settings-popup .custom-check__text {
        white-space: normal;
        text-align: left;
    }
</style>