<template>
    <div class="rating__media">
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"
             v-for="num in allStars"
             :key="num"
             @click="click(num)"
        >
            <path fill-rule="evenodd" clip-rule="evenodd" :d="getStarFill(num)" fill="#00A961" />
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        stars: Number
    },
    data(){
        return{
            allStars: 5,
            emptyStar: 'M9.00002 0.75C9.28549 0.75 9.5462 0.912054 9.67255 1.16803L11.8157 5.50985L16.6085 6.21039C16.8909 6.25167 17.1254 6.44967 17.2135 6.72118C17.3015 6.99268 17.2278 7.29062 17.0233 7.48977L13.5559 10.867L14.3742 15.6382C14.4225 15.9196 14.3068 16.204 14.0758 16.3718C13.8448 16.5396 13.5386 16.5617 13.2859 16.4288L9.00002 14.1749L4.71411 16.4288C4.46142 16.5617 4.1552 16.5396 3.92422 16.3718C3.69324 16.204 3.57755 15.9196 3.62582 15.6382L4.44413 10.867L0.976722 7.48977C0.772259 7.29062 0.698554 6.99268 0.786587 6.72118C0.874621 6.44967 1.10913 6.25167 1.39155 6.21039L6.18433 5.50985L8.32749 1.16803C8.45385 0.912054 8.71456 0.75 9.00002 0.75ZM9.00002 3.19444L7.35505 6.52697C7.2459 6.7481 7.03501 6.90145 6.79099 6.93712L3.11133 7.47495L5.77332 10.0677C5.95023 10.24 6.03097 10.4884 5.98923 10.7318L5.36116 14.3938L8.65093 12.6637C8.86947 12.5488 9.13057 12.5488 9.34911 12.6637L12.6389 14.3938L12.0108 10.7318C11.9691 10.4884 12.0498 10.24 12.2267 10.0677L14.8887 7.47495L11.2091 6.93712C10.965 6.90145 10.7541 6.7481 10.645 6.52697L9.00002 3.19444Z',
            fillStar: 'M9.67255 1.16803C9.5462 0.912054 9.28549 0.75 9.00002 0.75C8.71456 0.75 8.45385 0.912054 8.32749 1.16803L6.18433 5.50985L1.39155 6.21039C1.10913 6.25167 0.874621 6.44967 0.786587 6.72118C0.698554 6.99268 0.772259 7.29062 0.976722 7.48977L4.44413 10.867L3.62582 15.6382C3.57755 15.9196 3.69324 16.204 3.92422 16.3718C4.1552 16.5396 4.46142 16.5617 4.71411 16.4288L9.00002 14.1749L13.2859 16.4288C13.5386 16.5617 13.8448 16.5396 14.0758 16.3718C14.3068 16.204 14.4225 15.9196 14.3742 15.6382L13.5559 10.867L17.0233 7.48977C17.2278 7.29062 17.3015 6.99268 17.2135 6.72118C17.1254 6.44967 16.8909 6.25167 16.6085 6.21039L11.8157 5.50985L9.67255 1.16803Z'
        }
    },
    methods:{
        getStarFill(star){
            return (star <= this.stars) ? this.fillStar : this.emptyStar;
        },
        click(num){
            this.$emit('update:stars', num)
        }
    }
}
</script>