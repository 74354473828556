import instance from './index';
import suwAPI from '../config/config'

const calendarService = {
  
  setEvent(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiCalendar, data);  
  },

  getEventMonth() {
    let tzname = Intl.DateTimeFormat().resolvedOptions().timeZone;
    tzname = tzname.replace('/', "%252F");
    let month = new Date().getMonth()+1;
    return instance({ requiresAuth: true }).get(suwAPI.apiCalendar+'/month/'+tzname+'/'+month);
  },

  getEventNextMonth() {
    let tzname = Intl.DateTimeFormat().resolvedOptions().timeZone;
    tzname = tzname.replace('/', "%252F");
    let month = new Date().getMonth()+2;
    return instance({ requiresAuth: true }).get(suwAPI.apiCalendar+'/month/'+tzname+'/'+month);
  },

  getEventSelectedMonth(data) {
    let tzname = Intl.DateTimeFormat().resolvedOptions().timeZone;
    tzname = tzname.replace('/', "%252F");
    return instance({ requiresAuth: true }).get(suwAPI.apiCalendar+'/month/'+tzname+'/'+data.month+'/year/'+data.year);
  },

  removeEvent(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiCalendar+'/'+id);
  },

  updateEvent(data) {
    let event_id = data.id;
    delete data.id;
    return instance({ requiresAuth: true }).post(suwAPI.apiCalendar+'/'+event_id, data);  
  }

}

export default calendarService;