<template>
<div :class="{ 'popup-visible' : this.popups.viewfilePopup }">
    <div class="popup-wrap" @click.self="closePopup">
        <div class="popup view-file-popup" :class="{ 'active' : this.popups.viewfilePopup, 'wide' : (popups.viewfilePopupWidth > 580), 'pdf' : popups.viewfilePopupExt == 'pdf' }">
            <div class="popup__header">
                <div class="popup__title">
                    <span class="title-text"></span>
                </div>
                <div @click="closePopup" class="popup-remove">
                    <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                            fill="#333333"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                            fill="#333333"/>
                    </svg>
                </div>
            </div><!-- //popup__header -->

            <div class="popup__content">
                <div v-if="popups.viewfilePopupSrc" class="popup__section popup-section-noborder">
                    <video v-if="popups.viewfilePopupExt === 'mp4'" controls>
                        <source :src="popups.viewfilePopupSrc" type="video/mp4">
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <PDFJSViewer v-else-if="popups.viewfilePopupExt === 'pdf'" :fileName="popups.viewfilePopupSrc"></PDFJSViewer>
                    <img v-else :src="popups.viewfilePopupSrc" ref="image" alt="Start-Up World" />
                </div>
            </div>

            <div class="popup__footer">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store'
import PDFJSViewer from '../PDFJSViewer'

export default {
    name: "ViewfilePopup",
    components: {
        PDFJSViewer
    },
    data() {
      return {
          wide: true,
      }
    },
    methods: {
      closePopup() {
        store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: false});
        store.dispatch('CLEAR_SRC');
      },
    },
    computed: {
      popups: () => store.state.popups,
      userdata: () => store.state.user,
    },
    watch: {
        popups(newval, oldval) {
            console.log(newval)
            console.log(oldval)
        }
    },
    mounted(){
    },
}
</script>

<style>
video {width: 100%;}
.popup .popup__content:not(:last-child) {
    height: calc(100% - 44px);
}
.popup .popup__section.popup-section-noborder {
    height: calc(100% - 5px);
    margin: 0;
    text-align: center;
}
.popup .popup__section img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
}
@media (min-width: 580px) {
    .view-file-popup.wide {
        width: 80%;
        height: 80%;
    }
}
</style>