<template>
  <div class="chatWindow allMessages" :class="{'active' : chat.chat_open && !chat.conversation_open}">
    <div class="chatHeader">
      <div class="chatTitle">
        Messages
      </div>
      <div class="chatActivity">
        <button class="chatSearchBtn" @click.prevent="toggleSearch"></button>
        <button class="chatMenuBtn" @click.prevent="toggleNewChat"></button>
        <div class="hideBig">
          <div class="divider"></div>
          <button class="chatCloseBtn" @click.prevent="toggleChat">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 5L19 19M5 19L19 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="chatSearch" :class="{'active': showSearch}">
      <form @submit.prevent>
        <input type="text" placeholder="Search ..." v-model="searchChat" ref="searchChat">
        <span class="closeSearch" @click.prevent="toggleSearch"></span>
      </form>
    </div>
    <div class="chatSearchContent" :class="{'active': showSearch}">
      <div class="chatTabs">
        <div @click.prevent="changeSearchTab('chats')" class="chatTab" :class="{'active': current_search_tab == 'chats'}">
          Chats <span v-show="(chat.found_chats.contacts.length + chat.found_chats.messages.length) > 0">({{ chat.found_chats.contacts.length + chat.found_chats.messages.length }})</span>
        </div>
        <div @click.prevent="changeSearchTab('files')" class="chatTab" :class="{'active': current_search_tab == 'files'}">
          Files <span v-show="chat.found_chats.files.length">({{ chat.found_chats.files.length }})</span>
        </div>
      </div>
      <div class="chatTabContent" :class="{'active': current_search_tab == 'chats',
        'emptySearch': (chat.found_chats.contacts.length + chat.found_chats.messages.length) < 1 && !chat.search_chats,
        'emptyResult': (chat.found_chats.contacts.length + chat.found_chats.messages.length) < 1 && chat.search_chats }">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No results yet</p>
          <p class="emptyContainerText">Enter a search query.</p>
        </div>
        <div class="emptyResultContainer">
          <img src="@/assets/images/emptyResult.svg" alt="Empty">
          <p class="emptyContainerTitle">No results</p>
          <p class="emptyContainerText">There are no results for <span>«{{ chat.search_chats }}»</span>.<br>
            Change the query.</p>
        </div>
        <template v-if="chat.found_chats.contacts.length > 0">
          <div class="chatTabContentItem searchBlockHeader">Contacts</div>
          <div v-for="item in chat.found_chats.contacts" :key="item.id" class="chatTabContentItem"
            @click.prevent="openConversation(item.id)"
            :class="{ 'speakerOnline': (chat.statuses[item.to_user.id] == 'online'), 'speakerOffline': (chat.statuses[item.to_user.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.to_user.id] == 'disconnect') }">
            <div class="chatTabContentItemAvatar" :style="{'background-color': item.to_user.avatar ? 'transparent' : '#EB5757'}">
              <img v-if="item.to_user.avatar" :src="item.to_user.avatar" alt="Avatar">
              <template v-else>{{ item.to_user.shortname }}</template>
            </div>
            <div class="chatTabContentItemFileBodyRow">
              <div class="chatTabContentItemFileTitle" v-html="this.highLight(item.to_user.name)"></div>
              <span class="chatTabContentItemFileDate">{{item.date}}</span>
            </div>
            <p class="chatTabContentItemMessage"><span v-html="this.highLight(item.text)"></span></p>
          </div>
        </template>

        <template v-if="chat.found_chats.messages.length > 0">
          <div class="chatTabContentItem searchBlockHeader">Messages</div>
          <div v-for="item in chat.found_chats.messages" :key="item.id" class="chatTabContentItem"
            @click.prevent="openConversationFrom(item)"
            :class="{ 'speakerOnline': (chat.statuses[item.to_user.id] == 'online'), 'speakerOffline': (chat.statuses[item.to_user.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.to_user.id] == 'disconnect') }">
            <div class="chatTabContentItemAvatar" :style="{'background-color': item.to_user.avatar ? 'transparent' : '#EB5757'}">
              <img v-if="item.to_user.avatar" :src="item.to_user.avatar" alt="Avatar">
              <template v-else>{{ item.to_user.shortname }}</template>
            </div>
            <div class="chatTabContentItemFileBodyRow">
              <div class="chatTabContentItemFileTitle">{{ item.to_user.name }}</div>
              <span class="chatTabContentItemFileDate">{{item.date}}</span>
            </div>
            <p class="chatTabContentItemMessage"><span v-html="this.highLight(item.text)"></span></p>
          </div>
        </template>
      </div>
      <div class="chatTabContent" data-tab="files" :class="{'active': current_search_tab == 'files',
        'emptySearch': chat.found_chats.files.length < 1 && !chat.search_chats,
        'emptyResult': chat.found_chats.files.length < 1 && chat.search_chats }">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No results yet</p>
          <p class="emptyContainerText">Enter a search query.</p>
        </div>
        <div class="emptyResultContainer">
          <img src="@/assets/images/emptyResult.svg" alt="Empty">
          <p class="emptyContainerTitle">No results</p>
          <p class="emptyContainerText">There are no results for <span>«{{ chat.search_chats }}»</span>.<br>
            Change the query.</p>
        </div>

        <template v-if="chat.found_chats.files.length > 0">
          <div v-for="file in chat.found_chats.files" :key="file.id" class="chatTabContentItemFile pointer" @click.prevent="showAttach(file)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87868 1.87862C4.44129 1.31601 5.20435 0.999939 6 0.999939H14C14.2652 0.999939 14.5196 1.1053 14.7071 1.29283L20.7071 7.29283C20.8946 7.48037 21 7.73472 21 7.99994V19.9999C21 20.7956 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 22.9999 18 22.9999H6C5.20435 22.9999 4.44129 22.6839 3.87868 22.1213C3.31607 21.5586 3 20.7956 3 19.9999V3.99994C3 3.20429 3.31607 2.44123 3.87868 1.87862ZM6 2.99994C5.73478 2.99994 5.48043 3.1053 5.29289 3.29283C5.10536 3.48037 5 3.73472 5 3.99994V19.9999C5 20.2652 5.10536 20.5195 5.29289 20.707C5.48043 20.8946 5.73478 20.9999 6 20.9999H18C18.2652 20.9999 18.5196 20.8946 18.7071 20.707C18.8946 20.5195 19 20.2652 19 19.9999V8.41415L13.5858 2.99994H6Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0.999939C14.5523 0.999939 15 1.44765 15 1.99994V6.99994H20C20.5523 6.99994 21 7.44765 21 7.99994C21 8.55222 20.5523 8.99994 20 8.99994H14C13.4477 8.99994 13 8.55222 13 7.99994V1.99994C13 1.44765 13.4477 0.999939 14 0.999939Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H8C7.44772 14 7 13.5523 7 13Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 16.9999C7 16.4477 7.44772 15.9999 8 15.9999H16C16.5523 15.9999 17 16.4477 17 16.9999C17 17.5522 16.5523 17.9999 16 17.9999H8C7.44772 17.9999 7 17.5522 7 16.9999Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9C7 8.44772 7.44772 8 8 8H10C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10 10H8C7.44772 10 7 9.55228 7 9Z" fill="#00A961" />
            </svg>
            <div class="chatTabContentItemFileBody">
              <div class="chatTabContentItemFileBodyRow">
                <div class="chatTabContentItemFileTitle" v-html="this.highLight(file.user_defined_filename)"></div>
                <span class="chatTabContentItemFileDate">{{file.date}}</span>
              </div>
              <div class="chatTabContentItemFileBodyRow">
                <span class="chatTabContentItemFileSize">{{this.convertBytes(file.size)}}</span>
                <span class="chatTabContentItemFileAuthor">{{file.author}}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="chatDefaultContent" :class="{'active': !showSearch}">
      <div class="chatTabs">
        <div @click.prevent="changeTab('team')" class="chatTab" :class="{'active': current_tab == 'team'}">
          Team Members <span v-show="chat.team_members.length">({{ chat.team_members.length }})</span>
        </div>
        <div @click.prevent="changeTab('work')" class="chatTab" :class="{'active': current_tab == 'work'}">
          Work Pages <span v-show="chat.work_pages.length">({{ chat.work_pages.length }})</span>
        </div>
        <div v-if="userdata.user_profile.account_type !== 'entrepreneur'" @click.prevent="changeTab('projects')" class="chatTab" :class="{'active': current_tab == 'projects'}">
          Projects <span v-show="chat.projects.length">({{ chat.projects.length }})</span>
        </div>
      </div>

      <div class="chatTabContent" :class="{'active': current_tab == 'team', 'emptySearch': chat.team_members.length < 1}">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No chats yet</p>
          <p class="emptyContainerText">Add contacts to chat with.</p>
        </div>

        <div v-for="item in chat.team_members" :key="item.id" class="chatTabContentItem"
          @click.prevent="openConversation(item.id)"
          :class="{'unreadMessage': item.unread, 'speakerOnline': (chat.statuses[item.to_user.id] == 'online'), 'speakerOffline': (chat.statuses[item.to_user.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.to_user.id] == 'disconnect') }">
          <div class="chatTabContentItemAvatar" :style="{'background-color': item.to_user.avatar ? 'transparent' : '#EB5757'}">
            <img v-if="item.to_user.avatar" :src="item.to_user.avatar" alt="Avatar">
            <template v-else>{{ item.to_user.shortname }}</template>
          </div>
          <div class="chatTabContentItemFileBodyRow">
            <div class="chatTabContentItemFileTitle">{{ item.to_user.name }}
              <svg v-if="item.muted" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.9445 8.00001H1C0.867392 8.00001 0.740215 7.94733 0.646447 7.85356C0.552678 7.7598 0.5 7.63262 0.5 7.50001V4.50001C0.5 4.3674 0.552678 4.24023 0.646447 4.14646C0.740215 4.05269 0.867392 4.00001 1 4.00001H2.9445L5.5915 1.83401C5.62813 1.80398 5.67253 1.78498 5.71953 1.77921C5.76654 1.77344 5.81422 1.78114 5.85702 1.80141C5.89982 1.82168 5.93599 1.8537 5.9613 1.89373C5.98661 1.93376 6.00003 1.98015 6 2.02751V9.97251C6.00003 10.0199 5.98661 10.0663 5.9613 10.1063C5.93599 10.1463 5.89982 10.1783 5.85702 10.1986C5.81422 10.2189 5.76654 10.2266 5.71953 10.2208C5.67253 10.215 5.62813 10.196 5.5915 10.166L2.945 8.00001H2.9445ZM10.207 6.00001L11.975 7.76801L11.268 8.47501L9.5 6.70701L7.732 8.47501L7.025 7.76801L8.793 6.00001L7.025 4.23201L7.732 3.52501L9.5 5.29301L11.268 3.52501L11.975 4.23201L10.207 6.00001Z" fill="#AAADB1"/>
              </svg>
            </div>
            <span class="chatTabContentItemFileDate">{{item.date}}</span>
          </div>
          <p class="chatTabContentItemMessage">
            <span v-html="this.highLight(item.text)" class="text"></span>
            <span v-if="item.count_unread > 0" class="msgsCounter">{{item.count_unread}}</span>
          </p>
        </div>
      </div>

      <div class="chatTabContent" :class="{'active': current_tab == 'work', 'emptySearch': chat.work_pages.length < 1}">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No chats yet</p>
          <p class="emptyContainerText">Add contacts to chat with.</p>
        </div>

        <div v-for="item in chat.work_pages" :key="item.id" class="chatTabContentItem"
          @click.prevent="openConversation(item.id)"
          :class="{'unreadMessage': item.unread, 'speakerOnline': (chat.statuses[item.to_user.id] == 'online'), 'speakerOffline': (chat.statuses[item.to_user.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.to_user.id] == 'disconnect') }">
          <div class="chatTabContentItemAvatar" :style="{'background-color': item.to_user.avatar ? 'transparent' : '#EB5757'}">
            <img v-if="item.to_user.avatar" :src="item.to_user.avatar" alt="Avatar">
            <template v-else>{{ item.to_user.shortname }}</template>
          </div>
          <div class="chatTabContentItemFileBodyRow">
            <div class="chatTabContentItemFileTitle">{{ item.to_user.name }}
              <svg v-if="item.muted" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.9445 8.00001H1C0.867392 8.00001 0.740215 7.94733 0.646447 7.85356C0.552678 7.7598 0.5 7.63262 0.5 7.50001V4.50001C0.5 4.3674 0.552678 4.24023 0.646447 4.14646C0.740215 4.05269 0.867392 4.00001 1 4.00001H2.9445L5.5915 1.83401C5.62813 1.80398 5.67253 1.78498 5.71953 1.77921C5.76654 1.77344 5.81422 1.78114 5.85702 1.80141C5.89982 1.82168 5.93599 1.8537 5.9613 1.89373C5.98661 1.93376 6.00003 1.98015 6 2.02751V9.97251C6.00003 10.0199 5.98661 10.0663 5.9613 10.1063C5.93599 10.1463 5.89982 10.1783 5.85702 10.1986C5.81422 10.2189 5.76654 10.2266 5.71953 10.2208C5.67253 10.215 5.62813 10.196 5.5915 10.166L2.945 8.00001H2.9445ZM10.207 6.00001L11.975 7.76801L11.268 8.47501L9.5 6.70701L7.732 8.47501L7.025 7.76801L8.793 6.00001L7.025 4.23201L7.732 3.52501L9.5 5.29301L11.268 3.52501L11.975 4.23201L10.207 6.00001Z" fill="#AAADB1"/>
              </svg>
            </div>
            <span class="chatTabContentItemFileDate">{{item.date}}</span>
          </div>
          <p class="chatTabContentItemMessage">
            <span v-html="this.highLight(item.text)" class="text"></span>
            <span v-if="item.count_unread > 0" class="msgsCounter">{{item.count_unread}}</span>
          </p>
        </div>
      </div>

      <div class="chatTabContent" :class="{'active': current_tab == 'projects', 'emptySearch': chat.projects.length < 1}">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No chats yet</p>
          <p class="emptyContainerText">Add contacts to chat with.</p>
        </div>

        <div v-for="item in chat.projects" :key="item.id" class="chatTabContentItem"
          @click.prevent="openConversation(item.id)"
          :class="{'unreadMessage': item.unread, 'speakerOnline': (chat.statuses[item.to_user.id] == 'online'), 'speakerOffline': (chat.statuses[item.to_user.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.to_user.id] == 'disconnect') }">
          <div class="chatTabContentItemAvatar" :style="{'background-color': item.to_user.avatar ? 'transparent' : '#EB5757'}">
            <img v-if="item.to_user.avatar" :src="item.to_user.avatar" alt="Avatar">
            <template v-else>{{ item.to_user.shortname }}</template>
          </div>
          <div class="chatTabContentItemFileBodyRow">
            <div class="chatTabContentItemFileTitle">{{ item.to_user.name }}
              <svg v-if="item.muted" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.9445 8.00001H1C0.867392 8.00001 0.740215 7.94733 0.646447 7.85356C0.552678 7.7598 0.5 7.63262 0.5 7.50001V4.50001C0.5 4.3674 0.552678 4.24023 0.646447 4.14646C0.740215 4.05269 0.867392 4.00001 1 4.00001H2.9445L5.5915 1.83401C5.62813 1.80398 5.67253 1.78498 5.71953 1.77921C5.76654 1.77344 5.81422 1.78114 5.85702 1.80141C5.89982 1.82168 5.93599 1.8537 5.9613 1.89373C5.98661 1.93376 6.00003 1.98015 6 2.02751V9.97251C6.00003 10.0199 5.98661 10.0663 5.9613 10.1063C5.93599 10.1463 5.89982 10.1783 5.85702 10.1986C5.81422 10.2189 5.76654 10.2266 5.71953 10.2208C5.67253 10.215 5.62813 10.196 5.5915 10.166L2.945 8.00001H2.9445ZM10.207 6.00001L11.975 7.76801L11.268 8.47501L9.5 6.70701L7.732 8.47501L7.025 7.76801L8.793 6.00001L7.025 4.23201L7.732 3.52501L9.5 5.29301L11.268 3.52501L11.975 4.23201L10.207 6.00001Z" fill="#AAADB1"/>
              </svg>
            </div>
            <span class="chatTabContentItemFileDate">{{item.date}}</span>
          </div>
          <p class="chatTabContentItemMessage">
            <span v-html="this.highLight(item.text)" class="text"></span>
            <span v-if="item.count_unread > 0" class="msgsCounter">{{item.count_unread}}</span>
          </p>
        </div>
      </div>

    </div>
    <div class="chatMenu" :class="{'active': showNewChat}">
      <div class="chatSearch">
        <form @submit.prevent>
          <input type="text" placeholder="Search ..." v-model="searchNewContact" ref="searchNewContact">
        </form>
      </div>

      <div class="chatMiniTabs">
        <div @click.prevent="changeMiniTab('team')" class="chatMiniTab" :class="{'active': current_mini_tab == 'team'}">
          Team <span v-show="chat.new_contacts.team_members.length">({{ chat.new_contacts.team_members.length }})</span>
        </div>
        <div @click.prevent="changeMiniTab('work')" class="chatMiniTab" :class="{'active': current_mini_tab == 'work'}">
          Work <span v-show="chat.new_contacts.work_pages.length">({{ chat.new_contacts.work_pages.length }})</span>
        </div>
        <div v-if="userdata.user_profile.account_type !== 'entrepreneur'" @click.prevent="changeMiniTab('projects')" class="chatMiniTab" :class="{'active': current_mini_tab == 'projects'}">
          Projects <span v-show="chat.new_contacts.projects.length">({{ chat.new_contacts.projects.length }})</span>
        </div>
      </div>

      <div class="chatMiniTabContent" :class="{'active': current_mini_tab == 'team',
        'emptyResult': (chat.new_contacts.team_members.length == 0 && chat.search_new_contacts.length > 0),
        'emptySearch': (chat.new_contacts.team_members.length == 0 && chat.search_new_contacts.length == 0)
        }">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No results yet</p>
          <p class="emptyContainerText">Enter a search query.</p>
        </div>
        <div class="emptyResultContainer">
          <img src="@/assets/images/emptyResult.svg" alt="Empty">
          <p class="emptyContainerTitle">No results</p>
          <p class="emptyContainerText">There are no results for <span>«{{chat.search_new_contacts}}»</span>.<br>
            Change the query.</p>
        </div>
        <div v-for="item in chat.new_contacts.team_members" :key="item.id" class="chatMiniTabContentItem"
          @click.prevent="openNewConversation(item)"
          :class="{ 'speakerOnline': (chat.statuses[item.id] == 'online'), 'speakerOffline': (chat.statuses[item.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.id] == 'disconnect') }">
          <div class="chatMiniTabContentItemAvatar" :style="{'background-color': item.avatar ? 'transparent' : '#EB5757'}">
            <img v-if="item.avatar" :src="item.avatar" alt="Avatar">
            <template v-else>{{ item.shortname }}</template>
          </div>
          <p class="chatTabContentItemName">{{item.name}}</p>
        </div>
      </div>

      <div class="chatMiniTabContent" :class="{'active': current_mini_tab == 'work',
        'emptyResult': (chat.new_contacts.work_pages.length == 0 && chat.search_new_contacts.length > 0),
        'emptySearch': (chat.new_contacts.work_pages.length == 0 && chat.search_new_contacts.length == 0)
        }">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No results yet</p>
          <p class="emptyContainerText">Enter a search query.</p>
        </div>
        <div class="emptyResultContainer">
          <img src="@/assets/images/emptyResult.svg" alt="Empty">
          <p class="emptyContainerTitle">No results</p>
          <p class="emptyContainerText">There are no results for <span>«{{chat.search_new_contacts}}»</span>.<br>
            Change the query.</p>
        </div>
        <div v-for="item in chat.new_contacts.work_pages" :key="item.id" class="chatMiniTabContentItem"
          @click.prevent="openNewConversation(item)"
          :class="{ 'speakerOnline': (chat.statuses[item.id] == 'online'), 'speakerOffline': (chat.statuses[item.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.id] == 'disconnect') }">
          <div class="chatMiniTabContentItemAvatar" :style="{'background-color': item.avatar ? 'transparent' : '#EB5757'}">
            <img v-if="item.avatar" :src="item.avatar" alt="Avatar">
            <template v-else>{{ item.shortname }}</template>
          </div>
          <p class="chatTabContentItemName">{{item.name}}</p>
        </div>
      </div>

      <div class="chatMiniTabContent" :class="{'active': current_mini_tab == 'projects',
        'emptyResult': (chat.new_contacts.projects.length == 0 && chat.search_new_contacts.length > 0),
        'emptySearch': (chat.new_contacts.projects.length == 0 && chat.search_new_contacts.length == 0)
        }">
        <div class="emptySearchContainer">
          <img src="@/assets/images/emptySearch.svg" alt="Empty">
          <p class="emptyContainerTitle">No results yet</p>
          <p class="emptyContainerText">Enter a search query.</p>
        </div>
        <div class="emptyResultContainer">
          <img src="@/assets/images/emptyResult.svg" alt="Empty">
          <p class="emptyContainerTitle">No results</p>
          <p class="emptyContainerText">There are no results for <span>«{{chat.search_new_contacts}}»</span>.<br>
            Change the query.</p>
        </div>
        <div v-for="item in chat.new_contacts.projects" :key="item.id" class="chatMiniTabContentItem"
          @click.prevent="openNewConversation(item)"
          :class="{ 'speakerOnline': (chat.statuses[item.id] == 'online'), 'speakerOffline': (chat.statuses[item.id] == 'offline'), 'speakerDisconnect': (chat.statuses[item.id] == 'disconnect') }">
          <div class="chatMiniTabContentItemAvatar" :style="{'background-color': item.avatar ? 'transparent' : '#EB5757'}">
            <img v-if="item.avatar" :src="item.avatar" alt="Avatar">
            <template v-else>{{ item.shortname }}</template>
          </div>
          <p class="chatTabContentItemName">{{item.name}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="openChatBtn" :class="{'active' : chat.chat_open}" @click.prevent="toggleChat">
    <span v-show="chat.unread > 0" class="chatCounter">{{ chat.unread }}</span>
  </div>

  <audio id="msgAudio" preload>
    <source src="/msg.mp3" type="audio/mpeg">
  </audio>
</template>

<script>
  import store from '@/store'
  import func from '@/plugins/functions'

  export default {
    name: "Chat",
    components: {},
    data() {
      return {
        current_tab: 'team',
        current_mini_tab: 'team',
        current_search_tab: 'chats',
        showSearch: false,
        showNewChat: false,
      }
    },
    methods: {
      toggleChat() {
        store.dispatch('CHAT_TOGGLE_OPEN', !this.chat.chat_open);
        this.showSearch = false;
        this.showNewChat = false;
      },
      changeTab(tab) {
        this.current_tab = tab;
      },
      changeMiniTab(tab) {
        this.current_mini_tab = tab;
      },
      changeSearchTab(tab) {
        this.current_search_tab = tab;
      },

      toggleSearch() {
        this.showSearch = !this.showSearch;
        this.$nextTick(() => {
          if(this.showSearch) {
            this.$refs.searchChat.focus();
            this.showNewChat = false;
          } else {
            store.commit('CHAT_SET_SEARCH_CHATS', '');
          }
        });
      },
      toggleNewChat() {
        this.showNewChat = !this.showNewChat;
        this.$nextTick(() => {
          if(this.showNewChat) {
            this.$refs.searchNewContact.focus();
            this.showSearch = false;
            store.dispatch('CHAT_GET_NEW_CONTACTS');
          }
        });
      },

      openConversation(id) {
        store.dispatch('CHAT_CONVERSATION_TOGGLE_OPEN', {show: true, conversation_id: id});
        this.showSearch = false;
        this.showNewChat = false;
      },

      openConversationFrom(item) {
        store.dispatch('CHAT_CONVERSATION_TOGGLE_OPEN_FROM', {show: true, conversation_id: item.id});
      },

      openNewConversation(user) {
        store.dispatch('CHAT_NEW_CONVERSATION_OPEN', {user: user});
        this.showSearch = false;
        this.showNewChat = false;
      },

      convertBytes(bytes) {
        if(bytes <= 0) return '';
        let units = ['b', 'kb', 'mb'];
        let i = 0;
        for(i; bytes> 1024; i++) {
          bytes /= 1024;
        }

        return bytes.toFixed(0)+units[i];
      },
      showAttach: function (attachment) {
        store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
      },

      /* Search */
      searchNewContactHandler() {
        store.dispatch('CHAT_GET_NEW_CONTACTS');
      },
      searchChatHandler() {
        store.dispatch('CHAT_GET_SEARCH_CHATS');
      },


      highLight(text) {
        return func.highLight(text, this.chat.search_chats);
      },

    },
    watch: {
      searchNewContact: function () {
        this.debouncedSearchNewContact();
      },
      searchChat: function () {
        this.debouncedSearchChat();
      },
    },
    computed: {
      chat: () => store.state.chat,
      userdata: () => store.state.user,
      searchNewContact: {
        get() {
          return this.chat.search_new_contacts;
        },
        set(value) {
          store.commit('CHAT_SET_SEARCH_NEW_CONTACTS', value);
        }
      },
      searchChat: {
        get() {
          return this.chat.search_chats;
        },
        set(value) {
          store.commit('CHAT_SET_SEARCH_CHATS', value);
        }
      },
    },
    mounted() {
      store.dispatch('CHAT_GET_ALL_CONTACTS');
    },
    created() {
      this.debouncedSearchNewContact = func.debounce(this.searchNewContactHandler, 500);
      this.debouncedSearchChat = func.debounce(this.searchChatHandler, 500);
    },
  }
</script>
