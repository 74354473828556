<template>
  <div :class="{ 'popup-visible': this.popups.forumNewTopicPopup }">
    <div class="popup-wrap" @click.self="closeForumNewTopicPopup">
      <!-- // post-add -->
      <div class="popup post-add-popup" :class="{ active: this.popups.forumNewTopicPopup }">
        <form action="#" class="popup__form">
          <div class="popup__header">
            <div class="popup__title">
              <span class="title-text">New forum topic</span>
            </div>
            <div class="popup-action ml-auto">
              <div class="popup-action__item popup-action__item_remove" @click.prevent="closeForumNewTopicPopup">
                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                    fill="#333333" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                    fill="#333333" />
                </svg>
              </div>
            </div>
          </div>
          <!-- //popup__header -->
          <div class="popup__content">
            <div class="popup__section">
              <div class="form-field">
                <div class="form-field__header">
                  <label class="form-field__title">Topic title</label>
                </div>
                <div class="form-field__content">
                  <input v-model="new_topic.title" :class="{ 'input-errors' : this.errors.title }" ref="title" type="text" placeholder="Enter title" class="form-field__input" />
                </div>
                <p v-if="this.errors.title" class="form-errors">{{ this.errors.title }}</p>
              </div>
              <div class="form-field">
                <div class="form-field__header">
                  <label class="form-field__title bigger">Open to:</label>
                </div>
              </div>
              <div class="row">
                <div class="col col_6">
                  <div class="form-field">
                    <div class="form-field__content">
                      <div class="custom-check">
                        <label class="custom-check__label">
                          <input v-model="to_all" class="custom-check__input" type="checkbox" />
                          <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                              <g>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z"
                                  fill="white"></path>
                              </g>
                            </g>
                          </svg>
                          <span class="custom-check__text">All</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-field">
                    <div class="form-field__content">
                      <div class="custom-check">
                        <label class="custom-check__label">
                          <input v-model="new_topic.to_expert" class="custom-check__input" type="checkbox" />
                          <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                              <g>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z"
                                  fill="white"></path>
                              </g>
                            </g>
                          </svg>
                          <span class="custom-check__text">Experts</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col_6">
                  <div class="form-field">
                    <div class="form-field__content">
                      <div class="custom-check">
                        <label class="custom-check__label">
                          <input v-model="new_topic.to_investor" class="custom-check__input" type="checkbox" />
                          <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                              <g>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z"
                                  fill="white"></path>
                              </g>
                            </g>
                          </svg>
                          <span class="custom-check__text">Investors</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-field">
                    <div class="form-field__content">
                      <div class="custom-check">
                        <label class="custom-check__label">
                          <input v-model="new_topic.to_entrepreneur" class="custom-check__input" type="checkbox" />
                          <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                              <g>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z"
                                  fill="white"></path>
                              </g>
                            </g>
                          </svg>
                          <span class="custom-check__text">Start-Ups</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col_12">
                  <p v-if="this.errors.select" class="form-errors">{{ this.errors.select }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="popup__footer">
            <div class="btn-group btn-group_secondary content-between">
              <a @click.prevent="closeForumNewTopicPopup" href="#" class="btn btn_secondary popup-init">
                <span class="btn__text">Cancel</span>
              </a>
              <a @click.prevent="createTopic" href="#" class="btn btn_primary">
                <span class="btn__text">Send request</span>
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "ForumNewTopicPopup",
  data() {
    return {
    to_all: false,
      new_topic: {
        title: "",
        to_investor: false,
        to_expert: false,
        to_entrepreneur: false,
      },
      errors: {
        title: '',
        select: '',
      },
    };
  },
  methods: {
    closeForumNewTopicPopup() {
      store.dispatch("SHOW_POPUP", {
        popup: "forumNewTopicPopup",
        show: false,
      });
    },
    async createTopic() {
        this.errors.title = false;
        this.errors.select = false;
        if (!this.new_topic.title) {
          this.errors.title = 'This field is required';
          return false;
        }
        if (!this.new_topic.to_investor && !this.new_topic.to_expert && !this.new_topic.to_entrepreneur) {
          this.errors.select = 'Please select';
          return false;
        }
        const succes = await store.dispatch('STORE_FORUM_TOPIC', this.new_topic);
        if (succes) {
            this.resetInputData();
        }
    },
    resetInputData() {
        this.new_topic = {
            title: "",
            to_all: false,
            to_investor: false,
            to_expert: false,
            to_entrepreneur: false,
        };
    },
  },
  watch: {
    to_all(newVal) {
        if (newVal) {
            this.new_topic.to_investor = true;
            this.new_topic.to_expert = true;
            this.new_topic.to_entrepreneur = true;
        }
    },
    'new_topic.to_investor'(newVal) {if(!newVal) this.to_all = false;},
    'new_topic.to_expert'(newVal) {if(!newVal) this.to_all = false;},
    'new_topic.to_entrepreneur'(newVal) {if(!newVal) this.to_all = false;},
    'popups.forumNewTopicPopup'(newVal) {
      if (newVal) setTimeout(() => this.$refs.title.focus(), "500")
    },
  },
  computed: {
    popups: () => store.state.popups,
  },
};
</script>