<template>
  <main-layout>
    <Posts></Posts>
  </main-layout>
</template>

<script>
import store from '../store'
import MainLayout from "../layouts/Main.vue"
import Posts from '@/components/page/Posts'
export default {
  name: "Forum",
  components: {
    MainLayout,
    Posts,
  },
  methods: {
    getPage(id) {
      store.dispatch('GET_FORUM_TOPIC_DATA', id);
    },
  },
  created() {
    this.getPage(this.$route.params.id);
  },
  beforeRouteUpdate (to, from) {
    if (from.params.id != to.params.id) {
      this.getPage(to.params.id);
    }
  },
  beforeRouteLeave() {
    store.commit('PAGE_RESET')
  },
}
</script>
