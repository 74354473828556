<template>
    <div class="sidebar__nav nav">
        <div class="nav__group nav-group">
            <div class="nav-group__header">
                <div class="d-flex align-center">
                    <div class="nav-group__title">
                        <span class="title-text">Work Pages</span>
                        <span class="length-text">({{this.page.count_pages}})</span>
                    </div>
                    <ToolTip
                        :type="'left mw-460'"
                        :msg="'The Work Pages are for you and your team to work on building your business! It’s absolutely private and only those that are specifically invited to the work pages can see it!<br><br>You can invite other team members, experts, and investors to any of your work pages, and they will only have access to what you specifically invited them to!<br><br>This will serve as your team-integrated task management space, allowing you to upload posts, create calendar events, discuss tasks, with more to come.<br><br>Everything uploaded will be accessible to all that are invited to the specific work page, so you can use this space to ease the on-boarding process for new recruits/ new 3rd party team members (experts/ investors from the platform). By adding them to the relevant work pages you can have them filled in quicker, as well as give them immediate and direct access/communication with the members of your team that is relevant to their job.<br><br>For example you can create work pages for:<br><br>Web dev team<br><br>Co-founders<br><br>Marketing<br><br>Legal team<br><br>Catch up for new team-members<br><br>etc.'"
                    />
                </div>

                <div v-if="userdata.user_profile.in_company_role === 'administrator'" class="nav-group__init nav-init">
                    <button @click.prevent="showPageAddPopup" type="button" class="btn btn_tertiary">
                        <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z"
                                  fill="white"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z"
                                  fill="white"></path>
                        </svg>
                        <span class="btn__text">Add work page</span>
                    </button>

                </div>

            </div>
            <div class="nav-group__content">
                <div class="nav-group__list">
                    <div v-for="(page) in this.page.pages"  :key='page.id' class="nav-group__item nav-item"
                        :class="{'nav-item_active' : $route.name === 'page' && $route.params.id == page.id}">
                        <div class="nav-item__header">
                            <div class="nav-item__title">
                                <router-link :to="{name:'page', params: {id: page.id}}" class="nav-item__link">
                                    <span>{{page.title}}</span>
                                    <span class="badge left" v-if="page.count_new > 0">{{page.count_new}}</span>
                                </router-link>
                                <div v-if="page.role == 'administrator'" class="post-action ml-auto">
                                    <a @click.prevent="editPage(page)" href="#" class="post-action__item post-action__item_edit">
                                        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z" fill="#00A961"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.1582C14.777 2.1582 14.5631 2.24681 14.4053 2.40452L7.42724 9.38263L7.03079 10.9684L8.61658 10.572L15.5947 3.59386C15.7524 3.43615 15.841 3.22224 15.841 2.99919C15.841 2.77615 15.7524 2.56224 15.5947 2.40452C15.437 2.24681 15.2231 2.1582 15 2.1582ZM13.3447 1.34386C13.7837 0.904842 14.3791 0.658203 15 0.658203C15.6209 0.658203 16.2163 0.904842 16.6553 1.34386C17.0944 1.78288 17.341 2.37832 17.341 2.99919C17.341 3.62006 17.0944 4.2155 16.6553 4.65452L9.53035 11.7795C9.43423 11.8756 9.31379 11.9438 9.18192 11.9768L6.18192 12.7268C5.92634 12.7907 5.65597 12.7158 5.46969 12.5295C5.2834 12.3432 5.20851 12.0729 5.27241 11.8173L6.02241 8.81729C6.05538 8.68542 6.12357 8.56498 6.21969 8.46886L13.3447 1.34386Z" fill="#00A961"></path>
                                        </svg>
                                    </a>
                                    <a @click.prevent="deletePage(page)" href="#" class="post-action__item post-action__item_trash">
                                        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#00A961"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#00A961"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#00A961"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#00A961"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="userdata.user_profile.company_user_type != 'startup'" class="nav__group nav-group">
            <div class="nav-group__header with-tooltip">
                <div class="nav-group__title">
                    <span class="title-text">Projects</span>
                    <span class="length-text">({{this.page.count_projects}})</span>
                </div>
                <ToolTip
                    :type="'left mw-460'"
                    :msg="'Project pages are relevant to the experts and investors segments only:<br><br>To access a Project Page you must be invited to another platform members Work Page. Once invited and you join another team\'s Work Page, it will show under your Projects section. You will see the company name of the companys\' Work Page you were invited to with a down arrow at the right that when clicked will show you all the Work Pages you are invited to with that specific team and allow you to access them as you please.<br><br>This was organized as such to help you easily organize and interact with your clients or investees directly through the platform. This also allows for transparent and intuitive on-boarding because when a new member joins a Work/Project Page they have access to all of the previous posts and can use that as an opportunity to catch up on what the team is doing, working on, envisioning, etc.'"
                />
            </div>
            <div class="nav-group__content">
                <div class="nav-group__list">


                    <div v-for="(project, index) in this.page.projects"  :key='project.id' class="nav-group__item nav-group__item_dropdown nav-item"
                        :class="{'nav-item_active' : project.open || openedProject(index)}">
                        <div @click="project.open = !project.open" class="nav-item__header">
                            <div class="nav-item__title">
                                <span href="#" class="nav-item__link">{{project.title}}</span>
                            </div>
                            <div class="nav-item__ico">
                                <svg class="ico" width="18" height="18"
                                     viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.96967 6.21967C4.26256 5.92678 4.73744 5.92678 5.03033 6.21967L9 10.1893L12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967C14.3232 6.51256 14.3232 6.98744 14.0303 7.28033L9.53033 11.7803C9.23744 12.0732 8.76256 12.0732 8.46967 11.7803L3.96967 7.28033C3.67678 6.98744 3.67678 6.51256 3.96967 6.21967Z"
                                          fill="#5D6167"/>
                                </svg>
                            </div>
                        </div>
                        <div class="nav-item__dropdown">
                            <div class="nav-group__list">

                                <div v-for="page in project.pages"  :key='page.id' class="nav-group__item nav-item"
                                    :class="{'nav-item_active' : $route.name === 'page' && $route.params.id == page.id}">
                                    <div class="nav-item__header">
                                        <div class="nav-item__title">
                                            <router-link :to="{name:'page', params: {id: page.id}}" class="nav-item__link">
                                                {{page.title}}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import store from '../store'
    import ToolTip from '@/components/ToolTip'

    export default {
        name: "SidebarContent",
        components: {
            ToolTip,
        },
        props: {},
        methods: {
            showPageAddPopup: function() {
                store.dispatch('SHOW_POPUP', { popup: 'pageAddPopup', show: true});
            },

            openedProject(index) {
                let active = false;
                if(this.$route.name === 'page') {
                    this.page.projects[index].pages.find((o) => {
                        if (o.id == this.$route.params.id) active = true;
                    })
                }
                return active;
            },
            editPage(page) {
                console.log(page)
                let data = {
                    type: 'edit',
                    id: page.id,
                    title: page.title,
                    auto_join: page.auto_join,
                };
                store.dispatch('SHOW_POPUP', { popup: 'pageAddPopup', show: true, data: data});
            },
            deletePage(page) {
                store.dispatch('SET_PAGE_TO_DELETE', page.id);
                store.dispatch('SHOW_POPUP', {
                    popup: 'confirmPopup',
                    show: 'Are you sure you want to delete the page: "'+page.title+'"?',
                    route: '',
                    name: 'REMOVE_PAGE',
                    data: '',
                });
            },
        },
        created() {
            store.dispatch('GET_PAGES_DATA');
            store.dispatch('GET_PROJECTS_DATA');
        },
        computed: {
            page: () => store.state.page,
            userdata: () => store.state.user,
        },
    }
</script>
