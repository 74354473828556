<template>
<div class="post__item" :class="{'disabled': user.private}">
  <div class="post-item__header header_flex_wrap">
    <div class="post-item__avatar">
      <img v-if="user.avatar" :src="user.avatar" alt="" />
      <img v-else src="@/assets/images/avatar-default.png" alt="">
    </div>
    <div class="wysiwyg">
      <h3>
        <router-link :to="{name: 'profile', params: {id: user.id}}" :class="{'disabled': user.private}">{{user.name}}</router-link>
      </h3>
      <ul>
        <li v-if="user.position">{{user.position}}</li>
        <li v-if="user.company">
          <router-link :to="{name: 'company_profile', params: {id: user.company.id}}">{{user.company.companyname}}</router-link>
        </li>
        <li>{{user.location}}</li>
        <li v-if="user.type_of_investment">{{user.type_of_investment}}</li>
        <template v-if="user.company && !moreIndustry">
          <li v-for="industry in user.company.companyindustry.slice(0, 4)" :key="industry.id">{{industry.value}}</li>
          <li v-if="user.company.companyindustry.length > 4" @click.prevent="toggleMoreIndustry"><a href="#">. . .</a></li>
        </template>
        <template v-if="user.company && moreIndustry">
          <li v-for="industry in user.company.companyindustry" :key="industry.id">{{industry.value}}</li>
          <li @click.prevent="toggleMoreIndustry"><a href="#">. . .</a></li>
        </template>
      </ul>
      <a class="box align-self-left" href="#"
        v-if="user.account_type === 'expert' && type == 'favorites'"
        @click.prevent="addOrViewReview">
        <Rating :reviewsCount="user.reviews_count" :reviewsAvg="user.reviews_avg"></Rating>
      </a>
    </div>
    <a class="post-action ml-auto box align-self-center" href="#"
      v-if="user.account_type === 'expert' && type == 'normal'"
      @click.prevent="addOrViewReview">
      <Rating :reviewsCount="user.reviews_count" :reviewsAvg="user.reviews_avg"></Rating>
    </a>

    <div v-if="type == 'favorites'" class="post-action ml-auto">
      <div @click.prevent="toggleFavorites" class="post-action__item post-action__item_trash">
        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#5D6167"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#5D6167"></path>
        </svg>
      </div>
    </div>

  </div>
  <div class="post-item__content">
    <div class="wysiwyg">
      <p>{{user.description}}</p>
    </div>
  </div>
  <div class="post-item__footer">
    <div class="btn-group">
      <div class="btn-group btn-group_secondary">
        <a href="#" class="btn btn_secondary"
          v-if="user.account_type === 'expert' && user.reviews_count"
          @click.prevent="showReviews">
          <span class="btn__text">Read reviews</span>
        </a>
        <a v-if="user.in_my_crm" @click.prevent="removeFromCRM" href="#" class="btn btn_secondary d-none d-flex-tab">
          <span class="btn__text">Remove from CRM</span>
        </a>

        <a v-if="!user.in_my_crm && !user.private && !user.private_message" @click.prevent="addToCRM" href="#" class="btn btn_secondary">
          <span class="btn__text">Add to CRM</span>
        </a>
        <router-link :to="{ name: 'crmUser', params: { id: user.crm_id }}" v-if="user.in_my_crm" class="btn btn_secondary d-none d-flex-tab">
          <span class="btn__text">Open CRM Card</span>
        </router-link>

        <a v-if="!user.private_message" @click.prevent="sendMSG" href="#" class="btn btn_secondary"
          :class="{'d-none d-flex-tab': user.account_type === 'expert' && !user.in_my_crm && user.reviews_count}">
          <span class="btn__text">Send Message</span>
        </a>

        <a v-if="type == 'normal'" href="#" @click.prevent="toggleFavorites" class="btn d-none d-flex-tab" :class="{'btn_ninefold': user.favorite}">
          <template v-if="user.favorite">
            <svg class="btn__ico" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H11.25C11.6642 0.25 12 0.585786 12 1C12 1.41421 11.6642 1.75 11.25 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z" fill="#5D6167" />
            </svg>
            <span class="btn__text">Remove From Favorites</span>
          </template>
          <template v-if="!user.favorite && !user.private">
            <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="#00A961" />
            </svg>
            <span class="btn__text">Add to favorites</span>
          </template>
        </a>
        <a href="#" v-else>&nbsp;</a>

        <div class="form-field__content d-none-tab ml-auto">
          <div class="dropdown dropdown_secondary" :class="{'dropdown_active': open_dropdown}">
            <div class="dropdown__init">
              <a @click.prevent="open_dropdown = !open_dropdown" href="javascript:;" class="btn btn_secondary">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C10.5 4.57843 9.82843 5.25 9 5.25C8.17157 5.25 7.5 4.57843 7.5 3.75Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14.25C7.5 13.4216 8.17157 12.75 9 12.75C9.82843 12.75 10.5 13.4216 10.5 14.25C10.5 15.0784 9.82843 15.75 9 15.75C8.17157 15.75 7.5 15.0784 7.5 14.25Z" fill="#00A961" />
                </svg>
              </a>
            </div>
            <div class="dropdown__content">
              <ul class="dropdown__list">
                <li v-if="!user.private_message && !user.in_my_crm && user.account_type === 'expert' && user.reviews_count" class="dropdown__item">
                  <a @click.prevent="sendMSG" href="#" class="dropdown__link">
                    <span class="btn__text">Send Message</span>
                  </a>
                </li>
                <li v-if="user.in_my_crm" class="dropdown__item">
                  <router-link :to="{ name: 'crmUser', params: { id: user.crm_id }}" class="dropdown__link">
                    Open CRM Card
                  </router-link>
                </li>
                <li v-if="user.in_my_crm" class="dropdown__item">
                  <a @click.prevent="removeFromCRM" href="#" class="dropdown__link">Remove from CRM</a>
                </li>
                <li v-if="type == 'normal' && !user.private" class="dropdown__item">
                  <a href="#" @click.prevent="toggleFavorites" class="dropdown__link">
                    <span v-if="user.favorite">Remove From Favorites</span>
                    <span v-else>Add to favorites</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import store from '../store'
import Rating from '@/components/profile/Rating'

export default {
  name: "CardUser",
  components: {
    Rating,
  },
  props: {
    user: Object,
    tab: String,
    type: {
      default: "normal",
      type: String
    }
  },
  data() {
    return {
      open_dropdown: false,
      moreIndustry: false,
    }
  },
  methods: {
    async removeFromCRM() {
      await store.dispatch('REMOVE_CONTACT_FROM_CRM', { contact_id: this.user.crm_id });
      store.dispatch('EMPLOYEE_USER_RELOAD', {tab: this.tab, id: this.user.id});
      this.open_dropdown = false;
    },
    async addToCRM() {
      await store.dispatch('ADD_CONTACT_TO_CRM', { contact_id: this.user.id, type: 'user' });
      store.dispatch('EMPLOYEE_USER_RELOAD', {tab: this.tab, id: this.user.id});
      this.open_dropdown = false;
    },
    async toggleFavorites() {
      await store.dispatch('EMPLOYEE_FAVORITE_TOGGLE', {tab: this.tab, item_id: this.user.id, type: 'user'});
      store.dispatch('EMPLOYEE_USER_RELOAD', {tab: this.tab, id: this.user.id});
      this.open_dropdown = false;
    },
    sendMSG() {
        if (this.user.in_my_team) {
            store.dispatch('CHAT_USER_CONVERSATION_OPEN', this.user.id);
            return;
        }
        let data = {
            recipient: this.user.id,
            type: 'user'
        };
        store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: true, data: data});
        return;
    },
    addOrViewReview() {
      if(!this.user.my_review){
        let data = {
          id: this.user.id,
          type: 'user'
        };
          store.dispatch('SHOW_POPUP', { popup: 'fullReviewAddPopup', show: true, data: data });
      } else {
          this.showReviews();
      }
    },
    showReviews() {
      store.dispatch('EXPERT_REVIEWS', {id: this.user.id, type: 'user'});
    },
    toggleMoreIndustry() {
      this.moreIndustry = !this.moreIndustry;
      console.log(this.moreIndustry);
    }
  },
  computed: {
  },
}
</script>
