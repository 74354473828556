<template>
<div :class="{ 'popup-visible' : this.popups.forumTopicSuccessPopup }">
    <div class="popup-wrap" @click.self="closeForumTopicSuccessPopup">

        <!-- // post-add -->
        <div class="popup post-add-popup" :class="{ 'active' : this.popups.forumTopicSuccessPopup }">
            <form action="#" class="popup__form">
                <div class="popup__header">
                    <div class="popup__title">
                    <span class="title-text">Request sent</span>
                    </div>
                    <div class="popup-action ml-auto">
                    <div class="popup-action__item popup-action__item_remove" @click.prevent="closeForumTopicSuccessPopup">
                        <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333" />
                        </svg>
                    </div>
                    </div>
                </div><!-- //popup__header -->
                <div class="popup__content">
                    <div class="successefull__center">
                    <svg width="207" height="148" viewBox="0 0 207 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1920_17561)">
                        <path
                        d="M26.8573 136.696L26.3275 134.595C25.3882 134.195 24.4271 133.848 23.449 133.556L23.3821 134.533L23.1109 133.457C21.8978 133.109 21.0725 132.955 21.0725 132.955C21.0725 132.955 22.1872 137.218 24.5253 140.478L27.2497 140.959L25.1332 141.266C25.4274 141.622 25.7436 141.959 26.0799 142.275C29.4812 145.45 33.2696 146.907 34.5415 145.529C35.8134 144.151 34.0872 140.459 30.686 137.284C29.58 136.3 28.3305 135.492 26.9811 134.888L26.8573 136.696Z"
                        fill="#E6E6E6" />
                        <path
                        d="M31.8957 134.413L32.5222 132.339C31.9239 131.508 31.2794 130.711 30.5919 129.953L30.0327 130.754L30.3535 129.692C29.4941 128.764 28.8665 128.203 28.8665 128.203C28.8665 128.203 27.6296 132.432 27.956 136.438L30.0408 138.266L28.0712 137.429C28.1401 137.886 28.2376 138.339 28.3631 138.785C29.6428 143.271 32.1371 146.487 33.9342 145.969C35.7313 145.45 36.1507 141.393 34.871 136.907C34.4302 135.489 33.7759 134.148 32.9309 132.93L31.8957 134.413Z"
                        fill="#E6E6E6" />
                        <path
                        d="M69.769 136.184L69.2711 134.209C68.3883 133.833 67.485 133.507 66.5656 133.233L66.5028 134.151L66.2478 133.14C65.1077 132.812 64.332 132.668 64.332 132.668C64.332 132.668 65.3798 136.675 67.5773 139.738L70.1379 140.191L68.1486 140.479C68.4252 140.814 68.7224 141.131 69.0385 141.428C72.2352 144.412 75.7959 145.781 76.9913 144.486C78.1868 143.191 76.5644 139.721 73.3676 136.737C72.3281 135.812 71.1537 135.052 69.8854 134.485L69.769 136.184Z"
                        fill="#E6E6E6" />
                        <path
                        d="M74.5047 134.04L75.0936 132.09C74.5312 131.309 73.9255 130.56 73.2793 129.848L72.7537 130.601L73.0552 129.603C72.2475 128.73 71.6576 128.203 71.6576 128.203C71.6576 128.203 70.4951 132.178 70.8018 135.943L72.7613 137.661L70.9101 136.874C70.9749 137.304 71.0665 137.73 71.1844 138.148C72.3872 142.365 74.7316 145.388 76.4206 144.901C78.1097 144.414 78.504 140.6 77.3012 136.384C76.8868 135.051 76.2718 133.791 75.4777 132.646L74.5047 134.04Z"
                        fill="#E6E6E6" />
                        <path
                        d="M153.4 137.248L153.15 136.253C152.705 136.064 152.249 135.899 151.786 135.761L151.755 136.224L151.626 135.714C151.052 135.549 150.661 135.477 150.661 135.477C151.014 136.744 151.566 137.947 152.296 139.039L153.586 139.267L152.584 139.413C152.723 139.581 152.873 139.741 153.032 139.891C154.643 141.395 156.437 142.085 157.04 141.432C157.642 140.779 156.825 139.031 155.214 137.527C154.69 137.061 154.098 136.678 153.459 136.392L153.4 137.248Z"
                        fill="#E6E6E6" />
                        <path
                        d="M155.787 136.168L156.083 135.185C155.8 134.792 155.495 134.414 155.169 134.055L154.904 134.435L155.056 133.932C154.649 133.492 154.352 133.227 154.352 133.227C154.003 134.495 153.857 135.812 153.921 137.127L154.908 137.993L153.975 137.596C154.008 137.813 154.054 138.027 154.113 138.238C154.72 140.363 155.901 141.886 156.752 141.641C157.603 141.395 157.802 139.474 157.196 137.349C156.987 136.677 156.677 136.042 156.277 135.465L155.787 136.168Z"
                        fill="#E6E6E6" />
                        <path
                        d="M175.022 135.53L174.692 134.221C174.107 133.971 173.509 133.755 172.899 133.574L172.857 134.182L172.689 133.512C171.933 133.295 171.419 133.199 171.419 133.199C171.419 133.199 172.113 135.855 173.57 137.886L175.267 138.185L173.948 138.377C174.132 138.598 174.329 138.808 174.538 139.005C176.657 140.983 179.017 141.891 179.809 141.032C180.602 140.174 179.526 137.874 177.408 135.896C176.719 135.283 175.94 134.78 175.1 134.404L175.022 135.53Z"
                        fill="#E6E6E6" />
                        <path
                        d="M178.161 134.107L178.552 132.815C178.179 132.297 177.777 131.801 177.349 131.329L177.001 131.828L177.201 131.166C176.665 130.587 176.274 130.238 176.274 130.238C176.274 130.238 175.504 132.873 175.707 135.368L177.006 136.507L175.779 135.985C175.822 136.271 175.882 136.553 175.961 136.83C176.758 139.625 178.312 141.629 179.431 141.306C180.551 140.983 180.812 138.455 180.015 135.66C179.74 134.777 179.333 133.942 178.806 133.183L178.161 134.107Z"
                        fill="#E6E6E6" />
                        <path
                        d="M133.999 130.314L135.498 128.565C135.272 127.497 134.986 126.442 134.641 125.405L133.753 125.943L134.521 125.048C134.082 123.771 133.711 122.953 133.711 122.953C133.711 122.953 130.694 126.535 129.294 130.577L130.541 133.261L128.982 131.592C128.853 132.067 128.754 132.55 128.685 133.038C128.008 137.962 129.058 142.176 131.03 142.45C133.002 142.724 135.15 138.955 135.827 134.032C136.005 132.46 135.943 130.871 135.643 129.318L133.999 130.314Z"
                        fill="#E6E6E6" />
                        <path
                        d="M139.055 133.339L141.237 132.622C141.593 131.589 141.89 130.538 142.128 129.471L141.091 129.47L142.208 129.103C142.489 127.782 142.592 126.889 142.592 126.889C142.592 126.889 138.168 128.386 134.893 131.119L134.58 134.065L134.104 131.825C133.749 132.165 133.416 132.527 133.106 132.909C129.996 136.772 128.729 140.925 130.277 142.185C131.824 143.445 135.599 141.335 138.709 137.473C139.67 136.22 140.434 134.827 140.974 133.341L139.055 133.339Z"
                        fill="#E6E6E6" />
                        <path
                        d="M159.275 137.26L159.942 136.482C159.841 136.007 159.714 135.538 159.56 135.077L159.166 135.316L159.507 134.918C159.312 134.35 159.147 133.986 159.147 133.986C158.317 135.004 157.654 136.149 157.183 137.377L157.737 138.571L157.044 137.828C156.986 138.04 156.942 138.255 156.912 138.472C156.61 140.661 157.077 142.535 157.954 142.657C158.832 142.779 159.787 141.103 160.088 138.914C160.167 138.215 160.14 137.508 160.006 136.817L159.275 137.26Z"
                        fill="#E6E6E6" />
                        <path
                        d="M161.524 138.605L162.494 138.286C162.652 137.827 162.785 137.359 162.89 136.885L162.429 136.884L162.926 136.721C163.051 136.133 163.097 135.736 163.097 135.736C161.863 136.176 160.707 136.812 159.673 137.618L159.534 138.928L159.322 137.932C159.164 138.083 159.016 138.244 158.878 138.414C157.495 140.132 156.931 141.979 157.619 142.539C158.308 143.1 159.987 142.161 161.37 140.443C161.797 139.886 162.137 139.267 162.377 138.606L161.524 138.605Z"
                        fill="#E6E6E6" />
                        <path d="M0.124756 148.001C0.124756 148.001 163.128 131.102 207 148.001H0.124756Z" fill="#E6E6E6" />
                        <path
                        d="M207 44.8965C206.681 20.0136 194.059 0 178.534 0C169.204 0 160.922 7.22784 155.729 18.3965L161.835 33.8047L153.396 24.3075C151.268 30.7336 150.06 38.0703 150.06 45.8624C150.042 51.7687 150.765 57.6536 152.212 63.3779L168.576 60.7247L153.931 68.9605C158.702 82.1154 167.422 91.0999 177.473 91.6926L175.536 144.693L181.531 144.908L180.249 91.6395C194.562 90.2686 206.027 71.8708 206.946 48.8465L197.055 43.2841L207 44.8965Z"
                        fill="#E6E6E6" />
                        <path d="M103.34 43.3853L111.216 44.7788L113.842 31.5424L107.497 28.7559L103.34 43.3853Z" fill="#2F2E41" />
                        <path d="M112.811 45.5025L120.688 46.8957L123.314 33.6596L116.969 30.873L112.811 45.5025Z" fill="#2F2E41" />
                        <path d="M122.196 130.684V136.187H126.195L125.774 130.895L122.196 130.684Z" fill="#A0616A" />
                        <path d="M101.358 130.684V136.187H97.3589L97.7799 130.895L101.358 130.684Z" fill="#A0616A" />
                        <path
                        d="M102.831 79.6671L99.6338 99.2687C98.8196 104.26 98.3279 109.298 98.1618 114.353L97.5693 132.377L101.779 131.953L104.936 111.42C104.936 111.42 113.145 80.9372 116.092 87.0761C119.038 93.215 120.512 112.69 120.512 112.69L121.775 131.742H126.405L126.826 106.763C126.826 106.763 129.352 71.8347 123.037 68.8711L102.831 79.6671Z"
                        fill="#2F2E41" />
                        <path
                        d="M126.616 135.341C126.616 135.341 121.985 132.165 121.564 134.706L119.88 140.845C119.88 140.845 117.355 145.713 122.827 145.713C128.299 145.713 126.616 142.115 126.616 142.115V135.341Z"
                        fill="#2F2E41" />
                        <path
                        d="M96.938 135.341C96.938 135.341 101.569 132.165 101.99 134.706L103.673 140.845C103.673 140.845 106.199 145.713 100.727 145.713C95.2541 145.713 96.938 142.115 96.938 142.115V135.341Z"
                        fill="#2F2E41" />
                        <path
                        d="M114.513 40.6118C117.884 40.6118 120.617 37.8633 120.617 34.4729C120.617 31.0825 117.884 28.334 114.513 28.334C111.142 28.334 108.409 31.0825 108.409 34.4729C108.409 37.8633 111.142 40.6118 114.513 40.6118Z"
                        fill="#A0616A" />
                        <path
                        d="M110.619 37.1191C110.619 37.1191 111.25 43.0463 108.093 44.3165L113.355 50.667L120.301 45.7983C120.301 45.7983 115.25 44.7398 116.092 41.7762C116.933 38.8126 116.933 38.3893 116.933 38.3893L110.619 37.1191Z"
                        fill="#A0616A" />
                        <path
                        d="M109.251 43.5762C109.251 43.5762 113.671 46.9631 114.513 46.7514C115.355 46.5397 117.039 44.2112 117.039 44.2112C117.039 44.2112 123.248 44.3171 125.774 46.0106C128.299 47.704 123.353 58.1825 123.353 58.1825C123.353 58.1825 124.721 62.9454 123.037 64.2156C121.354 65.4857 123.458 68.661 123.458 68.661C123.458 68.661 119.249 76.4933 108.093 79.457C108.093 79.457 101.147 82.2089 101.358 80.7271C101.568 79.2453 103.673 62.3104 103.673 62.3104V44.7404L109.251 43.5762Z"
                        fill="#575A89" />
                        <path
                        d="M109.247 27.1758C109.247 27.1758 106.06 28.2444 105.898 32.4647C105.881 33.1397 105.956 33.8138 106.119 34.4687C106.299 35.2337 106.414 36.8798 105.023 39.031C104.091 40.4512 103.241 41.9232 102.474 43.44L102.464 43.4601L105.965 43.9002L106.71 38.064C107.071 35.2345 107.844 32.4738 109.004 29.8708L109.028 29.8165C109.028 29.8165 110.56 32.6773 113.623 32.6773L112.529 31.577C112.529 31.577 117.343 33.5575 119.093 33.5575C119.093 33.5575 120.406 34.4377 119.093 35.7581C117.781 37.0784 115.155 40.1592 116.687 42.5799C118.218 45.0005 116.905 45.6607 116.905 45.6607L119.312 44.3403L120.406 43.6802V44.5604L126.095 43.9002C126.095 43.9002 127.627 43.6802 124.345 39.9392C124.345 39.9392 123.478 38.4864 123.857 37.2335C124.169 36.2812 124.251 35.2681 124.096 34.2778C123.361 30.1524 120.476 21.9506 109.247 27.1758Z"
                        fill="#2F2E41" />
                        <path d="M85.3069 80.1973H142.592V112.131H135.316V120.913L127.477 112.131H85.3069V80.1973Z"
                        fill="#00A961" />
                        <path d="M94.7 91.3066H133.199V89.5769H94.7V91.3066Z" fill="white" />
                        <path d="M94.7 97.0293H133.199V95.2996H94.7V97.0293Z" fill="white" />
                        <path d="M94.7 102.75H133.199V101.02H94.7V102.75Z" fill="white" />
                        <path
                        d="M99.2532 61.6746L99.8846 66.3317L104.515 79.2445C104.515 79.2445 105.568 89.1938 108.093 87.9237C110.619 86.6535 107.672 77.7627 107.672 77.7627L103.884 65.6966L104.094 61.4629L99.2532 61.6746Z"
                        fill="#A0616A" />
                        <path
                        d="M106.199 45.1636L104.3 44.541L103.463 44.9519C103.463 44.9519 100.516 45.1636 100.095 47.2805C99.6741 49.3973 98.2007 62.5219 98.2007 62.5219L105.988 62.9452L106.199 45.1636Z"
                        fill="#575A89" />
                        <path
                        d="M127.352 61.6746L126.721 66.3317L122.09 79.2445C122.09 79.2445 121.038 89.1938 118.512 87.9237C115.986 86.6535 118.933 77.7627 118.933 77.7627L122.722 65.6966L122.511 61.4629L127.352 61.6746Z"
                        fill="#A0616A" />
                        <path
                        d="M120.406 45.1629L121.419 44.8309C121.987 44.6445 122.605 44.6877 123.143 44.9512C123.143 44.9512 126.089 45.1629 126.51 47.2798C126.931 49.3966 128.405 62.5212 128.405 62.5212L120.617 62.9445L120.406 45.1629Z"
                        fill="#575A89" />
                        <path d="M77.6861 4.06836H0V47.3745H9.8677V59.2836L20.498 47.3745H77.6861V4.06836Z" fill="#00A961" />
                        <path d="M64.9477 16.7891H12.7383V19.1348H64.9477V16.7891Z" fill="white" />
                        <path d="M64.9477 24.5488H12.7383V26.8946H64.9477V24.5488Z" fill="white" />
                        <path d="M64.9477 32.3066H12.7383V34.6524H64.9477V32.3066Z" fill="white" />
                        <path
                        d="M8.40909 64.8769C9.56903 64.8769 10.5094 63.9312 10.5094 62.7646C10.5094 61.598 9.56903 60.6523 8.40909 60.6523C7.24915 60.6523 6.30884 61.598 6.30884 62.7646C6.30884 63.9312 7.24915 64.8769 8.40909 64.8769Z"
                        fill="#E6E6E6" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1920_17561">
                        <rect width="207" height="148" fill="white" />
                        </clipPath>
                    </defs>
                    </svg>
                    <p>Your request has been sent, we are excited to take your request under consideration for our coming forums!</p>
                </div>
                </div>
                <div class="popup__footer">
                    <div class="btn-group btn-group_secondary content-center">
                    <a @click.prevent="closeForumTopicSuccessPopup" href="#" class="btn btn_primary">
                        <span class="btn__text">Okay</span>
                    </a>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store'

export default {
    name: "ForumTopicSuccessPopup",
    data() {
        return {
        }
    },
    methods: {
        closeForumTopicSuccessPopup() {
            store.dispatch('SHOW_POPUP', { popup: 'forumTopicSuccessPopup', show: false});
        },
    },
    computed: {
        popups: () => store.state.popups,
    },
    watch: {
    },
}
</script>
