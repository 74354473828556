
import calendarService from '../services/service.calendar'
import natsIO from '@/plugins/natsIO'

// import userProfileService from '@/services/service.userprofile'
// import axios from 'axios'
// import store from '../store'

const calendar = {
  state: {
    calendarEvents: '',
    calendarViewEvent: {},
    calendarRemoveEventId: '',
    prepareViewEvent: ''
  },
  mutations: {
    SET_CALENDAR_EVENT: (state, payload) => {
      state.calendarEvents = payload;
    },
    SET_VIEW_EVENT: (state, payload) => {
      state.calendarViewEvent = payload;
    },
    SET_ID_EVENT_REMOVE: (state, payload) => {
        state.calendarRemoveEventId = payload;
    },
    SET_PREPARE_VIEW_EVENT: (state, payload) => {
      state.prepareViewEvent = payload;
    }
  },
  actions: {
    async ADD_CALENDAR_EVENT({commit}, payload) {
        console.log(commit);
        const response = await calendarService.setEvent(payload);
        if (response.data.notifies) {
          response.data.notifies.forEach(notify => {
            natsIO.publishNotify(notify.to_user, notify);
          });
        }
        console.log(response);
    },
    async UPDATE_CALENDAR_EVENT({commit}, payload) {
        console.log(commit);
        const response = await calendarService.updateEvent(payload);
        if (response.data.notifies) {
          response.data.notifies.forEach(notify => {
            natsIO.publishNotify(notify.to_user, notify);
          });
        }
        console.log(response);
    },

    async GET_CALENDAR_EVENT({commit}) {
        const response = await calendarService.getEventMonth();
        const response_next = await calendarService.getEventNextMonth();
        if(response.status === 200) {
            if(response_next.status === 200) {
                for (let i = 0; i < response_next.data.length; i++) {
                    response.data.push(response_next.data[i]);
                }
            }
            commit('SET_CALENDAR_EVENT', response.data);
          }
    },

    async GET_CALENDAR_EVENT_MONTH({commit}, payload) {
      const response = await calendarService.getEventSelectedMonth(payload);
      if(response.status === 200) {
        commit('SET_CALENDAR_EVENT', response.data);
      }
    },

    SET_PREPARE_VIEW_EVENT({commit}, payload) {
      commit('SET_PREPARE_VIEW_EVENT', payload);
    },
    SET_VIEW_EVENT({commit}, payload) {
        let i = '';
        let options = { weekday: 'long'};
        for(i in this.state.calendar.calendarEvents) {
            let calEvent = this.state.calendar.calendarEvents[i];
            if(calEvent.id == payload) {
                let datetime = new Date(calEvent.event_datetime*1000);
                let datetimeEnd = new Date(calEvent.event_datetime*1000+calEvent.duration*60*1000);
                let data = {};
                data.title = calEvent.title;
                data.description = calEvent.description;

                data.date = new Intl.DateTimeFormat('en-US', options).format(datetime)+', '+datetime.getDate()+' '+datetime.toLocaleString('en-US', { month: 'long' })+' '+datetime.getFullYear();

                let hourStart = datetime.getHours();
                let minuteStart = datetime.getMinutes();
                let hourEnd = datetimeEnd.getHours();
                let minuteEnd = datetimeEnd.getMinutes();
                if(hourStart<10) hourStart = "0"+hourStart;
                if(minuteStart<10) minuteStart = "0"+minuteStart;
                if(hourEnd<10) hourEnd = "0"+hourEnd;
                if(minuteEnd<10) minuteEnd = "0"+minuteEnd;
                data.hour = hourStart+":"+minuteStart+" - "+hourEnd+":"+minuteEnd;
                let durationH = Math.floor(calEvent.duration/60);
                let durationM = calEvent.duration - Math.floor(calEvent.duration/60)*60;
                if(durationM<10) durationM = "0"+durationM;
                data.duration = durationH+":"+durationM;
                data.participants = calEvent.participants;
                data.attachment = calEvent.attachment;
                data.id = calEvent.id;
                data.data = calEvent;
                commit('SET_VIEW_EVENT', data);
                continue;
            }
        }
    },

    RESET_VIEW_EVENT({commit}) {
        commit('SET_VIEW_EVENT', {});
    },

    SET_ID_EVENT_REMOVE({commit}, payload) {
        commit('SET_ID_EVENT_REMOVE', payload);
    },

    async REMOVE_EVENT({commit, dispatch}, payload) {
        console.log(payload);
        let response = await calendarService.removeEvent(payload);
        if (response.data.notifies) {
          response.data.notifies.forEach(notify => {
            natsIO.publishNotify(notify.to_user, notify);
          });
        }
        commit('SET_ID_EVENT_REMOVE', '');
        dispatch('GET_CALENDAR_EVENT');
    }
  },
  getters: {
  }
}
export default calendar;