import instance from './index';
import suwAPI from '../config/config'

const pageService = {

  getPages() {
    return instance({ requiresAuth: true }).get(suwAPI.apiPage);
  },

  getProjects() {
    return instance({ requiresAuth: true }).get(suwAPI.apiPage+'/projects');
  },

  getHomePage() {
    return instance({ requiresAuth: true }).get(suwAPI.apiPage+'/home');
  },

  getPage(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPage+'/'+id);
  },

  getPageFiles(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPage+'/'+data.id+'/files?page='+data?.page+'&search='+data?.search+'&posts_type='+data?.posts_type);
  },

  storePage(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPage, data);
  },

  getAllMembers() {
    return instance({ requiresAuth: true }).get(suwAPI.apiPage+'/all-members');
  },

  searchUsersForInvite(data) {
    if(data.page) {
      return instance({ requiresAuth: true }).get(suwAPI.apiPage+'/search-users?search='+data.search+'&page_id='+data.page);
    } else {
      return instance({ requiresAuth: true }).get(suwAPI.apiPage+'/search-users?search='+data.search);
    }
  },

  addUsers(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPage+'/'+data.id+'/assign', data);
  },

  removeUsers(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPage+'/'+data.id+'/remove', {users: data.users});
  },

  getPosts(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPost+'/'+data.id+'?page='+data?.page+'&search='+data?.search+'&posts_type='+data?.posts_type);
  },

  storePost(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPost, data);
  },

  updatePost(data) {
    return instance({ requiresAuth: true }).put(suwAPI.apiPost+'/'+data.id, data);
  },

  updatePage(data) {
    return instance({ requiresAuth: true }).put(suwAPI.apiPage+'/'+data.id, data);
  },

  removePage(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiPage+'/'+id);
  },

  removePost(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiPost+'/'+id);
  },

  putFiles(data) {
    let formData = new FormData();
    data.forEach(function(file) {
      formData.append('files[]', file);
    });
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiPutFiles, formData);
  },

  removePostFile(id) {
    return instance({ requiresAuth: true, formData: true }).delete(suwAPI.apiPost+'/file/'+id);
  },

  getComments(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPostComment+'/'+id);
  },

  storeComment(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPostComment, data);
  },

  updateComment(data) {
    return instance({ requiresAuth: true }).put(suwAPI.apiPostComment+'/'+data.id, data);
  },

  removeComment(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiPostComment+'/'+id);
  },

  removeCommentFile(id) {
    return instance({ requiresAuth: true, formData: true }).delete(suwAPI.apiPostComment+'/file/'+id);
  },

  getCommentsWithSearch(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPostComment+'/'+data.id+'?search='+data?.search+'&comment='+data.comment_id);
  },

  getRepliesWithSearch(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPostComment+'/replies/'+data.id+'?search='+data?.search+'&comment='+data.comment_id);
  },

  getReplies(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPostComment+'/replies/'+id);
  },

  likePost(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPost+'/'+id+'/like/');
  },

  likeComment(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPostComment+'/'+id+'/like/');
  },

  savedPost(id) {
    return instance({ requiresAuth: true }).post(suwAPI.apiPost+'/'+id+'/saved/');
  },


  getTopicPost(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiPost+'/'+id+'/topic-post');
  },

}

export default pageService;