import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Cover from '@/pages/Cover'
import Forum from '@/pages/Forum'
import ForumTopic from '@/pages/ForumTopic'
import Experts from '@/pages/Experts'
import Investors from '@/pages/Investors'
import StartUps from '@/pages/StartUps'
import CRM from '@/pages/CRM'
import CRMUser from '@/pages/CRMUser'
import Jobs from '@/pages/Jobs'
import Payments from '@/pages/Payments'
import Contacts from '@/pages/Contacts'
import Page from '@/pages/Page'
import Post from '@/pages/Post'


export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'cover',
      component: Cover
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/pages/Home')
    },
    {
      path: '/forum',
      name: 'forum',
      component: Forum
    },
    {
      path: '/forum/:id',
      name: 'forumTopic',
      component: ForumTopic
    },
    {
      path: '/experts',
      name: 'experts',
      component: Experts
    },
    {
      path: '/investors',
      name: 'investors',
      component: Investors
    },
    {
      path: '/startups',
      name: 'startups',
      component: StartUps
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/pages/Notifications')
    },
    {
        path: '/crm/:id',
        name: 'crmUser',
        component: CRMUser
    },
    {
      path: '/crm',
      name: 'crm',
      component: CRM
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: Jobs
    },
    {
      path: '/payments',
      name: 'payments',
      component: Payments
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/pages/About')
    },
    {
      path: '/profile/:id',
      name: 'profile',
      component: () => import('@/pages/Profile')
    },
    {
      path: '/profile',
      name: 'profile_edit',
      component: () => import('@/pages/ProfileEdit')
    },
    {
      path: '/company_profile/:id',
      name: 'company_profile',
      component: () => import('@/pages/CompanyProfile')
    },
    {
      path: '/company_profile',
      name: 'company_profile_edit',
      component: () => import('@/pages/CompanyProfileEdit')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/pages/Home')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/pages/Register'),
    },
    {
      path: '/account_create/:key?',
      name: 'account_create',
      component: () => import('@/pages/AccountCreate')
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts
    },
    {
      path: '/confirm_email/:key?/:expires?/:signature?',
      name: 'confirm_email',
      props: true,
      component: () => import('@/pages/ConfirmEmail')
    },
    {
      path: '/cancel_request_joining/:uid?/:email?',
      name: 'cancel_request_joining',
      props: true,
      component: () => import('@/pages/ConfirmEmail')
    },
    {
      path: '/company/:company?/invitation/:invitation?/uid/:uid?',
      name: 'company',
      props: true,
      component: () => import('@/pages/CreationByInvitation')
    },
    {
      path: '/register_invitation',
      name: 'register_invitation',
      props: true,
      component: () => import('@/pages/RegisterByInvitation')
    },
    {
      path: '/forgot_password',
      name: 'forgot_password',
      component: () => import('@/pages/ForgotPassword')
    },
    {
      path: '/reset_password/:key?/:email?',
      name: 'reset_password',
      component: () => import('@/pages/ResetPassword')
    },
    {
      path: '/page/:id',
      name: 'page',
      component: Page
    },
    {
      path: '/post/:id',
      name: 'post',
      component: Post
    },
  ],
})

router.beforeEach((to, from, next) => {
  if(to.name !== from.name) store.commit('CLOSE_ALL_POPUPS');
  localStorage.setItem('route_name',to.name);
  localStorage.setItem('route_param_id',to?.params.id);
  store.commit('SET_ROUTE_IN_FORUM', to);
  store.commit('SET_SIDEBAR_MEDIA_OPEN', false);
  store.commit('SET_SIDEBAR_NOTIFICATION_OPEN', false);
  store.commit('SET_SIDEBAR_PAGES_OPEN', false);
  if(!localStorage.access_token){
    if( !['cover', 'register', 'account_create', 'confirm_email', 'forgot_password', 'reset_password', 'company', 'register_invitation', 'cancel_request_joining'].includes(to.name) ) {
      next({ name: 'cover' })
    }
  } else {
    if( ['cover', /* 'register', 'account_create', */ 'confirm_email', 'forgot_password', 'reset_password', 'cancel_request_joining'].includes(to.name) ) {
      next({ name: 'home' })
    }
  }
  next()
})