<template>
<div :class="{ 'popup-visible' : this.popups.successPopup }">
<div class="popup-wrap" @click.self="closePopup">
    <!-- // successfully -->
    <div class="popup successfully-popup" :class="{ 'active' : this.popups.successPopup }">
        <form action="#" class="popup__form">
            <div class="popup__header">
                <div class="popup__title">
                    <span class="title-text" v-html="this.popups.successPopup"></span>
                </div>
                <div @click.prevent="closePopup" class="popup-remove">
                    <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                              fill="#333333"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                              fill="#333333"/>
                    </svg>
                </div>
            </div><!-- //popup__header -->
            <div class="popup__content">
                <div class="popup__section">
                    <div class="wysiwyg">

                    </div>
                </div>
            </div>
            <div class="popup__footer">
                <div class="btn-group btn-group_secondary content-between">
                    <a href=""  @click.prevent="closeExperiencePopup" class=""></a>
                    <a href="#" @click.prevent="closePopup" class="btn btn_primary" ref="okBtn">
                        <span class="btn__text">Ok</span>
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
</template>

<script>
import store from '@/store'

export default {
    name: "SuccessPopup",
    methods: {
        closePopup() {
            store.dispatch('SHOW_POPUP', { popup: 'successPopup', show: false});
        },
    },
    computed: {
        popups: () => store.state.popups,
    },
    watch: {
        'popups.successPopup'(newVal) {
            if (newVal) setTimeout(() => this.$refs.okBtn.focus(), "500")
        },
    },
}
</script>
