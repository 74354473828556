<template>
  <div class="row">
    <div class="col col_8 col_tab-12">
      <div class="main">

        <div v-show="['home', 'forumTopic'].includes($route.name)" class="header__wysiwyg wysiwyg">
          <h2>
            <template v-if="['home'].includes($route.name)">Posts</template>
            <template v-else>{{this.page.title}}</template>
            <ToolTip
              :type="'top mw-460'"
              :msg="'In this section you can interact with your team via posting. You can post different tasks to be worked on, files such as power points, videos, pictures, that are being worked on or necessary for finishing a task, etc.<br><br>You can comment on, like, and save posts for later (click on the all posts button and switch to saved posts to view saved posts).<br><br>At the top of this section we have our search bar (right now it only gives results regarding the specific text in the posts, we will continue fixing this search section to make it as functional and useful as possible as we can)<br><br>To Add a post, click on the + Add Post button, write the text, add your files by clicking on the paper clip to the right of the add post button, and add post at the bottom left of the bubble (or press the enter button, Shift+Enter= line skip).<br><br>We will add more features to the posting section such as connecting them directly to calendar events and more to be coming.'"
            />
          </h2>
        </div>
        <div class="search">
          <div class="form-field form-field_tertiary">
            <div class="form-field__content">
              <input v-model="searchPosts" type="text" placeholder="Search ...." class="form-field__input">
              <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z"
                  fill="#333333"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z"
                  fill="#333333"></path>
              </svg>
              <svg @click="clearSearch" v-show="searchPosts.length" class="clear-search ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path></svg>
            </div>
          </div><!-- //form-field -->
        </div>

        <div class="section section_tertiary">
          <div class="section__header p_desktop_0 direction-row">
            <div class="btn-group btn-group_secondary">
              <a @click.prevent="showPostAddPopup" href="#" class="btn btn_primary btn_secondary-ico">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z"
                    fill="white"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z"
                    fill="white"></path>
                </svg>
                <span class="btn__text show popup-init" data-popupname="addtopic-popup">Add post</span>
              </a>
            </div>
            <div class="section__actions green_no-borders">
              <div class="form-field mb_0">
                <div class="form-field__content">
                  <div class="dropdown dropdown_secondary" :class="{ 'dropdown_active': openSelect }">
                    <div @click="openSelect = !openSelect" class="dropdown__init">
                      <span class="title-text">{{posts_type}}</span>
                      <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z"
                          fill="#00A961" />
                      </svg>
                    </div>
                    <div class="dropdown__content right">
                      <ul class="dropdown__list">
                        <li class="dropdown__item">
                          <a @click.prevent="filterPosts('all')" href="#" class="dropdown__link">All posts</a>
                        </li>
                        <li class="dropdown__item">
                          <a @click.prevent="filterPosts('saved')" href="#" class="dropdown__link">Saved posts</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div><!-- //form-field -->
            </div>
          </div>
        </div>

        <!-- main-content -->
        <div class="main__content">
          <div class="entrepreneur">


            <div class="section section_tertiary">

              <div class="posts">

                <div v-show="searchPosts.length >= 3" class="post__item">
                  <div class="post-item__header">
                    <h3>Results for "{{searchPosts}}"</h3>
                  </div>
                  <div class="post-item__content">
                    Found {{this.page.posts.length}} results
                  </div>
                </div>

                <div v-show="this.page.posts.length" class="post__list">

                  <div v-for="post in this.page.posts" :key='post.id'>
                    <PostView :post="post"></PostView>
                  </div>


                </div>

                <a @click.prevent="loadmore" v-show="page.meta.page < page.meta.total_pages" href="javascript:;"
                  class="btn btn_secondary">
                  <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_7883_838)">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.75 2.25C1.16421 2.25 1.5 2.58579 1.5 3V6.75H5.25C5.66421 6.75 6 7.08579 6 7.5C6 7.91421 5.66421 8.25 5.25 8.25H0.75C0.335786 8.25 0 7.91421 0 7.5V3C0 2.58579 0.335786 2.25 0.75 2.25Z"
                        fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12 10.5C12 10.0858 12.3358 9.75 12.75 9.75H17.25C17.6642 9.75 18 10.0858 18 10.5V15C18 15.4142 17.6642 15.75 17.25 15.75C16.8358 15.75 16.5 15.4142 16.5 15V11.25H12.75C12.3358 11.25 12 10.9142 12 10.5Z"
                        fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.92021 1.79729C8.13724 1.44527 9.42362 1.40727 10.6593 1.68686C11.895 1.96645 13.0397 2.55451 13.9867 3.39616C14.9336 4.23782 15.6519 5.30564 16.0745 6.49998C16.2127 6.89047 16.0082 7.31904 15.6177 7.45722C15.2272 7.5954 14.7986 7.39086 14.6605 7.00038C14.3224 6.0449 13.7477 5.19064 12.9902 4.51732C12.2326 3.844 11.3168 3.37355 10.3283 3.14988C9.33973 2.92621 8.31063 2.9566 7.337 3.23823C6.36338 3.51985 5.47696 4.04352 4.76047 4.76038C4.75492 4.76592 4.7493 4.77138 4.74358 4.77674L1.26358 8.04674C0.961725 8.33039 0.487081 8.31562 0.203437 8.01376C-0.0802062 7.7119 -0.0654396 7.23726 0.23642 6.95361L3.70808 3.69145C4.6022 2.79976 5.70705 2.1482 6.92021 1.79729ZM17.7966 9.9866C18.0802 10.2885 18.0654 10.7631 17.7636 11.0467L14.2919 14.3089C13.3978 15.2006 12.293 15.8522 11.0798 16.2031C9.86276 16.5551 8.57639 16.5931 7.3407 16.3135C6.10502 16.0339 4.9603 15.4458 4.01335 14.6042C3.06641 13.7625 2.3481 12.6947 1.92547 11.5004C1.78729 11.1099 1.99182 10.6813 2.38231 10.5431C2.77279 10.405 3.20136 10.6095 3.33954 11C3.67765 11.9555 4.25229 12.8097 5.00985 13.483C5.76741 14.1564 6.68318 14.6268 7.67173 14.8505C8.66028 15.0741 9.68938 15.0438 10.663 14.7621C11.6366 14.4805 12.523 13.9568 13.2395 13.24C13.2429 13.2366 13.2462 13.2333 13.2496 13.2301C13.2519 13.2279 13.2541 13.2258 13.2564 13.2236L16.7364 9.95361C17.0383 9.66997 17.5129 9.68474 17.7966 9.9866Z"
                        fill="#00A961" />
                    </g>
                    <defs>
                      <clipPath id="clip0_7883_838">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span class="btn__text">Load more</span>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col col_4 col_tab-12">
      <aside class="sidebar sidebar_secondary" :class="{'sidebar_active': (page.open_sidebar_media || forum.open_sidebar_notification)}">
        <!-- header -->
        <div @click="closeSidebarMedia" class="sidebar__header">
          <svg class="title-ico" width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.24408 4.4107C6.91864 4.73614 6.91864 5.26378 7.24408 5.58922L11.6548 9.99996L7.24408 14.4107C6.91864 14.7361 6.91864 15.2638 7.24408 15.5892C7.56951 15.9147 8.09715 15.9147 8.42259 15.5892L13.4226 10.5892C13.748 10.2638 13.748 9.73614 13.4226 9.4107L8.42259 4.4107C8.09715 4.08527 7.56951 4.08527 7.24408 4.4107Z"
              fill="#5D6167" />
          </svg>
          <span class="title-text">Back</span>
        </div>
        <!-- content -->
        <Files v-if="['home', 'page'].includes($route.name)"></Files>
        <Notifications v-if="['forumTopic'].includes($route.name)"></Notifications>
      </aside>
    </div>
  </div>

  <PostAddPopup></PostAddPopup>
  <ViewfilePopup></ViewfilePopup>
  <PostCommentEditPopup></PostCommentEditPopup>

</template>

<script>
  import store from '@/store'
  import func from '@/plugins/functions'
  import PostAddPopup from '@/components/popups/PostAdd'
  import ViewfilePopup from '@/components/popups/Viewfile'
  import PostCommentEditPopup from '@/components/popups/PostCommentEdit'
  import Files from '@/components/page/Files'
  import Notifications from '@/components/page/Notifications'
  import PostView from '@/components/page/PostView'
  import ToolTip from '@/components/ToolTip'

  export default {
      name: "Page",
      components: {
          PostAddPopup,
          ViewfilePopup,
          PostCommentEditPopup,
          Files,
          Notifications,
          PostView,
          ToolTip,
      },
      data() {
          return {
              openSelect: false,
          }
      },
      methods: {
          showPostAddPopup: function() {
              store.dispatch('SHOW_POPUP', { popup: 'postAddPopup', show: true});
          },
          loadmore() {
              store.dispatch('POSTS_LOAD_MORE');
          },

          filterPosts(type) {
            store.dispatch('SET_POSTS_TYPE', type);
            this.openSelect = false;
          },

          clearSearch() {
            store.commit('SET_POSTS_SEARCH', '');
          },

          searchPostsHandler() {
              store.dispatch('GET_POSTS_DATA');
          },

          closeSidebarMedia() {
            if (['home', 'page'].includes(this.$route.name))
              store.commit('SET_SIDEBAR_MEDIA_OPEN', false);
            else
              store.commit('SET_SIDEBAR_NOTIFICATION_OPEN', false);
          },
      },
      watch: {
          searchPosts: function () {
              this.debouncedSearchPosts();
          },
      },
      created() {
          this.debouncedSearchPosts = func.debounce(this.searchPostsHandler, 500);
      },
      computed: {
          page: () => store.state.page,
          forum: () => store.state.forum,
          userdata: () => store.state.user,
          posts_type() {
            return this.page.posts_type == 'all' ? 'All posts' : 'Saved posts';
          },
          searchPosts: {
            get() {
              return this.page.searchPosts;
            },
            set(value) {
              store.dispatch('SET_POSTS_SEARCH', value);
            }
          },
      },
  }
</script>

<style>
.post-item__content .files .files__list {
    flex-direction: row;
    flex-wrap: wrap;
}
</style>