<template>
  <div :class="{ 'popup-visible': this.popups.fullJobPopup }">
    <div class="popup-wrap" @click.self="closePopup">
      <div :class="{'active':this.popups.fullJobPopup, 'popup_posts' : job.type_popup == 'view'}" class="popup popup_tertiary mh_100">
        <template v-if="job.type_popup == 'view'">
          <div class="popup__header sticky_header">
            <div class="popup__title">
              <span class="title-text">Your Job Postings</span>
            </div>
            <div class="popup-action ml-auto" @click="closePopup">
              <div class="popup-action__item popup-action__item_remove">
                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="popup__content">
            <div v-if="job.my_jobs.length" class="posts jobs">
              <div class="posts__list">
                <CardJob v-for="job in job.my_jobs" :key="job.id" :job="job" :type="'my_jobs'"></CardJob>
              </div>
            </div>
            <div v-else class="wysiwyg align-center">
              <img class="mt100" src="@/assets/images/job-posting-empty.svg" alt="No content">
              <h3>No content to display yet</h3>
              <p class="mb20">Your posts that you add will be displayed here</p>
              <button @click.prevent="addJob" type="button" class="btn btn_primary"><span class="btn__text">Make Job Posting</span></button>
            </div>
          </div>
          <div v-if="job.my_jobs.length" class="popup__footer">
            <button @click.prevent="addJob" type="button" class="btn btn_primary"><span class="btn__text">Add Job Posting</span></button>
          </div>
        </template>
        <!-- Edit / Store Job -->
        <div v-else class="popup__form">
          <div class="popup__header multilevel">
            <div @click.prevent="setPopupType('view')" class="popup-action">
              <div class="popup-action__item_back">
                <svg class="ico" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.18036 0.96967C6.47325 1.26256 6.47325 1.73744 6.18036 2.03033L2.21068 6L6.18036 9.96967C6.47325 10.2626 6.47325 10.7374 6.18036 11.0303C5.88746 11.3232 5.41259 11.3232 5.11969 11.0303L0.619694 6.53033C0.326801 6.23744 0.326801 5.76256 0.619694 5.46967L5.11969 0.96967C5.41259 0.676777 5.88746 0.676777 6.18036 0.96967Z" fill="#00A961"></path>
                </svg>
                <span>Back</span>
              </div>
              <div class="popup-action__item popup-action__item_remove" @click="closePopup">
                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path>
                </svg>
              </div>
            </div>
            <div class="popup__title">
              <span v-if="job.type_popup == 'add'" class="title-text">Make job posting</span>
              <span v-else class="title-text">Edit your current job listing</span>
            </div>
            <div class="job__company__logo">
              <img v-if="userdata.user_profile.company.companylogo" :src="userdata.user_profile.company.companylogo.link" alt="Avatar">
              <img v-else-if="userdata.user_profile.avatar" :src="userdata.user_profile.avatar" alt="Avatar">
              <img v-else src="@/assets/images/no-logo.png" alt="Avatar">
            </div>
          </div>

          <div class="popup__content">
            <div class="row">
              <div class="col col_6 col_mob-12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label v-if="userdata.user_profile.companyname" class="form-field__title">Company name</label>
                    <label v-else class="form-field__title">Name</label>
                  </div>
                  <div class="form-field__content">
                    <input type="text" class="form-field__input" disabled
                      :value="userdata.user_profile.companyname ?? (userdata.user_profile.firstname +' '+ userdata.user_profile.lastname)">
                  </div>
                </div>
              </div>
              <div class="col col_6 col_mob-12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label class="form-field__title">Industry</label>
                  </div>
                  <div class="form-field__content">
                    <input type="text" class="form-field__input" disabled :value="userdata.user_profile.companyindustry">
                  </div>
                </div>
              </div>
              <div class="col col_6 col_mob-12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label class="form-field__title">Country</label>
                  </div>
                  <div class="form-field__content">
                    <input type="text" class="form-field__input" disabled :value="userdata.user_profile.companycountry">
                  </div>
                </div>
              </div>
              <div class="col col_6 col_mob-12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label class="form-field__title">City /Town</label>
                  </div>
                  <div class="form-field__content">
                    <input type="text" class="form-field__input" disabled :value="userdata.user_profile.companycity">
                  </div>
                </div>
              </div>

              <div class="col col_6 col_mob-12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label class="form-field__title">Type of employment*</label>
                  </div>
                  <div class="form-field__content">
                    <div :class="{'dropdown_active': selectToE}" class="dropdown">
                      <div @click.prevent="openDropDown('selectToE')" class="dropdown__init btn btn_select">
                        <span class="title-text">
                          {{ job.my_type_of_employment.filter(a => a.selected).length ?
                            job.my_type_of_employment.filter(a => a.selected).map(a => a.value).join(',') :
                            'Please select ...' }}
                        </span>
                        <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                        </svg>
                      </div>
                      <div class="dropdown__content">
                        <ul class="dropdown__list">
                          <li v-for="toe in job.my_type_of_employment" :key="toe.id" class="dropdown__item">
                            <div class="custom-check dropdown__link">
                              <label class="custom-check__label">
                                <input class="custom-check__input"
                                  v-model="toe.selected"
                                  type="checkbox" name="chekbox">
                                <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                    <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                  </g>
                                </svg>
                                <span class="custom-check__text">{{toe.value}}</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p class="form-errors" v-show="errors.toe">{{errors.toe}}</p>
                  </div>
                </div>
              </div>

              <div class="col col_6 col_mob-12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label class="form-field__title">Field of expertise*</label>
                  </div>
                  <div class="form-field__content">
                    <div :class="{'dropdown_active': selectFoE}" class="dropdown">
                      <div @click.prevent="openDropDown('selectFoE')" class="dropdown__init btn btn_select">
                        <span class="title-text">
                          {{ job.my_field_of_expertise.filter(a => a.selected).length ?
                            job.my_field_of_expertise.filter(a => a.selected).map(a => a.value).join(',') :
                            'Please select ...' }}
                        </span>
                        <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                        </svg>
                      </div>
                      <div class="dropdown__content">
                        <ul class="dropdown__list">
                          <li v-for="(foe, index) in job.my_field_of_expertise" :key="foe.id" class="dropdown__item">
                            <div class="custom-check dropdown__link">
                              <label class="custom-check__label">
                                <input class="custom-check__input" v-model="foe.selected"
                                  @change="inputChangeFoE(index)"
                                  type="checkbox" name="chekbox">
                                <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                    <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                  </g>
                                </svg>
                                <span class="custom-check__text">{{foe.value}}</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p class="form-errors" v-show="errors.foe">{{errors.foe}}</p>
                  </div>
                </div>
              </div>

              <div class="col col_12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label class="form-field__title">Job title*</label>
                  </div>
                  <div class="form-field__content">
                    <input type="text" placeholder="Please enter ..." class="form-field__input"
                      v-model="job.current_job.title" :class="{ 'input-errors' : this.errors.title }">
                    <p class="form-errors" v-show="errors.title">{{errors.title}}</p>
                  </div>
                </div>
              </div>

              <div class="col col_12">
                <div class="form-field">
                  <div class="form-field__header">
                    <label class="form-field__title">Description*</label>
                  </div>
                  <div class="form-field__content">
                    <textarea placeholder="Please enter ..." class="form-field__input"
                      v-model="job.current_job.description" :class="{ 'input-errors' : this.errors.description }"></textarea>
                    <p class="form-errors" v-show="errors.description">{{errors.description}}</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="popup__footer">
            <div class="btn-group btn-group_secondary content-between">
              <a v-if="job.type_popup == 'add'" @click.prevent="setPopupType('view')" href="#" class="btn btn_secondary popup-init">
                <span class="btn__text">Cancel</span>
              </a>
              <a v-if="job.type_popup == 'edit'" @click.prevent="deleteJob" href="#" class="btn btn_quaternary popup-init">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#EB5757"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#EB5757"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#EB5757"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#EB5757"></path>
                </svg>
                <span class="btn__text">Remove</span>
              </a>
              <a v-if="job.type_popup == 'edit'" @click.prevent href="#" class="btn btn_ico btn_ninefold ml-auto">
                <svg class="btn__ico" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.91663 2.91667C2.59446 2.91667 2.33329 3.17783 2.33329 3.5V11.6667C2.33329 11.9888 2.59446 12.25 2.91663 12.25H11.0833C11.4055 12.25 11.6666 11.9888 11.6666 11.6667V3.5C11.6666 3.17783 11.4055 2.91667 11.0833 2.91667H2.91663ZM1.16663 3.5C1.16663 2.5335 1.95013 1.75 2.91663 1.75H11.0833C12.0498 1.75 12.8333 2.5335 12.8333 3.5V11.6667C12.8333 12.6332 12.0498 13.4167 11.0833 13.4167H2.91663C1.95013 13.4167 1.16663 12.6332 1.16663 11.6667V3.5Z" fill="#5D6167"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33333 0.582031C9.6555 0.582031 9.91667 0.843198 9.91667 1.16536V3.4987C9.91667 3.82086 9.6555 4.08203 9.33333 4.08203C9.01117 4.08203 8.75 3.82086 8.75 3.4987V1.16536C8.75 0.843198 9.01117 0.582031 9.33333 0.582031Z" fill="#5D6167"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66671 0.582031C4.98887 0.582031 5.25004 0.843198 5.25004 1.16536V3.4987C5.25004 3.82086 4.98887 4.08203 4.66671 4.08203C4.34454 4.08203 4.08337 3.82086 4.08337 3.4987V1.16536C4.08337 0.843198 4.34454 0.582031 4.66671 0.582031Z" fill="#5D6167"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16663 5.83333C1.16663 5.51117 1.42779 5.25 1.74996 5.25H12.25C12.5721 5.25 12.8333 5.51117 12.8333 5.83333C12.8333 6.1555 12.5721 6.41667 12.25 6.41667H1.74996C1.42779 6.41667 1.16663 6.1555 1.16663 5.83333Z" fill="#5D6167"></path>
                </svg>
                <span class="btn__text">Latest update {{job.current_job.last_update}}</span>
              </a>
              <a @click.prevent="saveJob" href="#" class="btn btn_primary">
                <span class="btn__text">Save post</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import CardJob from "@/components/CardJob.vue"

  export default {
    name: 'FullJob',
    components: {
      CardJob,

    },
    data() {
      return {
        selectFoE: false,
        selectToE: false,
        errors: {
          title: '',
          description: '',
          toe: '',
          foe: '',
        },
      }
    },
    methods: {
      closePopup() {
        store.dispatch('SHOW_POPUP', { popup: 'fullJobPopup', show: false });
      },

      setPopupType(type) {
        store.commit('JOB_TYPE_POPUP_SET', type);
      },

      addJob() {
        store.commit('JOB_CLEAR_CURRENT');
        store.commit('JOB_TYPE_POPUP_SET', 'add');
      },

      inputChangeFoE(current) {
        let self = this;
        let count = 0;
        let indexes = [];
        this.job.my_field_of_expertise.forEach(function(item, index) {
          if (item.selected) {
            indexes.push(index);
            count ++;
            if (count > 4) {
              if (current == indexes[indexes.length - 2])
                self.job.my_field_of_expertise[indexes[indexes.length - 3]].selected = false;
              else
                self.job.my_field_of_expertise[indexes[indexes.length - 2]].selected = false;
            }
          }
        });
      },

      saveJob() {
        if (!this.job.my_type_of_employment.filter(a => a.selected).length) {
          this.errors.toe = 'Please select Type of employment.';
          return false;
        } else {
          this.errors.toe = '';
        }
        if (!this.job.my_field_of_expertise.filter(a => a.selected).length) {
          this.errors.foe = 'Please select Field of expertise.';
          return false;
        } else {
          this.errors.foe = '';
        }
        if (!this.job.current_job.title) {
          this.errors.title = 'The Job title field is required.';
          return false;
        } else {
          this.errors.title = '';
        }
        if (!this.job.current_job.description) {
          this.errors.description = 'The Description field is required.';
          return false;
        } else {
          this.errors.description = '';
        }

        store.dispatch('SAVE_JOB');
      },
      deleteJob() {
        store.commit('SET_JOB_TO_DELETE', this.job.current_job.id);
        store.dispatch('SHOW_POPUP', {
            popup: 'confirmPopup',
            show: 'Are you sure you want to delete this job?',
            route: '',
            name: 'JOB_DELETE',
            data: '',
        });
      },

      openDropDown(target) {
        let old = this[target];
          this.selectFoE = false;
          this.selectToE = false;
          this[target] = !old;
      },

      closeSelect(e) {
        if (!e.target.closest(".dropdown_active")) {
          this.selectFoE = false;
          this.selectToE = false;
        }
      },

    },
    computed: {
      userdata: () => store.state.user,
      popups: () => store.state.popups,
      job: () => store.state.job,
    },
    watch: {
      'popups.fullJobPopup'(newVal, oldVal) {
          if (newVal != oldVal) store.commit('JOB_CLEAR_CURRENT');
      },
    },
    created() {
      document.addEventListener('click', this.closeSelect);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.closeSelect)
    }
  }
</script>
