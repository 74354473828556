import instance from './index';
import suwAPI from '../config/config'

const employeeService = {

  getExperts(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiEmployee+'/experts?type='+data.type
      +'&search='+data?.search
      +'&rating='+data?.rating
      +'&foe='+data.foe
      +'&page='+data?.page);
  },

  getInvestors(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiEmployee+'/investors?type='+data.type
      +'&search='+data?.search
      +'&fof='+data?.fof
      +'&investment='+data?.investment
      +'&page='+data?.page);
  },

  getStartups(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiEmployee+'/startups?type='+data.type
      +'&search='+data?.search
      +'&industry='+data?.industry
      +'&funding='+data?.funding
      +'&page='+data?.page);
  },


  getUser(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/'+id+'/view');
  },

  getCompany(id) {
    return instance({ requiresAuth: true }).get(suwAPI.apiGetCompany+'/'+id);
  },

  getUserReviews(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiUserProfile+'/'+data.id+'/reviews/'+data.type);
  },

  deleteReview(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiUserProfile+'/reviews/'+id);
  },

  toggleFavorite(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiEmployee+'/favorite', data);
  },

  getFavorites(tab) {
    return instance({ requiresAuth: true }).get(suwAPI.apiEmployee+'/favorites/'+tab);
  },


}

export default employeeService;