<template>
  <div class="msg-notify" :class="{ 
    'msg-notify-visible' : this.notifications.popupNotify, 
    'msg-notify-success' : this.notifications.popupNotifyType == 'success',
    'msg-notify-error' : this.notifications.popupNotifyType == 'error',
    'msg-notify-info' : this.notifications.popupNotifyType == 'info',
    'msg-notify-warning' : this.notifications.popupNotifyType == 'warning'
    }"
    
    >
    <div class="popup__header">
      <!-- Success Icon -->
      <div v-if="this.notifications.popupNotifyType == 'success'" class="popup__title">
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.01657 3.21555C10.9096 2.78783 12.8902 2.98352 14.663 3.77342C15.1675 3.9982 15.7587 3.77146 15.9834 3.26699C16.2082 2.76252 15.9815 2.17134 15.477 1.94656C13.3103 0.981129 10.8896 0.74196 8.57581 1.26472C6.26206 1.78748 4.17929 3.04416 2.63811 4.84734C1.09693 6.65052 0.179919 8.90358 0.0238445 11.2705C-0.13223 13.6374 0.480993 15.9914 1.77206 17.9813C3.06312 19.9713 4.96285 21.4906 7.18792 22.3126C9.41299 23.1346 11.8442 23.2154 14.1189 22.5428C16.3936 21.8703 18.39 20.4804 19.8103 18.5806C21.2306 16.6807 21.9986 14.3721 22 12V11.08C22 10.5277 21.5523 10.08 21 10.08C20.4477 10.08 20 10.5277 20 11.08V11.9994C19.9989 13.9402 19.3705 15.8286 18.2084 17.3831C17.0464 18.9375 15.413 20.0746 13.5518 20.6249C11.6907 21.1752 9.70153 21.1091 7.88102 20.4365C6.06051 19.764 4.50619 18.5209 3.44987 16.8928C2.39354 15.2646 1.89181 13.3387 2.01951 11.4021C2.14721 9.46552 2.89749 7.62211 4.15845 6.14678C5.41942 4.67145 7.12351 3.64326 9.01657 3.21555ZM21.7075 4.70674C22.0978 4.31602 22.0975 3.68285 21.7068 3.29252C21.316 2.9022 20.6829 2.90251 20.2925 3.29323L10.9996 12.5954L8.70711 10.3029C8.31658 9.91235 7.68342 9.91235 7.29289 10.3029C6.90237 10.6934 6.90237 11.3266 7.29289 11.7171L10.2929 14.7171C10.4805 14.9047 10.7349 15.0101 11.0002 15.01C11.2656 15.0099 11.52 14.9044 11.7075 14.7167L21.7075 4.70674Z" fill="#00A961"/>
        </svg>
      </div>
      <!-- Error icon -->
      <div v-if="this.notifications.popupNotifyType == 'error'" class="popup__title">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 12C11.5523 12 12 11.5523 12 11V7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7V11C10 11.5523 10.4477 12 11 12ZM11 16C10.4477 16 10 15.5523 10 15C10 14.4477 10.4477 14 11 14H11.01C11.5623 14 12.01 14.4477 12.01 15C12.01 15.5523 11.5623 16 11.01 16H11Z" fill="#EB5757"/>
        </svg>
      </div>
      <!-- Info icon -->
      <div v-if="this.notifications.popupNotifyType == 'info'" class="popup__title">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10ZM11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8H11.01C11.5623 8 12.01 7.55228 12.01 7C12.01 6.44772 11.5623 6 11.01 6H11Z" fill="#1885F5"/>
        </svg>
      </div>
      <!-- Warning icon -->
      <div v-if="this.notifications.popupNotifyType == 'warning'" class="popup__title">
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.528 1.28306C10.9773 1.0301 11.4842 0.897217 11.9998 0.897217C12.5154 0.897217 13.0223 1.0301 13.4716 1.28306C13.9209 1.53601 14.2974 1.90049 14.5648 2.34133L14.5677 2.34608L23.0376 16.4861L23.0458 16.4999C23.3077 16.9535 23.4463 17.4678 23.4478 17.9917C23.4493 18.5155 23.3135 19.0306 23.0541 19.4857C22.7947 19.9408 22.4207 20.32 21.9692 20.5857C21.5177 20.8513 21.0046 20.9941 20.4808 20.9999L20.4698 21L3.51879 21C2.99498 20.9942 2.48182 20.8513 2.03035 20.5857C1.57887 20.32 1.20483 19.9408 0.945426 19.4857C0.686022 19.0306 0.550303 18.5155 0.55177 17.9917C0.553236 17.4678 0.691839 16.9535 0.953786 16.4999L0.961909 16.4861L9.43191 2.34609L10.2898 2.85995L9.43478 2.34133C9.70218 1.90049 10.0787 1.53601 10.528 1.28306ZM11.146 3.3766L2.68246 17.5058C2.59729 17.6556 2.55224 17.8249 2.55176 17.9973C2.55127 18.1719 2.59651 18.3436 2.68298 18.4953C2.76945 18.647 2.89413 18.7734 3.04462 18.8619C3.1938 18.9497 3.36317 18.9973 3.53617 19H20.4634C20.6364 18.9973 20.8058 18.9497 20.9549 18.8619C21.1054 18.7734 21.2301 18.647 21.3166 18.4953C21.403 18.3436 21.4483 18.1719 21.4478 17.9973C21.4473 17.8249 21.4023 17.6556 21.3171 17.5058L12.8548 3.37859L12.8536 3.3766C12.7645 3.23054 12.6395 3.10977 12.4904 3.02583C12.3406 2.94151 12.1716 2.89722 11.9998 2.89722C11.8279 2.89722 11.6589 2.94151 11.5092 3.02583C11.3601 3.10977 11.235 3.23054 11.146 3.3766ZM11.9998 6.99996C12.552 6.99996 12.9998 7.44768 12.9998 7.99996V12C12.9998 12.5522 12.552 13 11.9998 13C11.4475 13 10.9998 12.5522 10.9998 12V7.99996C10.9998 7.44768 11.4475 6.99996 11.9998 6.99996ZM11.9998 15C11.4475 15 10.9998 15.4477 10.9998 16C10.9998 16.5522 11.4475 17 11.9998 17H12.0098C12.562 17 13.0098 16.5522 13.0098 16C13.0098 15.4477 12.562 15 12.0098 15H11.9998Z" fill="#F2994A"/>
        </svg>
      </div>
        <span class="title-text">{{ this.notifications.popupNotifyMsg }}</span>
    </div>
    <div class="popup-remove" @click.prevent="closeCalendarEventPopup">
        <!-- Success Close Icon -->
        <div v-if="this.notifications.popupNotifyType == 'success'" @click.prevent="closeSystemMessage">
          <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                  fill="#00A961"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                  fill="#00A961"/>
          </svg>
        </div>
        <!-- Error Close Icon -->
        <div v-if="this.notifications.popupNotifyType == 'error'" @click.prevent="closeSystemMessage">
          <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                  fill="#EB5757"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                  fill="#EB5757"/>
          </svg>
        </div>
        <!-- Info Close Icon -->
        <div v-if="this.notifications.popupNotifyType == 'info'" @click.prevent="closeSystemMessage">
          <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                  fill="#1885F5"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                  fill="#1885F5"/>
          </svg>
        </div>
        <!-- Warning Close Icon -->
        <div v-if="this.notifications.popupNotifyType == 'warning'" @click.prevent="closeSystemMessage">
          <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                  fill="#F2994A"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                  fill="#F2994A"/>
          </svg>
        </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "SystemMessage",
  data() {
    return {
    }
  },
  methods: {
    closeSystemMessage: function() {
      store.dispatch('SHOW_POPUP_NOTIFY', { type: false, msg: false, show: false });
    }
  },
  computed: {
    company: () => store.state.company,
    notifications: () => store.state.notifications,
  }
}
</script>