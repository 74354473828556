<template>
<div class="post__item">
  <div class="post-item__header">
    <div class="post-item__avatar">
      <img v-if="post.author.avatar" :src="post.author.avatar" alt="" />
      <img v-else src="@/assets/images/avatar-default.png" alt="Start-Up World" />
    </div>
    <div class="wysiwyg">
      <router-link :to="{name: 'profile', params: { id: post.author.id}}" class="">
        <h3>{{post.author.name}}</h3>
      </router-link>
      <ul>
        <li>{{post.author.position}}</li>
        <li>
          <router-link :to="{name: 'company_profile', params: {id: post.author.company_id }}">
            {{ post.author.companyname }}
          </router-link>
        </li>
        <li>{{post.author.country}}, {{post.author.city}}</li>
        <li>{{post.date}}</li>
      </ul>
    </div>

    <div v-if="userdata.user_profile.id == post.author.id" class="post-action ml-auto">
      <a @click.prevent="editPost" href="#" class="post-action__item post-action__item_edit">
        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z"
            fill="#00A961"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15 2.1582C14.777 2.1582 14.5631 2.24681 14.4053 2.40452L7.42724 9.38263L7.03079 10.9684L8.61658 10.572L15.5947 3.59386C15.7524 3.43615 15.841 3.22224 15.841 2.99919C15.841 2.77615 15.7524 2.56224 15.5947 2.40452C15.437 2.24681 15.2231 2.1582 15 2.1582ZM13.3447 1.34386C13.7837 0.904842 14.3791 0.658203 15 0.658203C15.6209 0.658203 16.2163 0.904842 16.6553 1.34386C17.0944 1.78288 17.341 2.37832 17.341 2.99919C17.341 3.62006 17.0944 4.2155 16.6553 4.65452L9.53035 11.7795C9.43423 11.8756 9.31379 11.9438 9.18192 11.9768L6.18192 12.7268C5.92634 12.7907 5.65597 12.7158 5.46969 12.5295C5.2834 12.3432 5.20851 12.0729 5.27241 11.8173L6.02241 8.81729C6.05538 8.68542 6.12357 8.56498 6.21969 8.46886L13.3447 1.34386Z"
            fill="#00A961"></path>
        </svg>
      </a>
      <a @click.prevent="deletePost" href="#" class="post-action__item post-action__item_trash">
        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z"
            fill="#00A961"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z"
            fill="#00A961"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
            fill="#00A961"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z"
            fill="#00A961"></path>
        </svg>
      </a>
    </div>
  </div>
  <div class="post-item__content overflow">
    <div class="wysiwyg" :class="{'full' : read_more_open}">
      <div class="pre_wrap" ref="post" v-html="highLight(post.text)"></div>
    </div>

    <div v-if="big_text" class="readmore_btns">
      <a @click.prevent="toggleReadMore" href="#">Read <span v-if="read_more_open">less</span> <span v-else>more</span></a>
    </div>

    <div v-show="post.files.length" class="files">
      <div class="files__list">
        <div v-for="file in post.files" :key="file.id" class="files__item">
          <a href="#" @click.prevent="showAttach(file)" class="files__link">
            <svg v-show="file.ext_type === 'video'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5932 4.58313C17.843 4.71168 18 4.96906 18 5.25V12.75C18 13.0309 17.843 13.2883 17.5932 13.4169C17.3434 13.5454 17.0427 13.5236 16.8141 13.3603L11.5641 9.6103C11.367 9.46952 11.25 9.24222 11.25 9C11.25 8.75779 11.367 8.53049 11.5641 8.3897L16.8141 4.6397C17.0427 4.47641 17.3434 4.45457 17.5932 4.58313ZM13.2903 9L16.5 11.2926V6.70739L13.2903 9Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V12.75C1.5 13.1642 1.83579 13.5 2.25 13.5H10.5C10.9142 13.5 11.25 13.1642 11.25 12.75V5.25C11.25 4.83579 10.9142 4.5 10.5 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H10.5C11.7426 3 12.75 4.00736 12.75 5.25V12.75C12.75 13.9926 11.7426 15 10.5 15H2.25C1.00736 15 0 13.9926 0 12.75V5.25Z" fill="#00A961" />
            </svg>
            <svg v-show="file.ext_type === 'document'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
            </svg>
            <span v-if="file.ext_type === 'image'">
              <img :src="file.link" style="max-height: 60px;">
              <div class="files__text">{{file.user_defined_filename}}</div>
            </span>
            <span v-else class="files__text">{{file.user_defined_filename}}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="btn-group">
      <div class="btn-group btn-group_secondary">
        <a v-if="show_comments" @click.prevent="showComments(false)" href="#" class="btn btn_ninefold">
          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V13.9393L4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 11.921 14.7803 11.7803C14.921 11.6397 15 11.4489 15 11.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H3.75ZM2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V11.25C16.5 11.8467 16.2629 12.419 15.841 12.841C15.419 13.2629 14.8467 13.5 14.25 13.5H5.56066L2.78033 16.2803C2.56583 16.4948 2.24324 16.559 1.96299 16.4429C1.68273 16.3268 1.5 16.0533 1.5 15.75V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901Z"
              fill="#00A961" />
          </svg>
          <span class="btn__text d-none d-block-tab">Hide comments</span>
          <span v-if="post.comments > 0" class="btn__text"> ({{post.comments}})</span>
        </a>
        <a v-else-if="post.search_exist" @click.prevent="expandAfterSearch" href="#" class="btn">
          <span class="btn__text">View thread</span>
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9.74051 2.73237C9.48016 2.99272 9.48016 3.41483 9.74051 3.67518L12.6024 6.53711L9.74051 9.39904C9.48016 9.65939 9.48016 10.0815 9.74051 10.3418C10.0009 10.6022 10.423 10.6022 10.6833 10.3418L14.0167 7.00851C14.277 6.74816 14.277 6.32605 14.0167 6.06571L10.6833 2.73237C10.423 2.47202 10.0009 2.47202 9.74051 2.73237Z"
              fill="#00A961" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14.2119 6.53776C14.2119 6.16957 13.9134 5.87109 13.5452 5.87109L5.54525 5.87109C4.66119 5.87109 3.81335 6.22228 3.18822 6.8474C2.5631 7.47253 2.21191 8.32037 2.21191 9.20443L2.21191 13.8711C2.21191 14.2393 2.51039 14.5378 2.87858 14.5378C3.24677 14.5378 3.54525 14.2393 3.54525 13.8711L3.54525 9.20443C3.54525 8.67399 3.75596 8.16529 4.13103 7.79021C4.50611 7.41514 5.01481 7.20443 5.54525 7.20443L13.5452 7.20443C13.9134 7.20443 14.2119 6.90595 14.2119 6.53776Z"
              fill="#00A961" />
          </svg>
        </a>
        <a v-else @click.prevent="showComments(true)" href="#" class="btn">
          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V13.9393L4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 11.921 14.7803 11.7803C14.921 11.6397 15 11.4489 15 11.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H3.75ZM2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V11.25C16.5 11.8467 16.2629 12.419 15.841 12.841C15.419 13.2629 14.8467 13.5 14.25 13.5H5.56066L2.78033 16.2803C2.56583 16.4948 2.24324 16.559 1.96299 16.4429C1.68273 16.3268 1.5 16.0533 1.5 15.75V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901Z"
              fill="#00A961" />
          </svg>
          <span v-if="post.comments > 0" class="btn__text d-none d-block-tab">Show comments</span>
          <span v-if="post.comments > 0" class="btn__text"> ({{post.comments}})</span>
          <span v-else class="btn__text d-none d-block-tab">Add comment</span>
        </a>

        <a @click.prevent="likePost" href="#" class="btn" :class="{'btn_ninefold': !post.liked}">
          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.56464 1.1954C7.68502 0.92455 7.95361 0.75 8.25 0.75C9.04565 0.75 9.80871 1.06607 10.3713 1.62868C10.9339 2.19129 11.25 2.95435 11.25 3.75V6H14.7412C15.0657 5.997 15.3871 6.06425 15.6833 6.19717C15.9808 6.33072 16.2458 6.52737 16.4598 6.7735C16.6739 7.01963 16.8318 7.30935 16.9228 7.62258C17.0137 7.93582 17.0355 8.26508 16.9865 8.58755L15.9515 15.3375C15.9515 15.3376 15.9516 15.3373 15.9515 15.3375C15.87 15.8737 15.5975 16.3631 15.1842 16.7145C14.7719 17.0649 14.2471 17.255 13.7061 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V9.75C0.75 9.15326 0.987053 8.58097 1.40901 8.15901C1.83097 7.73705 2.40326 7.5 3 7.5H4.7626L7.56464 1.1954ZM6 8.40916L8.70587 2.32095C8.93191 2.39306 9.13978 2.51846 9.31066 2.68934C9.59196 2.97064 9.75 3.35218 9.75 3.75V6.75C9.75 7.16421 10.0858 7.5 10.5 7.5H14.745L14.7535 7.49995C14.8622 7.49872 14.9699 7.52114 15.0691 7.56566C15.1683 7.61018 15.2566 7.67573 15.3279 7.75777C15.3993 7.83981 15.4519 7.93639 15.4822 8.0408C15.5125 8.14511 15.5198 8.25476 15.5035 8.36215C15.5036 8.36205 15.5035 8.36225 15.5035 8.36215L14.4685 15.1125C14.4414 15.2914 14.3505 15.4544 14.2127 15.5715C14.0749 15.6887 13.8994 15.7521 13.7185 15.75L6 15.75V8.40916ZM4.5 15.75V9H3C2.80109 9 2.61032 9.07902 2.46967 9.21967C2.32902 9.36032 2.25 9.55109 2.25 9.75V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H4.5Z"
              fill="#00A961" />
          </svg>
          <span class="btn__text">{{ post.liked ? 'Liked' : 'Like' }} {{post.likes ? '('+post.likes+')' : ''}}</span>
        </a>
        <a @click.prevent="savedPost" href="#" class="btn" :class="{'btn_ninefold': !post.saved}">
          <svg class="btn__ico" width="12" height="16" viewBox="0 0 12 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.25 2C2.05109 2 1.86032 2.07902 1.71967 2.21967C1.57902 2.36032 1.5 2.55109 1.5 2.75V13.2926L5.56407 10.3897C5.82484 10.2034 6.17516 10.2034 6.43593 10.3897L10.5 13.2926V2.75C10.5 2.55109 10.421 2.36032 10.2803 2.21967C10.1397 2.07902 9.94891 2 9.75 2H2.25ZM0.65901 1.15901C1.08097 0.737053 1.65326 0.5 2.25 0.5H9.75C10.3467 0.5 10.919 0.737053 11.341 1.15901C11.7629 1.58097 12 2.15326 12 2.75V14.75C12 15.0309 11.843 15.2883 11.5932 15.4169C11.3434 15.5454 11.0427 15.5236 10.8141 15.3603L6 11.9217L1.18593 15.3603C0.957318 15.5236 0.656617 15.5454 0.406814 15.4169C0.157011 15.2883 0 15.0309 0 14.75V2.75C0 2.15326 0.237053 1.58097 0.65901 1.15901Z"
              fill="#00A961" />
          </svg>
          <span class="btn__text">Save for Later</span>
        </a>
      </div>
    </div>
  </div>

<Comments v-show="show_comments" :post="post.id"></Comments>

</div>
</template>

<script>
import store from '@/store'
import Comments from '@/components/page/Comments'
import func from '@/plugins/functions'

export default {
  name: "PostView",
  components: {
    Comments,
  },
  props: ['post'],
  data() {
    return {
      openSelect: false,
      show_comments: false,
      big_text: false,
      read_more_open: false,
    }
  },
  methods: {
    showComments(status) {
      if(status) {
        store.dispatch('GET_POST_COMMENTS', this.post.id);
      }
      this.show_comments = status;
    },
    expandAfterSearch() {
      store.dispatch('GET_POST_COMMENTS_BY_SEARCH', {
        id: this.post.id,
        search: this.page.searchPosts,
        comment_id: this.page.comment_to_highlight
      });
      this.show_comments = true;
    },
    editPost() {
      let data = {
        type: 'edit',
        text: this.post.text,
        files: this.post.files,
        post_id: this.post.id,
      };
      store.dispatch('SHOW_POPUP', { popup: 'postAddPopup', show: true, data: data});
    },
    deletePost() {
      store.dispatch('SET_POST_TO_DELETE', this.post.id);
      store.dispatch('SHOW_POPUP', {
        popup: 'confirmPopup',
        show: 'Are you sure you want to delete this post?',
        route: '',
        name: 'REMOVE_POST',
        data: '',
      });
    },

    likePost() {
      store.dispatch('LIKE_POST', this.post.id);
    },

    savedPost() {
      store.dispatch('SAVED_POST', this.post.id);
    },

    showAttach: function(attachment) {
      if (attachment.type === 'link') {
        window.open(attachment.link, '_blank');
        return;
      }
      store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
    },

    highLight(text) {
      return func.highLight(text, this.page.searchPosts);
    },

    checkHeight() {
      this.$nextTick(function () {
        if (this.$refs.post.scrollHeight > 142) {
          this.big_text = true;
        } else {
          this.big_text = false;
        }
      })
    },
    toggleReadMore() {
      this.read_more_open = !this.read_more_open;
    },

  },
  computed: {
    page: () => store.state.page,
    userdata: () => store.state.user,
  },
  watch: {
    'post.id'(newVal, oldVal) {
      if (newVal != oldVal) this.show_comments = false;
    },
    'page.comment_to_highlight'(newVal, oldVal) {
      if (newVal != oldVal) this.show_comments = false;
    },
  },
  updated() {
    this.checkHeight();
  },
  mounted() {
    this.checkHeight();
  },
}
</script>

<style>
.post-item__content .files .files__list {
    flex-direction: row;
    flex-wrap: wrap;
}
</style>