<template>
    <div :class="{ 'popup-visible' : this.popups.postPersonalMessagePopup }">
        <div class="popup-wrap" @click.self="closePersonalMessagePopup">

            <!-- // post-add -->
            <div class="popup post-add-popup" :class="{ 'active' : this.popups.postPersonalMessagePopup }">
                <form action="#" class="popup__form">
                    <div v-if="this.chatSpinner" class="chat_spinner_wrap chat_spinner_popup chat_spinner_msg ">
                        <div class="chat_spinner"></div>
                    </div>
                    <div class="popup__header">
                        <div class="popup__title">
                            <span class="title-text">Send message</span>
                        </div>
                        <div class="popup-action ml-auto">
                            <div class="popup-action__item popup-action__item_remove" @click.prevent="closePersonalMessagePopup">
                                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333" />
                                </svg>
                            </div>
                        </div>
                    </div><!-- //popup__header -->
                    <div class="popup__content">
                        <div class="form-field">
                            <div class="form-field__header">
                                <label class="form-field__title">Message</label>
                            </div>
                            <div class="form-field__content border-bug">
                                <textarea placeholder="Write message" class="form-field__input"
                                    v-model="this.popups.postPersonalMessageData.text"
                                    ref="inputField"
                                    style="height:160px;"
                                ></textarea>
                            </div>
                            <Emoji @insert-emoji="insertEmoji" :show-emoji="showEmoji" style="bottom: -15px;left: 0;height:220px;"></Emoji>
                        </div>
                        <div class="attach direction-row">
                            <div @click.prevent="uploadInit" class="attach__init attach__init_secondary">
                                <svg class="ico" width="20" height="20" viewBox="0 0 20 20" fill="#BFC8D1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1499 1.98991C12.4864 1.98991 11.85 2.2535 11.3809 2.72268L3.72253 10.381C2.9406 11.1629 2.50132 12.2235 2.50132 13.3293C2.50132 14.4351 2.9406 15.4956 3.72253 16.2775C4.50445 17.0594 5.56496 17.4987 6.67077 17.4987C7.77658 17.4987 8.83709 17.0594 9.61901 16.2775L17.2773 8.61917C17.6028 8.29374 18.1304 8.29374 18.4559 8.61917C18.7813 8.94461 18.7813 9.47225 18.4559 9.79768L10.7975 17.456C9.70304 18.5505 8.2186 19.1654 6.67077 19.1654C5.12294 19.1654 3.6385 18.5505 2.54401 17.456C1.44953 16.3615 0.834656 14.8771 0.834656 13.3293C0.834656 11.7814 1.44953 10.297 2.54401 9.20251L10.2023 1.54417C10.9841 0.762424 12.0444 0.323242 13.1499 0.323242C14.2555 0.323242 15.3158 0.762424 16.0975 1.54417C16.8793 2.32592 17.3185 3.3862 17.3185 4.49176C17.3185 5.59732 16.8793 6.6576 16.0975 7.43935L8.43086 15.0977C7.96184 15.5667 7.32572 15.8302 6.66244 15.8302C5.99915 15.8302 5.36303 15.5667 4.89401 15.0977C4.425 14.6287 4.16151 13.9925 4.16151 13.3293C4.16151 12.666 4.425 12.0299 4.89401 11.5608L11.9694 4.49383C12.295 4.16858 12.8226 4.16889 13.1479 4.49452C13.4731 4.82015 13.4728 5.34779 13.1472 5.67303L6.07253 12.7394C5.91629 12.8958 5.82818 13.1082 5.82818 13.3293C5.82818 13.5505 5.91607 13.7627 6.07253 13.9192C6.22898 14.0756 6.44118 14.1635 6.66244 14.1635C6.8837 14.1635 7.09589 14.0756 7.25235 13.9192L14.919 6.26084C15.388 5.79168 15.6518 5.15514 15.6518 4.49176C15.6518 3.82823 15.3882 3.19187 14.919 2.72268C14.4498 2.2535 13.8135 1.98991 13.1499 1.98991Z" fill="#BFC8D1" />
                                </svg>
                            </div>
                            <span class="chatFooterSmile black" :class="{'active': showEmoji}" @click.prevent="toggleEmoji" style="margin-top:5px;"></span>
                        </div>
                    </div>

                    <div class="popup__footer">
                        <div class="attach">
                            <div class="attach-item__input">
                                <input type="file" id="file" class="input" multiple
                                accept=".pdf,.doc,.docx,.odt,.xls,.xlsx,.ods,.ppt,.ppts,.odp,.jpeg,.jpg,.png,.gif,.mp4" ref="file"
                                @change.prevent="handleFileUpload">
                            </div>
                            <div v-show="this.files" class="attach__list">
                                <div v-for="(file, index) in this.files" :key="file.id" class="attach__item attach-item">
                                <div class="attach-item__ico">
                                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="#BFC8D1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961" />
                                    </svg>
                                </div>
                                <div class="attach-item__title">
                                    <span class="title-text">{{file.name}}</span>
                                </div>
                                <div @click.prevent="removeFile(index)" class="attach-item__remove">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="#BFC8D1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#333333" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#333333" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#333333" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#333333" />
                                    </svg>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-group btn-group_secondary content-between">
                            <a href=""  @click.prevent="closePersonalMessagePopup" class="btn btn_secondary">
                                <span class="btn__text">Cancel</span>
                            </a>
                            <a href="#" @click.prevent="sendMsg" class="btn btn_primary">
                                <span class="btn__text">Send message</span>
                            </a>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import func from '@/plugins/functions'
import Emoji from '@/components/chat/Emoji'

export default {
    name: "SendMessagePopup",
    components: {
        Emoji,
    },
    data() {
        return {
            files: [],
            showEmoji: false,
            chatSpinner: false,
        }
    },
    methods: {
        closePersonalMessagePopup() {
            this.files = [];
            store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: false});
        },
        async sendMsg() {
            this.chatSpinner = true;
            if(this.files.length > 0) {
                await store.dispatch('PUT_MSG_FILES', this.files);
            }
            let data = {
                message: this.popups.postPersonalMessageData.text,
                recipient: this.popups.postPersonalMessageData.recipient,
                type: this.popups.postPersonalMessageData.type ?? 'user',
                sender: this.userdata.user_profile.id,
                sender_fullname: this.userdata.user_profile.firstname+' '+this.userdata.user_profile.lastname,
            };
            if(this.files) {
                data.attachment_ids = this.chat.put_files;
            }
            await store.dispatch('ADD_MESSAGE', data);
            store.dispatch('CHAT_CLEAR_PUT_FILES');
            this.files = [];
            this.chatSpinner = false;
            store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: false});
        },
        uploadInit() {
            const elem = this.$refs.file;
            elem.click();
        },
        handleFileUpload() {
            this.$refs.file.files
            for (let i = 0; i < this.$refs.file.files.length; i++) {
                this.files.push(this.$refs.file.files[i]);
            }
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        inputEvent(event){
            func.inputEvent(event, this.sendMsg, (()=>this.popups.postPersonalMessageData.text += "\n"))
        },
        toggleEmoji() {
            this.showEmoji = !this.showEmoji;
        },
        insertEmoji(data) {
            let index = this.$refs.inputField.selectionStart;
            this.popups.postPersonalMessageData.text = this.$refs.inputField.value.slice(0, index) + data+ this.$refs.inputField.value.slice(index);
            this.showEmoji = false;
        },
    },
    computed: {
        popups: () => store.state.popups,
        userdata: () => store.state.user,
        chat: () => store.state.chat,
    },
    watch: {
        'popups.postPersonalMessagePopup'(newVal) {
            if (newVal) {
                this.$refs.inputField.addEventListener('keydown', this.inputEvent);
                setTimeout(() => this.$refs.inputField.focus(), "500");
            } else {
                this.$refs.inputField.removeEventListener('keydown', this.inputEvent);
            }
        },
    },
    created() {
        this.files = [];
    },
}
</script>
