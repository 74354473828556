<template>
    <div class="middle middle_login login">
        <div class="row">
            <div class="col col_6 col_tab-12">
                <login></login>
            </div>
            <div class="col col_6 col_tab-12">
                <sign-on-right></sign-on-right>
            </div>
        </div>
    </div>
</template>

<script>
import Login from '../components/Login.vue'
import SignInRight from '../components/SignInRight.vue'

export default {
    name: 'Cover',
    components: {
        "login":Login,
        'sign-on-right':SignInRight,
    },
    props: {
        msg: String,
    },
    mounted() {
        if(!localStorage.access_token) {
            // this.$router.push('/')
        }
    }
    
}
</script>
