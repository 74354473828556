import instance from './index';
import suwAPI from '../config/config'

const crmService = {

  getAllContacts() {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMContacts+'/all');
  },

  getSearchAllContacts(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMCard+'search-all/'+data);
  },





  getContacts() {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMContacts);
  },

  setContact(data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiCRMContacts, data);
  },

  removeContact(data) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiCRMContacts+'/'+data.contact_id);
  },

  getSearchContacts(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMCard+'search/'+data);
  },

  getCardInfo(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMCard+'/'+data);
  },

  postCardMyNote(id, data) {
    delete data.card_id;
    delete data.file;
    return instance({ requiresAuth: true }).post(suwAPI.apiCRMCard+'/'+id+'/mynotes', data);
  },

  editCardMyNote(id, data) {
    delete data.card_id;
    delete data.file;
    return instance({ requiresAuth: true }).put(suwAPI.apiCRMCard+'/'+id+'/mynotes', data);
  },

  deleteCardMyNote(id, data) {
    console.log(data);
    delete data.card_id;
    delete data.file;
    return instance({ requiresAuth: true }).delete(suwAPI.apiCRMCard+'/'+id+'/mynotes/'+data);
  },

  getCardMynotes(id, page = 1) {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMCard+'/'+id+'/mynotes?page='+page);
  },

  postCardNote(id, data) {
    delete data.card_id;
    delete data.file;
    return instance({ requiresAuth: true }).post(suwAPI.apiCRMCard+'/'+id+'/notes', data);
  },

  getCardNotes(id, page = 1) {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMCard+'/'+id+'/notes?page='+page);
  },

  removeCard(data) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiCRMCard+data);
  },

  getCardNotesMedia(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRMCard+'/'+data+'/notes_media');
  },

  getCrmConverstationMessage(id, type = 'user') {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRM+'/conversation-crm/'+id+'?type='+type);
  },

  getConverstationMessage(id, page = 1) {
    return instance({ requiresAuth: true }).get(suwAPI.apiCRM+'/conversation/'+id+'?page='+page);
  },

  postConverstationMessage(data) {
    delete data.file;
    return instance({ requiresAuth: true }).post(suwAPI.apiCRM+'/conversation', data);
  },

  getAllConverstations(search){
    return instance({ requiresAuth: true }).get(suwAPI.apiCRM+'/all_conversations?search='+search);
  },

  getManagerConverstations(search){
    return instance({ requiresAuth: true }).get(suwAPI.apiCRM+'/manager_conversations?search='+search);
  },

}

export default crmService;