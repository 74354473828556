import employeeService from '@/services/service.employee'

const employee = {
  state: {
    searchUsers: '',

    order_rating: 'desc',
    expert_type: 'Companies', // 'Users'
    expert_all_foe: true,
    field_of_expertise: [],
    selected_foe: [],
    expert_users: {
        data: [],
        meta: {},
    },
    expert_reviews: [],
    my_review: '',

    investor_type: 'Users', // 'Companies'
    type_of_investments: [],
    selected_investments: [],
    investor_all_investments: true,
    investor_all_fof: true,
    investor_same_fof: false,
    self_fof: [],
    field_of_focus: [],
    selected_fof: [],
    investor_users: {
      data: [],
      meta: {},
    },

    startup_type: 'Companies', // 'Users'
    startup_all_industry: true,
    selected_industry: [],
    industries: [],
    stage_of_funding: [],
    selected_sof: [],
    startup_all_sof: true,
    startup_users: {
      data: [],
      meta: {},
    },

    current_page: 1,

    favorites: [],

  },
  mutations: {
    SET_EMPLOYEE_SEARCH: (state, payload) => {
      state.searchUsers = payload;
      state.current_page = 1;
    },

    /* Experts */
    SET_EXPERT_TYPE: (state, payload) => {
      state.expert_type = payload;
      state.searchUsers = '';
      state.current_page = 1;
      state.expert_users = {
          data: [],
          meta: {},
      };
    },
    SET_EXPERTS_DATA: (state, payload) => {
      state.expert_users.data = payload;
    },
    SET_EXPERTS_META: (state, payload) => {
      state.expert_users.meta = payload.users.meta;
      state.field_of_expertise = payload.foe;
      state.selected_foe = state.field_of_expertise.filter(a => a.selected);
    },
    SET_EXPERTS_ORDER: (state, payload) => {
      state.order_rating = payload;
      state.current_page = 1;
    },
    EXPERTS_LOAD_MORE: (state) => {
      state.current_page = state.expert_users.meta.current_page + 1;
    },

    EXPERT_FILTER_FOE: (state) => {
      state.selected_foe = state.field_of_expertise.filter(a => a.selected);
      state.expert_all_foe = (state.selected_foe.length > 0) ? false : true;
      state.current_page = 1;
    },
    SELECT_ALL_FOE: (state) => {
      if (state.expert_all_foe)
        state.field_of_expertise.forEach((item, index) => {
          state.field_of_expertise[index].selected = false;
        });
      state.current_page = 1;
    },
    EXPERT_REMOVE_FOE: (state, payload) => {
      state.field_of_expertise.forEach((item, index) => {
        if (item.id == payload)
          state.field_of_expertise[index].selected = false;
      });
      state.current_page = 1;
    },

    EXPERT_REVIEWS: (state, payload) => {
      state.expert_reviews = payload.data;
    },
    SET_MY_REVIEW: (state, payload) => {
      state.my_review = payload;
    },


    /* Insvestors */
    SET_INVESTOR_TYPE: (state, payload) => {
      state.investor_type = payload;
      state.searchUsers = '';
      state.current_page = 1;
      state.investor_users = {
        data: [],
        meta: {},
      };
    },
    SET_INVESTORS_DATA: (state, payload) => {
      state.investor_users.data = payload;
    },
    SET_INVESTORS_META: (state, payload) => {
      state.investor_users.meta = payload.users.meta;
      state.field_of_focus = payload.fof;
      state.type_of_investments = payload.investments;
      state.self_fof = payload.self_fof;
      state.selected_investments = state.type_of_investments.filter(a => a.selected);
      state.selected_fof = state.field_of_focus.filter(a => a.selected);
    },
    INVESTORS_LOAD_MORE: (state) => {
      state.current_page = state.investor_users.meta.current_page + 1;
    },

    INVESTORS_FILTER_INVESTMENT: (state) => {
      state.selected_investments = state.type_of_investments.filter(a => a.selected);
      state.investor_all_investments = (state.selected_investments.length > 0) ? false : true;
      state.current_page = 1;
    },
    SELECT_ALL_INVESTMENT: (state) => {
      if (state.investor_all_investments)
        state.type_of_investments.forEach((item, index) => {
          state.type_of_investments[index].selected = false;
        });
      state.current_page = 1;
    },
    INVESTORS_REMOVE_INVESTMENT: (state, payload) => {
      state.type_of_investments.forEach((item, index) => {
        if (item.id == payload)
          state.type_of_investments[index].selected = false;
      });
      state.current_page = 1;
    },

    INVESTORS_FILTER_FOF: (state) => {
      state.selected_fof = state.field_of_focus.filter(a => a.selected);
      state.investor_all_fof = (state.selected_fof.length > 0) ? false : true;
      state.investor_same_fof = false;
      state.current_page = 1;
    },
    SELECT_ALL_FOF: (state) => {
      if (state.investor_all_fof) {
        state.field_of_focus.forEach((item, index) => {
          state.field_of_focus[index].selected = false;
        });
        state.investor_same_fof = false;
      }
      state.current_page = 1;
    },
    SELECT_SAME_FOF: (state) => {
      state.field_of_focus.forEach((item, index) => {
        if(state.self_fof.indexOf(item.id) != -1)
          state.field_of_focus[index].selected = true;
      });
      state.investor_all_fof = false;
      state.current_page = 1;
    },
    INVESTORS_REMOVE_FOF: (state, payload) => {
      state.field_of_focus.forEach((item, index) => {
        if (item.id == payload)
          state.field_of_focus[index].selected = false;
      });
      state.investor_same_fof = false;
      state.current_page = 1;
    },


    /* StartUps */
    SET_STARTUP_TYPE: (state, payload) => {
      state.startup_type = payload;
      state.searchUsers = '';
      state.current_page = 1;
      state.startup_users = {
        data: [],
        meta: {},
      };
    },
    SET_STARTUPS_DATA: (state, payload) => {
      state.startup_users.data = payload;
    },
    SET_STARTUPS_META: (state, payload) => {
      state.startup_users.meta = payload.users.meta;
      state.industries = payload.industries;
      state.stage_of_funding = payload.stage_of_funding;
      state.selected_industry = state.industries.filter(a => a.selected);
    },
    STARTUPS_LOAD_MORE: (state) => {
      state.current_page = state.startup_users.meta.current_page + 1;
    },

    STARTUP_FILTER_INDUSTRY: (state) => {
      state.selected_industry = state.industries.filter(a => a.selected);
      state.startup_all_industry = (state.selected_industry.length > 0) ? false : true;
      state.current_page = 1;
    },
    SELECT_ALL_INDUSTRY: (state) => {
      if (state.startup_all_industry)
        state.industries.forEach((item, index) => {
          state.industries[index].selected = false;
        });
      state.current_page = 1;
    },
    STARTUP_REMOVE_INDUSTRY: (state, payload) => {
      state.industries.forEach((item, index) => {
        if (item.id == payload)
          state.industries[index].selected = false;
      });
      state.current_page = 1;
    },
    STARTUP_FILTER_SOF: (state) => {
      state.selected_sof = state.stage_of_funding.filter(a => a.selected);
      state.startup_all_sof = (state.selected_sof.length > 0) ? false : true;
      state.current_page = 1;
    },
    SELECT_ALL_SOF: (state) => {
      if (state.startup_all_sof)
        state.stage_of_funding.forEach((item, index) => {
          state.stage_of_funding[index].selected = false;
        });
      state.current_page = 1;
    },
    STARTUP_REMOVE_SOF: (state, payload) => {
      state.stage_of_funding.forEach((item, index) => {
        if (item.id == payload)
          state.stage_of_funding[index].selected = false;
      });
      state.current_page = 1;
    },


    EMPLOYEE_RELOAD: (state, payload) => {
      let index = state[payload.type].data.findIndex(x => x.id == payload.data.id);
      state[payload.type].data[index] = payload.data;
      if (state.favorites.length) { // update favorites
        if ('companyname' in payload.data) { // company
          let index = state.favorites.findIndex(x => x.company?.id == payload.data.id);
          state.favorites[index].company = payload.data;
          if (!payload.data.favorite) state.favorites.splice(index, 1); // remove from Favorites
        }
        if ('firstname' in payload.data) { // user
          let index = state.favorites.findIndex(x => x.user?.id == payload.data.id);
          state.favorites[index].user = payload.data;
          if (!payload.data.favorite) state.favorites.splice(index, 1); // remove from Favorites
        }
      }
    },

    JOB_FAVORITE_RELOAD: (state, payload) => {
      if (state.favorites.length) {
        let index = state.favorites.findIndex(x => x.job?.id == payload.id);
        state.favorites[index].job = payload;
        if (!payload.favorite) state.favorites.splice(index, 1); // remove from Favorites
      }
    },

    /* Favorites */
    FAVORITES_SET: (state, payload) => {
      state.favorites = payload.data;
    },
    FAVORITES_RESET: (state) => {
      state.favorites = [];
    },
  },

  actions: {
    /* Experts */
    async GET_DATA_EXPERTS({commit, dispatch}, payload) {
      if (payload == 'first') {
        this.state.employee.searchUsers = '';
        this.state.employee.current_page = 1;
      }
      const response = await employeeService.getExperts({
        type: this.state.employee.expert_type,
        search: this.state.employee.searchUsers,
        page: this.state.employee.current_page,
        rating: this.state.employee.order_rating,
        foe: this.state.employee.field_of_expertise.filter(a => a.selected).map(a => a.id),
      });
      if(response.status === 200) {
        if (payload == 'loadmore') {
          commit('SET_EXPERTS_DATA', this.state.employee.expert_users.data.concat(response.data.users.data));
        } else {
          commit('SET_EXPERTS_DATA', response.data.users.data);
        }
        commit('SET_EXPERTS_META', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },
    EXPERTS_LOAD_MORE({commit, dispatch}) {
      commit('EXPERTS_LOAD_MORE');
      dispatch('GET_DATA_EXPERTS', 'loadmore');
    },

    SET_EXPERTS_ORDER({commit, dispatch}, payload) {
      commit('SET_EXPERTS_ORDER',payload);
      dispatch('GET_DATA_EXPERTS');
    },
    EXPERT_FILTER_FOE({commit, dispatch}) {
      commit('EXPERT_FILTER_FOE');
      dispatch('GET_DATA_EXPERTS');
    },
    EXPERT_REMOVE_FOE({commit, dispatch}, payload) {
      commit('EXPERT_REMOVE_FOE', payload);
      commit('EXPERT_FILTER_FOE', payload);
      dispatch('GET_DATA_EXPERTS');
    },
    SELECT_ALL_FOE({commit, dispatch}) {
      commit('SELECT_ALL_FOE');
      dispatch('GET_DATA_EXPERTS');
    },




    /* Investors */
    async GET_DATA_INVESTORS({commit, dispatch}, payload) {
      if (payload == 'first') {
        this.state.employee.searchUsers = '';
        this.state.employee.current_page = 1;
      }
      const response = await employeeService.getInvestors({
        type: this.state.employee.investor_type,
        search: this.state.employee.searchUsers,
        page: this.state.employee.current_page,
        fof: this.state.employee.field_of_focus.filter(a => a.selected).map(a => a.id),
        investment: this.state.employee.type_of_investments.filter(a => a.selected).map(a => a.id),
      });
      if(response.status === 200) {
        if (payload == 'loadmore') {
          commit('SET_INVESTORS_DATA', this.state.employee.investor_users.data.concat(response.data.users.data));
        } else {
          commit('SET_INVESTORS_DATA', response.data.users.data);
        }
        commit('SET_INVESTORS_META', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },
    INVESTORS_LOAD_MORE({commit, dispatch}) {
      commit('INVESTORS_LOAD_MORE');
      dispatch('GET_DATA_INVESTORS', 'loadmore');
    },

    INVESTORS_FILTER_INVESTMENT({commit, dispatch}) {
      commit('INVESTORS_FILTER_INVESTMENT');
      dispatch('GET_DATA_INVESTORS');
    },
    INVESTORS_REMOVE_INVESTMENT({commit, dispatch}, payload) {
      commit('INVESTORS_REMOVE_INVESTMENT', payload);
      commit('INVESTORS_FILTER_INVESTMENT', payload);
      dispatch('GET_DATA_INVESTORS');
    },
    SELECT_ALL_INVESTMENT({commit, dispatch}) {
      commit('SELECT_ALL_INVESTMENT');
      dispatch('GET_DATA_INVESTORS');
    },

    INVESTORS_FILTER_FOF({commit, dispatch}) {
      commit('INVESTORS_FILTER_FOF');
      dispatch('GET_DATA_INVESTORS');
    },
    INVESTORS_REMOVE_FOF({commit, dispatch}, payload) {
      commit('INVESTORS_REMOVE_FOF', payload);
      commit('INVESTORS_FILTER_FOF', payload);
      dispatch('GET_DATA_INVESTORS');
    },
    SELECT_ALL_FOF({commit, dispatch}) {
      commit('SELECT_ALL_FOF');
      dispatch('GET_DATA_INVESTORS');
    },
    SELECT_SAME_FOF({commit, dispatch}) {
      commit('SELECT_SAME_FOF');
      dispatch('GET_DATA_INVESTORS');
    },


    /* StartUps */
    async GET_DATA_STARTUPS({commit, dispatch}, payload) {
      if (payload == 'first') {
        this.state.employee.searchUsers = '';
        this.state.employee.current_page = 1;
      }
      const response = await employeeService.getStartups({
        type: this.state.employee.startup_type,
        search: this.state.employee.searchUsers,
        page: this.state.employee.current_page,
        funding: this.state.employee.stage_of_funding.filter(a => a.selected).map(a => a.id),
        industry: this.state.employee.industries.filter(a => a.selected).map(a => a.id),
      });
      if(response.status === 200) {
        if (payload == 'loadmore') {
          commit('SET_STARTUPS_DATA', this.state.employee.startup_users.data.concat(response.data.users.data));
        } else {
          commit('SET_STARTUPS_DATA', response.data.users.data);
        }
        commit('SET_STARTUPS_META', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    STARTUPS_LOAD_MORE({commit, dispatch}) {
      commit('STARTUPS_LOAD_MORE');
      dispatch('GET_DATA_STARTUPS', 'loadmore');
    },

    STARTUP_FILTER_INDUSTRY({commit, dispatch}) {
      commit('STARTUP_FILTER_INDUSTRY');
      dispatch('GET_DATA_STARTUPS');
    },
    STARTUP_REMOVE_INDUSTRY({commit, dispatch}, payload) {
      commit('STARTUP_REMOVE_INDUSTRY', payload);
      commit('STARTUP_FILTER_INDUSTRY', payload);
      dispatch('GET_DATA_STARTUPS');
    },
    SELECT_ALL_INDUSTRY({commit, dispatch}) {
      commit('SELECT_ALL_INDUSTRY');
      dispatch('GET_DATA_STARTUPS');
    },
    SELECT_ALL_SOF({commit, dispatch}) {
      commit('SELECT_ALL_SOF');
      commit('STARTUP_FILTER_SOF');
      dispatch('GET_DATA_STARTUPS');
    },
    STARTUP_FILTER_SOF({commit, dispatch}) {
      commit('STARTUP_FILTER_SOF');
      dispatch('GET_DATA_STARTUPS');
    },
    STARTUP_REMOVE_SOF({commit, dispatch}, payload) {
      commit('STARTUP_REMOVE_SOF', payload);
      commit('STARTUP_FILTER_SOF', payload);
      dispatch('GET_DATA_STARTUPS');
    },


    async EMPLOYEE_USER_RELOAD({commit, dispatch}, payload) {
      const response = await employeeService.getUser(payload.id);
      if(response.status === 200) {
        commit('EMPLOYEE_RELOAD', {type: payload.tab+'_users', data: response.data.user});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async EMPLOYEE_COMPANY_RELOAD({commit, dispatch}, payload) {
      const response = await employeeService.getCompany(payload.id);
      if(response.status === 200) {
        commit('EMPLOYEE_RELOAD', {type: payload.tab+'_users', data: response.data});
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async EXPERT_REVIEWS({commit, dispatch}, payload) {
      const response = await employeeService.getUserReviews(payload);
      if(response.status === 200) {
        commit('EXPERT_REVIEWS', response.data);
        dispatch('SHOW_POPUP', { popup: 'fullReviewsViewPopup', show: true });
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },
    SET_MY_REVIEW({commit}, payload) {
      commit('SET_MY_REVIEW', payload);
    },
    async REMOVE_REVIEW({commit, dispatch}) {
      let type = this.state.employee.my_review.type;
      let id = type === 'user' ? this.state.employee.my_review.user_id : this.state.employee.my_review.company_id;
      const response = await employeeService.deleteReview(this.state.employee.my_review.id);
      if(response.status === 200) {
        commit('SET_MY_REVIEW', '');
        dispatch('EXPERT_REVIEWS', {id: id, type: type});
        if(type == 'user') dispatch('EMPLOYEE_USER_RELOAD', {tab: 'expert', id: id});
        if(type == 'user') dispatch('SET_VIEW_USER_PROFILE', id);
        if(type == 'company') dispatch('EMPLOYEE_COMPANY_RELOAD', {tab: 'expert', id: id});
        if(type == 'company') dispatch('GET_COMPANY_INFO', id);
        if(localStorage.route_name === 'crmUser') dispatch('GET_CRM_CARD',localStorage.route_param_id);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    /* Favorites */
    async EMPLOYEE_FAVORITE_TOGGLE({dispatch}, payload) {
      const response = await employeeService.toggleFavorite(payload);
      if(response.status !== 200) {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
      }
    },

    async EMPLOYEE_FAVORITE_SHOW({commit, dispatch}, payload) {
      const response = await employeeService.getFavorites(payload);
      if(response.status === 200) {
        commit('FAVORITES_SET', response.data);
        dispatch('SHOW_POPUP', { popup: 'fullFavoritesViewPopup', show: true });
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },


  },
  getters: {}
}
export default employee;
