<template>
  <main-layout>
    <div class="row">
      <div class="col col_8 col_tab-12">
        <div class="main">
          <!-- main-header -->
          <div class="main__header header">
            <div class="header__wysiwyg wysiwyg">
              <h2>Community Forums</h2>
            </div>
            <div class="header__actions">
              <div class="btn-group btn-group_secondary">
                <a @click.prevent="openForumNewTopicPopup" href="#" class="btn btn_primary btn_secondary-ico">
                  <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z"
                      fill="white" />
                  </svg>
                  <span class="btn__text show popup-init" data-popupname="addtopic-popup">New Forum Topic</span>
                </a>
              </div>
              <ToolTip
                :type="'top-right'"
                :msg="'For now you can only request for community forums to be created. In the earlier stage we wanted to control the flow of forum spaces to stem the proliferation of unused forums. When our communities grow we will improve the community forums section considerably, allowing privately created and managed forums, restricted access/invite only forums, Sub-Forums and more!<br><br>Until then by clicking over here on the + New Forum Topic button you will be able to suggest to us the title for a new Community Forum, then let us know what segments of the platform this forum is intended for. You can choose all segments, any combination of two or any of the segments alone. Finish by sending your request forward.<br><br>Our team will review the request and if we see it\'s an appropriate time with enough relevant members for the Community Forum we will create it.<br>Only the segment/s that were clicked to be a part of the Community Forum will be able to see it and have access to it once it is created.'"
              />
            </div>
          </div>
          <!-- main-content -->
          <div class="main__content">
            <div class="entrepreneur">
              <div class="section">
                <div class="section__header direction-row">
                  <div class="section__wysiwyg wysiwyg">
                    <h2>Topic</h2>
                  </div>
                  <div class="section__actions green_no-borders">
                    <div class="form-field mb_0">
                      <div class="form-field__content">
                        <div class="dropdown dropdown_secondary" :class="{ 'dropdown_active': openSelect }">
                          <div @click="openSelect = !openSelect" class="dropdown__init">
                            <span class="title-text">{{forum.type}}</span>
                            <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z"
                                fill="#00A961" />
                            </svg>
                          </div>
                          <div class="dropdown__content right">
                            <ul class="dropdown__list">
                              <li class="dropdown__item">
                                <a @click.prevent="changeTypeToShow('All')" href="#" class="dropdown__link">All</a>
                              </li>
                              <li class="dropdown__item">
                                <a @click.prevent="changeTypeToShow('Followed')" href="#" class="dropdown__link">Followed</a>
                              </li>
                              <li class="dropdown__item">
                                <a @click.prevent="changeTypeToShow('Unfollowed')" href="#" class="dropdown__link">Unfollowed</a>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div><!-- //form-field -->
                  </div>
                </div>
                <div class="section__content">
                  <div class="search">
                    <div class="form-field form-field_tertiary">
                      <div class="form-field__content">
                        <input v-model="searchTopics" type="text" placeholder="Search ...." class="form-field__input" required>
                        <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z"
                            fill="#333333" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z"
                            fill="#333333" />
                        </svg>

                        <svg @click="clearSearch" v-show="searchTopics.length" class="clear-search ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path></svg>
                      </div>
                    </div><!-- //form-field -->
                  </div>
                </div>
                <div class="section__content p_0">

                  <div class="forum">

                    <div class="forum__list">

                      <div v-for="(topic, index) in forum.topics" :key="topic.id" class="forum__item">
                        <a @click.prevent.self="goToTopic(topic.id)" href="#" class="forum__link">
                          <span @click="goToTopic(topic.id)" class="forum__title">{{topic.title}}</span>
                          <svg @click="goToTopic(topic.id)" class="forum__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.75 5.25C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H12C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V9.75C12.75 9.33579 13.0858 9 13.5 9C13.9142 9 14.25 9.33579 14.25 9.75V14.25C14.25 14.8467 14.0129 15.419 13.591 15.841C13.169 16.2629 12.5967 16.5 12 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H8.25C8.66421 3.75 9 4.08579 9 4.5C9 4.91421 8.66421 5.25 8.25 5.25H3.75Z"
                              fill="#00A961" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V3H11.25C10.8358 3 10.5 2.66421 10.5 2.25Z"
                              fill="#00A961" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M16.2803 1.71967C16.5732 2.01256 16.5732 2.48744 16.2803 2.78033L8.03033 11.0303C7.73744 11.3232 7.26256 11.3232 6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L15.2197 1.71967C15.5126 1.42678 15.9874 1.42678 16.2803 1.71967Z"
                              fill="#00A961" />
                          </svg>
                          <span @click="toggleFollow(index)" v-if="topic.followed" class="forum__status btn btn_tiny btn_octal">Unfollow</span>
                          <span @click="toggleFollow(index)" v-else class="forum__status btn btn_tiny btn_sevenfold">Follow</span>
                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a @click.prevent="loadmore" v-show="forum.meta.page < forum.meta.total_pages" href="javascript:;"
            class="btn btn_secondary">
            <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7883_838)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.75 2.25C1.16421 2.25 1.5 2.58579 1.5 3V6.75H5.25C5.66421 6.75 6 7.08579 6 7.5C6 7.91421 5.66421 8.25 5.25 8.25H0.75C0.335786 8.25 0 7.91421 0 7.5V3C0 2.58579 0.335786 2.25 0.75 2.25Z"
                  fill="#00A961" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 10.5C12 10.0858 12.3358 9.75 12.75 9.75H17.25C17.6642 9.75 18 10.0858 18 10.5V15C18 15.4142 17.6642 15.75 17.25 15.75C16.8358 15.75 16.5 15.4142 16.5 15V11.25H12.75C12.3358 11.25 12 10.9142 12 10.5Z"
                  fill="#00A961" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.92021 1.79729C8.13724 1.44527 9.42362 1.40727 10.6593 1.68686C11.895 1.96645 13.0397 2.55451 13.9867 3.39616C14.9336 4.23782 15.6519 5.30564 16.0745 6.49998C16.2127 6.89047 16.0082 7.31904 15.6177 7.45722C15.2272 7.5954 14.7986 7.39086 14.6605 7.00038C14.3224 6.0449 13.7477 5.19064 12.9902 4.51732C12.2326 3.844 11.3168 3.37355 10.3283 3.14988C9.33973 2.92621 8.31063 2.9566 7.337 3.23823C6.36338 3.51985 5.47696 4.04352 4.76047 4.76038C4.75492 4.76592 4.7493 4.77138 4.74358 4.77674L1.26358 8.04674C0.961725 8.33039 0.487081 8.31562 0.203437 8.01376C-0.0802062 7.7119 -0.0654396 7.23726 0.23642 6.95361L3.70808 3.69145C4.6022 2.79976 5.70705 2.1482 6.92021 1.79729ZM17.7966 9.9866C18.0802 10.2885 18.0654 10.7631 17.7636 11.0467L14.2919 14.3089C13.3978 15.2006 12.293 15.8522 11.0798 16.2031C9.86276 16.5551 8.57639 16.5931 7.3407 16.3135C6.10502 16.0339 4.9603 15.4458 4.01335 14.6042C3.06641 13.7625 2.3481 12.6947 1.92547 11.5004C1.78729 11.1099 1.99182 10.6813 2.38231 10.5431C2.77279 10.405 3.20136 10.6095 3.33954 11C3.67765 11.9555 4.25229 12.8097 5.00985 13.483C5.76741 14.1564 6.68318 14.6268 7.67173 14.8505C8.66028 15.0741 9.68938 15.0438 10.663 14.7621C11.6366 14.4805 12.523 13.9568 13.2395 13.24C13.2429 13.2366 13.2462 13.2333 13.2496 13.2301C13.2519 13.2279 13.2541 13.2258 13.2564 13.2236L16.7364 9.95361C17.0383 9.66997 17.5129 9.68474 17.7966 9.9866Z"
                  fill="#00A961" />
              </g>
              <defs>
                <clipPath id="clip0_7883_838">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span class="btn__text">Load more</span>
          </a>

        </div>
      </div>

      <div class="col col_4 col_tab-12">
        <aside class="sidebar sidebar_secondary" :class="{'sidebar_active': forum.open_sidebar_notification}">
          <!-- header -->
          <div @click.prevent="closeSidebarNotifications" class="sidebar__header">
            <svg class="title-ico" width="20" height="20" viewBox="0 0 20 20" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.24408 4.4107C6.91864 4.73614 6.91864 5.26378 7.24408 5.58922L11.6548 9.99996L7.24408 14.4107C6.91864 14.7361 6.91864 15.2638 7.24408 15.5892C7.56951 15.9147 8.09715 15.9147 8.42259 15.5892L13.4226 10.5892C13.748 10.2638 13.748 9.73614 13.4226 9.4107L8.42259 4.4107C8.09715 4.08527 7.56951 4.08527 7.24408 4.4107Z"
                fill="#5D6167" />
            </svg>
            <span class="title-text">Back</span>

          </div>
          <!-- content -->
          <div class="sidebar__content with-tooltip">
            <div class="section section_mobile-secondary">
              <div class="section__header">
                <div class="section__wysiwyg wysiwyg">
                  <h2>Notifications
                    <ToolTip
                      :type="'top-right'"
                      :msg="'This notification box will give you a macro-look at what is going on in the Community Forums space that is relevant to you, this will aggregate all of your updates relative to all the forums they come from. But you will only see how many updates you have from each Community Forum, not the specifics of the updates. This you will receive in the notification box within each specific Community Forum.'"
                    />
                  </h2>
                </div>
              </div>
              <div class="section__content">
                <div class="notifications">
                  <div class="notifications__list">

                    <div class="notifications__item"
                      v-for="(forum_update, indexFU) in this.notifications.personalNotifications.forum_updates"
                      :key='indexFU'
                      v-show="forum_update.count_new > 0" >
                      <div class="notifications__media icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2006_275)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0.833333 2.5C1.29357 2.5 1.66667 2.8731 1.66667 3.33333V7.5H5.83333C6.29357 7.5 6.66667 7.8731 6.66667 8.33333C6.66667 8.79357 6.29357 9.16667 5.83333 9.16667H0.833333C0.373096 9.16667 0 8.79357 0 8.33333V3.33333C0 2.8731 0.373096 2.5 0.833333 2.5Z"
                              fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M13.3334 11.6673C13.3334 11.2071 13.7065 10.834 14.1667 10.834H19.1667C19.6269 10.834 20 11.2071 20 11.6673V16.6673C20 17.1276 19.6269 17.5007 19.1667 17.5007C18.7065 17.5007 18.3334 17.1276 18.3334 16.6673V12.5007H14.1667C13.7065 12.5007 13.3334 12.1276 13.3334 11.6673Z"
                              fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7.68913 1.99612C9.04138 1.60498 10.4707 1.56277 11.8437 1.87342C13.2166 2.18408 14.4886 2.83748 15.5407 3.77265C16.5929 4.70782 17.391 5.89428 17.8606 7.22133C18.0141 7.65521 17.7869 8.13139 17.353 8.28493C16.9191 8.43846 16.4429 8.2112 16.2894 7.77733C15.9137 6.71569 15.2752 5.76651 14.4335 5.01838C13.5918 4.27024 12.5742 3.74752 11.4759 3.499C10.3775 3.25048 9.23403 3.28425 8.15223 3.59716C7.07042 3.91007 6.08551 4.49193 5.28941 5.28844C5.28325 5.2946 5.277 5.30066 5.27065 5.30662L1.40398 8.93996C1.06858 9.25512 0.541201 9.23871 0.226042 8.90331C-0.089118 8.56791 -0.0727107 8.04053 0.262689 7.72537L4.12008 4.10075C5.11355 3.10997 6.34116 2.38602 7.68913 1.99612ZM19.774 11.0953C20.0891 11.4307 20.0727 11.9581 19.7373 12.2733L15.8799 15.8979C14.8864 16.8887 13.6588 17.6126 12.3109 18.0025C10.9586 18.3937 9.52932 18.4359 8.15634 18.1252C6.78335 17.8146 5.51144 17.1612 4.45928 16.226C3.40712 15.2908 2.60901 14.1044 2.13941 12.7773C1.98587 12.3435 2.21313 11.8673 2.64701 11.7137C3.08088 11.5602 3.55707 11.7875 3.7106 12.2213C4.08628 13.283 4.72477 14.2321 5.5665 14.9803C6.40823 15.7284 7.42576 16.2511 8.52414 16.4997C9.62253 16.7482 10.766 16.7144 11.8478 16.4015C12.9296 16.0886 13.9145 15.5067 14.7106 14.7102C14.7143 14.7065 14.718 14.7028 14.7218 14.6992C14.7243 14.6968 14.7268 14.6944 14.7294 14.692L18.596 11.0587C18.9314 10.7435 19.4588 10.7599 19.774 11.0953Z"
                              fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2006_275">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="notifications__content">
                        <div class="notifications__wisywig wisywig bold">
                          <h4>{{forum_update.count_new > 0 ? forum_update.count_new : 'No'}} updates in</h4>
                          <p>
                            <router-link :to="{name: 'forumTopic', params: {id: forum_update.page_id}}">{{forum_update.title}}</router-link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div v-show="notifications.personalNotifications.forum_updates.filter(o => o.count_new > 0).length < 1" class="notifications__item">
                        There are no updates
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>

    <ForumNewTopicPopup></ForumNewTopicPopup>
    <ForumTopicSuccessPopup></ForumTopicSuccessPopup>
  </main-layout>
</template>

<script>
    import MainLayout from "../layouts/Main.vue"
    import store from '../store'
    import func from '@/plugins/functions'
    import ForumNewTopicPopup from '@/components/popups/ForumNewTopic'
    import ForumTopicSuccessPopup from '@/components/popups/ForumTopicSuccess'
    import ToolTip from '@/components/ToolTip'

    export default {
        name: "Forum",
        components: {
            MainLayout,
            ForumNewTopicPopup,
            ForumTopicSuccessPopup,
            ToolTip,
        },
        data() {
            return {
                openSelect: false,
            }
        },
        methods: {
            openForumNewTopicPopup() {
                store.dispatch('SHOW_POPUP', { popup: 'forumNewTopicPopup', show: true});
            },
            goToTopic(id) {
              this.$router.push({name:'forumTopic', params: {id: id}});
            },
            toggleFollow(index) {
              store.dispatch('FOLLOW_FORUM_TOPIC', this.forum.topics[index].id);
              this.forum.topics[index].followed = !this.forum.topics[index].followed;
            },

            changeTypeToShow(type) {
              store.dispatch('SET_FORUM_TYPE_SHOW', type);
              this.openSelect = false;
            },

            loadmore() {
                store.dispatch('FORUM_LOAD_MORE');
            },

            clearSearch() {
              store.commit('SET_FORUM_TOPICS_SEARCH', '');
            },
            searchTopicsHandler() {
                store.dispatch('FORUM_GET_DATA');
            },

            closeSidebarNotifications() {
              store.commit('SET_SIDEBAR_NOTIFICATION_OPEN', false);
            },

            closeSelect(e) {
              if (!e.target.closest(".dropdown_active")) {
                this.openSelect = false
              }
            },
        },
        computed: {
            forum: () => store.state.forum,
            userdata: () => store.state.user,
            notifications: () => store.state.notifications,
            searchTopics: {
              get() {
                  return this.forum.searchTopics;
                },
                set(value) {
                  store.dispatch('SET_FORUM_TOPICS_SEARCH', value);
                }
            },
        },
        watch: {
            searchTopics: function () {
                this.debouncedSearchPosts();
            },
        },
        mounted() {
            store.dispatch('GET_FORUM_TOPICS');
            store.dispatch('GET_NOTIFICATIONS_PERSONAL');
            document.addEventListener('click', this.closeSelect)
        },
        created() {
            this.debouncedSearchPosts = func.debounce(this.searchTopicsHandler, 500);
        },
        beforeUnmount () {
            document.removeEventListener('click',this.closeSelect)
        }
    }
</script>
