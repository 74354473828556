<template>
  <div class="sidebar__content with-tooltip">
    <div class="section section_mobile-secondary">
      <div class="section__header">
        <div class="section__wysiwyg wysiwyg">
          <h2>Files & media
            <ToolTip 
              :type="'top-right'"
              :msg="'This section was created with the intent to help you organize and jump into working on any of the files uploaded in your posts. The files will show both in the post as well as here. In the meantime all files are downloadable from the platform except for PDFs which are temporarily ‘view only’ through the platform.'"
            />
          </h2>
        </div>
      </div>
      <div class="section__content">
        <div class="post__form">
          <form action="#">
            <div class="form-field  form-field_tertiary">
              <div class="form-field__content">
                <input type="text" placeholder="Search file & media ..." v-model="searchFiles"
                  class="form-field__input no_borders" required>
                <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z"
                    fill="#333333" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z"
                    fill="#333333" />
                </svg>
              </div>
            </div>
          </form>
        </div>
        <div class="files">
          <div v-show="this.page.files.length" class="files__list">
            <div v-for="file in this.page.files" :key="file.id" class="files__item">
              <a @click.prevent="showAttach(file)" href="#" class="files__link">
                <svg v-show="file.ext_type === 'video'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5932 4.58313C17.843 4.71168 18 4.96906 18 5.25V12.75C18 13.0309 17.843 13.2883 17.5932 13.4169C17.3434 13.5454 17.0427 13.5236 16.8141 13.3603L11.5641 9.6103C11.367 9.46952 11.25 9.24222 11.25 9C11.25 8.75779 11.367 8.53049 11.5641 8.3897L16.8141 4.6397C17.0427 4.47641 17.3434 4.45457 17.5932 4.58313ZM13.2903 9L16.5 11.2926V6.70739L13.2903 9Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V12.75C1.5 13.1642 1.83579 13.5 2.25 13.5H10.5C10.9142 13.5 11.25 13.1642 11.25 12.75V5.25C11.25 4.83579 10.9142 4.5 10.5 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H10.5C11.7426 3 12.75 4.00736 12.75 5.25V12.75C12.75 13.9926 11.7426 15 10.5 15H2.25C1.00736 15 0 13.9926 0 12.75V5.25Z" fill="#00A961" />
                </svg>
                <svg v-show="file.ext_type === 'document'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
                </svg>
                <svg v-show="file.ext_type === 'image'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961" />
                </svg>
                <span class="files__text">{{ file.user_defined_filename }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import func from '@/plugins/functions'
  import ToolTip from '@/components/ToolTip'

  export default {
      name: "Page",
      components: {
        ToolTip,
      },
      data() {
          return {
          }
      },
      methods: {
          showAttach: function(attachment) {
              store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
          },

          searchFilesHandler() {
              store.dispatch('GET_PAGE_FILES');
          },

      },
      watch: {
          searchFiles: function () {
              this.debouncedSearchFiles();
          },
      },
      created() {
          this.debouncedSearchFiles = func.debounce(this.searchFilesHandler, 500);
      },
      computed: {
          page: () => store.state.page,
          searchFiles: {
            get() {
              return this.page.searchFiles;
            },
            set(value) {
              store.dispatch('SET_FILES_SEARCH', value);
            }
          },
      },
  }
</script>
