<template>
  <div class="section">
    <div class="section__header">
      <div class="section__wysiwyg wysiwyg">
        <h2>Messages
          <ToolTip
            :type="'top'"
            :msg="'This space will be used for organizing all of the conversations you have with members that are not already working with you in one way or another (team member, Work/Project Page member)<br><br>In the bottom right of your screen you have an instant messenger that is functional between you, members of your team and members of your Work/Project pages. We did this separation so that your instant messenger that is more immediately accessible will be more focused towards your business, its creation, growth and management.<br>Instead of cluttering your work space everyone not currently directly involved with your business will be organized and accessible only through your CRM messenger area as well as their individual CRM Cards if you added them as a contact to your CRM'"
          />
        </h2>
      </div>
      <div class="section__actions">
        <div class="search">
          <div class="form-field form-field_tertiary">
            <div class="form-field__content">
              <input v-model="search" type="text" placeholder="Search ...." class="form-field__input" required>
              <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333"/>
              </svg>
            </div>
          </div><!-- //form-field -->
        </div>
      </div>
    </div>
    <div class="section__content">

      <div class="box overflow-content">
        <div class="box__list">
          <template v-for="(message) in this.crm.all_conversations" :key='message.index'>
            <div class="box__item align_big-desktop-center" :class="{'box__item_read': message.unread == 0}">
              <template v-if="message.type == 'user'">
                <div class="box__avatar box__avatar_quaternary">
                  <img v-if="message.author.avatar" :src="message.author.avatar" alt="" />
                  <img v-else src="@/assets/images/avatar-default.png" alt="Start-Up World" />
                </div>
              </template>

              <template v-if="message.type == 'company'">
                <div class="box__company__logo box__company__logo_quaternary">
                  <img v-if="message.company.companylogo" :src="message.company.companylogo.link" alt="" />
                  <img v-else src="@/assets/images/no-logo.png" alt="Start-Up World" />
                </div>
              </template>

              <div class="box__main">
                <div class="box__header" v-if="message.type == 'user'">
                  <div class="wysiwyg wysiwyg_secondary mb_0">
                    <router-link :to="{name: 'profile', params: { id: message.author.id}}">
                      <h3 v-if="crm.searchMSG.length >= 1" v-html="highLight(message.author.name)"></h3>
                      <h3 v-else>{{message.author.name}}</h3>
                    </router-link>
                    <ul>
                      <li v-if="message.author.position">{{message.author.position}}</li>
                      <li v-if="message.author.companyname">
                        <router-link :to="{name: 'company_profile', params: { id: message.author.company_id}}">
                          <span v-if="crm.searchMSG.length >= 1" v-html="highLight(message.author.companyname)"></span>
                          <span v-else>{{message.author.companyname}}</span>
                        </router-link>
                      </li>
                      <li v-if="crm.searchMSG.length >= 1">
                        <span v-html="highLight(message.author.country)"></span>,&nbsp;<span v-html="highLight(message.author.city)"></span>
                      </li>
                      <li v-else>{{message.author.country}}, {{message.author.city}}</li>
                    </ul>
                  </div>
                </div>
                <div class="box__header" v-if="message.type == 'company'">
                  <div class="wysiwyg wysiwyg_secondary mb_0">
                    <router-link :to="{name: 'company_profile', params: { id: message.company.id}}">
                      <h3 v-if="crm.searchMSG.length >= 1" v-html="highLight(message.company.companyname)"></h3>
                      <h3 v-else>{{message.company.companyname}}</h3>
                    </router-link>
                    <ul>
                      <template v-if="message.company.companyindustry">
                        <li v-for="industry in message.company.companyindustry" :key="industry.id">
                          <span v-if="crm.searchMSG.length >= 1" v-html="highLight(industry.value)"></span>
                          <span v-else>{{industry.value}}</span>
                        </li>
                      </template>
                      <li v-if="crm.searchMSG.length >= 1">
                        <span v-html="highLight(message.company.companycountry)"></span>,&nbsp;<span v-html="highLight(message.company.companycity)"></span>
                      </li>
                      <li v-else>{{message.company.companycountry}}, {{message.company.companycity}}</li>
                    </ul>
                  </div>
                </div>
                <div class="box__content">
                  <div class="btn-group">
                    <div v-if="message.unread > 0" class="btn-group__btn btn-group__btn_secondary">
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V13.9393L4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 11.921 14.7803 11.7803C14.921 11.6397 15 11.4489 15 11.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H3.75ZM2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V11.25C16.5 11.8467 16.2629 12.419 15.841 12.841C15.419 13.2629 14.8467 13.5 14.25 13.5H5.56066L2.78033 16.2803C2.56583 16.4948 2.24324 16.559 1.96299 16.4429C1.68273 16.3268 1.5 16.0533 1.5 15.75V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901Z" fill="#00A961"></path>
                      </svg>
                      <span class="btn__text">Unread messages ({{message.unread}})</span>
                    </div>
                    <div class="btn-group__btn">
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill="#5D6167"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5V8.53647L12.3354 9.82918C12.7059 10.0144 12.8561 10.4649 12.6708 10.8354C12.4856 11.2059 12.0351 11.3561 11.6646 11.1708L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.5C8.25 4.08579 8.58579 3.75 9 3.75Z" fill="#5D6167"/>
                      </svg>
                      <span class="btn__text">{{message.datetime}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box__actions ml-auto">
                <div class="btn-group btn-group_secondary">
                  <template v-if="message.type == 'user'">
                    <router-link class="btn btn_tertiary" target="_blank"
                      :to="{ name: 'crmUser', params: { id: message.author.crm_id }}"
                      v-if="message.author.in_my_crm">
                      <span class="btn__text">Open CRM Card</span>
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.25C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H12C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V9.75C12.75 9.33579 13.0858 9 13.5 9C13.9142 9 14.25 9.33579 14.25 9.75V14.25C14.25 14.8467 14.0129 15.419 13.591 15.841C13.169 16.2629 12.5967 16.5 12 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H8.25C8.66421 3.75 9 4.08579 9 4.5C9 4.91421 8.66421 5.25 8.25 5.25H3.75Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V3H11.25C10.8358 3 10.5 2.66421 10.5 2.25Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2803 1.71967C16.5732 2.01256 16.5732 2.48744 16.2803 2.78033L8.03033 11.0303C7.73744 11.3232 7.26256 11.3232 6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L15.2197 1.71967C15.5126 1.42678 15.9874 1.42678 16.2803 1.71967Z" fill="#00A961"/>
                      </svg>
                    </router-link>
                    <a class="btn btn_tertiary" href="#"
                      v-if="!message.author.in_my_crm && !message.author.private && !message.author.private_message"
                      @click.prevent="addToCRM(message)">
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="white"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="white"></path>
                      </svg>
                      <span class="btn__text">Add to CRM</span>
                    </a>
                  </template>
                  <template v-if="message.type == 'company'">
                    <router-link class="btn btn_tertiary" target="_blank"
                      :to="{ name: 'crmUser', params: { id: message.company.crm_id }}"
                      v-if="message.company.in_my_crm">
                      <span class="btn__text">Open CRM Card</span>
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.25C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H12C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V9.75C12.75 9.33579 13.0858 9 13.5 9C13.9142 9 14.25 9.33579 14.25 9.75V14.25C14.25 14.8467 14.0129 15.419 13.591 15.841C13.169 16.2629 12.5967 16.5 12 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H8.25C8.66421 3.75 9 4.08579 9 4.5C9 4.91421 8.66421 5.25 8.25 5.25H3.75Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V3H11.25C10.8358 3 10.5 2.66421 10.5 2.25Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2803 1.71967C16.5732 2.01256 16.5732 2.48744 16.2803 2.78033L8.03033 11.0303C7.73744 11.3232 7.26256 11.3232 6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L15.2197 1.71967C15.5126 1.42678 15.9874 1.42678 16.2803 1.71967Z" fill="#00A961"/>
                      </svg>
                    </router-link>
                    <a class="btn btn_tertiary" href="#"
                      v-if="!message.company.in_my_crm"
                      @click.prevent="addToCRM(message)">
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="white"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="white"></path>
                      </svg>
                      <span class="btn__text">Add to CRM</span>
                    </a>
                  </template>

                  <a class="btn btn_secondary" href="#"
                    @click.prevent="openMsg(message)">
                    <span class="btn__text">Open message</span>
                  </a>
                </div>
              </div>


            </div>

          </template>
        </div>

        <div v-show="!this.crm.all_conversations.length" class="section__content crm_contacts_empty">
          <h3>No messages.</h3>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import store from '@/store'
import ToolTip from '@/components/ToolTip'
import func from '@/plugins/functions'

export default {
  name: "Messages",
  components: {
    ToolTip,
  },
  data() {
    return {
    }
  },
  beforeMount() {
    this.getAllConverstations();
  },
  updated() {
  },
  methods: {
    getAllConverstations: function() {
        store.dispatch('GET_ALL_CONVERSTATIONS');
    },
    openMsg(message) {
        if (message.author.in_my_team && message.type === 'user') {
            store.dispatch('CHAT_USER_CONVERSATION_OPEN', message.author.id);
            return;
        }
        store.dispatch('OPEN_CONVERSATION', message);
    },
    async addToCRM(message) {
        if (message.type == 'user') await store.dispatch('ADD_CONTACT_TO_CRM', { contact_id: message.author.id, type: 'user' });
        if (message.type == 'company') await store.dispatch('ADD_CONTACT_TO_CRM', { contact_id: message.to_company_id, type: 'company' });
        store.dispatch('GET_ALL_CONVERSTATIONS');
        store.dispatch('GET_CRM_ALL_CONTACTS');
    },

    highLight(text) {
      return func.highLight(text, this.crm.searchMSG);
    },

    searchHandler() {
        // console.log(this.crm.searchMSG)
      store.dispatch('GET_ALL_CONVERSTATIONS');
    },
  },
  computed: {
    crm: () => store.state.crm,
    userdata: () => store.state.user,
    search: {
      get() {
        return this.crm.searchMSG;
      },
      set(value) {
        store.commit('SET_CRM_MESSAGE_SEARCH', value);
      }
    },
  },
  watch: {
    search: function () {
      this.debouncedSearch();
    },
  },
  created() {
    this.debouncedSearch = func.debounce(this.searchHandler, 500);
  },
}
</script>

<style>
.wysiwyg b {
    background-color: #CCEEDF;
    font-weight: 700;
}
</style>