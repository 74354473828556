<template>
    <div class="rating">
        <div class="rating__media" >
            <RatingStars :stars="reviewsAvg"></RatingStars>
        </div>

        <div class="rating__text">
            <span class="title-text">{{reviewsCount > 0 ? reviewsCount : ''}}</span>
        </div>
    </div>
</template>

<script>
import RatingStars from '@/components/profile/RatingStars'
export default {
    components: {
        RatingStars
    },
    props: {
        reviewsCount: Number,
        reviewsAvg: Number
    },
}
</script>

<style scoped>

</style>