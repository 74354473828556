import { router } from '@/router'
import forumService from '@/services/service.forum'
import natsIO from '@/plugins/natsIO'

const forum = {
  state: {
    open_sidebar_notification: false,

    topics: {},
    meta: {},

    type: 'All',
    searchTopics: '',
    current_page: 1,

    current_route: '',
    current_route_param: '',

    notifications: [],
  },
  mutations: {
    SET_SIDEBAR_NOTIFICATION_OPEN: (state, payload) => {
      state.open_sidebar_notification = payload;
    },
    SET_FORUM_DATA: (state, payload) => {
      state.topics = payload;
    },
    SET_FORUM_META: (state, payload) => {
      state.meta.page = payload.current_page;
      state.meta.total_pages = payload.last_page;
    },
    SET_FORUM_TYPE_SHOW:(state, payload) => {
      state.type = payload;
    },
    SET_FORUM_TOPICS_SEARCH: (state, payload) => {
      state.searchTopics = payload;
    },
    SET_CURRENT_FORUM_PAGE: (state, payload) => {
      state.current_page = payload;
    },
    RESET_FORUM_TOPICS_SEARCH:(state) => {
      state.type = 'All';
      state.current_page = 1;
      state.searchTopics = '';
    },
    RESET_FORUM_TOPICS_PAGE:(state) => {
      state.current_page = 1;
    },

    SET_ROUTE_IN_FORUM:(state, payload) => {
      state.current_route = payload?.name;
      state.current_route_param = payload?.params.id;
    },

    SET_FORUM_TOPIC_NOTIFICATIONS:(state, payload) => {
      state.notifications = payload?.data;
    },
  },

  actions: {
    SET_FORUM_TOPICS_SEARCH({commit}, payload) {
      commit('RESET_FORUM_TOPICS_PAGE');
      commit('SET_FORUM_TOPICS_SEARCH', payload);
    },

    SET_FORUM_TYPE_SHOW({commit, dispatch}, payload) {
      commit('SET_FORUM_TYPE_SHOW', payload);
      dispatch('FORUM_GET_DATA');
    },

    GET_FORUM_TOPICS({commit, dispatch}) {
      commit('RESET_FORUM_TOPICS_SEARCH');
      dispatch('FORUM_GET_DATA');
    },

    FORUM_LOAD_MORE({commit, dispatch}) {
      commit('SET_CURRENT_FORUM_PAGE', this.state.forum.meta.page + 1);
      dispatch('FORUM_GET_DATA', 'loadmore');
    },

    async FORUM_GET_DATA({commit, dispatch}, payload) {
      const response = await forumService.getTopics({
        type: this.state.forum.type,
        search: this.state.forum.searchTopics,
        page: this.state.forum.current_page,
      });
      if(response.status === 200) {
        if (payload == 'loadmore') {
          commit('SET_FORUM_DATA', this.state.forum.topics.concat(response.data.data));
        } else {
          commit('SET_FORUM_DATA', response.data.data);
        }
        commit('SET_FORUM_META', response.data.meta);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    FORUM_UPDATE_BY_NOTIFY({dispatch}, payload) {
      if(this.state.forum.current_route == 'forum' &&
        payload?.for.includes(this.state.user.user_profile.account_type)
      ) {
        dispatch('FORUM_GET_DATA');
      }
    },


    async STORE_FORUM_TOPIC({dispatch}, payload) {
      const response = await forumService.storeForumTopic(payload);
      if(response.status === 200) {
        dispatch('SHOW_POPUP', { popup: 'forumNewTopicPopup', show: false});
        dispatch('SHOW_POPUP', { popup: 'forumTopicSuccessPopup', show: true});
        if (response.data.notify) natsIO.publishNotify('admin', response.data.notify);
        return true;
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },

    async FOLLOW_FORUM_TOPIC({dispatch}, payload) {
      const response = await forumService.followTopic(payload);
      if(response.status === 200) {
        return true;
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },



    async GET_FORUM_TOPIC_DATA({commit, dispatch}, payload) {
      const response = await forumService.getForumTopicPage(payload);
      if(response.status === 200) {
        commit('SET_PAGE_DATA', response.data);
        dispatch('GET_POSTS_DATA', 'empty');
        dispatch('GET_FORUM_TOPIC_NOTIFICATIONS', payload);
      } else {
        dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
        router.push({ name: 'forum'});
      }
    },

    async GET_FORUM_TOPIC_NOTIFICATIONS({commit, dispatch}, payload) {
      const response = await forumService.getTopicNotifications(payload);
      if(response.status === 200) {
        commit('SET_FORUM_TOPIC_NOTIFICATIONS', response.data);
      } else dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: response.data.errors });
    },


  },
  getters: {

  }
}
export default forum;
