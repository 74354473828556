<template>
    <div class="row content-center">
        <div :class="{ 'popup-visible' : this.user.login_error }">
            <div class="popup-wrap">
                <div class="popup successfully-popup" :class="{ 'active' : this.user.login_error }">
                    <form action="#" class="popup__form">
                        <div class="popup__header">
                            <div class="popup__title">
                                <span class="title-text"></span>
                            </div>
                            <div class="popup-remove" @click="popupRemove">
                                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                        fill="#333333"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#333333"/>
                                </svg>
                            </div>
                        </div><!-- //popup__header -->
                        <div class="popup__content">
                            <div class="popup__section">
                                <div class="wysiwyg">
                                    <p v-if="this.user.login_not_confirm">Your email has not been confirmed yet. <br>Please verify your email by following the link we sent you.</p>
                                    <p v-else>
                                        The email and password you entered did not match our records. <br>Please double-check and try again.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="popup__footer">
                            <div class="btn-wrap btn-wrap_secondary content-right single-btn-right">
                                <div class="form-field"><div class="form-field__content"><button @click.prevent="popupRemove" class="btn btn_primary">Ok</button></div></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col col_7 col_big-desktop-10 col_desktop-12 col_tab-8 col_mob-12">
            <div class="login__main">
                <div class="login__header header">
                    <logo></logo>
                </div>
                <div class="login__content">
                    <div class="login__form login-form">
                        <div class="login-form__header">
                            <span class="title-text">Sign in</span>
                        </div>
                        <div class="login-form__content">
                            <form class="login__form" v-on:submit.prevent="logon">
                                <div class="row">
                                    <div class="col col_12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Email</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input v-model="email" type="text" placeholder="Enter your email "
                                                        class="form-field__input" required>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>
                                    <div class="col col_12">
                                        <div class="form-field form-field_secondary form-field_password">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Password</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input v-model="password" :type="passwordType" placeholder="Enter password"
                                                        class="form-field__input" required>
                                                <svg @click="switchTypeInput" class="form-field__ico"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    version="1.1" id="Capa_1" x="0px" y="0px"
                                                    viewBox="0 0 488.85 488.85"
                                                    style="enable-background:new 0 0 488.85 488.85;"
                                                    xml:space="preserve">
                                                    <g>
                                                        <path d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2   s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025   c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3   C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7   c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div class="form-field__footer">
                                                <router-link :to="{name: 'forgot_password'}">Forgot Password?</router-link>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>
                                    <div class="col col_12">
                                        <div class="form-field form-field_secondary form-field_password">
                                            <div class="form-field__content">
                                                <button type="submit"
                                                        class="btn btn_primary btn_bigger w-100" @submit.prevent="logon">Sign In
                                                </button>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>
                                    <div class="col col_12">
                                        <div class="form-field">
                                            <div class="form-field__devider">
                                                <span class="devider-text">or</span>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>
                                    <div class="col col_6">
                                        <div class="form-field">
                                            <div class="form-field__content">
                                                <button type="submit" @click.prevent="signinSoc('google')" class="btn_quintuple w-100">
                                                    <svg class="btn__ico" width="18" height="18"
                                                            viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip01)">
                                                            <path d="M3.99364 6.78747L0.927879 5.01746C0.335496 6.21456 3.51563e-05 7.56464 0 9C0 10.4155 0.339082 11.7679 0.94057 12.9756L3.99343 11.2131C3.69232 10.535 3.52171 9.78718 3.52174 9C3.52178 8.21285 3.6925 7.46525 3.99364 6.78747Z"
                                                                    fill="#F8B00E"></path>
                                                            <path d="M14.9035 2.20669C13.3219 0.830885 11.255 -0.00150966 8.99371 2.05557e-06C5.89992 0.00207627 3.13854 1.58151 1.51611 4.00163L4.58437 5.77315C5.58214 4.41211 7.18734 3.52171 9.00003 3.52174C10.3215 3.52178 11.5328 3.99414 12.4789 4.77686C12.7105 4.96842 13.0491 4.95088 13.2616 4.73836L14.9326 3.06735C15.1735 2.82639 15.1606 2.43028 14.9035 2.20669Z"
                                                                    fill="#E82A2A"></path>
                                                            <path d="M17.9384 7.95868C17.9034 7.66073 17.6529 7.4352 17.353 7.4352L9.97833 7.43481C9.65419 7.43481 9.39136 7.69761 9.39136 8.02178V10.3677C9.39136 10.6918 9.65415 10.9546 9.97833 10.9546H14.1109C13.7302 11.9453 13.0691 12.7969 12.2274 13.416L13.9827 16.4563C15.7495 15.259 17.0928 13.4487 17.6845 11.3118C18.0062 10.1499 18.0641 9.02641 17.9384 7.95868Z"
                                                                    fill="#356EF1"></path>
                                                            <path d="M11.2124 14.0056C10.5354 14.3072 9.78814 14.4783 9.00007 14.4783C7.18534 14.4784 5.58106 13.5879 4.58399 12.2274L1.52881 13.9914C3.13763 16.3877 5.86807 18.0001 9 18.0001C10.4158 18.0001 11.7634 17.6539 12.9688 17.0479L11.2124 14.0056Z"
                                                                    fill="#2D9B42"></path>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip01">
                                                                <rect width="18" height="18"
                                                                        fill="white"></rect>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span class="btn__text">With Google</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col_6">
                                        <div class="form-field">
                                            <div class="form-field__content">
                                                <button type="submit" class="btn_quintuple w-100" @click.prevent="signinSoc('linkedin')">
                                                    <svg class="btn__ico" width="19" height="18"
                                                            viewBox="0 0 19 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                            <path d="M17.2018 0H1.79817C1.08118 0 0.5 0.581177 0.5 1.29817V16.7018C0.5 17.4188 1.08118 18 1.79817 18H17.2018C17.9188 18 18.5 17.4188 18.5 16.7018V1.29817C18.5 0.581177 17.9188 0 17.2018 0V0ZM6.8847 13.6055H4.69279V7.01106H6.8847V13.6055ZM5.78882 6.1106H5.77454C5.039 6.1106 4.56329 5.60426 4.56329 4.97145C4.56329 4.32436 5.05356 3.83203 5.80338 3.83203C6.55319 3.83203 7.01462 4.32436 7.0289 4.97145C7.0289 5.60426 6.55319 6.1106 5.78882 6.1106ZM14.7883 13.6055H12.5966V10.0776C12.5966 9.19102 12.2793 8.58636 11.4862 8.58636C10.8807 8.58636 10.5201 8.99423 10.3616 9.38795C10.3036 9.52885 10.2895 9.72578 10.2895 9.92285V13.6055H8.09773C8.09773 13.6055 8.12643 7.62973 8.09773 7.01106H10.2895V7.94476C10.5808 7.49542 11.1019 6.85629 12.2648 6.85629C13.7069 6.85629 14.7883 7.79878 14.7883 9.82425V13.6055Z"
                                                                    fill="#0E4FB5"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect width="18" height="18" fill="white"
                                                                        transform="translate(0.5)"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span class="btn__text">With LinkedIn</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="login__footer footer">
                    <p>Don’t have an account?  <router-link :to="{name: 'account_create'}" @click.prevent="resetSoc()">Register now</router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Logo from "./Logo0.vue"
    import store from '../store'

    export default {
        name: "Login",
        components: {
            "logo":Logo,
        },
        data() {
            return {
                email: '',
                password: '',
                passwordType: 'password'
            }
        },
        methods: {
            logon: function() {
                let creds = {}
                creds.email = this.email
                creds.password = this.password
                store.dispatch('USER_LOGIN', creds)
            },
            signinSoc(social){
                store.dispatch('USER_SOCIAL_LOGIN', social);
            },
            switchTypeInput: function() {
                this.passwordType = this.passwordType === "password" ? "text" : "password";
            },
            popupRemove: function() {
                store.dispatch('USER_LOGIN_RESET');
            },
            resetSoc() {
                store.dispatch('USER_RESET_SOCIAL_LOGIN');
            }
        },
        computed: {
            user: () => store.state.user,
        }
    }
</script>
