<template>
  <div class="chatWindow singleMessage" :class="{'active' : chat.conversation_open && chat.chat_open}">
    <div class="chatHeader messageHead">
      <button class="backBtn" @click.prevent="closeConversation"></button>

      <div class="messageAvatar"
        :class="{ 'speakerOnline': (chat.statuses[chat.conversation.user.id] == 'online'), 'speakerOffline': (chat.statuses[chat.conversation.user.id] == 'offline'), 'speakerDisconnect': (chat.statuses[chat.conversation.user.id] == 'disconnect') }"
        :style="{'background-color': chat.conversation.user.avatar ? 'transparent' : '#EB5757'}">
        <img v-if="chat.conversation.user.avatar" :src="chat.conversation.user.avatar" alt="Avatar">
        <template v-else>{{ chat.conversation.user.shortname }}</template>
      </div>

      <p class="messageName">{{ chat.conversation.user.name }}</p>
      <a :class="{'active': chat.conversation.muted}" @click.prevent="toggleMute" class="mutedIcon" href="#">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.92633 10.6665H1.33366C1.15685 10.6665 0.987279 10.5963 0.862254 10.4713C0.73723 10.3462 0.666992 10.1767 0.666992 9.99985V5.99985C0.666992 5.82304 0.73723 5.65347 0.862254 5.52845C0.987279 5.40342 1.15685 5.33318 1.33366 5.33318H3.92633L7.45566 2.44518C7.50449 2.40515 7.56369 2.37981 7.62637 2.37211C7.68905 2.36442 7.75262 2.37468 7.80969 2.40172C7.86676 2.42875 7.91497 2.47144 7.94872 2.52481C7.98247 2.57818 8.00037 2.64004 8.00033 2.70318V13.2965C8.00037 13.3597 7.98247 13.4215 7.94872 13.4749C7.91497 13.5283 7.86676 13.571 7.80969 13.598C7.75262 13.625 7.68905 13.6353 7.62637 13.6276C7.56369 13.6199 7.50449 13.5946 7.45566 13.5545L3.92699 10.6665H3.92633ZM13.6097 7.99985L15.967 10.3572L15.0243 11.2999L12.667 8.94252L10.3097 11.2999L9.36699 10.3572L11.7243 7.99985L9.36699 5.64252L10.3097 4.69985L12.667 7.05719L15.0243 4.69985L15.967 5.64252L13.6097 7.99985Z" fill="#006137" />
        </svg>
      </a>
      <div class="chatActivity">
        <button class="messageSearchBtn" @click.prevent="toggleSearch"></button>
        <button class="messageMenuBtn" @click.prevent="toggleMenu"></button>
        <div class="hideBig">
          <div class="divider"></div>
          <button class="chatCloseBtn" @click.prevent="toggleChat">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 5L19 19M5 19L19 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="messagesSearch" :class="{'active': showSearch || chat.chat_search_mode}">
      <div class="searchNav" v-show="chat.found_messages_count > 1">
        <svg @click="goPrevFound" :class="{'lastSearchItem': !chat.found_counts?.prev}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 15.7071C5.68342 16.0976 6.31658 16.0976 6.70711 15.7071L12 10.4142L17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071C19.0976 15.3166 19.0976 14.6834 18.7071 14.2929L12.7071 8.29289C12.3166 7.90237 11.6834 7.90237 11.2929 8.29289L5.29289 14.2929C4.90237 14.6834 4.90237 15.3166 5.29289 15.7071Z" fill="#00A961" />
        </svg>
        <div class="searchCount">
          <span class="searchCurrent">{{ chat.found_counts?.current }}</span>/<span class="searchTotal">{{ chat.found_messages_count }}</span>
        </div>
        <svg @click="goNextFound" :class="{'lastSearchItem': !chat.found_counts?.next}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#00A961" />
        </svg>
      </div>
      <form @submit.prevent>
        <input type="text" placeholder="Search ..." v-model="searchMessage" ref="searchMessage">
        <span class="closeMessageSearch" @click.prevent="toggleSearch"></span>
      </form>
    </div>

    <div class="chatBody" :class="{'emptyMessages': chat.conversation.messages.data.length < 1}" id="chatBody">
      <div class="chatMessage" ref="chatMessage">

        <div class="emptyMessagesContainer">
          <img src="@/assets/images/emptyMessages.svg" alt="Empty">
          <template v-if="searchMessage.length > 0">
            <p class="emptyContainerTitle">No results</p>
            <p class="emptyContainerText">There are no results for <span>«{{searchMessage}}»</span>.</p>
            <p class="emptyContainerText">Change the query.</p>
          </template>
          <template v-else>
            <p class="emptyContainerTitle">There is nothing here yet</p>
            <p class="emptyContainerText">Write a new message to start chatting...</p>
          </template>
        </div>

        <template v-for="(item, index) in chat.conversation.messages.data" :key="item.id">

          <div class="singleMessageBlock"
            :class="{'yourBlock': item.author_id == user.user_profile.id, 'opponentBlock': item.author_id != user.user_profile.id}"
            :ref="item.id">
            <div class="singleMessageHead">
              <p class="singleMessageHeadName">{{ item.author_id == user.user_profile.id ? (user.user_profile.name + ' (You)') : chat.conversation.user.name }}</p>
              <span class="singleMessageHeadTime">{{item.time}}</span>
            </div>
            <div class="singleMessageText">
              <div v-html="highLight(item.text)"></div>
              <template v-if="item.attach">
                <div v-for="attach in item.attach" :key="attach.id" class="singleMessageAttachment" @click.prevent="showAttach(attach)">
                  
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
                    </svg>
                    <div v-if="attach.user_defined_filename" class="singleMessageAttachmentTitle">{{this.fileNameFormat(attach.user_defined_filename)}}</div>
                    <div v-if="attach.size" class="singleMessageAttachmentSize">{{this.convertBytes(attach.size)}}</div>
                  
                </div>
              </template>

            </div>
          </div>

          <div class="dateBlock"
            v-if="index == (chat.conversation.messages.data.length -1) || 
              ( index < (chat.conversation.messages.data.length -1) && chat.conversation.messages.data[index+1].today_start != item.today_start )">
            {{item.day}}
          </div>

        </template>


      </div>

      <button v-if="showBtnBottom || chat.conversation.unread > 0" @click="scrollToLastMessage" class="goDownBtn">
        <span v-if="chat.conversation.unread > 0" class="counter">{{ chat.conversation.unread }}</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#5D6167"/>
        </svg>
      </button>
    </div>
    <div class="chatFooter">
      <div v-if="this.chatSpinner" class="chat_spinner_wrap">
          <div class="chat_spinner"></div>
      </div>
      <form @submit.prevent ref="inpuMsgForm">
        <textarea rows="1" v-model="message" placeholder="Write message" ref="inputMsg"></textarea>
        <div class="chatFooterActivity">
          <span @click="uploadInit" class="chatFooterAttachment"></span>
          <div class="attach">
            <div class="attach-item__input">
                <input type="file" id="file" class="input" multiple
                    accept=".pdf,.doc,.docx,.odt,.xls,.xlsx,.ods,.ppt,.ppts,.odp,.jpeg,.jpg,.png,.gif,.mp4"
                    ref="file" 
                    @change.prevent="handleFileUpload">
            </div>
          </div>
          <span class="chatFooterSmile" :class="{'active': showEmoji}" @click.prevent="toggleEmoji"></span>
          <button class="sendMessage" @click.prevent="sendMsg"></button>
        </div>
        <Emoji @insert-emoji="insertEmoji" :show-emoji="showEmoji"></Emoji>
      </form>
      <!--  -->
      <div v-if="this.files.length" class="chatFooterAttachmentContainer" :class="{ 'counted' : this.files.length > 2, ' active' : !this.expandFiles }">
        <div class="chatFooterAttachmentHead">
          <p>Attached files: <span class="attachmentCouner">{{this.files.length}}</span></p>
          <span class="showAttachments"   @click="showAttachmentsToggle"></span>
        </div>
        
          <div v-for="(file, index) in this.files" :key="index" class="chatFooterAttachmentItem" >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
            </svg>
            <p class="chatFooterAttachmentItemTitle">{{this.fileNameFormat(file.name)}}</p>
            <span class="chatFooterAttachmentItemSize">{{this.convertBytes(file.size)}}</span>
            <button class="updateAttachment"></button>
            <button class="removeAttachment" @click="removeFile(index)"></button>
          </div>
        
      </div>
    </div>
    <div class="messagesMenu" :class="{'active': showMenu}">
      <button @click.prevent="toggleDeleteChat" class="deleteBtn">Delete</button>
      <button @click.prevent="setUnread" class="unreadBtn">Mark as unread for me</button>
      <button @click.prevent="toggleMute" class="muteBtn">
        <span v-if="chat.conversation.muted">UnMute</span>
        <span v-else>Mute</span>
      </button>
      <button v-if="!chat.conversation.user.in_my_crm && !chat.conversation.user.private && !chat.conversation.user.private_message" @click.prevent="addToCRM(chat.conversation.user.id)" href="#" class="btn">
        <span class="btn__text">Add to CRM</span>
      </button>
      <template v-if="chat.conversation.user.in_my_crm">
        <router-link :to="{ name: 'crmUser', params: { id: chat.conversation.user.crm_id }}" @click="toggleChat" class="dropdown__link">
          Open CRM Card
        </router-link>
      </template>
      <router-link :to="{name: 'profile', params: {id: chat.conversation.user.id}}" @click="toggleChat" :class="{'disabled': chat.conversation.user.private}">Open Profile Page</router-link>
    </div>
    <div class="deleteModal" :class="{'active': showDeletePopup}">
      <div class="deleteModalBody">
        <div class="deleteModalBodyHead">
          <p>Delete Chat</p>
          <span @click="toggleDeleteChat" class="closeModal"></span>
        </div>
        <div class="deleteModalBodyContent">
          <p>Are you sure you want to delete this chat?</p>
          <div class="custom-check">
            <label class="custom-check__label">
              <input class="custom-check__input" v-model="onlyForMe" type="checkbox">
              <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
              </svg>
              <span class="custom-check__text">only for me</span>
            </label>
          </div>
        </div>
        <div class="deleteModalFooter">
          <button @click="toggleDeleteChat" class="cancelBtn">Cancel</button>
          <button @click="deleteChat" class="applyBtn">Delete</button>
        </div>
      </div>
    </div>

    <div class="deleteAlert" :class="{'active': showDeleteAlert}">
      <div class="deleteCountDown">
        <span class="deleteTimer" ref="deleteTimer"></span>
        <svg><circle r="11" cx="12" cy="12"></circle></svg>
      </div>
      <p>Сhat deleted</p>
      <button class="deletionAbort" ref="deletionAbort">Cancel</button>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import func from '@/plugins/functions'
  import Emoji from '@/components/chat/Emoji'

  export default {
    name: "Conversation",
    components: {Emoji},
    data() {
      return {
        showSearch: false,
        showMenu: false,
        showEmoji: false,
        message: '',
        files: [],
        expandFiles: true,
        triggerScroll: true,
        showDeletePopup: false,
        showDeleteAlert: false,
        onlyForMe: false,
        showBtnBottom: false,
        chatSpinner: false,
        is_mobile: false,
      }
    },
    methods: {
      toggleChat() {
        store.dispatch('CHAT_TOGGLE_OPEN', !this.chat.chat_open);
        this.showMenu = false;
      },

      toggleSearch() {
        this.showSearch = !this.showSearch;
        this.$nextTick(() => {
          if(this.showSearch) this.$refs.searchMessage.focus();
          else store.commit('CHAT_SET_SEARCH_MESSAGE', '');
        });
        
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },

      /* Emoji */
      toggleEmoji() {
        this.showEmoji = !this.showEmoji;
      },
      insertEmoji(data) {
        let index = this.$refs.inputMsg.selectionStart;
        this.message = this.message.slice(0, index) + data+ this.message.slice(index);
        this.showEmoji = false;
      },

      toggleMute() {
        store.dispatch('CHAT_TOGGLE_MUTE');
        this.showMenu = false;
      },

      setUnread() {
        store.dispatch('CHAT_SET_UNREAD');
        this.showMenu = false;
      },

      addToCRM(id) {
        store.dispatch('CHAT_ADD_CONTACT_TO_CRM', { contact_id: id, type: 'user' });
        this.showMenu = false;
      },

      closeConversation() {
        this.message = '';
        this.$refs.inputMsg.style.height = '17px';
        store.dispatch('CHAT_CONVERSATION_TOGGLE_OPEN', {show: false, conversation_id: false});
      },

      toggleDeleteChat() {
        this.showDeletePopup = !this.showDeletePopup;
        this.showMenu = false;
      },

      deleteChat() {
        this.showDeletePopup = false;
        this.showDeleteAlert = true;

        let countdown = 5;
        this.$refs.deleteTimer.textContent = countdown;

        let deletionCounter = setInterval(() => {
          countdown = --countdown <= 0 ? 0 : countdown;
          this.$refs.deleteTimer.textContent = countdown;
        }, 1000);

        let timeout = setTimeout(() => {
          clearInterval(deletionCounter);
          this.showDeleteAlert = false;
          store.dispatch('CHAT_DELETE_CONVERSATION', {onlyForMe: this.onlyForMe});
          this.onlyForMe = false;
        }, 5500)

        this.$refs.deletionAbort.addEventListener("click", () => {
          clearInterval(deletionCounter);
          clearTimeout(timeout);
          this.showDeleteAlert = false;
          this.onlyForMe = false;
        })
      },

      async sendMsg() {
        if(!(this.message.length > 0 || this.files.length > 0)) return false;
        this.chatSpinner = true;
        if(this.files.length > 0) {
          let data = {
            conversation_id: this.chat.conversation_id,
            files: this.files,
          };
          await store.dispatch('PUT_CHAT_FILES', data);
        }
        await store.dispatch('CHAT_NEW_MESSAGE', {mesage: this.message});
        this.message = '';
        this.files = [];
        this.chatSpinner = false;
        this.inputMsgChange();
      },

      inputMsgChange() {
        if(this.message.length > 0) this.$refs.inpuMsgForm.classList.add('active')
        else this.$refs.inpuMsgForm.classList.remove('active')
        this.$refs.inputMsg.style.height = '17px';
        let height = this.$refs.inputMsg.scrollHeight;
        height = height < 17 ? 17 : height;
        height = height > 119 ? 119 : height;
        this.$refs.inputMsg.style.height = height + 'px';
        if(this.message.length === 0) this.$refs.inputMsg.style.height = '17px';
      },

      inputEvent(event) {
          func.inputEvent(event, this.sendMsg, (()=>this.message += "\n"))
      },


      loadmore() {
        let container = this.$el.querySelector(".chatBody");
        container.onscroll = async() => {
          let containerHeight = container.offsetHeight;
          let saved = container.scrollHeight;
          let scrollTop = container.scrollTop;
          let scrollBottom = saved-scrollTop-containerHeight;
          this.showBtnBottom = scrollBottom > 60 ? true : false;
          // console.log('bottom:'+(saved-scrollTop-containerHeight), 'clientHeight:'+container.clientHeight)
          // console.log('scrollHeight:'+this.$refs.chatMessage.scrollHeight, 'clientHeight:'+this.$refs.chatMessage.clientHeight, 'scrollTop:'+this.$refs.chatMessage.scrollTop)
          if(container.scrollTop <= 0 && this.triggerScroll) { /* Scroll UP */
            if(parseInt(this.chat.conversation.messages.meta_top?.current_page + 1) <= this.chat.conversation.messages.meta_top?.last_page){
              this.triggerScroll = false;
              if(this.chat.chat_search_mode) await store.dispatch('CHAT_CONVERSATION_LOAD_MORE_FROM', 'top');
              else await store.dispatch('CHAT_CONVERSATION_LOAD_MORE', 'top');
              container.scrollTop = container.scrollHeight - saved;
              this.triggerScroll = true;
            }
          }
          if(container.scrollTop >= (saved - containerHeight - 1) && this.triggerScroll) { /* Scroll DOWN */
            if(parseInt(this.chat.conversation.messages.meta_bottom?.current_page + 1) <= parseInt(this.chat.conversation.messages.meta_bottom?.last_page)){
              this.triggerScroll = false;
              if(this.chat.chat_search_mode) await store.dispatch('CHAT_CONVERSATION_LOAD_MORE_FROM', 'bottom');
              else await store.dispatch('CHAT_CONVERSATION_LOAD_MORE', 'bottom');
              container.scrollTop = scrollTop;
              this.triggerScroll = true;
            }
          }
          /* set read new messages */
          if(this.triggerScroll) {
            for(let msg of this.chat.conversation.messages.data) {
              if(msg.unread && msg.author_id === this.chat.conversation.user.id) {
                if((containerHeight + scrollTop) >= this.$refs[msg.id].offsetTop - 5 ) {
                  store.dispatch('CHAT_SET_READ_MSG', msg.id);
                  this.debouncedSetReadMessage(msg.id);
                  break;
                }
              }
            }
          }
        }
      },

      scrollInit() {
        this.$nextTick(() => {
          if(this.$refs[this.chat.found_message_id]) {
            let alignTop = true;
            if(this.chat.conversation.unread > 0) alignTop = false;
            this.$refs[this.chat.found_message_id].scrollIntoView(alignTop);
          }
        });
      },

      // Files
      uploadInit() {
        const elem = this.$refs.file;
        elem.click();
      },

      handleFileUpload() {
        this.$refs.file.files
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          this.files.push(this.$refs.file.files[i]);
        }
        if(this.files.length > 0) this.$refs.inpuMsgForm.classList.add('active');
      },

      removeFile(index) {
        if(this.files.length > 0) this.$refs.inpuMsgForm.classList.add('active');
        this.files.splice(index, 1);
      },

      showAttachmentsToggle() {
        this.expandFiles = !this.expandFiles;
      },

      convertBytes(bytes) {
        if(bytes <= 0) return '';
        let units = ['b', 'kb', 'mb'];
        let i = 0;
        for(i; bytes> 1024; i++) {
          bytes /= 1024;
        }

        return bytes.toFixed(0)+units[i];
      },

      fileNameFormat(filename) {
        var filename_split = filename.split('.');
        var ext = filename_split[filename_split.length - 1];
        filename_split.pop();
        var name =  filename_split.join();
        if(name.length > 23) {
            return name.substr(0,13) + "..." + name.substr(-5) + "." +ext;
        }
        return filename;
      },
      
      showAttach: function (attachment) {
        store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
      },

      setReadMessage(id) {
        store.dispatch('CHAT_SET_READ_MSG_ON_SERVER', id);
      },

      async scrollToLastMessage() {
        this.triggerScroll = false;
        await store.dispatch('CHAT_CONVERSATION_LOAD_MESSAGES', {conversation_id: this.chat.conversation_id, view_all: 'with_new'});
        this.triggerScroll = true;
        this.scrollInit();
      },

      /* Search */
      goPrevFound() {
        store.dispatch('CHAT_CONVERSATION_SEARCH_MESSAGE', {show: true, found_message_id: this.chat.found_counts?.prev});
      },

      goNextFound() {
        store.dispatch('CHAT_CONVERSATION_SEARCH_MESSAGE', {show: true, found_message_id: this.chat.found_counts?.next});
      },

      searchMessageHandler() {
        if(this.chat.search_message.length > 0) store.dispatch('CHAT_CONVERSATION_SEARCH_MESSAGE', {show: true});
        else if(this.chat.conversation_id) store.dispatch('CHAT_CONVERSATION_LOAD_MESSAGES', {conversation_id: this.chat.conversation_id});
      },
      highLight(text) {
        return func.highLight(text, this.chat.search_message, 'span');
      },
      focus() {
        console.log((this.chat.conversation_open && this.chat.chat_open && !this.is_mobile))
        if(this.chat.conversation_open && this.chat.chat_open && !this.is_mobile)
          this.$nextTick(() => this.$refs.inputMsg.focus());
      },

    },

    watch: {
      searchMessage() {
        this.debouncedSearchMessage();
      },
      'chat.found_message_id'() {
        if(this.chat.found_message_id) this.scrollInit();
      },
      message() {
        this.inputMsgChange();
      },
      'chat.conversation_open'(newVal) {
        if(newVal) this.focus();
      },
      'chat.chat_open'(newVal) {
        if(newVal) this.focus();
      },
    },
    computed: {
      chat: () => store.state.chat,
      user: () => store.state.user,
      searchMessage: {
        get() {
          return this.chat.search_message;
        },
        set(value) {
          store.commit('CHAT_SET_SEARCH_MESSAGE', value);
        }
      },
    },
    mounted() {
      this.is_mobile = (window.innerWidth <= 578) ? true : false;
      this.scrollInit();
      this.loadmore();
      this.$refs.inputMsg.addEventListener('keydown', this.inputEvent);
      this.debouncedSearchMessage = func.debounce(this.searchMessageHandler);
      this.debouncedSetReadMessage = func.debounce(this.setReadMessage);
    },
    beforeUnmount() {
      this.$refs.inputMsg.removeEventListener('keydown', this.inputEvent)
    }
  }
</script>
<style>
.chat_spinner_wrap {
   position: absolute;
   width: 100%;
   height: 100%;
   background: rgb(255 255 255 / 80%);
   z-index: 5;
}
.chat_spinner {
   width: 56px;
   height: 56px;
   border-radius: 50%;
   border: 9px solid;
   border-color: #dbdcef;
   border-right-color: #00a961;
   animation: chat_spinner-d3wgkg 1s infinite linear;
   position: sticky;
   z-index: 1;
   left: calc(50% - 28px);
   top: calc(50% - 28px);
}
.chat_spinner_popup .chat_spinner {
  position: inherit;
}
.chat_spinner_msg {
  left: 0;
  top: 0;
}
@keyframes chat_spinner-d3wgkg {
   to {
      transform: rotate(1turn);
   }
}
</style>