<template>
    <header id="header" class="site-header" :class="{'site-header_menu-active' : this.open}">
        <div class="container">

            <div class="nav-toggle" @click.prevent="toogleMenu">
                <span class="nav-toggle__link"></span>
                <span class="nav-toggle__link"></span>
                <span class="nav-toggle__link"></span>
            </div>

            <!-- logo -->
            <logo></logo>

            <!-- navigation -->
            <div class="site-header__navigation navigation">
                <nav class="nav">
                    <ul class="nav__list">
                        <li class="nav__item">
                            <router-link :to="{name: 'home'}" class="nav__link">Home</router-link>
                        </li>
                        <li class="nav__item">
                            <router-link :to="{name: 'forum'}" class="nav__link"
                            :class="{'router-link-active': this.$route.name == 'forumTopic'}">Community Forums</router-link>
                        </li>
                        <li class="nav__item">
                            <div class="dropdown d-flex-desktop align-center flex_desktop-wrap"
                                :class="{ dropdown_active: showDropdown }">
                                <a class="dropdown__init nav__link d-flex" href="#"
                                    :class="{'router-link-active': ['experts', 'investors', 'startups'].includes($route.name)}"
                                    @click.prevent="showDropdown = !showDropdown">
                                   <span>Our Network</span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.08748 4.83751C3.31529 4.60971 3.68463 4.60971 3.91244 4.83751L6.99996 7.92503L10.0875 4.83751C10.3153 4.60971 10.6846 4.60971 10.9124 4.83751C11.1402 5.06532 11.1402 5.43466 10.9124 5.66247L7.41244 9.16247C7.18463 9.39028 6.81529 9.39028 6.58748 9.16247L3.08748 5.66247C2.85967 5.43466 2.85967 5.06532 3.08748 4.83751Z" fill="#5D6167"/>
                                    </svg>
                                </a> 
                                <div class="dropdown__content">
                                    <ul class="dropdown__list">
                                        <li class="dropdown__item"><router-link :to="{name: 'startups'}" class="dropdown__link">Entrepreneurs</router-link></li>
                                        <li class="dropdown__item"><router-link :to="{name: 'experts'}" class="dropdown__link">Experts</router-link></li>
                                        <li class="dropdown__item"><router-link :to="{name: 'investors'}" class="dropdown__link">Investors</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li class="nav__item">
                            <router-link :to="{name: 'notifications'}" class="nav__link">
                                <span>Notifications</span>
                                <span class="badge" v-if="notifications.count_new > 0">{{ notifications.count_new }}</span>
                            </router-link>
                        </li>
                        <li class="nav__item"><router-link :to="{name: 'crm'}" class="nav__link">CRM</router-link></li>
                        <li class="nav__item"><router-link :to="{name: 'jobs'}" class="nav__link">Jobs</router-link></li>
                        <!-- <li class="nav__item"><router-link :to="{name: 'payments'}" class="nav__link">Payments</router-link></li> -->
                        <!-- <li class="nav__item"><router-link :to="{name: 'about'}" class="nav__link">About</router-link></li> -->
                    </ul><!-- //nav__list -->
                </nav><!-- //nav -->
            </div>

            <user-corner></user-corner>
        </div><!-- //container -->
    </header><!-- //site-header -->
</template>

<script>
    // import VLink from "./VLink.vue"
    import store from '../store'
    import Logo from "./Logo.vue"
    import UserCorner from "./UsersCorner.vue"

    export default {
        data() {
            return {
                open: false,
                showDropdown: false,
            };
        },
        components: {
            // VLink,
            Logo,
            "user-corner":UserCorner,
        },
        methods: {
            toogleMenu(e) {
                e.preventDefault();
                e.stopPropagation();
                this.open = !this.open;
                if(this.open) document.body.classList.add('modal-open')
                if(!this.open) document.body.classList.remove('modal-open')
            },
            closeSelect(e) {
                if (!e.target.closest(".dropdown_active")) {
                    this.showDropdown = false;
                }
            },
        },
        computed: {
            notifications: () => store.state.notifications,
        },
        mounted() {
            document.addEventListener('click', this.closeSelect)
        },
        beforeUnmount() {
            document.removeEventListener('click', this.closeSelect)
        }
    }
</script>