//**** Layout with header on each page and with sidebar
<template>
  <div class="pt_60">
    <nav-header></nav-header><!-- * header-panel * -->

    <div @click.prevent="openSidebarPages" class="sub-header sub-header_main">
        <span class="sub-header__text">Work Pages</span>
        <svg class="sub-header__ico" width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.24408 4.4107C6.91864 4.73614 6.91864 5.26378 7.24408 5.58922L11.6548 9.99996L7.24408 14.4107C6.91864 14.7361 6.91864 15.2638 7.24408 15.5892C7.56951 15.9147 8.09715 15.9147 8.42259 15.5892L13.4226 10.5892C13.748 10.2638 13.748 9.73614 13.4226 9.4107L8.42259 4.4107C8.09715 4.08527 7.56951 4.08527 7.24408 4.4107Z"
                fill="#5D6167"/>
        </svg>
    </div>

    <div @click.prevent="openSidebarNotification" v-if="['forum', 'forumTopic', 'post'].includes($route.name)" class="sub-header sub-header_secondary">
      <span class="sub-header__text">Notifications</span>
      <svg class="sub-header__ico" width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M7.24408 4.4107C6.91864 4.73614 6.91864 5.26378 7.24408 5.58922L11.6548 9.99996L7.24408 14.4107C6.91864 14.7361 6.91864 15.2638 7.24408 15.5892C7.56951 15.9147 8.09715 15.9147 8.42259 15.5892L13.4226 10.5892C13.748 10.2638 13.748 9.73614 13.4226 9.4107L8.42259 4.4107C8.09715 4.08527 7.56951 4.08527 7.24408 4.4107Z"
          fill="#5D6167" />
      </svg>
    </div>

    <div @click.prevent="openSidebarMedia" v-if="['home', 'page'].includes($route.name)" class="sub-header sub-header_secondary">
      <span class="sub-header__text">Files &amp; media</span>
      <svg class="sub-header__ico" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24408 4.4107C6.91864 4.73614 6.91864 5.26378 7.24408 5.58922L11.6548 9.99996L7.24408 14.4107C6.91864 14.7361 6.91864 15.2638 7.24408 15.5892C7.56951 15.9147 8.09715 15.9147 8.42259 15.5892L13.4226 10.5892C13.748 10.2638 13.748 9.73614 13.4226 9.4107L8.42259 4.4107C8.09715 4.08527 7.56951 4.08527 7.24408 4.4107Z" fill="#5D6167"></path>
      </svg>
    </div>


    <div class="middle">
        <div class="container">
            <div class="row">
                <div class="col col_3 col_big_desktop_4 col_tab-12">
                    <sidebar></sidebar>
                </div>
                <div class="col col_9 col_big_desktop_8 col_tab-12">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>

    <CompanyLogoPopup></CompanyLogoPopup>
    <UserEditLogoPopup />
    <PageAddPopup></PageAddPopup>
    <settings-popup></settings-popup>
    <SendMessagePopup></SendMessagePopup>

    <FullReviewsViewPopup></FullReviewsViewPopup>
    <FullReviewAddPopup></FullReviewAddPopup>

    <feedBackPopup />

    <ConfirmPopup></ConfirmPopup>
    <errors-popup></errors-popup>
    <success-popup></success-popup>
    <main-footer></main-footer><!-- * footer-panel * -->
  </div>
  <Chat></Chat>
  <Conversation></Conversation>
</template>

<script>
    import Sidebar from "../components/Sidebar.vue"
    import Navheader from "../components/Navheader.vue"
    import Mainfooter from "../components/MainFooter.vue"
    import errorsPopup from "../components/popups/Errors.vue"
    import successPopup from "../components/popups/Successfully.vue"
    import settingsPopup from '@/components/popups/Settings'
    import ConfirmPopup from '@/components/popups/Confirm'
    import PageAddPopup from '@/components/popups/PageAdd'
    import CompanyLogoPopup from '@/components/popups/CompanyLogo'
    import UserEditLogoPopup from '@/components/popups/UserEditLogo'
    import SendMessagePopup from '@/components/popups/SendMessage'
    import Chat from '@/components/chat/Chat'
    import Conversation from '@/components/chat/Conversation'
    import FullReviewAddPopup from '@/components/popups/FullReviewAdd'
    import FullReviewsViewPopup from '@/components/popups/FullReviewsView'
    import feedBackPopup from '@/components/popups/FeedBack'
    import store from '../store'

    export default {
        name: "MainLayout",
        components: {
            "nav-header": Navheader,
            "sidebar": Sidebar,
            "main-footer": Mainfooter,
            "errors-popup" : errorsPopup,
            "success-popup" : successPopup,
            "settings-popup" : settingsPopup,
            ConfirmPopup,
            PageAddPopup,
            CompanyLogoPopup,
            UserEditLogoPopup,
            SendMessagePopup,
            Chat,
            Conversation,
            FullReviewAddPopup,
            FullReviewsViewPopup,
            feedBackPopup,
        },
        methods: {
            openSidebarPages() {
                store.commit('SET_SIDEBAR_PAGES_OPEN', true);
            },
            openSidebarMedia() {
                store.commit('SET_SIDEBAR_MEDIA_OPEN', true);
            },
            openSidebarNotification() {
                store.commit('SET_SIDEBAR_NOTIFICATION_OPEN', true);
            },
        }
    }
</script>
