<template>
<div :class="{ 'popup-visible' : this.popups.calendarEventPopup }">
    <div class="popup-wrap" @click.self="closePopup">
        <div class="popup education-popup event-popup" :class="{ 'active' : this.popups.calendarEventPopup }">
            <form action="#" class="popup__form">
                <div class="popup__header">
                    <div class="popup__title">
                        <span v-if="this.popups.calendarEventPopupData.title" class="title-text">Edit event</span>
                        <span v-else class="title-text">Add event</span>
                    </div>
                    <div class="popup-remove" @click.prevent="closePopup">
                        <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                fill="#333333"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                fill="#333333"/>
                        </svg>
                    </div>
                </div><!-- //popup__header -->
                <form class="main__form" autocomplete="off" v-on:submit.prevent="addEvent">
                <div class="popup__content">
                <div class="popup__section">
                    <div class="row">
                        <div class="col col_12">
                            <div class="form-field">
                                <div class="form-field__header">
                                    <label class="form-field__title">Title</label>
                                </div>
                                <div class="form-field__content">
                                    <input type="text" :value="this.popups.calendarEventPopupData.title" ref="title"
                                    :class="{ 'input-errors' : this.errors.title }"
                                    placeholder="Enter the title"
                                    class="form-field__input" required>

                                    <p v-if="this.errors.title"
                                        class="form-errors">{{ this.errors.title }}</p>
                                </div>
                            </div><!-- //form-field -->
                        </div>

                        <div class="col col_12">
                            <div class="form-field">
                                <div class="form-field__header">
                                    <label class="form-field__title">Description</label>
                                </div>
                                <div class="form-field__content border-bug" :class="{ 'input-errors' : this.errors.description }">
                                    <textarea :value="this.popups.calendarEventPopupData.description" ref="description" placeholder="Enter the description"
                                    class="form-field__input"></textarea>
                                </div>
                                    <p v-if="this.errors.description"
                                        class="form-errors">{{ this.errors.description }}</p>
                            </div><!-- //form-field -->
                        </div>



                        <div class="col col_4 col_tab-6 col_mob-12">
                            <div class="form-field form-field_tertiary">
                                <div class="form-field__header">
                                    <label class="form-field__title">Date</label>
                                </div>
                                <div class="form-field__content">
                                    <input :value="this.popups.calendarEventPopupData.date" type="text" placeholder="Сhoose a date"
                                           ref="date"
                                           :class="{ 'input-errors' : this.errors.date }"
                                           class="form-field__input form-field__input_calendar"
                                           required>
                                    <svg class="form-field__ico" width="18" height="18"
                                         viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M3.75 3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.4142 3.33579 15.75 3.75 15.75H14.25C14.6642 15.75 15 15.4142 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H3.75ZM1.5 4.5C1.5 3.25736 2.50736 2.25 3.75 2.25H14.25C15.4926 2.25 16.5 3.25736 16.5 4.5V15C16.5 16.2426 15.4926 17.25 14.25 17.25H3.75C2.50736 17.25 1.5 16.2426 1.5 15V4.5Z"
                                              fill="#00A961"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V1.5C11.25 1.08579 11.5858 0.75 12 0.75Z"
                                              fill="#00A961"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V4.5C6.75 4.91421 6.41421 5.25 6 5.25C5.58579 5.25 5.25 4.91421 5.25 4.5V1.5C5.25 1.08579 5.58579 0.75 6 0.75Z"
                                              fill="#00A961"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M1.5 7.5C1.5 7.08579 1.83579 6.75 2.25 6.75H15.75C16.1642 6.75 16.5 7.08579 16.5 7.5C16.5 7.91421 16.1642 8.25 15.75 8.25H2.25C1.83579 8.25 1.5 7.91421 1.5 7.5Z"
                                              fill="#00A961"/>
                                    </svg>
                                    <p v-if="this.errors.date"
                                        class="form-errors">{{ this.errors.date }}</p>
                                </div>
                            </div><!-- //form-field -->
                        </div>


                        <div class="col col_4 col_tab-6 col_mob-12">
                            <div class="form-field">
                                <div class="form-field__header">
                                    <label class="form-field__title">Hour</label>
                                </div>
                                <div class="form-field__content" :class="{ 'input-errors' : this.errors.time }">

                                    <select :value="this.popups.calendarEventPopupData.hour" ref="time">
                                        <option v-for="(time, index) in this.timepick" :key="index" :value="time">{{time}}</option>
                                    </select>
                                    <p v-if="this.errors.time"
                                    class="form-errors">{{ this.errors.time }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col col_4 col_tab-6 col_mob-12">
                            <div class="form-field">
                                <div class="form-field__header">
                                    <label class="form-field__title">Duration</label>
                                </div>
                                <div class="form-field__content" :class="{ 'input-errors' : this.errors.time }">
                                    <select :value="this.popups.calendarEventPopupData.duration" ref="duration">
                                        <option v-for="(time, index) in this.timepick" :key="index" :value="time">{{time}}</option>
                                    </select>
                                    <p v-if="this.errors.duration"
                                    class="form-errors">{{ this.errors.duration }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col col_12">
                            <div class="form-field">
                                <div class="form-field__header">
                                    <label class="form-field__title">Guests</label>
                                </div>
                                <div class="form-field__content">

                                <div class="select-smart select">
                                    <div class="select__header select-header">
                                        <div class="select-header__content">
                                            <div class="select-header__list">
                                                <div v-for="(guest, index) in this.selected_guests_members" :key="index">
                                                    <div class="select-header__item select-item">
                                                    <div class="select-item__title">
                                                        <span class="title-text">{{guest}}</span>
                                                    </div>
                                                    <div v-if="index !== this.userdata.user_profile.id" class="select-item__action" @click="removeFromGuests(index)">
                                                        <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0303 3.96967C14.3232 4.26256 14.3232 4.73744 14.0303 5.03033L5.03033 14.0303C4.73744 14.3232 4.26256 14.3232 3.96967 14.0303C3.67678 13.7374 3.67678 13.2626 3.96967 12.9697L12.9697 3.96967C13.2626 3.67678 13.7374 3.67678 14.0303 3.96967Z" fill="#00A961"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 3.96967C4.26256 3.67678 4.73744 3.67678 5.03033 3.96967L14.0303 12.9697C14.3232 13.2626 14.3232 13.7374 14.0303 14.0303C13.7374 14.3232 13.2626 14.3232 12.9697 14.0303L3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967Z" fill="#00A961"></path>
                                                        </svg>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div @click="showSearchGuests" class="select-header__init"
                                        :class="{ 'up' : this.guests_search }">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 11.7803C4.26256 12.0732 4.73744 12.0732 5.03033 11.7803L9 7.81066L12.9697 11.7803C13.2626 12.0732 13.7374 12.0732 14.0303 11.7803C14.3232 11.4874 14.3232 11.0126 14.0303 10.7197L9.53033 6.21967C9.23744 5.92678 8.76256 5.92678 8.46967 6.21967L3.96967 10.7197C3.67678 11.0126 3.67678 11.4874 3.96967 11.7803Z" fill="#5D6167"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    <div v-show="this.guests_search" class="select__dropdown">

                                        <div class="tabs">
                                            <div class="tabs-header">
                                                <div class="tabs-header__item tabs-header__item_active">
                                                    <span class="tabs-header__text">Team Member</span>
                                                </div>
                                                <div class="tabs-header__item">
                                                    <span class="tabs-header__text">Contacts</span>
                                                </div>
                                                <div class="tabs-header__item">
                                                    <span class="tabs-header__text">Other users</span>
                                                </div>
                                            </div>

                                            <div class="tabs-body">
                                                <div class="tabs-body__item tabs-body__item_active">
                                                    <div class="select__search">
                                                    <div class="form-field  form-field_tertiary">
                                                        <div class="form-field__content">
                                                            <input type="text" placeholder="Search ...."
                                                            v-model="cardsTeamMembers"
                                                            v-on:input="SearchTeamMembersHandler"
                                                            class="form-field__input" required="">
                                                            <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="select__list">
                                                    <div class="select__item select-item"
                                                        v-show="!this.selected_guests_members[member.id]"
                                                        v-for="member in this.company.companyMembers" :key='member.id'
                                                        @click.prevent="addToGuests(member.id, member.firstname, member.lastname)">
                                                        <div class="select-item__avatar">
                                                            <img v-if="member.avatar" :src="member.avatar" alt="" />
                                                            <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                                        </div>
                                                        <div class="select-item__wysiwyg wysiwyg">
                                                            <span class="title-text">{{ member.firstname }} {{ member.lastname }}</span>
                                                            <ul>
                                                                <li class="list__item">{{ member.position}}</li>
                                                                <li class="list__item">{{ member.companyname }}</li>
                                                                <li class="list__item">{{member.country}}, {{member.city}}</li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="tabs-body__item">
                                                    <div class="select__search">
                                                    <div class="form-field  form-field_tertiary">
                                                        <div class="form-field__content">
                                                            <input type="text" placeholder="Search ...."
                                                            v-model="cardsContact"
                                                            v-on:input="SearchContactsHandler" class="form-field__input" required="">
                                                            <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="select__list">
                                                    <div class="select__item select-item"
                                                        v-show="!this.selected_guests_members[contact.id]"
                                                        v-for="contact in this.crm.contacts" :key='contact.id'
                                                        @click.prevent="addToGuests(contact.id, contact.firstname, contact.lastname)">
                                                        <div class="select-item__avatar">
                                                            <img v-if="contact.avatar" :src="contact.avatar" alt="" />
                                                            <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                                        </div>
                                                        <div class="select-item__wysiwyg wysiwyg">
                                                            <span class="title-text">{{ contact.firstname }} {{ contact.lastname }}</span>
                                                            <ul>
                                                                <li class="list__item">{{ contact.position}}</li>
                                                                <li class="list__item">{{contact.company.companyname}}</li>
                                                                <li class="list__item">{{contact.country}}, {{contact.city}}</li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="tabs-body__item">
                                                    <div class="select__search">
                                                    <div class="form-field  form-field_tertiary">
                                                        <div class="form-field__content">
                                                            <input type="text" placeholder="Search ...."
                                                            v-model="searchuser" v-on:input="searchUserHandler" class="form-field__input" required="">
                                                            <svg class="form-field__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#333333"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9572 11.9572C12.2501 11.6643 12.725 11.6643 13.0179 11.9572L16.2804 15.2197C16.5733 15.5126 16.5733 15.9875 16.2804 16.2804C15.9875 16.5733 15.5126 16.5733 15.2197 16.2804L11.9572 13.0179C11.6643 12.725 11.6643 12.2501 11.9572 11.9572Z" fill="#333333"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="select__list">
                                                    <div class="select__item select-item"
                                                        v-show="!this.selected_guests_members[contact.id]"
                                                        v-for="contact in this.company.inviteSearchResult" :key='contact.id'
                                                        @click.prevent="addToGuests(contact.id, contact.firstname, contact.lastname)">
                                                        <div class="select-item__avatar">
                                                            <img v-if="contact.avatar" :src="contact.avatar" alt="" />
                                                            <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                                        </div>
                                                        <div class="select-item__wysiwyg wysiwyg">
                                                            <span class="title-text">{{ contact.firstname }} {{ contact.lastname }}</span>
                                                            <ul>
                                                                <li class="list__item">{{ contact.position}}</li>
                                                                <li class="list__item">{{contact.company.companyname}}</li>
                                                                <li class="list__item">{{contact.country}}, {{contact.city}}</li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                </div>

                            </div>
                        </div>

                        <div class="col col_12">
                            <div class="form-field">
                                <div class="form-field__header">
                                    <label class="form-field__title">Attach file</label>
                                </div>
                                <div class="form-field__content">
                                    <div class="attach">
                                        <div class="attach__list">
                                            <div v-if="this.popups.calendarEventPopupData.attachment && !this.file && !this.removedAttach" class="attach__item attach-item">
                                                <div class="attach-item__ico">
                                                    <svg class="ico" width="18" height="18"
                                                         viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z"
                                                              fill="#00A961"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z"
                                                              fill="#00A961"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z"
                                                              fill="#00A961"/>
                                                    </svg>
                                                </div>
                                                <div class="attach-item__input">
                                                    <!-- <input type="file" id="file" class="input" ref="file" @change.prevent="handleFileUpload"> -->
                                                </div>
                                                <div class="attach-item__title">
                                                    <span class="title-text">
                                                        {{this.popups.calendarEventPopupData.attachment.user_defined_filename}}
                                                    </span>
                                                </div>
                                                <div @click="clearAttach" class="attach-item__remove">
                                                    <svg width="18" height="18"
                                                         viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z"
                                                              fill="#333333"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z"
                                                              fill="#333333"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                                                              fill="#333333"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z"
                                                              fill="#333333"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div v-show="this.file" class="attach__item attach-item">
                                                <div class="attach-item__ico">
                                                    <svg class="ico" width="18" height="18"
                                                         viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z"
                                                              fill="#00A961"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z"
                                                              fill="#00A961"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z"
                                                              fill="#00A961"/>
                                                    </svg>
                                                </div>
                                                <div class="attach-item__input">
                                                    <input type="file" id="file" class="input" ref="file" @change.prevent="handleFileUpload">
                                                </div>
                                                <div class="attach-item__title">
                                                    <span class="title-text">
                                                        {{this.file.name}}
                                                    </span>
                                                </div>
                                                <div @click="clearAttach" class="attach-item__remove">
                                                    <svg width="18" height="18"
                                                         viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z"
                                                              fill="#333333"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z"
                                                              fill="#333333"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                                                              fill="#333333"/>
                                                        <path fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z"
                                                              fill="#333333"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="attach__init" @click.prevent="uploadInit">
                                            <a href="#" class="btn btn_secondary">
                                                <span class="btn__text">Upload file</span>
                                            </a>
                                        </div>

                                        <div class="attach__wysiwyg wysiwyg">
                                            <p>
                                                Maximum allowed file size is 10MB
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- //form-field -->
                        </div>



                    </div>
                </div>
            </div>
                </form>
                <div class="popup__footer">
                    <div class="btn-group btn-group_secondary content-between">
                        <a href="" @click.prevent="closePopup" class="btn btn_secondary">
                            <span class="btn__text">Cancel</span>
                        </a>
                        <a href="#" @click.prevent="saveEvent" class="btn btn_primary">
                            <span class="btn__text">Save event</span>
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store'
// import axios from 'axios'

export default {
    name: "CalendarEventPopup",
    components: {

    },
    data() {
        return {
            guests_search: false,
            selected_guests_members:{},
            file: '',
            removedAttach: false,
            cardsContact: '',
            cardsTeamMembers: '',
            errors:{
                title: false,
                description: false,
                date: false,
                time: false,
                duration: false,
                guests: false,
                show: false
            },
            timepick: '',
            editableEvent: {},
            update: true,
            searchuser: ''
        }
    },
    methods: {
        restoreData() {
            let data = {};
            data.title = this.$refs.title.value;
            data.description = this.$refs.description.value;
            data.date = this.$refs.date.value;
            data.hour = this.$refs.time.value;
            data.duration = this.$refs.duration.value;
            data.id = this.calendar.calendarViewEvent.id;
            this.popups.calendarEventPopupData = data;
        },
        showSearchGuests: function() {
            this.restoreData();
            this.guests_search = !this.guests_search;
            this.tabsInit();
        },
        getLoaded(){
            if (jQuery(".tabs-header").length) {
                this.tabsInit();
            }

            jQuery(".form-field__input_calendar").each(function() {
                jQuery(this).datepicker({
                    minDate: 0,
                    dayNamesShort: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
                    prevText: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 19L8 12L15 5" stroke="#183246" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    nextText: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 5L16 12L9 19" stroke="#183246" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                });
            });

            jcf.setOptions('Select', {
                wrapNative: false,
                useCustomScroll: false,
                fakeDropInBody: false,
                multipleCompactStyle: true
            });

            jcf.replaceAll();
        },
        tabsInit: function() {
            let position, tabsBodyItem = jQuery(".tabs-body__item");
            jQuery('.tabs-header').on("click", ".tabs-header__item", function () {
                position = jQuery(this).index();
                jQuery(this).addClass("tabs-header__item_active").siblings(".tabs-header__item").removeClass("tabs-header__item_active");
                tabsBodyItem.eq(position).addClass("tabs-body__item_active").siblings(tabsBodyItem).removeClass("tabs-body__item_active");
            });
        },
        closePopup: function() {
            store.dispatch('SHOW_POPUP', { popup: 'calendarEventPopup', show: false});
            this.selected_guests_members = {};
            this.update = true;
            this.file = '';
            this.resetErrors();
        },
        SearchContactsHandler: function() {
            if(this.cardsContact.length >= 1) {
                store.dispatch('SEARCH_CRM_CONTACTS', this.cardsContact);
            } else {
                store.dispatch('GET_CRM_CONTACTS');
            }
        },
        SearchTeamMembersHandler: function() {
            if(this.cardsTeamMembers.length >= 1) {
                store.dispatch('SEARCH_COMPANY_TEAM_MEMBERS', this.cardsTeamMembers);
            } else {
                store.dispatch('GET_COMPANY_TEAM_MEMBERS');
            }
        },

        searchUserHandler: function() {
            if(this.searchuser.length >= 1) {
                store.dispatch('SEARCH_USER_ON_PLATFORM', this.searchuser);
            } else {
                store.dispatch('SEARCH_USER_ON_PLATFORM');
            }
        },

        addToGuests: function(id, name, lastname) {
            this.restoreData();
            this.selected_guests_members[id] = name + ' ' + lastname;
        },
        removeFromGuests: function(id) {
            delete this.selected_guests_members[id];
        },
        uploadInit: function() {
            this.restoreData();
            const elem = this.$refs.file;
            elem.click();
        },
        handleFileUpload: function() {
            this.restoreData();
            this.file = this.$refs.file.files[0];
            this.removedAttach = false;
        },
        clearAttach: function() {
            this.restoreData();
            this.file = '';
        },
        resetErrors() {
            this.errors.title = false;
            this.errors.description = false;
            this.errors.date = false;
            this.errors.time = false;
            this.errors.duration = false;
            this.errors.guests = false;
            this.errors.show = false;
        },
        async saveEvent() {
            this.resetErrors();
            this.restoreData();

            if(!this.$refs.title.value){
                this.errors.title = 'This field is required.';
                this.errors.show = true;
            }

            if(!this.$refs.description.value){
                this.errors.description = 'This field is required.';
                this.errors.show = true;
            }

            if(!this.$refs.date.value){
                this.errors.date = 'This field is required.';
                this.errors.show = true;
            }

            if(!this.$refs.time.value){
                this.errors.time = 'This field is required.';
                this.errors.show = true;
            }

            if(!this.$refs.duration.value){
                this.errors.duration = 'This field is required.';
                this.errors.show = true;
            }

            if(this.errors.show) {
                return;
            }

            if(this.file) {
                await store.dispatch('PUT_FILE', this.file);
            }

            let data = {};
            let guest = '';
            let participants = [];
            data.title = this.$refs.title.value;
            data.description = this.$refs.description.value;
            data.day = new Date(this.$refs.date.value).getDate();
            data.month = new Date(this.$refs.date.value).getMonth()+1;
            data.year = new Date(this.$refs.date.value).getFullYear();
            data.tzname = Intl.DateTimeFormat().resolvedOptions().timeZone;
            data.hour = parseInt(this.$refs.time.value.split(":")[0]);
            data.minute = parseInt(this.$refs.time.value.split(":")[1]);
            data.duration = parseInt(this.$refs.duration.value.split(":")[0])*60+parseInt(this.$refs.duration.value.split(":")[1]);
            for (guest in this.selected_guests_members) {
                participants.push(guest);
            }
            data.participants = participants;

            if(this.popups.calendarEventPopupData.attachment && !this.file && !this.removedAttach){
                data.attachment_id = this.popups.calendarEventPopupData.attachment.id;
            } else if(this.removedAttach) {
                data.attachment_id = "";
            } else {
                if(this.userdata.put_file === false)
                    data.attachment_id = "";
                else
                    data.attachment_id = this.userdata.put_file.id;
            }

            if(this.popups.calendarEventPopupData.id) {
                data.id = this.popups.calendarEventPopupData.id;
                await store.dispatch('UPDATE_CALENDAR_EVENT', data);
                await store.dispatch('SET_VIEW_EVENT', this.popups.calendarEventPopupData.id);
                store.dispatch('SHOW_POPUP', { popup: 'calendarShowEventPopup', show: false});
            } else {
                await store.dispatch('ADD_CALENDAR_EVENT', data);
            }

            store.dispatch('SHOW_POPUP', { popup: 'calendarEventPopup', show: false});
            store.dispatch('GET_CALENDAR_EVENT');
            this.selected_guests_members = {};
            this.update = true;
            this.file = '';
            this.resetErrors();
            this.userdata.put_file = '';
        },
        getTimepick: function() {
            let times = [];
            let interval = 15;
            let hour = '';
            let minute = '';
            for (let h = 0; h < 24; h++) {
                if(h<10) { hour = '0'+h; } else { hour = h;}
                for (let m = 0; m < 60; m=m+interval) {
                    if(m<10) { minute = '0'+m; } else { minute = m; }
                    times.push(hour+":"+minute);
                }
            }
            this.timepick = times;
        },
    },
    computed: {
        popups: () => store.state.popups,
        userdata: () => store.state.user,
        company: () => store.state.company,
        crm: () => store.state.crm,
        calendar: () => store.state.calendar,
    },
    mounted(){
        this.getLoaded();
        this.getTimepick();
    },
    updated(){
        let upd= true;
        if(this.userdata.user_profile.id && this.update){
            this.selected_guests_members[this.userdata.user_profile.id] = this.userdata.user_profile.firstname + ' ' + this.userdata.user_profile.lastname;
            this.update = false;
        }

        if(this.popups.calendarEventPopupData.participants && upd) {
            this.selected_guests_members = this.popups.calendarEventPopupData.participants;
            upd = false;
        }


        jcf.destroyAll();
        jcf.setOptions('Select', {
            wrapNative: false,
            useCustomScroll: false,
            fakeDropInBody: false,
            multipleCompactStyle: true
        });
        jcf.replaceAll();
        upd = false;
    },
    watch: {
        'popups.calendarEventPopup'(newVal) {
            if (newVal) setTimeout(() => this.$refs.title.focus(), "500")
        },
    },
}

</script>
