<template>
<div :class="{ 'popup-visible': this.popups.fullReviewsViewPopup }">
  <div class="popup-wrap" @click.self="closePopup">
    <div :class="{'active':this.popups.fullReviewsViewPopup}" class="popup popup_tertiary popup_posts mh_100">

      <div class="popup__header multilevel sticky_header">
        <div class="popup-action">
          <div class="popup-action__item_back" @click="closePopup">
            <svg class="ico" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.18036 0.96967C6.47325 1.26256 6.47325 1.73744 6.18036 2.03033L2.21068 6L6.18036 9.96967C6.47325 10.2626 6.47325 10.7374 6.18036 11.0303C5.88746 11.3232 5.41259 11.3232 5.11969 11.0303L0.619694 6.53033C0.326801 6.23744 0.326801 5.76256 0.619694 5.46967L5.11969 0.96967C5.41259 0.676777 5.88746 0.676777 6.18036 0.96967Z" fill="#00A961"></path>
            </svg>
            <span>Back</span>
          </div>
          <div class="popup-action__item popup-action__item_remove" @click="closePopup">
            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path>
            </svg>
          </div>
        </div>
        <div class="popup__title">
          <span class="title-text">Read Reviews</span>
        </div>
      </div>


      <div class="popup__content">

        <div class="posts">
          <div v-if="employee.expert_reviews.length" class="posts__list">

            <div v-for="review in employee.expert_reviews" :key="review.id" class="post__item">
              <div class="post-item__header">
                <div class="post-item__avatar">
                  <img v-if="review.user_id_from_info.avatar" :src="review.user_id_from_info.avatar" alt="" />
                  <img v-else src="@/assets/images/avatar-default.png" alt="Start-Up World" />
                </div>
                <div class="wysiwyg">
                  <h3 class="mb_0">{{review.user_id_from_info.name}}</h3>
                  <ul class="mb_0">
                    <li>{{review.user_id_from_info.country}}, {{review.user_id_from_info.city}}</li>
                    <li>Posted {{review.date}}</li>
                  </ul>
                  <div class="box__actions mr_big-desktop-auto review_card_actions">
                      <RatingStars :stars="review.rating"></RatingStars>
                  </div>
                </div>


                <div v-if="userdata.user_profile.id == review.user_id_from" class="post-action ml-auto align-self-left">
                  <a @click.prevent="editReview(review)" href="#" class="post-action__item post-action__item_edit">
                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z" fill="#00A961"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.1582C14.777 2.1582 14.5631 2.24681 14.4053 2.40452L7.42724 9.38263L7.03079 10.9684L8.61658 10.572L15.5947 3.59386C15.7524 3.43615 15.841 3.22224 15.841 2.99919C15.841 2.77615 15.7524 2.56224 15.5947 2.40452C15.437 2.24681 15.2231 2.1582 15 2.1582ZM13.3447 1.34386C13.7837 0.904842 14.3791 0.658203 15 0.658203C15.6209 0.658203 16.2163 0.904842 16.6553 1.34386C17.0944 1.78288 17.341 2.37832 17.341 2.99919C17.341 3.62006 17.0944 4.2155 16.6553 4.65452L9.53035 11.7795C9.43423 11.8756 9.31379 11.9438 9.18192 11.9768L6.18192 12.7268C5.92634 12.7907 5.65597 12.7158 5.46969 12.5295C5.2834 12.3432 5.20851 12.0729 5.27241 11.8173L6.02241 8.81729C6.05538 8.68542 6.12357 8.56498 6.21969 8.46886L13.3447 1.34386Z" fill="#00A961"></path>
                    </svg>
                  </a>
                  <a @click.prevent="deleteReview(review)" href="#" class="post-action__item post-action__item_trash">
                    <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#00A961"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#00A961"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#00A961"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#00A961"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="post-item__content">
                <div class="wysiwyg">
                  <p>{{review.comment}}</p>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>
</div>
</template>

<script>
import store from '@/store'
import RatingStars from '@/components/profile/RatingStars'

export default {
  name: 'FullReviewsView',
  components: {
    RatingStars
  },
  props: {},
  data() {
    return {}
  },
  methods: {
    closePopup() {
      store.dispatch('SHOW_POPUP', { popup: 'fullReviewsViewPopup', show: false });
    },
    editReview(review) {
      let data = {
          id: review.type === 'company' ? review.company_id : review.user_id,
          review_id: review.id,
          type: review.type,
          stars: review.rating,
          comment: review.comment,
        };
          store.dispatch('SHOW_POPUP', { popup: 'fullReviewAddPopup', show: true, data: data });
    },
    deleteReview(review) {
        store.dispatch('SET_MY_REVIEW', review);
        store.dispatch('SHOW_POPUP', {
            popup: 'confirmPopup',
            show: 'Are you sure you want to delete your review?',
            route: '',
            name: 'REMOVE_REVIEW',
            data: '',
        });
    },

  },
  computed: {
    popups: () => store.state.popups,
    employee: () => store.state.employee,
    userdata: () => store.state.user,
  },
}
</script>
