import instance from './index';
import suwAPI from '../config/config'

const companyService = {

  getMyCompany() {
    return instance({ requiresAuth: true }).get(suwAPI.apiGetCompany);
  },

  getCompany(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiGetCompany+'/'+data);
  },

  industry() {
    return instance().get(suwAPI.apiIndustry);
  },

  industryBusiness() {
    return instance().get(suwAPI.apiIndustryBusiness);
  },

  industryInvestor() {
    return instance().get(suwAPI.apiIndustryInvestor);
  },

  industrySearch(data) {
    return instance().get(suwAPI.apiCompanySearch+data);
  },

  putCompany(data) {
    let Companyid = data.id;
    delete data.id;
    return instance({ requiresAuth: true }).put(suwAPI.apiGetCompany+'/'+Companyid, data);
  },

  getMedia(data) {
    return instance({ requiresAuth: true }).get(suwAPI.apiGetCompany+'/'+data+'/media');
  },

  postMedia(data) {
    let compid = data.compid;
    delete data.compid;
    return instance({ requiresAuth: true }).post(suwAPI.apiGetCompany+'/'+compid+'/media', data);
  },

  putMedia(data) {
    let compid = data.compid;
    let id = data.id;
    delete data.compid;
    delete data.id;
    return instance({ requiresAuth: true }).put(suwAPI.apiGetCompany+'/'+compid+'/media/'+id, data);
  },

  removeMedia(id) {
    return instance({ requiresAuth: true }).delete(suwAPI.apiGetCompany+'/'+id+'/media');
  },

  getMembers(id){
    return instance({ requiresAuth: true }).get(suwAPI.apiGetCompany+'/'+id+'/members');
  },

  searchMembers(id, slug) {
    return instance({ requiresAuth: true }).get(suwAPI.apiGetCompany+'/'+id+'/members/search/'+slug);
  },

  removeMember(company_id, member_id){
    return instance({ requiresAuth: true }).delete(suwAPI.apiGetCompany+'/'+company_id+'/members/'+member_id);
  },

  sendInvite(company, user, role) {
    return instance({ requiresAuth: true }).put(suwAPI.apiGetCompany+'/'+company+'/members/'+user, role);
  },

  sendInviteEmail(company, data) {
    return instance({ requiresAuth: true }).post(suwAPI.apiGetCompany+'/'+company+'/members', data);
  },

  joinReject(link) {
    console.log(link);
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiBaseUrl+link);
  },

  joinAccept(link) {
    console.log(link);
    return instance({ requiresAuth: true, formData: true }).post(suwAPI.apiBaseUrl+link);
  },

  setLike(data){
      let compid = data.compid;
      delete data.compid;
      return instance({ requiresAuth: true }).post(suwAPI.apiGetCompany+'/'+compid+'/like', data);
  },

  requestMedia(data){
    return instance({ requiresAuth: true }).post(suwAPI.apiGetCompany+'/request-media/' + data.id);
  },

}

export default companyService;